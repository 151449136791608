/**
 * Descrive a quale entità appartiene il documento
 */
export enum TipoEntita {
  /**
   * Nessuna
   */
  Nessuna = 1,
  /**
   * SchedaCup
   */
  SchedaCup = 2,
  /**
   * Determina
   */
  Determina = 3,
  /**
   * Delibera
   */
  Delibera = 4,
  /**
   * Fattura
   */
  Fattura = 5,
  /**
   * Ricevuta
   */
  Ricevuta = 6,
  /**
   * CedolinoPaga
   */
  CedolinoPaga = 7,
  /**
   * Liquidazione
   */
  Liquidazione = 27,
  /**
   * Mandato
   */
  Mandato = 8,
  /**
   * Quietanza
   */
  Quietanza = 9,
  /**
   * ProgettoDefinitivo DOCUMENTO
   */
  ProgettoDefinitivo = 10,
  /**
   * ProgettoEsecutivo DOCUMENTO
   */
  ProgettoEsecutivo = 11,
  /**
   * ProgettoIntergrato DOCUMENTO
   */
  ProgettoIntegrato = 12,
  /**
   * Ordine Di Servizio DOCUMENTO
   */
  OrdineDiServizio = 13,
  /**
   * SAL DOCUMENTO
   */
  SAL = 14,
  /**
   * Annotazione
   */
  Annotazione = 15,
  /**
   * QE Bozza
   */
  QuadroEconomicoBozza = 16,
  /**
   * QE Attuale
   */
  QuadroEconomicoAttuale = 17,
  /**
   * QE Storico
   */
  QuadroEconomicoStorico = 18,
  /**
   * Incassato
   */
  Incassato = 19,
  /**
   * FonteFinanziamento
   */
  FonteFinanziamento = 20,
  /**
   * Entrata Corrispondenza
   */
  Entrata = 21,
  /**
   * Uscita Corrispondenza
   */
  Uscita = 22,
  /**
   * Delibera di Giunta
   */
  DeliberaDiGiunta = 23,
  /**
   * Delibera di Consiglio
   */
  DeliberaDiConsiglio = 24,
  /**
   * Contratto
   */
  Contratto = 25,
  /**
   * Altro
   */
  Altro = 26,
}

export enum TipoChiave {
  Nessuno = 0,
  Testo = 1,
  Data = 2,
 
  Numero = 4,
  Valuta = 5,
  Lista = 6,
}

/**
 * Indica l'area di ricerca. Se non inserito ricerca su tutto
 */
export enum AreaDocumentale {
  /**
   * Esecuzione
   */
  Esecuzione = 1,
  /**
   * FonteFinanaziamento
   */
  FonteFinanaziamento = 2,
  /**
   * Annotazioni
   */
  Annotazioni = 3,
}

/**
 * Indica il nome della Tab 
 */
export enum NomeTab {
  /**
   * Tab documenti di Esecuzione
   */
  Documenti = 0,
  /**
   * Tab fonti di finanziamento
   */
  Fonte = 1,
  /**
   * Tab progetto
   */
  Progetto = 2,
  /**
   * Tab Gara
   */
  Gara = 3,
}

/**
 * La lista dei modelli
 */
export interface NomeModello {
  nomeModello: string;
}

/**
 *
 */
export interface Tipo {
  tipo: string;
}

export class DocumentoResponse {
  success: boolean;
  dto: Documento;
}

/**
 * Descrive il documento
 */
export class Documento {
  /**
   * Id del cup associato al documento
   */
  cupid?: number;
  /**
   * Indica l'id dell'entità a cui è collegato
   */
  entityID?: number;
  /**
   * Indica il tipo di entità a cui è collegato
   */
  entityTipo?: TipoEntita;
  /**
   * File da archiviare
   */
  file: File;
  /**
   * Identificativo del documanto
   */
  id: number;
  /**
   * Elenco delle chiavi associate
   */
  keys: DocumentoKeyValue;
  /**
   * ID del modello documentale
   */
  modelloID: number;
  /**
   * Nome del file
   */
  nomeFile?: string;
  /**
   * Indica la dimensioni in byte del documento
   */
  sizeByte?: number;
  /**
   * Nome del file
   */
  changeNomeFile?: string;
  /**
   * Id del documento recuperato dalla cartella monitorata
   */
  idDocumentoBozza?: number;
  /**
   * Identificativo sezione
   */
  idSezione?: number;
  /**
   * Modello associato al documento
   */
  modello?: ModelloDocumentale;
  /**
   * Protocollo documento
   */
  protocollo?: string;
  dataInserimento?: Date;
  dataDocumento?: Date;

  constructor(modelloID?: number, modello?: ModelloDocumentale) {
    this.id = null;
    this.keys = null;
    this.modelloID = modelloID || null;
    this.file = null;
    this.nomeFile = null;
    this.entityID = null;
    this.entityTipo = TipoEntita.Nessuna;
    this.cupid = null;
    this.sizeByte = null;
    this.changeNomeFile = '';
    this.idDocumentoBozza = null;
    this.modello = modello || null;
    this.idSezione = 0;
  }
}

/**
 * Descrive i campi chiave valore associati al documento
 */
export class DocumentoKeyValue {
  /**
   * Identificativo dell'entità Key/Value
   */
  id: number;
  /**
   * Id del del documento
   */
  idDocumento?: number;
  /**
   * Identificativo dell'entità Key/Value
   */
  entityId?: number;
  /**
   * Id del modello
   */
  modelloId?: number;
  /**
   * Nome modello
   */
  nomeModello?: string;
  /**
   * Valore della chiave 1
   */
  key1_Value?: string;
  /**
   * Valore della chiave 2
   */
  key2_Value: Date;
  /**
   * Valore della chiave 3
   */
  key3_Value?: string;
  /**
   * Valore della chiave 4
   */
  key4_Value?: string;
  /**
   * Valore della chiave 5
   */
  key5_Value?: string;
  /**
   * Valore della chiave 6
   */
  key6_Value?: string;
  /**
   * Valore della chiave 7
   */
  key7_Value?: string;
  /**
   * Valore della chiave 8
   */
  key8_Value?: string;
  /**
   * Valore della chiave 9
   */
  key9_Value?: string;
  /**
   * Valore della chiave 10
   */
  key10_Value?: string;
  /**
   * Valore della chiave 11
   */
  key11_Value?: string;
  /**
   * Se true key1_value è visualizzato in griglia
   */
  key1_Attivo?: boolean;
  /**
   * Se true key2_value è visualizzato in griglia
   */
  key2_Attivo?: boolean;
  /**
   * Se true key3_value è visualizzato in griglia
   */
  key3_Attivo?: boolean;
  /**
   * Se true key4_value è visualizzato in griglia
   */
  key4_Attivo?: boolean;
  /**
   * Se true key5_value è visualizzato in griglia
   */
  key5_Attivo?: boolean;
  /**
   * Se true key6_value è visualizzato in griglia
   */
  key6_Attivo?: boolean;
  /**
   * Se true key7_value è visualizzato in griglia
   */
  key7_Attivo?: boolean;
  /**
   * Se true key8_value è visualizzato in griglia
   */
  key8_Attivo?: boolean;
  /**
   * Se true key9_value è visualizzato in griglia
   */
  key9_Attivo?: boolean;
  /**
   * Se true key10_value è visualizzato in griglia
   */
  key10_Attivo?: boolean;
  /**
   * Stringa che contiene composizione chiave/valore delle chiavi attive
   */
  allKeyValueActive?: string;
  /**
   * Stringa che contiene composizione chiave/valore delle chiavi attive per il tooltip
   */
  allKeyValueTooltip?: string;
  /**
   * Nome del file
   */
  nomeFile?: string;
  /**
   * Protocollo documento
   */
  protocollo?: string;

  constructor(
    id: number,
    idDocumento: number,
    key1_Value: string,
    key2_Value: Date,
    key3_Value: string,
    key4_Value: string,
    key5_Value: string,
    key6_Value: string,
    key7_Value: string,
    key8_Value: string,
    key9_Value?: string,
    key10_Value?: string,
    key11_Value?: string,
    key1_Attivo?: boolean,
    key2_Attivo?: boolean,
    key3_Attivo?: boolean,
    key4_Attivo?: boolean,
    key5_Attivo?: boolean,
    key6_Attivo?: boolean,
    key7_Attivo?: boolean,
    key8_Attivo?: boolean,
    key9_Attivo?: boolean,
    key10_Attivo?: boolean,
    nomeFile?: string,
    entityId?: number,
    modelloId?: number,
    nomeModello?: string,
    allKeyValueActive?: string,
    allKeyValueTooltip?: string,
    protocollo?: string
  ) {
    this.id = id || 0;
    this.idDocumento = idDocumento || 0;
    this.key1_Value = key1_Value || null;
    this.key2_Value = key2_Value || null;
    this.key3_Value = key3_Value || null;
    this.key4_Value = key4_Value || null;
    this.key5_Value = key5_Value || null;
    this.key6_Value = key6_Value || null;
    this.key7_Value = key7_Value || null;
    this.key8_Value = key8_Value || null;
    this.key9_Value = key9_Value || null;
    this.key10_Value = key10_Value || null;
    this.key11_Value = key11_Value || null;
    this.key1_Attivo = key1_Attivo || true;
    this.key2_Attivo = key2_Attivo || true;
    this.key3_Attivo = key3_Attivo || true;
    this.key4_Attivo = key4_Attivo || true;
    this.key5_Attivo = key5_Attivo || true;
    this.key6_Attivo = key6_Attivo || true;
    this.key7_Attivo = key7_Attivo || true;
    this.key8_Attivo = key8_Attivo || true;
    this.key9_Attivo = key9_Attivo || true;
    this.key10_Attivo = key10_Attivo || true;
    this.nomeFile = nomeFile || '';
    this.entityId = entityId || null;
    this.modelloId = modelloId || null;
    this.nomeModello = nomeModello || null;
    this.allKeyValueActive = allKeyValueActive || null;
    this.allKeyValueTooltip = allKeyValueTooltip || null;
    this.protocollo = protocollo || '';
  }
}

/**
 * Descrive i campi che servono per l'update delle key del documento
 */
export class DocumentoKeyValueUpdate {
  /**
   * Il tipo di entità del documento
   */
  entita: TipoEntita;
  /**
   * L'id dell'entità
   */
  entitaID: number;
  /**
   * Le chiavi modello modificate
   */
  chiaviModello: DocumentoKeyValue;
  /**
   * L'id del documento
   */
  documentoID?: number;

  constructor(
    entita: TipoEntita,
    entitaID: number,
    chiaviModello: DocumentoKeyValue,
    documentoID?: number
  ) {
    this.entita = entita || null;
    this.entitaID = entitaID || null;
    this.chiaviModello = chiaviModello || undefined;
    this.documentoID = documentoID || null;
  }
}

export class KeyActive {
  /**
   * Se true key1_value è visualizzato in griglia
   */
  key1_Attivo?: boolean;
  /**
   * Se true key2_value è visualizzato in griglia
   */
  key2_Attivo?: boolean;
  /**
   * Se true key3_value è visualizzato in griglia
   */
  key3_Attivo?: boolean;
  /**
   * Se true key4_value è visualizzato in griglia
   */
  key4_Attivo?: boolean;
  /**
   * Se true key5_value è visualizzato in griglia
   */
  key5_Attivo?: boolean;
  /**
   * Se true key6_value è visualizzato in griglia
   */
  key6_Attivo?: boolean;
  /**
   * Se true key7_value è visualizzato in griglia
   */
  key7_Attivo?: boolean;
  /**
   * Se true key8_value è visualizzato in griglia
   */
  key8_Attivo?: boolean;
  /**
   * Se true key9_value è visualizzato in griglia
   */
  key9_Attivo?: boolean;
  /**
   * Se true key10_value è visualizzato in griglia
   */
  key10_Attivo?: boolean;
}

export interface KeyEtichetta {
  key1_Etichetta?: string;
  key2_Etichetta?: string;
  key3_Etichetta?: string;
  key4_Etichetta?: string;
  key5_Etichetta?: string;
  key6_Etichetta?: string;
  key7_Etichetta?: string;
  key8_Etichetta?: string;
  key9_Etichetta?: string;
  key10_Etichetta?: string;
}

/**
 * Classe che descrive il documento da salvare nella cartella monitorata
 */
export class DraftDocument {
  folderId: string;
  file: File;
}

export class DeleteResponse {
  success: boolean;
  message: string;
}

export class UpdateResponse {
  success: boolean;
  message: string;
  id: number;
}

export class VerificaModello {
  success: boolean;
  message: string;
}

export class SezioniDocumentResponse {
  success: boolean;
  dtos: SezioniDocument[];
}

export class SezioniDocument {
  id: number;
  nome: string;

  constructor(id?: number, nome?: string) {
    this.id = id || null;
    this.nome = nome || null;
  }
}

export class ModelloSezione {
  idSezione: number;
  idModello: number;
  constructor(idSezione?: number, idModello?: number) {
    this.idModello = idModello || 0;
    this.idSezione = idSezione || 0;
  }
}

/**
 * Descrive il documento
 */
export class ModelloDocumentale {
  id: number;
  nome: string;
  systemModello: boolean;
  attivo: boolean;
  keysModelloDocumentale: ModelloDocumentaleKeys;
  modelliSezione: ModelloSezione[];

  constructor(
    nome: string,
    modelliSezione: ModelloSezione[],
    keysModelloDocumentale: ModelloDocumentaleKeys,
    id?: number,
    attivo?: boolean,
    systemModello?: boolean
  ) {
    this.id = id || 0;
    this.nome = nome || '';
    this.systemModello = systemModello || false;
    this.attivo = attivo;
    this.keysModelloDocumentale = keysModelloDocumentale || undefined;
    this.modelliSezione = modelliSezione || [];
  }
}

/**
 * Chiavi che identificano il modello documentale
 */
export class ModelloDocumentaleKeys {
  id: number;
  key1_Attivo?: boolean;
  key1_Tipo: TipoChiave;
  key1_Etichetta: string;
  key2_Attivo?: boolean;
  key2_Tipo: TipoChiave;
  key2_Etichetta: string;
  key3_Attivo?: boolean;
  key3_Etichetta: string;
  key3_Tipo: TipoChiave;
  key4_Attivo?: boolean;
  key4_Tipo: TipoChiave;
  key4_Etichetta: string;
  key5_Attivo?: boolean;
  key5_Tipo: TipoChiave;
  key5_Etichetta: string;
  key6_Attivo?: boolean;
  key6_Tipo: TipoChiave;
  key6_Etichetta: string;
  key7_Attivo?: boolean;
  key7_Tipo: TipoChiave;
  key7_Etichetta: string;
  key8_Tipo: TipoChiave;
  key8_Etichetta: string;
  key8_Attivo?: boolean;
  key9_Tipo: TipoChiave;
  key9_Etichetta: string;
  key9_Attivo?: boolean;
  key10_Tipo: TipoChiave;
  key10_Etichetta: string;
  key10_Attivo?: boolean;

  key1_CustomValues?: string;
  key2_CustomValues?: string;
  key3_CustomValues?: string;
  key4_CustomValues?: string;
  key5_CustomValues?: string;
  key6_CustomValues?: string;
  key7_CustomValues?: string;
  key8_CustomValues?: string;
  key9_CustomValues?: string;
  key10_CustomValues?: string;

  key1_Obbligatoria?: boolean;
  key2_Obbligatoria?: boolean;
  key3_Obbligatoria?: boolean;
  key4_Obbligatoria?: boolean;
  key5_Obbligatoria?: boolean;
  key6_Obbligatoria?: boolean;
  key7_Obbligatoria?: boolean;
  key8_Obbligatoria?: boolean;
  key9_Obbligatoria?: boolean;
  key10_Obbligatoria?: boolean;
}

/**
 * Classe che filtra i documenti nella griglia
 */
export class DocumentoSearch {
  /**
   * Id del cup
   */
  cupId?: number;
  /**
   * Id del documento
   */
  id?: number;
  /**
   * IDs del modello documentale
   */
  modelloIDs?: number[];
  /**
   * Indica il tipo di entità a cui è collegato
   */
  entityId?: number;
  /**
   * Indica il tipo di entità a cui è collegato
   */
  entityTipo?: TipoEntita;
  /**
   * Indica l'area di ricerca
   */
  sezione?: SezioniDocument;
  /**
   * La colonna da ordinare
   */
  colonnaOrdinamento?: ColOrdinamento;
  /**
   * il tipo di ordinamento
   */
  ordinamento?: TipoOrdinamento;
  /**
   * Filtro ricera da da Inserimento inizio
   */
  dataInserimentoStart?: Date;
  /**
   * Filtro ricera da da Inserimento fine
   */
  dataInserimentoStop?: Date;
  /**
   * Filtro ricerca documento start
   */
  dataDocumentoStart?: Date;
  /**
   * Filtro ricerca documento end
   */
  dataDocumentoStop?: Date;

  constructor(
    cupId?: number,
    id?: number,
    modelloIDs?: number[],
    entityId?: number,
    sezione?: SezioniDocument,
    entityTipo?: TipoEntita,
    colonnaOrdinamento?: ColOrdinamento,
    ordinamento?: TipoOrdinamento,
    dataInserimentoStart?: Date,
    dataInserimentoStop?: Date,
    dataDocumentoStart?: Date,
    dataDocumentoStop?: Date,
  ) {
    this.cupId = cupId || null;
    this.sezione = sezione || null;
    this.id = id || null;
    this.modelloIDs = modelloIDs || [];
    this.entityId = entityId || null;
    this.entityTipo = entityTipo || null;
    this.colonnaOrdinamento = colonnaOrdinamento || null;
    this.ordinamento = ordinamento || TipoOrdinamento.Nessuno;
    this.dataInserimentoStart = dataInserimentoStart || null;
    this.dataInserimentoStop = dataInserimentoStop || null;
    this.dataDocumentoStart = dataDocumentoStart || null;
    this.dataDocumentoStop = dataDocumentoStop || null;
  }
}

export enum TipoOrdinamento {
  Nessuno = 0,
  Ascendente = 1,
  Discendente = 2,
}

export enum ColOrdinamento {
  Key1 = 1,
  Key2 = 2,
  Key3 = 3,
  Key4 = 4,
  Key5 = 5,
  Key6 = 6,
  Key7 = 7,
  Key8 = 8,
  Key9 = 9,
  Key10 = 10,
  DataInserimento = 11,
  Progressivo = 12,
  Sezione = 13,
  Modello = 14,
  DataDocumento = 15,
  NomeFile = 16
}

export class DocumentoCompleteResponse {
  success: boolean;
  dto: DocumentoComplete;
}
/**
 * Classe che descrive il documento caricato
 */
export class DocumentoComplete {
  /**
   * Grandezza del documento
   */
  blob: any;
  /**
   * Id del documento
   */
  id: number;
  /**
   * Nome del file
   */
  nomeFile?: string;
  /**
   * Il tipo di file
   */
  mediaType: string;
  /**
   * Dimensione del file in Megabyte
   */
  size_megabyte: number;
  /**
   * Id del documento bozza,
   * se questo id è presente alla crazione verrà effettuata l'oprazione di salvataggio del
   * documento dalla cartella monitorata
   */
  idDocumentoBozza: number;

  constructor(
    blob?: any,
    id?: number,
    nomeFile?: string,
    mediaType?: string,
    size_megabyte?: number,
    idDocumentoBozza?: number
  ) {
    this.blob = blob || undefined;
    this.id = id || null;
    this.nomeFile = nomeFile || null;
    this.mediaType = mediaType || null;
    this.size_megabyte = size_megabyte || null;
    this.idDocumentoBozza = idDocumentoBozza || null;
  }
}

export interface DocumentoKeyControl {
  key1_Control: any;
  key2_Control: any;
  key3_Control?: any;
  key4_Control?: any;
  key5_Control?: any;
  key6_Control?: any;
  key7_Control?: any;
  key8_Control?: any;
  key9_Control?: any;
  key10_Control?: any;
}
