import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { ModelloDocumentale, ModelloDocumentaleKeys, ModelloSezione, SezioniDocument, TipoChiave } from 'src/app/model/documento';
import { ModelloDocumentaleCustom } from 'src/app/model/scenari';
import { DocumentiService } from 'src/app/service/documenti.service';
import { SharedService } from 'src/app/service/shared.service';
import { DialogModel } from '../shared/rbk-modal/rbk-modal';
import { Subject } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';
import * as _ from 'lodash';

export class ModelliSelectList {
  public id: number;
  public descrizione: string;
}

@Component({
    selector: 'app-modelli-documentali',
    templateUrl: './modelli-documentali.html',
    styleUrls: ['./modelli-documentali.scss'],
  })
  export class ModelliDocumentaliComponent implements OnInit {
    public sezioni: SezioniDocument[] = [];
    public sezioneSelezionata: SezioniDocument;
    public sezioniSelezionate: SezioniDocument[];
    public caricamentoGrid: boolean = false;
    public modelloDocumentaleSelezionato: ModelloDocumentale;
    public modelliDocumentale: ModelloDocumentale[] = [];
    public datiModelloDocumentale: ModelloDocumentaleCustom;
    public visible: boolean = false;
    public inestazioneModello: string;
    public formGroup: FormGroup;
    public tipoChiavi: any[] = [
      {label: TipoChiave[TipoChiave.Nessuno], value: null},
      {label: TipoChiave[TipoChiave.Testo], value: TipoChiave.Testo},
      {label: TipoChiave[TipoChiave.Data], value: TipoChiave.Data},    
      {label: TipoChiave[TipoChiave.Numero], value: TipoChiave.Numero},
      {label: TipoChiave[TipoChiave.Valuta], value: TipoChiave.Valuta},
      {label: TipoChiave[TipoChiave.Lista], value: TipoChiave.Lista}
    ];

    public updateModello: boolean = false;
    public TipoChiave = TipoChiave;

    public dialogModel: DialogModel;
    public visibleDialog: boolean = false;

    public process: string = '';
    public processState = new Subject<string>();
    public modelloId: number;
    public isModelloActiveChange: boolean = false;
    public modelliSelect: ModelliSelectList[] = [
      {id: 1, descrizione: 'Modelli di sistema'},
      {id: 2, descrizione: 'Modelli creati'},
    ];
    public modelloSelected: ModelliSelectList;
   
   constructor(
    private _documentiService: DocumentiService,
    private _spinner: NgxSpinnerService,
    private _sharedService: SharedService){}
   
    async ngOnInit ()
    {
      localStorage.removeItem('titoloCup');
      this.sezioni = await this._sharedService.getSezioniModelloDocumentale();
      this.modelloSelected = this.modelliSelect.find(m => m.id === 2);
      await this.filterBrowse();
    }

    async filterBrowse(){
      this.modelliDocumentale = [];
      this.caricamentoGrid = true;
      let sezione = '*';
      let modelloSelected = '*';
      if(this.sezioneSelezionata) {
        sezione = this.sezioneSelezionata.nome;
      }
      if(this.modelloSelected) {
        modelloSelected = this.modelloSelected.id.toString();
      }
      const response = await this._documentiService.getModelliDocumentaliSezione(sezione, modelloSelected).toPromise();
      if(response) {          
        this.caricamentoGrid = false;
        this.modelliDocumentale = response;
      }
    }

    async modificaModelloDocumentale(){
      this._spinner.show();
      const verificaModello = await this._documentiService.getModelloUsato(this.modelloId).toPromise();
      if(verificaModello && verificaModello.success) {
        this.dialogModel = {
          title: "Attenzione",
          message: "Modello documentale già in uso. Non è possibile modificarlo",
          btnConferma: "Ok",
          hasBtnAnnulla: false
        };
        this.visibleDialog = true;
      } else {
        this.formGroup.enable();
        this.formGroup.get('key2_Tipo').disable();
        this.isModelloActiveChange = false;
      }
      this._spinner.hide();
    }

    changeActiveModello(active: boolean) {
      if(this.isModelloActiveChange) {
        this.process = 'ChangeActiveModello'
        this.dialogModel = {
          title: "Attenzione",
          message: "Vuoi rendere visibile il modello documentale?",
          btnConferma: "Ok",
          btnAnnulla: "Annulla",
          hasBtnAnnulla: true,
        };
        this.visibleDialog = true;
      }
    }

    onChangeKey(keyValue: any, keyValueTipo?: any, index?: number) {
      let key_Tipo = `key${index}_Tipo`;
      if(keyValue && !keyValueTipo) {
        this.formGroup.controls[key_Tipo].addValidators(Validators.required);
        $(`p-dropdown#key${index}_Tipo`).addClass("ng-dirty ng-invalid");
      } else {
        this.formGroup.controls[key_Tipo].clearValidators();
        $(`p-dropdown#key${index}_Tipo`).removeClass("ng-dirty ng-invalid");
      }
      this.formGroup.controls[key_Tipo].updateValueAndValidity();
    }

    valorizzaModelloDocumentale(modello?: ModelloDocumentale){
      this.caricamentoGrid = true;
      this.modelloId = modello?.id;
      this.visible = true;
      this.updateModello = true;
      this.inestazioneModello = modello ? `Modifica Modello Documentale` : `Nuovo Modello Documentale`;
      const modelloSezione = this.getSezioni(modello);
      this.sezioniSelezionate = this.sezioni.filter(x => modello?.modelliSezione?.find(m => m.idSezione === x.id))
      this.formGroup = new FormGroup({
          id: new FormControl(modello?.id || 0),
          nome: new FormControl(modello?.nome, Validators.required),
          attivo: new FormControl(modello?.attivo || false),
          modelliSezione: new FormControl(this.sezioniSelezionate, Validators.required),
          systemModello: new FormControl(modello?.systemModello || false),

          key1_Tipo: new FormControl(modello?.keysModelloDocumentale?.key1_Tipo || ''),
          key1_Etichetta: new FormControl(modello?.keysModelloDocumentale?.key1_Etichetta),
          key1_Attivo: new FormControl(modello?.keysModelloDocumentale?.key1_Attivo || ''),
          key1_CustomValues: new FormControl(modello ? JSON.parse(modello?.keysModelloDocumentale?.key1_CustomValues) || [] : []),
          key1_Obbligatoria: new FormControl(modello?.keysModelloDocumentale?.key1_Obbligatoria || false),

          key2_Tipo: new FormControl(modello?.keysModelloDocumentale?.key2_Tipo || TipoChiave.Data),
          key2_Etichetta: new FormControl(modello?.keysModelloDocumentale?.key2_Etichetta || 'Data', Validators.required),
          key2_Attivo: new FormControl(modello?.keysModelloDocumentale?.key2_Attivo || true),
          key2_CustomValues: new FormControl(modello ? JSON.parse(modello?.keysModelloDocumentale?.key2_CustomValues) || [] : []),
          key2_Obbligatoria: new FormControl(modello?.keysModelloDocumentale?.key2_Obbligatoria || true),

          key3_Tipo: new FormControl(modello?.keysModelloDocumentale?.key3_Tipo || ''),
          key3_Etichetta: new FormControl(modello?.keysModelloDocumentale?.key3_Etichetta || ''),
          key3_Attivo: new FormControl(modello?.keysModelloDocumentale?.key3_Attivo || ''),
          key3_CustomValues: new FormControl(modello ? JSON.parse(modello?.keysModelloDocumentale?.key3_CustomValues) || [] : []),
          key3_Obbligatoria: new FormControl(modello?.keysModelloDocumentale?.key3_Obbligatoria || false),

          key4_Tipo: new FormControl(modello?.keysModelloDocumentale?.key4_Tipo || ''),
          key4_Etichetta: new FormControl(modello?.keysModelloDocumentale?.key4_Etichetta || ''),
          key4_Attivo: new FormControl(modello?.keysModelloDocumentale?.key4_Attivo || ''),
          key4_CustomValues: new FormControl(modello ? JSON.parse(modello?.keysModelloDocumentale?.key4_CustomValues) || [] : []),
          key4_Obbligatoria: new FormControl(modello?.keysModelloDocumentale?.key4_Obbligatoria || false),

          key5_Tipo: new FormControl(modello?.keysModelloDocumentale?.key5_Tipo || ''),
          key5_Etichetta: new FormControl(modello?.keysModelloDocumentale?.key5_Etichetta || ''),
          key5_Attivo: new FormControl(modello?.keysModelloDocumentale?.key5_Attivo || ''),
          key5_CustomValues: new FormControl(modello ? JSON.parse(modello?.keysModelloDocumentale?.key5_CustomValues) || [] : []),
          key5_Obbligatoria: new FormControl(modello?.keysModelloDocumentale?.key5_Obbligatoria || false),

          key6_Tipo: new FormControl(modello?.keysModelloDocumentale?.key6_Tipo || ''),
          key6_Etichetta: new FormControl(modello?.keysModelloDocumentale?.key6_Etichetta || ''),
          key6_Attivo: new FormControl(modello?.keysModelloDocumentale?.key6_Attivo || ''),
          key6_CustomValues: new FormControl(modello ? JSON.parse(modello?.keysModelloDocumentale?.key6_CustomValues) || [] : []),
          key6_Obbligatoria: new FormControl(modello?.keysModelloDocumentale?.key6_Obbligatoria || false),

          key7_Tipo: new FormControl(modello?.keysModelloDocumentale?.key7_Tipo || ''),
          key7_Etichetta: new FormControl(modello?.keysModelloDocumentale?.key7_Etichetta || ''),
          key7_Attivo: new FormControl(modello?.keysModelloDocumentale?.key7_Attivo || ''),
          key7_CustomValues: new FormControl(modello ? JSON.parse(modello?.keysModelloDocumentale?.key7_CustomValues) || [] : []),
          key7_Obbligatoria: new FormControl(modello?.keysModelloDocumentale?.key7_Obbligatoria || false),

          key8_Tipo: new FormControl(modello?.keysModelloDocumentale?.key8_Tipo || ''),
          key8_Etichetta: new FormControl(modello?.keysModelloDocumentale?.key8_Etichetta || ''),
          key8_Attivo: new FormControl(modello?.keysModelloDocumentale?.key8_Attivo || ''),
          key8_CustomValues: new FormControl(modello ? JSON.parse(modello?.keysModelloDocumentale?.key8_CustomValues) || [] : []),
          key8_Obbligatoria: new FormControl(modello?.keysModelloDocumentale?.key8_Obbligatoria || false),

          key9_Tipo: new FormControl(modello?.keysModelloDocumentale?.key9_Tipo || ''),
          key9_Etichetta: new FormControl(modello?.keysModelloDocumentale?.key9_Etichetta || ''),
          key9_Attivo: new FormControl(modello?.keysModelloDocumentale?.key9_Attivo || ''),
          key9_CustomValues: new FormControl(modello ? JSON.parse(modello?.keysModelloDocumentale?.key9_CustomValues) || [] : []),
          key9_Obbligatoria: new FormControl(modello?.keysModelloDocumentale?.key9_Obbligatoria || false),

          key10_Tipo: new FormControl(modello?.keysModelloDocumentale?.key10_Tipo || ''),
          key10_Etichetta: new FormControl(modello?.keysModelloDocumentale?.key10_Etichetta || ''),
          key10_Attivo: new FormControl(modello?.keysModelloDocumentale?.key10_Attivo || ''),
          key10_CustomValues: new FormControl(modello ? JSON.parse(modello?.keysModelloDocumentale?.key10_CustomValues) || [] : []),
          key10_Obbligatoria: new FormControl(modello?.keysModelloDocumentale?.key10_Obbligatoria || false)
      });

      if(!!modello){
        this.formGroup.disable();
        if(!modello?.systemModello) {
          this.formGroup.controls['attivo'].enable();
          this.isModelloActiveChange = true;
        }
      }
      this.caricamentoGrid = false;
    }

    async salvaModelloDocumentale(){
      this._spinner.show();
      const modelloDocumentale = this.valorizzaModelloDocumentaleSalvataggio();
      const isKeyActive = this.controlloChiaviAttive(modelloDocumentale.keysModelloDocumentale);
      if(isKeyActive) {
        const response = await this._documentiService.addOrUpdateModelloDocumentale(modelloDocumentale).toPromise();
        if (response && response.success){
          this.process = 'Salva';
          this.dialogModel = {
            title: "Successo",
            message: "Modello documentale salvato con successo!",
            btnConferma: "Ok",
            hasBtnAnnulla: false
          };
          this.visibleDialog = true;
        } else {
          this.dialogModel = {
            title: "Attenzione",
            message: response.message,
            btnConferma: "Ok",
            hasBtnAnnulla: false
          };
          this.visibleDialog = true;
        }
      } else {
        this.dialogModel = {
          title: "Attenzione",
          message: "Attivare almeno una chiave tra quelle valorizzate",
          btnConferma: "Ok",
          hasBtnAnnulla: false
        };
        this.visibleDialog = true;
      }
      this._spinner.hide();
    }

    onClosePopup() {
      this.visibleDialog = false;
      this.isModelloActiveChange = false;
      if (this.process === 'ChangeActiveModello') {
        this.formGroup.get('attivo').setValue(!this.formGroup.get('attivo').value);
      }
      this.process = '';
    }

    async onConfirmPopup() {
      this.visibleDialog = false;
      switch(this.process) {
        case 'Salva':
          this.updateModello = false;
          await this.filterBrowse();
          break;
        case 'Cancella':
          await this.filterBrowse();
          break;
        case 'ChangeActiveModello':
          this.visibleDialog = false;
          await this.salvaModelloDocumentale();
          this.isModelloActiveChange = false;
          this.updateModello = false;
          await this.filterBrowse();
          break;
        default:
          break;
      }
      this.process = '';
    }

    async deleteModelloDocumentale(id: number){
      if(id){
      const response = await this._documentiService.deleteModelloDocumentale(id).toPromise();
        if(response && response.success){
          this.visibleDialog = true;
          this.process = 'Cancella';
          this.dialogModel = {
            title: "Successo",
            message: "Cancellazione avvenuta con successo!",
            btnConferma: "Ok",
            hasBtnAnnulla: false
          };
        } else {
          this.visibleDialog = true;
          this.dialogModel = {
            title: "Attenzione",
            message: response.message,
            btnConferma: "Ok",
            hasBtnAnnulla: false
          };
        }
      }
    }

    public clearFilter() {
      delete this.sezioneSelezionata;
    }

    private GetTipoByKey(nomekey: string) : number 
    {
      let retVal : number = 0;
      if(this.formGroup.controls[nomekey] && this.formGroup.controls[nomekey].value)
      {
        retVal =  this.formGroup.controls[nomekey].value;
      }
      return retVal;
    }

    private valorizzaModelloDocumentaleSalvataggio(){
      let keysModelloDocumentale: ModelloDocumentaleKeys = new ModelloDocumentaleKeys();
      keysModelloDocumentale.key1_Attivo = Boolean(this.formGroup.controls['key1_Attivo'].value);
      keysModelloDocumentale.key1_Tipo = this.GetTipoByKey("key1_Tipo");
      keysModelloDocumentale.key1_Etichetta = this.formGroup.controls['key1_Etichetta'].value;
      keysModelloDocumentale.key2_Attivo = Boolean(this.formGroup.controls['key2_Attivo'].value);
      keysModelloDocumentale.key2_Tipo = this.GetTipoByKey("key2_Tipo");
      keysModelloDocumentale.key2_Etichetta = this.formGroup.controls['key2_Etichetta'].value;
      keysModelloDocumentale.key3_Attivo = Boolean(this.formGroup.controls['key3_Attivo'].value);
      keysModelloDocumentale.key3_Tipo = this.GetTipoByKey("key3_Tipo");
      keysModelloDocumentale.key3_Etichetta = this.formGroup.controls['key3_Etichetta'].value;
      keysModelloDocumentale.key4_Attivo = Boolean(this.formGroup.controls['key4_Attivo'].value);
      keysModelloDocumentale.key4_Tipo = this.GetTipoByKey("key4_Tipo");
      keysModelloDocumentale.key4_Etichetta = this.formGroup.controls['key4_Etichetta'].value;
      keysModelloDocumentale.key5_Attivo = Boolean(this.formGroup.controls['key5_Attivo'].value);
      keysModelloDocumentale.key5_Tipo =this.GetTipoByKey("key5_Tipo");
      keysModelloDocumentale.key5_Etichetta = this.formGroup.controls['key5_Etichetta'].value;
      keysModelloDocumentale.key6_Attivo = Boolean(this.formGroup.controls['key6_Attivo'].value);
      keysModelloDocumentale.key6_Tipo =this.GetTipoByKey("key6_Tipo");
      keysModelloDocumentale.key6_Etichetta = this.formGroup.controls['key6_Etichetta'].value;
      keysModelloDocumentale.key7_Attivo = Boolean(this.formGroup.controls['key7_Attivo'].value);
      keysModelloDocumentale.key7_Tipo = this.GetTipoByKey("key7_Tipo");
      keysModelloDocumentale.key7_Etichetta = this.formGroup.controls['key7_Etichetta'].value;
      keysModelloDocumentale.key8_Tipo =this.GetTipoByKey("key8_Tipo");
      keysModelloDocumentale.key8_Etichetta = this.formGroup.controls['key8_Etichetta'].value;
      keysModelloDocumentale.key8_Attivo = Boolean(this.formGroup.controls['key8_Attivo'].value);
      keysModelloDocumentale.key9_Tipo =this.GetTipoByKey("key9_Tipo");
      keysModelloDocumentale.key9_Etichetta = this.formGroup.controls['key9_Etichetta'].value;
      keysModelloDocumentale.key9_Attivo = Boolean(this.formGroup.controls['key9_Attivo'].value);
      keysModelloDocumentale.key10_Tipo =this.GetTipoByKey("key10_Tipo");
      keysModelloDocumentale.key10_Etichetta = this.formGroup.controls['key10_Etichetta'].value;
      keysModelloDocumentale.key10_Attivo = Boolean(this.formGroup.controls['key10_Attivo'].value);
      keysModelloDocumentale.key1_CustomValues = this.valorizzaCustomValue(this.formGroup.controls['key1_CustomValues'].value);
      keysModelloDocumentale.key2_CustomValues = this.valorizzaCustomValue(this.formGroup.controls['key2_CustomValues'].value);
      keysModelloDocumentale.key3_CustomValues = this.valorizzaCustomValue(this.formGroup.controls['key3_CustomValues'].value);
      keysModelloDocumentale.key4_CustomValues = this.valorizzaCustomValue(this.formGroup.controls['key4_CustomValues'].value);
      keysModelloDocumentale.key5_CustomValues = this.valorizzaCustomValue(this.formGroup.controls['key5_CustomValues'].value);
      keysModelloDocumentale.key6_CustomValues = this.valorizzaCustomValue(this.formGroup.controls['key6_CustomValues'].value);
      keysModelloDocumentale.key7_CustomValues = this.valorizzaCustomValue(this.formGroup.controls['key7_CustomValues'].value);
      keysModelloDocumentale.key8_CustomValues = this.valorizzaCustomValue(this.formGroup.controls['key8_CustomValues'].value);
      keysModelloDocumentale.key9_CustomValues = this.valorizzaCustomValue(this.formGroup.controls['key9_CustomValues'].value);
      keysModelloDocumentale.key10_CustomValues = this.valorizzaCustomValue(this.formGroup.controls['key10_CustomValues'].value);
      
      keysModelloDocumentale.key1_Obbligatoria = this.formGroup.controls['key1_Obbligatoria'].value;
      keysModelloDocumentale.key2_Obbligatoria = this.formGroup.controls['key2_Obbligatoria'].value;
      keysModelloDocumentale.key3_Obbligatoria = this.formGroup.controls['key3_Obbligatoria'].value;
      keysModelloDocumentale.key4_Obbligatoria = this.formGroup.controls['key4_Obbligatoria'].value;
      keysModelloDocumentale.key5_Obbligatoria = this.formGroup.controls['key5_Obbligatoria'].value;
      keysModelloDocumentale.key6_Obbligatoria = this.formGroup.controls['key6_Obbligatoria'].value;
      keysModelloDocumentale.key7_Obbligatoria = this.formGroup.controls['key7_Obbligatoria'].value;
      keysModelloDocumentale.key8_Obbligatoria = this.formGroup.controls['key8_Obbligatoria'].value;
      keysModelloDocumentale.key9_Obbligatoria = this.formGroup.controls['key9_Obbligatoria'].value;
      keysModelloDocumentale.key10_Obbligatoria = this.formGroup.controls['key10_Obbligatoria'].value;
      
      const nome = this.formGroup.controls['nome'].value;
      const attivo = this.formGroup.controls['attivo'].value;
      const id = this.formGroup.controls['id'].value;
      const modelliSezione = this.setModelliSezione();

      return new ModelloDocumentale(nome, modelliSezione, keysModelloDocumentale, id, attivo);
    }

    controlloChiaviAttive(keyModello: ModelloDocumentaleKeys) {
      let isActive = false;
      for(let i = 1; i <= 10; i++) {
        let key_Attivo = `key${i}_Attivo`;
        if (keyModello[key_Attivo]) {
          isActive = true;
          return isActive;
        }
      }
      return isActive;
    }


    valorizzaCustomValue(valore){
      return !!valore ? JSON.stringify(valore) : null;
    }

    setModelliSezione(){
      const modelliSezione = [];
      const modelliSelezionati = this.formGroup.controls['modelliSezione'].value;
      if(!!modelliSelezionati){
        for(let idSezione of modelliSelezionati){
          const idSez = idSezione.id;
          modelliSezione.push(new ModelloSezione(idSez, this.formGroup.controls['id'].value))
        }  
      }
      return modelliSezione;
    }

    getModelliSezione(modelloSelezionato?: any){
      let modelliSezione: ModelloSezione[] = [];
      if(!!modelloSelezionato?.modelliSezione){
        for(let modello of modelloSelezionato.modelliSezione){
          modelliSezione.push(new ModelloSezione(modello.idModello, this.sezioneSelezionata.id))
        }  
      }
      return modelliSezione;
    }

    getSezioni(modelloSelezionato?: any){
      let modelliSezione: SezioniDocument[] = [];
      if(!!modelloSelezionato?.modelliSezione){
        for(let modello of modelloSelezionato.modelliSezione){
          let nomeSezione = this.sezioni.find(s => s.id === modello.idSezione).nome;
          modelliSezione.push(new SezioniDocument(modello.idModello, nomeSezione));
        }  
      }
      return modelliSezione;
    }

    closeModelloDocumentale() {
      this.updateModello = false;
      this.isModelloActiveChange = false;
    }

  }