import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CUP } from 'src/app/model/cup';
import { QE, QEItemBase, QEMainBase, QEModel, QEResponse } from 'src/app/model/qe';
import { FormGroup } from '@angular/forms';
import { CupService } from 'src/app/service/cup.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { QEService } from 'src/app/service/qe.service';
import { SharedService } from 'src/app/service/shared.service';
import { UserClientService } from 'src/app/service/user-client.service';
import { AuthDto, PermessiArea, Ruolo, SearcherUserDto, TipoPermesso, Utente } from 'src/app/model/utente';
import { Dizionario, ModelloDocumentaleCustom, Scenario, TipologiaDizionario, } from 'src/app/model/scenari';
import { ScenarioService } from 'src/app/service/scenario.service';
import { ConfirmationService, MessageService } from 'primeng/api';
import { NomeTab, TipoEntita } from 'src/app/model/documento';
import { RuoloPipe } from 'src/app/pipe/ruolo-pipe';
import { PermessoPipe } from 'src/app/pipe/permessi-pipe';

@Component({
  selector: 'app-cup-main',
  templateUrl: './cup-main.component.html',
  styleUrls: ['./cup-main.component.scss'],
})
export class CupMainComponent implements OnInit {
  formGroup: FormGroup;
  showDeletedCupDialog: boolean = false;
  rowsImpegnate: QEItemBase[];
  updateFontiIncassi = false;

  user: Utente;
  isInEdit: boolean = false;
  isId: boolean;
  idCup: number;
  cup: CUP;
  qe: QE;
  activeIndex: number = 0;
  refreshDocumenti: boolean = false;
  isEsecuzioneEnable: boolean = false;
  /**
   * Indica che la form è in modifica
   */
  public isEditCup: boolean = false;
  dizionarioCup: Dizionario[];
  refreshRowsQE: boolean = false;
  /**
   * Ricarica la Tab documenti
   */
  public isRefreshTab: boolean = false;
  /**
   * Indica la tab del QE attiva
   */
  public indexTabQE: number = 1;
  /**
   * Index per i tab delle fonti di finanziamento
   */
  public activeIndexFonti: number = 0;
  /**
   * I dati che vengono associati ai documenti da caricare
   */
  public datiModelloDocumentale: ModelloDocumentaleCustom;
  /**
   * Se true indica se l'utente è abilitato a vedere alcuni campi
   */
  public isUtenteAbilitato: boolean = false;
  public isCupInitialized: boolean = false;
  /**
   * Pipe utilizzata per i ruoli
   */
  public RuoloPipe = new RuoloPipe();
  /**
   * Permessi dell'utente loggato
   */
  public userAuth: AuthDto[];
  /**
   * I permessi delle aree 
   */
  public permessi: PermessiArea;
  /**
   * L'enum del tipo permesso
   */
  public TipoPermesso = TipoPermesso;
  /**
   * Enum delle Tab documenti
   */
  public NomeTab = NomeTab;
  /**
   * Se true attiva la Tab Progetto
   */
  public activeTabProgetto: boolean = false;
  /**
   * Se true attiva la Tab Gare
   */
  public activeTabGare: boolean = false;
  /**
   * La lista dei tipi dizionario
   */
  public tipiDizionario: Dizionario[];

  constructor(
    private router: Router,
    private qeServices: QEService,
    private route: ActivatedRoute,
    private cupServices: CupService,
    private spinner: NgxSpinnerService,
    private sharedServices: SharedService,
    private userClientService: UserClientService,
    private scenarioServices: ScenarioService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private _sharedServices: SharedService
  ) {
    
  }

  async ngOnInit() {
    this.spinner.show();
    this.idCup = this.sharedServices.getIdCup();
    this.activeIndex = this.sharedServices.getIndex();
    this.user = this.userClientService.getUser();
    if(this.user && this.user.roles && 
			(this.user?.roles === this.RuoloPipe.transform(Ruolo.SuperAdministrator) 
			|| this.user?.roles === this.RuoloPipe.transform(Ruolo.administrator))) {
        this.isUtenteAbilitato = true;
      }
      if (!this.idCup) {
        this.isId = this.route.snapshot.paramMap.get('id') ? true : false;
        if (this.isId) {
          this.idCup = Number(this.route.snapshot.paramMap.get('id'));
        }
      }
      this.getPermessiAreaFromUser(this.user);


    const searcherAuth = new SearcherUserDto();
    searcherAuth.clientId = this.user.clientId;
    searcherAuth.idCup = this.idCup;
    searcherAuth.username = this.user.userName;
    const response = await this.userClientService.getUserAuth(searcherAuth).toPromise();
    if (response && response.success) {
      if (response.dto && response.dto.auths && response.dto.auths.length > 0) {
        this.userAuth = response.dto.auths;
        this.userAuth.forEach(auth => {
          auth.idArea
        });
      }
    }
    this.tipiDizionario = await this.sharedServices.getAllDizionari(true);
    if (this.tipiDizionario) {
      this.dizionarioCup = this.tipiDizionario.filter(d => d.categoria === TipologiaDizionario.CUP_Stato);
    }
    this.sharedServices.deleteRowsImpegnate();
    localStorage.setItem('idCup', this.idCup.toString());
    await this.loadCUP();
    
    // Seleziona di default il tab del Quadro economico attuale
    if (this.cup && !this.activeIndex && this.activeIndex !== 0) {
      this.activeIndex = 2;
    }

    this.activeIndexFonti = 0;
    const dati =
      this._sharedServices.getParametriNavigaDaDocumentiArchviazione();
    if (
      dati &&
      dati?.sezione === 'Fonte di Finanziamento' &&
      dati?.sottoSezione === 'Documento Fonti'
    ) {
      this.activeIndexFonti = 3;
    }
    if (dati && dati?.sezione === 'Documento') {
      this.activeIndex = 4;
    }

    if(this.activeIndex !== 6) {
      if (this.cup && this.qe) {
        this.activeIndex = 5;
        this.handleChange(this.activeIndex);
      } else {
        this.activeIndex = 4;
        this.handleChange(this.activeIndex);
      } 
    }
    this.spinner.hide();
  }

  fineUpload(event) {
    if (event) {
      this.activeIndex = 0;
      this.handleChange(this.activeIndex);
    }
    this.formGroup.disable();
    this.isEditCup = false;
    this.sharedServices.deleteParametriNavigaDaDocumentiArchviazione();
    this.sharedServices.setAnteprimaDocumentoCartellaMonitorata(false, undefined);
  }

  scrollToElement(id: string) {
    const element = document.getElementById(id);
    element?.scrollIntoView({ behavior: 'smooth' });
  }

  refreshCup(){
    this.loadCUP();
  }


  /**
   * Recupero del Cup e sua inizializzazione
   */
  public async loadCUP() {
    delete this.cup;
    const response = await this.cupServices.getCupById(this.idCup).toPromise();
    if (response) {
      this.cup = response;
      localStorage.setItem('titoloCup', this.cup.titolo);
      if(this.cup.qEs && this.cup.qEs.length > 0) {
        this.qe = this.cup?.qEs[0]?.current || this.cup?.qEs[0]?.draft;
      }
      this.inizializzaCUP();
    } else {
      this.messageService.add({ severity: 'error', summary: 'Attenzione', detail: 'Errore nel caricamento del CUP' });
    }
  }

  /**
  * Salvo le modifiche apportate ai campi del Cup
  * @param cupForm form dei campi modificati del Cup
  */
  public async save(cupForm: FormGroup<any>) {
    this.spinner.show();
    let severity = 'error';
    let detail = 'Errore nel salvataggio del CUP';
    if (cupForm && cupForm.value) {
      const resultEditCup = await this.cupServices.updateCup(cupForm.value).toPromise();
      if (resultEditCup) {
        this.spinner.hide();
        this.isEditCup = true;
        this.idCup = resultEditCup;
        severity = 'success';
        detail = 'CUP salvato con successo';

        if (this.formGroup) {
          this.loadCUP();
          this.formGroup.disable();
          this.isEditCup = false;
        }
      }
    }
    this.sharedServices.setListaCup(this.cup);
    this.spinner.hide();
    this.messageService.add({ severity: severity, summary: '', detail: detail });
  }

  /**
   * OnChange dei tab
   */
  async handleChange(handle: any) {
    let tabIndex: any;
    handle && handle.index !== undefined
      ? (tabIndex = handle.index)
      : (tabIndex = handle);

    if (tabIndex !== 0 && this.formGroup) {
      this.formGroup.disable();
      this.isEditCup = false;
    }

    switch (tabIndex) {
      case 0:
        if (this.isEditCup) {
          this.formGroup.disable();
          this.isEditCup = false;
        }
        this.isCupInitialized = true;
        break;
      case 1:
          const tab2 = handle.originalEvent
          if (tab2 && tab2.target && tab2.target.innerText === 'Progetto') {
            this.activeTabProgetto = true;
            this.isRefreshTab = true;
          }
        break;
      case 3:
          const tab3 = handle.originalEvent
          if (tab3 && tab3.target && tab3.target.innerText === 'Gare') {
            this.activeTabGare = true;
            this.isRefreshTab = true;
          }
        break;
      case 4:
        const tab4 = handle.originalEvent
        if (tab4 && tab4.target && tab4.target.innerText === 'Documenti') {
          this.isRefreshTab = true;
        }
        break;
      default:
        break;
    }
  }

  handleChangeCup(tabIndex: number) {
    switch(tabIndex) {
      case 0:
        this.isCupInitialized = true;
        break;
    }
  }

  handleChangeQE(e) {
    this.indexTabQE = e.index;
  }

  updateFontiEIncassi(aggiorna: boolean) {
    this.updateFontiIncassi = aggiorna;
  }

  changeIndex(e) {
    let index = e || e.index;
    if (this.updateFontiIncassi) {
      if (index === 0 || index === 1 || index === 2 || index === 3) {
        this.refreshDocumenti = !this.refreshDocumenti;
      }
    }
    this.updateFontiIncassi = false;
  }

  /**
   * Aggiorna il valore del isRefreshTab per aggiornare la lista dei documenti
   * @param refreshTab il valore del refresh
   */
  public onRefreshTab(refreshTab: boolean) {
    this.isRefreshTab = refreshTab;
  }
  /**
   * Aggiorna il valore del isRefreshTab per aggiornare la lista dei documenti
   * @param refreshTab il valore del refresh
   */
  public onRefreshCup(cupInitialized: boolean) {
    this.isCupInitialized = cupInitialized;
  }

  cancellazioneCup() {
    this.confirmationService.confirm({
      message: 'Sei sicuro di voler eliminare questo Cup?',
      header: 'Eliminazione',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Si, elimina',
      rejectLabel: 'No, torna indietro',
      acceptButtonStyleClass: 'p-button-danger p-button-text',
      rejectButtonStyleClass: 'p-button-text',
      accept: () => {
        this.showDeletedCupDialog = true;
      },
    });
  }

  async deleteCup() {
    this.spinner.show();
    const response = await this.cupServices.deleteCup(this.idCup).toPromise();
    if (response) {
      this.router.navigateByUrl('');
    }
    this.spinner.hide();
  }

  onReceiveRows(event) {
    this.rowsImpegnate =  event;
    this.sharedServices.setRowImpegnate(event);
    this.refreshRowsQE = true;
  }

  /**
  * Inizializzazione del Cup
  */
  private async inizializzaCUP() {
    this.datiModelloDocumentale = this.scenarioServices.getModelloDocumentaleCustom(Scenario.Cup);
    if (this.datiModelloDocumentale) {
      this.datiModelloDocumentale.isFrom = Scenario.Cup;
      this.datiModelloDocumentale.isEdit = true;
      this.datiModelloDocumentale.entityID = this.cup?.id;
      this.datiModelloDocumentale.idCup = this.cup?.id;
      this.datiModelloDocumentale.cup = this.cup;
      this.datiModelloDocumentale.entityTipo = TipoEntita.SchedaCup;
      this.formGroup = this.scenarioServices.getFormGroup(Scenario.Cup);
      this.formGroup.setValue({
        id: this.cup?.id,
        codice: this.cup?.codice,
        titolo: this.cup?.titolo,
        dataCup: new Date(this.cup?.dataCup),
        indirizzo: this.cup?.indirizzo,
        importo: this.cup?.importo,
        descrizione: this.cup?.descrizione,
        stato: this.cup?.stato,
        allegato: this.cup?.allegato || null
      });
      this.formGroup.disable();
      this.datiModelloDocumentale.formGroup = this.formGroup;
      this.datiModelloDocumentale.DatiForm[6].lista = 
        this.dizionarioCup.filter(x=> x.attivo || x.descrizione === this.cup?.stato).map(d => d.descrizione);
    }
  }

  private async getPermessiAreaFromUser(user: Utente) {
    if(user) {
      this.permessi = new PermessiArea();
      const searcherAuth = new SearcherUserDto();
      searcherAuth.clientId = this.user.clientId;
      searcherAuth.idCup = this.idCup;
      searcherAuth.username = this.user.userName;
      const response = await this.userClientService.getUserAuth(searcherAuth).toPromise();
      if (response && response.success) {
        if (response.dto && response.dto.auths && response.dto.auths.length > 0) {
          this.userAuth = response.dto.auths;
          this.userAuth.forEach(auth => {
            if(auth) {
              switch(auth.idArea) {
                case 1:
                  this.permessi.permessoCup = auth.tipoPermesso;
                  break;
                case 2:
                  this.permessi.permessoQE = auth.tipoPermesso;
                  break;
                case 3:
                  this.permessi.permessoEsecuzione = auth.tipoPermesso;
                  break;
                case 5:
                  this.permessi.permessoDocumenti = auth.tipoPermesso;
                  break;
                case 6:
                  this.permessi.permessoFonti = auth.tipoPermesso;
                  break;
                case 7:
                  this.permessi.permessoPromemoria = auth.tipoPermesso;
                  break;
                case 8:
                  this.permessi.permessoAnnotazioni = auth.tipoPermesso;
                  break;
              }
            }
          });
        }
      }
    }
  }
}
