import { Component, Input } from '@angular/core';
import { ConfirmationService, MessageService } from "primeng/api";
import { NgxSpinnerService } from 'ngx-spinner';
import { DialogService } from 'primeng/dynamicdialog';
import { SharedService } from 'src/app/service/shared.service';
import { Fonte, Incasso } from 'src/app/model/fonte';
import { FontiService } from 'src/app/service/fonti.service';
import { ModelloDocumentaleCustom, Scenario } from 'src/app/model/scenari';
import { FormGroup } from '@angular/forms';
import { ScenarioService } from 'src/app/service/scenario.service';
import { CUP } from 'src/app/model/cup';
import { NomeTab, TipoEntita } from 'src/app/model/documento';
import { Ruolo, TipoPermesso, Utente } from 'src/app/model/utente';
import { RuoloPipe } from 'src/app/pipe/ruolo-pipe';

interface risposta {
  success: boolean,
  message: string
}

@Component({
  selector: 'app-fonti',
  templateUrl: './fonti-finanziamento.component.html',
  styleUrls: ['./fonti-finanziamento.component.scss'],
  providers: [DialogService, ConfirmationService, MessageService]
})

export class FontiFinanziamento {
  @Input() cupId: number;
  @Input() cup: CUP;
  /**
   * Permesso in base all'utente loggato
   */
  @Input() public permesso: TipoPermesso;
  /**
   * Utente loggato
   */
  @Input() public user: Utente;

  // Id dell'elemento appena creato da inviare all'onchange per procedere con il salvataggio del documento se collegato 
	entityIdAdd: number;

  filteredTab: Fonte[];
  filteredTabStorico: Fonte[];
  filteredTabIncassi: Incasso[];

  selectedFonte: Fonte;
  fonte: Fonte;
  incasso: Incasso;
  datiModelloDocumentale: ModelloDocumentaleCustom;
  formGroup: FormGroup;

  isId: boolean;
  visible: boolean;
  openIncassi: boolean = false;
  isInEdit: boolean;
  totale: number;
  totaleIncassi: number;
  header: string;
  storicizza: boolean;
  isFromStorico: boolean;
  idFonte: number;
  idIncasso: number;

  /**
   * Al caricamento delle fonti di finanziamento, il flag serve per ricaricare la griglia dei documenti
   */
  refreshDocumenti: boolean = false;
  public isRefreshTabDocumenti: boolean = false;
  public TipoPermesso = TipoPermesso;
	public Ruolo = Ruolo;
	public RuoloPipe = new RuoloPipe();
  /**
   * Enum delle Tab documenti
   */
  public NomeTab = NomeTab;
  
  constructor(
    private fontiServices: FontiService,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private spinner: NgxSpinnerService,
    private scenarioServices: ScenarioService,
    private _sharedServices: SharedService
  ) { }

  async ngOnInit() {
    await this.onUpdateFontiIncassiStorico(); 
  }

  async onUpdateFontiIncassiStorico(verificaDatiCartellaMonitorata: boolean = true){
     const fontiAttuali = await this.fontiServices.getFontiFinanziamentoAttuali(this.cupId).toPromise();
     if(fontiAttuali && fontiAttuali.success){
       this.filteredTab = fontiAttuali.dtos;
       this.totale = this.filteredTab.map(t => t.importoFinanziamento).reduce((acc, value) => acc + value, 0);
     } else {
      console.log('500');
     }
     
     if(verificaDatiCartellaMonitorata){
       this.openDialogNavigazioneDaDocumenti(this.filteredTab);
     }
     const incassi = await this.fontiServices.getIncassi(this.cupId).toPromise();
     if(incassi && incassi.success) {
       this.filteredTabIncassi = incassi.dtos;
       this.totaleIncassi = this.filteredTabIncassi.map(t => t.importoIncassato).reduce((acc, value) => acc + value, 0);
     } else {
      console.log('500');
     }
     const fontiStoriche = await this.fontiServices.getFontiFinanziamentoStoriche(this.cupId).toPromise();
     if(fontiStoriche && fontiStoriche.success){
       this.filteredTabStorico = fontiStoriche.dtos;
     } else {
      console.log('500');
     }
   }
 
   openDialogNavigazioneDaDocumenti(listaFonti: Fonte[]){
     const dati = this._sharedServices.getParametriNavigaDaDocumentiArchviazione();
     const idFonte = dati?.idSottoSezione;
     const fonte = listaFonti.find(fonte => fonte.id === idFonte);
     if(dati && dati?.sezione === 'Fonte di Finanziamento'){
       switch(dati?.sottoSezione){
         case 'Fonte di finaziamento':
           this.showDialogForm(false);
         break;
           case 'Registra incasso':
             this.showDialogIncassi(fonte);
         break;
           case 'Sostituisci fonte':
             this.sostituisciFonte(fonte);
             /// Bisogna aggiungere i documenti??!!!??? Alessia
         break;
       }
     } 
   }

  closeDialog(event) {
    this.visible = false;
    this.openIncassi = false;
  }

  /**
   * Aggiorna il valore del isRefreshTab per aggiornare la lista dei documenti
   * @param refreshTab il valore del refresh
   */
  public onRefreshTab(refreshTab: boolean) {
    this.isRefreshTabDocumenti = refreshTab;
  }

  handleChangeFonti(handle: any) {
    let tabIndex: any;
    handle && handle.index !== undefined ? (tabIndex = handle.index) : (tabIndex = handle);
    switch (tabIndex) {
      case 3:
        const tab = handle.originalEvent
        if (tab && tab.target && tab.target.innerText === 'Documenti Fonti') {
            this.isRefreshTabDocumenti = true;
        }
        break;
      default:
        break;
    }
  }

  showDialogForm(storicizza: boolean, fonte?: Fonte, isFromStorico: boolean = false) {
    this.storicizza = storicizza;
    this.isFromStorico = isFromStorico;

    const formGroup = this.scenarioServices.getFormGroup(Scenario.Fonte);
    this.datiModelloDocumentale = this.scenarioServices.getModelloDocumentaleCustom(Scenario.Fonte);

    if (fonte) {      
      this.datiModelloDocumentale.isEdit = true;
      this.datiModelloDocumentale.entityID = fonte.id;
      formGroup.setValue({
        ente: fonte?.ente,
        atto: fonte?.atto,
        numero: fonte?.numero,
        del: new Date(fonte?.del),
        descrizione: fonte?.descrizione,
        importoFinanziamento: fonte?.importoFinanziamento,
      });
      formGroup.disable();
    } else {
      this.datiModelloDocumentale.isEdit = false;
    }

    this.datiModelloDocumentale.formGroup = formGroup;
    this.datiModelloDocumentale.idCup = this.cup?.id;
    this.datiModelloDocumentale.cup = this.cup;
    this.datiModelloDocumentale.entityTipo = TipoEntita.FonteFinanziamento;

    if (isFromStorico) {
      this.header = "Visualizza Fonte di Finanziamento Storicizzata";
    } else if (storicizza && fonte) {
      this.header = "Sostituisci Fonte di Finanziamento";
      formGroup.setValue({
        ente: fonte?.ente,
        atto: "",
        numero: "",
        del: null,
        descrizione: "",
        importoFinanziamento: 0,
      });
    } else if (fonte && !storicizza) {
      this.header = "Modifica Fonte di Finanziamento";
    } else {
      this.header = "Fonte di Finanziamento";
    }
    this.fonte = (fonte && (!storicizza || isFromStorico)) ? fonte : undefined;
    this.visible = true;
  }

  showDialogIncassi(fonte: Fonte, incasso?: Incasso) {
    const formGroup = this.scenarioServices.getFormGroup(Scenario.Incasso);
    this.datiModelloDocumentale = this.scenarioServices.getModelloDocumentaleCustom(Scenario.Incasso);
    if (incasso) {      
      this.datiModelloDocumentale.isEdit = true;
      this.datiModelloDocumentale.entityID = incasso.id;
      formGroup.setValue({
        ente: '',
        atto: incasso.atto,
        numero: incasso.numero,
        del: new Date(incasso.del),
        descrizione: incasso.descrizione,
        importoIncassato: incasso.importoIncassato,
        fonteFinanziamentoID: incasso.fonteFinanziamentoID,
      });
      formGroup.disable();
    } else {
      this.datiModelloDocumentale.isEdit = false;
      formGroup.get('ente')?.setValue(fonte?.ente);
    }

    this.datiModelloDocumentale.formGroup = formGroup;
    this.datiModelloDocumentale.idCup = this.cup?.id;
    this.datiModelloDocumentale.cup = this.cup;
    this.datiModelloDocumentale.entityTipo = TipoEntita.Incassato;

    this.fonte = fonte;
    this.incasso = incasso;
    this.openIncassi = true;
  }

  /**
   * Aggiungi nuova fonte di finanziamento
   */
  async addFonte(form) {
    let fonte = form.value;
    fonte.cupId = this.cupId;
    this.spinner.show();
    const response = await this.fontiServices.createFonteFinanziamento(fonte).toPromise();
    if(response && response.success){
        this.entityIdAdd = response.dto.id;
        await this.update();
        this.scenarioServices.showSuccessMessage('Fonte di Finanziamento aggiunta con successo');  
    } else {
      this.scenarioServices.showErrorMessage('Errore nel salvataggio della Fonte di Finanziamento');
    }
    this.spinner.hide();
  }

  /**
   * Salvataggio o Storicizzazione della fonte
   */
  async saveFonte(form) {
    console.log(form);
    let fonte = form.value;
    fonte.cupId = this.cupId;
    this.spinner.show();
    if (this.storicizza) {
      const response = await this.fontiServices.replaceFonteFinanziamento(fonte, this.datiModelloDocumentale.entityID).toPromise();
      if(response){
        this.update();
        this.scenarioServices.showSuccessMessage('Fonte storicizzata e sostituita con successo');  
      } else {
        this.scenarioServices.showErrorMessage('Errore durante la fase di storicizzazione e sostituzione della fonte');
      }
    } else {
      const response = await this.fontiServices.updateFonteFinanziamento(fonte, this.datiModelloDocumentale.entityID).toPromise();
      if(response && response.success){
        this.update();
        this.scenarioServices.showSuccessMessage('Fonte modificata con successo');
      } else {
        this.scenarioServices.showErrorMessage('Errore nel salvataggio della fonte');
      }
    }
    this.spinner.hide();
    
    this.visible = false;
    this.openIncassi = false;
  }

  /**
   * Aggiunge nuovo incasso
   */
  async addIncasso(form) {
    this.spinner.show();
    let incasso = form.value;
    incasso.fonteFinanziamentoID = this.fonte.id;
    const response = await this.fontiServices.createIncasso(incasso).toPromise();
    if(response && response.success){
      console.log('incasso ', response);
        this.entityIdAdd = response.dto.id;
        await this.update();     
        this.scenarioServices.showSuccessMessage('Incasso aggiunto con successo');  
    } else {
      this.scenarioServices.showErrorMessage('Errore nel salvataggio dell\' Incasso');
    }
    this.spinner.hide();
  }

  /**
   * Salvataggio incasso
   */
  async saveIncasso(form) {
    this.spinner.show();
    let incasso = form.value;
    incasso.fonteFinanziamentoID = this.incasso.fonteFinanziamentoID;
    const response = await this.fontiServices.updateIncasso(incasso, this.datiModelloDocumentale.entityID).toPromise();
    if(response && response.success){
         this.scenarioServices.showSuccessMessage('Incasso modificato con successo');  
        await this.update();
    } else {
      this.scenarioServices.showErrorMessage('Errore nella modifica dell\'incasso');
    }
    this.spinner.hide();
    
    this.visible = false;
    this.openIncassi = false;
  }

  /**
   * Se presente incasso, verrà eliminato l'incasso, altrimenti se presente la fonte verrà sostituita
   * @param fonte 
   * @param incasso 
   */
  eliminaIncasso(idIncasso: number) {
      this.confirmationService.confirm({
        message: "Sei sicuro di voler eliminare questo incasso?",
        header: "Eliminazione",
        icon: "pi pi-exclamation-triangle",
        acceptLabel: "Si, elimina",
        rejectLabel: "No, torna indietro",
        acceptButtonStyleClass: "p-button-danger p-button-text",
        rejectButtonStyleClass: "p-button-text",
        accept: async () => {
          const response = await this.fontiServices.deleteIncasso(idIncasso).toPromise();
          if(response){
            this.messageService.add(
              {
                severity: response ? 'success' : 'error', summary: response ? 'Success' : 'Error',
                detail: response ? "Incasso eliminato con successo" : 'Errore durante l\'eliminazione dell\'Incasso'
              });
              await this.update();
          }
        }
      });
  }

  sostituisciFonte(fonte){
    this.fonte = fonte;
    this.confirmationService.confirm({
      message: "Dopo l'aggiornamento, questa fonte verrà salvata nello storico! Sei sicuro di voler eseguire l'aggiornamento?",
      header: "Aggiornamento",
      icon: "pi pi-exclamation-triangle",
      acceptLabel: "Si, aggiorna",
      rejectLabel: "No, torna indietro",
      acceptButtonStyleClass: "p-button-danger p-button-text",
      rejectButtonStyleClass: "p-button-text",
      accept: () => {
        this.showDialogForm(true, fonte);
      }
    });
  }

  confirmStorico(id: number) {
    this.confirmationService.confirm({
      message: "Sei sicuro di voler eliminare questa fonte dallo storico?",
      header: "Eliminazione",
      icon: "pi pi-exclamation-triangle",
      acceptLabel: "Si, elimina",
      rejectLabel: "No, torna indietro",
      acceptButtonStyleClass: "p-button-danger p-button-text",
      rejectButtonStyleClass: "p-button-text",
      accept: () => {
         this.fontiServices.deleteFonteStorica(id).subscribe({
          next: async (resp) => {
            if (resp) {
              console.log(resp);
              this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Fonte eliminata con successo' });
              await this.update();
            }
          },
          error: (err) => {
            console.log(err)
            this.messageService.add({ severity: 'error', summary: 'Error', detail: "Errore durante l'eliminazione della fonte" });

          }
        })
      }
    });
  }

  /**
   * click Bottone eliminazione Fonte di finanziamento
   */
  eliminaFonteMessaggioConferma(idFonte: number) {
    this.confirmationService.confirm({
      message: "Sei sicuro di voler eliminare questa fonte?",
      header: "Eliminazione",
      icon: "pi pi-exclamation-triangle",
      acceptLabel: "Si, elimina",
      rejectLabel: "No, torna indietro",
      acceptButtonStyleClass: "p-button-danger p-button-text",
      rejectButtonStyleClass: "p-button-text",
      accept: async () => {
        const response = await this.fontiServices.deleteFonteAttuale(idFonte).toPromise();
        if(response){
          this.messageService.add(
            {
              severity: response ? 'success' : 'error', summary: response ? 'Success' : 'Error',
              detail: response ? "Fonte eliminata con successo" : 'Errore durante l\'eliminazione della Fonte di Finanziamento'
            });
            await this.update();
        }
      }
    });

  }

  /**
   * Caricamento Fonti di Finanziamento e Incassi
   * Aggiorna le griglie dopo un aggiunta/modifica di una fonte attuale, fonte storica o incasso
   */
  async update() {
    delete this.filteredTab;
    delete this.filteredTabStorico;
    delete this.filteredTabIncassi;
    await this.onUpdateFontiIncassiStorico(false);
    this.refreshDocumenti = true;
  }

}
