import { Component, Input } from '@angular/core';
import { ScadenzeService } from 'src/app/service/scadenze.service';
import { Scadenza } from 'src/app/model/scadenza';
import { Router } from '@angular/router';
import { SharedService } from 'src/app/service/shared.service';
import { SearcherUserDto, TipoPermesso, Utente } from 'src/app/model/utente';
import { UserClientService } from 'src/app/service/user-client.service';
import { DialogModel } from '../shared/rbk-modal/rbk-modal';

@Component({
  selector: 'app-scadenzario',
  templateUrl: './scadenzario.component.html',
  styleUrls: ['./scadenzario.component.scss'],
})
export class ScadenzarioComponent {
  @Input() public user: Utente;
  scadenze: Scadenza[];
  titolo: string;
  oggi: Date = new Date;
  left = "left";
  public dialogModel: DialogModel;
  public visibleDialog: boolean = false;

  constructor(
    private scadenzeServices: ScadenzeService,
    private sharedServices: SharedService,
    private route: Router,
    private userClientService: UserClientService,
  ) {
    this.scadenze = this.sharedServices.getScadenze();
  }

 async ngOnInit() {
    if (!this.scadenze) {
    const response = await this.scadenzeServices.getAllScadenze().toPromise()
    if(response){
      this.scadenze = response || [];
      for (let scadenza of this.scadenze) {
        scadenza.data = new Date(`${scadenza.data.toString()}`);
      };
    }
    }
  }

  async selectedCard(id: number, scadenza: Scadenza) {
    let permessoSelect: boolean = true;
    const user = this.userClientService.getUser();
    if(user) {
      const searcherAuth = new SearcherUserDto();
      searcherAuth.clientId = this.user.clientId;
      searcherAuth.idCup = id;
      searcherAuth.username = this.user.userName;
      const response = await this.userClientService.getUserAuth(searcherAuth).toPromise();
      if (response && response.success) {
        const dto = response.dto;
        if (dto && dto.auths && dto.auths.length > 0) {
          const areaPromemoria = dto.auths.find(auth => auth.idArea === 7);
          if(areaPromemoria && areaPromemoria.tipoPermesso === TipoPermesso.None) {
            permessoSelect = false;
          }
        }
      }
      if(permessoSelect) {
        this.sharedServices.changeActiveIndex(6, scadenza);
        this.sharedServices.setCupId(id);
        this.route.navigateByUrl('cup/' + id);
      } else {
        this.dialogModel = {
          title: "Attenzione",
          message: "Non hai i permessi per visualizzare il promemoria.",
          btnConferma: "Chiudi",
          hasBtnAnnulla: false
        };
        this.visibleDialog = true;
      }
    }
  }
}
