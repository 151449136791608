import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { DeleteResponse, Documento, DocumentoCompleteResponse, DocumentoKeyValueUpdate, DocumentoResponse, DocumentoSearch, DraftDocument, ModelloDocumentale, SezioniDocument, SezioniDocumentResponse, TipoEntita, UpdateResponse, VerificaModello } from '../model/documento';
import { BaseHttpService } from '../basehttp.service';
import { DocumentiArchiviatiBrowseResponse, DocumentiDaArchiviareBrowse, DocumentiDaArchiviareBrowseResponse, DocumentoIsDaCancellareResponse, DraftSearch } from '../components/documenti-sospesi/documenti-sospesi';

@Injectable({
    providedIn: 'root'
})

export class DocumentiService {
    BASE_URL = '';
    DOCUMENTI_URL = 'api/Documenti';

    constructor(
        @Inject('BASE_URL') baseUrl: string,
        private http: HttpClient,
        private apiClient: BaseHttpService
    ) {
        this.BASE_URL = baseUrl;
        this.DOCUMENTI_URL = baseUrl + this.DOCUMENTI_URL;
    }

    public getSezioniModelliDocumentali(): Observable<SezioniDocumentResponse> {
        return this.http.get<SezioniDocumentResponse>(`${this.DOCUMENTI_URL}/getSezioniDocument`);
    }

    public getModelliDocumentaliSezione(nomeSezione: string, modelliSelected: string): Observable<ModelloDocumentale[]> {
        return this.http.get<ModelloDocumentale[]>(`${this.DOCUMENTI_URL}/GetModelliDocumentali/${nomeSezione}&${modelliSelected}`);
    }

    public getAllModelliDocumentali(): Observable<ModelloDocumentale[]> {
        return this.http.get<ModelloDocumentale[]>(`${this.DOCUMENTI_URL}/GetAllModelliDocumentali`);
    }

    public addOrUpdateModelloDocumentale(modelloDocumentale: ModelloDocumentale){
        return this.http.post<UpdateResponse>(`${this.DOCUMENTI_URL}/AddOrUpdateModelloDocumentale`, modelloDocumentale);
    }
    public getModelloUsato(modelloId: number){
        return this.http.get<VerificaModello>(`${this.DOCUMENTI_URL}/ModelloUsato/${modelloId}`);
    }

    deleteModelloDocumentale(documentoId: number): Observable<DeleteResponse> {
        return this.http.delete<DeleteResponse>(`${this.DOCUMENTI_URL}/DeleteModelloDocumentale/${documentoId}`);
    }

    /**
     * Metodo per recuperare i documenti da lavorare nella cartella Monitorata
     * @returns lista dei modelli documentali
     */
    getDraftDocumentAsync(): Observable<DocumentiDaArchiviareBrowseResponse> {
        return this.http.get<DocumentiDaArchiviareBrowseResponse>(`${this.DOCUMENTI_URL}/draft`);
    }

    /**
     * Metodo per recuperare i documenti lavorati nella cartella Monitorata
     * @returns lista dei modelli documentali
     */
    getDraftWorkedDocument(): Observable<DocumentiArchiviatiBrowseResponse> {
        return this.http.get<DocumentiArchiviatiBrowseResponse>(`${this.DOCUMENTI_URL}/draftWorked`);
    }

    /**
     * Metodo per recuperare i documenti archiviati nella cartella Monitorata
     * @returns lista dei modelli documentali
     */
    getSearchDraftDocument(draftSearch: DraftSearch) {
        return this.http.post<DocumentiDaArchiviareBrowseResponse>(`${this.DOCUMENTI_URL}/searchdraft`, draftSearch);
    }

    /**
     * Metodo per recuperare i documenti archiviati nella cartella Monitorata
     * @returns lista dei modelli documentali
     */
    changeIsDaCancellareDraftDocument(idDocumentoDraft: number, isDacancellareDraft: boolean) {
        return this.http.post<DocumentoIsDaCancellareResponse>(`${this.DOCUMENTI_URL}/changeDraft`, {idDocumentoDraft: idDocumentoDraft, isDaCancellareDraft: isDacancellareDraft});
    }

      /**
     * Metodo per recuperare i documenti archiviati nella cartella Monitorata
     * @returns lista dei modelli documentali
     */
      getDocumentoArchiviato(idDocumento: number): Observable<DocumentoCompleteResponse> {
        return this.http.get<DocumentoCompleteResponse>(`${this.DOCUMENTI_URL}/GetDraftDocumento/${idDocumento}`);
    }
    /**
     * Recupera documento
     */
    getDocumento(documentoId: number): Observable<DocumentoCompleteResponse> {
        return this.http.get<DocumentoCompleteResponse>(`${this.DOCUMENTI_URL}/GetDocumento/${documentoId}`);
    }

    /**
     * Metodo per recuperare i documenti associati ad un cup
     * @returns lista dei documenti
     */
    getAllDocumenti(idCup: number): Observable<Documento[]> {
        return this.http.get<Documento[]>(`${this.DOCUMENTI_URL}/GetAll/${idCup}`);
    }

     /**
     * Carica il documento da cartella monitorata a scenario scelto
     * @returns modello documentale
     */
     uploadDraftDocument(documento: DraftDocument) {
        return this.http.post<DocumentiDaArchiviareBrowse[]>(`${this.DOCUMENTI_URL}/draft`, documento);
    }

    /**
     * Crea un nuovo modello documentale
     * @returns modello documentale
     */
    createModelloDocumentale(modelloDocumentale: ModelloDocumentale): Observable<ModelloDocumentale> {
        return this.http.post<ModelloDocumentale>(`${this.DOCUMENTI_URL}/CreateModelloDocumentale`, modelloDocumentale);
    }

    /**
     * Crea un documento
     * @returns documento
     */
    createDocumento(documento: Documento): Observable<DocumentoResponse> {
        console.log("DOCUMENTO ", documento)
        const keys = JSON.stringify(documento.keys);
        const formData: FormData = new FormData();
        formData.append('CUPID', documento?.cupid?.toString());
        formData.append('EntityID', documento.entityID.toString());
        formData.append('File', documento.file);
        formData.append('EntityTipo', documento.entityTipo ? documento.entityTipo.toString() : '');
        formData.append('ModelloID', documento.modelloID.toString());
        formData.append('IdSezione', documento.idSezione.toString());
        formData.append('Keys.ID', documento.keys.id.toString());
        formData.append('Keys.Key1_Value', documento.keys.key1_Value);
        formData.append('Keys.Key2_Value', documento.keys.key2_Value.toJSON());
        formData.append('Keys.Key3_Value', documento.keys.key3_Value);
        documento.keys.key4_Value ? formData.append('Keys.Key4_Value', documento.keys.key4_Value) : null;
        documento.keys.key5_Value ? formData.append('Keys.Key5_Value', documento.keys.key5_Value) : null;
        documento.keys.key6_Value ? formData.append('Keys.Key6_Value', documento.keys.key6_Value) : null;
        documento.keys.key7_Value ? formData.append('Keys.Key7_Value', documento.keys.key7_Value) : null;
        documento.keys.key8_Value ? formData.append('Keys.Key8_Value', documento.keys.key8_Value) : null;
        documento.keys.key9_Value ? formData.append('Keys.Key9_Value', documento.keys.key9_Value) : null;
        documento.keys.key10_Value ? formData.append('Keys.Key10_Value', documento.keys.key10_Value) : null;
        formData.append('NomeFile', documento.file.name);
        documento?.idDocumentoBozza ? formData.append('IdDocumentoBozza', documento?.idDocumentoBozza?.toString()) : null;
        return this.apiClient.postForm(formData, `${this.DOCUMENTI_URL}/Add`);
    }

    /**
     * Recupera il Blob(dati) del documento di riferimento
     * @returns Blob(dati)
     */
    getBlobDocumento(documentoId: number): Observable<any> {
        return this.http.get<any>(`${this.DOCUMENTI_URL}/GetBlob/${documentoId}`);
    }

    /**
     * Consente di cancellare un documento
     */
    deleteDocumento(documentoId: number): Observable<boolean> {
        return this.http.delete<boolean>(`${this.DOCUMENTI_URL}/Delete/${documentoId}`);
    }

    /**
     * Filtra i documenti in base al modello selezionato
     */
    filterDocumento(documentoSearch: DocumentoSearch, sezioni: SezioniDocument[]): Observable<Documento[]> | null {
        if (!documentoSearch.cupId) {
            console.log('Sta provando a entrare anche se non ha ancora il CupId');
            return null;
        }
    
        let filterURL = `${this.DOCUMENTI_URL}/Browse?CUPID=${documentoSearch.cupId}`;
    
        if (documentoSearch.modelloIDs.length > 0) {
            documentoSearch.modelloIDs.forEach(iDs => {
                filterURL += `&ModelloIDs=${iDs}`;
            });
        }
    
        filterURL += `&EntityID=${documentoSearch.entityId || 0}`;

        filterURL += `&Sezione=${documentoSearch.sezione.id}`;
        
        if (documentoSearch.colonnaOrdinamento > 0) {
            filterURL += `&ColonnaOrdinamento=${documentoSearch.colonnaOrdinamento}`;
        }

        if (documentoSearch.colonnaOrdinamento > 0) {
            filterURL += `&Ordinamento=${documentoSearch.ordinamento}`;
        }
        
    
        return this.http.get<Documento[]>(filterURL);
    }   

    /**
     * Update nomeFile del documento
     */
    public updateNomeFile(documentoId: number, nomeFile: string): Observable<DocumentoKeyValueUpdate> {
        return this.http.put<DocumentoKeyValueUpdate>(`${this.DOCUMENTI_URL}/UpdateNomeFile?nomeFile=${nomeFile}&documentoId=${documentoId}`, {documentoId, nomeFile});
    }

    /**
     * Update key del documento
     */
    public updateKeyDocumento(eDocKeysValue: DocumentoKeyValueUpdate): Observable<DocumentoKeyValueUpdate> {
        return this.http.put<DocumentoKeyValueUpdate>(`${this.DOCUMENTI_URL}/UpdateChiaviModello`, eDocKeysValue);
    }

    async arrayBufferToBase64(blob: any) {
        var blobDoc = new Blob([blob],);
        var binary = '';
        var bytes = new Uint8Array(await blobDoc.arrayBuffer());
        var len = bytes.byteLength;
        for (var i = 0; i < len; i++) {
            binary += String.fromCharCode(bytes[i]);
        }
        return window.btoa(binary);
    }
        arrayToBlobBig(arrayToblob: any, mediaType: string) {
        const binStr = atob(arrayToblob);
        const len = binStr.length;
        const arr = new Uint8Array(len);
        for (let i = 0; i < len; i++) {
            arr[i] = binStr.charCodeAt(i);
        }
        return URL.createObjectURL(new Blob([arr], { type: "application/pdf" }));
    }
}