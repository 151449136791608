import { Font } from 'jspdf';
import { Injectable } from '@angular/core';

import { Cell, Workbook, Worksheet } from 'exceljs';

import * as fs from 'file-saver';

import { CUP } from '../model/cup';

import {
  QE,
  QECompare,
  QEExcelTotaliEsecuzione,
  QETotaliSezioniExcel,
} from '../model/qe';

import { ExcelReportData } from '../components/rbk-export-excel/rbk-export-excel';

import { DatePipe, DecimalPipe } from '@angular/common';

import { Fase1 } from '../model/fase1';
import { forEach } from 'lodash';

/**

 * Tipo di documeno per l'esportazione in excel

 */

export enum EsportaExcelTipo {
  Nessuno,

  QuadroEconomico = 1,

  Raffronto = 2,

  Esecuzione = 3,

  EsecuzioneEstesa = 4,

  QuadroEconomicoConTotaliSezioni = 5,

  QuadroEconomicoDettaglio = 6,
}

/**

 * Colonne per Excel Raffronto

 */

class ColonneRaffrontoExcel {
  titolo: string;

  descrizione: string;

  importoQEInEsame1: number | string;

  importoQEInEsame2: number | string;

  importoQEPrecedent1: number | string;

  importoQEPrecedent2: number | string;

  delta: number;
}

/**

 * Colonne per Excel quadro economico

 */

class ColonneQuadroEconomicoExcel {
  titolo: string;

  descrizione: string;

  importo1: number | string;

  importo2: number | string;

  importo3: number | string;

  importo4: number | string;

  importo5: number | string;

  importo6: number | string;
}

/**

 * Colonne per Excel quadro economico

 */

class ColonneQuadroEconomicoConTotaliExcel {
  titolo: string;

  descrizione: string;

  importo1: number | string;

  importo2: number | string;

  importoImpegno: number | string;

  importoGiustificativo: number | string;

  importoLiquidazione: number | string;

  importoMandati: number | string;
}

/**

 * ColonneEsecuzioneExcel

 */

class ColonneEsecuzioneExcel {
  descrizioneFase1: string;
  nci1: string;
  valoreFase1: number | string;

  descrizioneFase2: string;
  nci2: string;
  valoreFase2: number | string;

  descrizioneFase3: string;
  nci3: string;
  valoreFase3: number | string;

  descrizioneFase4: string;
  nci4: string;
  valoreFase4: number | string;

  constructor() {
    this.descrizioneFase1 = '';
    this.nci1 = '';
    this.valoreFase1 = '';

    this.descrizioneFase2 = '';
    this.nci2 = '';
    this.valoreFase2 = '';

    this.descrizioneFase3 = '';
    this.nci3 = '';
    this.valoreFase3 = '';

    this.descrizioneFase4 = '';
    this.nci4 = '';
    this.valoreFase4 = '';
  }
}

/**

 * Colonne per Excel esecuzione Estesa

 */

class ColonneEstesaEsecuzioneExcel {
  tipoFase1: string;

  dataFase1: string;

  numeroFase1: number | string;

  oggettoFase1: string;

  beneficiarioFase1: string;

  importoTotaleFase1: number | string;

  tipoFase2: string;

  dataFase2: string;

  numeroFase2: number | string;

  creditoreFase2: string;

  oggettoFase2: string;

  importoNettoFase2: number | string;

  cassaFase2: number | string;

  ivaFase2: number | string;

  importoTotaleFase2: number | string;

  dataFase3: string;

  numeroFase3: number | string;

  oggettoFase3: string;

  beneficiarioFase3: string;

  importoTotaleFase3: number | string;

  dataFase4: string;

  numeroFase4: number | string;

  importoFase4: number | string;

  beneficiarioFase4: string;

  file: string;

  constructor() {
    this.tipoFase1 = '';

    this.dataFase1 = '';

    this.numeroFase1 = '';

    this.oggettoFase1 = '';

    this.beneficiarioFase1 = '';

    this.importoTotaleFase1 = '';

    this.tipoFase2 = '';

    this.dataFase2 = '';

    this.numeroFase2 = '';

    this.creditoreFase2 = '';

    this.oggettoFase2 = '';

    this.importoNettoFase2 = '';

    this.cassaFase2 = '';

    this.ivaFase2 = '';

    this.importoTotaleFase2 = '';

    this.dataFase3 = '';

    this.numeroFase3 = '';

    this.oggettoFase3 = '';

    this.beneficiarioFase3 = '';

    this.importoTotaleFase3 = '';

    this.dataFase4 = '';

    this.numeroFase4 = '';

    this.importoFase4 = '';

    this.beneficiarioFase4 = '';

    this.file = '';
  }
}

@Injectable({ providedIn: 'root' })
export class ExportExcelService {
  /**

   * Formattazione per le celle decimal

   */

  public patternFormattaNumeri = '#,##0.00;[Red]-#,##0.00';

  /**

  * Formattazione per le celle Date

  */

  public patternFormattaData = '#,##0.00;[Red]-#,##0.00';

  /**

   * Pipe per la data

   */

  public datePipe = new DatePipe('it-IT');

  /**

  * Pipe per la data

  */

  public decimalPipe = new DecimalPipe('it-IT');

  /**

   * Exportazione del Raffronto in Excel

   */

  public exportExcelRaffronto(dati: ExcelReportData) {
    const title = dati.title;

    const qeCompare: QECompare = dati.qeCompare;

    const cup = dati.cup;

    let data: any[] = [];

    const labelqeFirst = qeCompare.firstQE.publishedAt
      ? 'QE in Esame'
      : 'Bozza';

    const labelqeSecond = qeCompare.firstQE.publishedAt
      ? 'QE Precedente'
      : 'QE Attuale';

    const righeExcel: ColonneRaffrontoExcel[] = [];

    let totaleInterventoFirst = 0;

    let totaleInterventoSecond = 0;

    for (let itemLivello1 of qeCompare.items) {
      const nomeSezioneLivello1 =
        itemLivello1.firstSectionTitle || itemLivello1.secondSectionTitle;

      const newRow = new ColonneRaffrontoExcel();

      newRow.titolo = `${nomeSezioneLivello1}  ${itemLivello1.firstSectionDescription}`;

      newRow.descrizione = ``;

      righeExcel.push(newRow);

      /* let totaleFirst = 0;

      let totaleSecond = 0;
 */

      for (let itemLivello2 of itemLivello1.items) {
        if (
          itemLivello2.firstSubIndex === 0 &&
          itemLivello2.secondSubIndex === 0
        ) {
          var nomeSezioneLivello2 = `${nomeSezioneLivello1}.${
            itemLivello2.firstIndex || itemLivello2.secondIndex
          }`;

          const newRow = new ColonneRaffrontoExcel();

          newRow.titolo = nomeSezioneLivello2;

          newRow.descrizione = itemLivello2.firstSectionTitle;

          newRow.importoQEPrecedent1 = '';

          newRow.importoQEPrecedent2 = itemLivello2.secondTotal;

          newRow.importoQEInEsame1 = '';

          newRow.importoQEInEsame2 = itemLivello2.firstTotal;

          newRow.delta = itemLivello2.firstTotal - itemLivello2.secondTotal;

          righeExcel.push(newRow);
        }

        if (
          (itemLivello2.firstSubIndex !== 0 &&
            itemLivello2.secondSubIndex !== 0) ||
          (itemLivello2.firstSubIndex !== 0 &&
            itemLivello2.secondSectionQEMainId === 0) ||
          (itemLivello2.firstSectionQEMainId === 0 &&
            itemLivello2.secondSubIndex !== 0)
        ) {
          var nomeSezioneLivello3 = `${nomeSezioneLivello2}.${
            itemLivello2.firstSubIndex === 0
              ? itemLivello2.secondSubIndex
              : itemLivello2.firstSubIndex
          }`;

          const newRow = new ColonneRaffrontoExcel();

          newRow.titolo = nomeSezioneLivello3;

          newRow.descrizione = itemLivello2.firstSectionTitle;

          newRow.importoQEPrecedent1 = '';

          newRow.importoQEPrecedent2 = itemLivello2.secondTotal;

          newRow.importoQEInEsame1 = '';

          newRow.importoQEInEsame2 = itemLivello2.firstTotal;

          newRow.delta = itemLivello2.firstTotal - itemLivello2.secondTotal;

          righeExcel.push(newRow);

          /* totaleFirst += itemLivello2.firstTotal;
  
          totaleSecond += itemLivello2.secondTotal; */
        }

        /* for (let itemLivello3 of itemLivello2.items) {

          const nomeSezioneLivello3 = `${nomeSezioneLivello2}.${itemLivello3.firstIndex}`;

          const newRow = new ColonneRaffrontoExcel();

          newRow.titolo = nomeSezioneLivello3;

          newRow.descrizione = itemLivello3.firstSectionTitle;

          newRow.importoQEPrecedent1 = itemLivello3.secondTotal;

          newRow.importoQEPrecedent2 = '';

          newRow.importoQEInEsame1 = itemLivello3.firstTotal;

          newRow.importoQEInEsame2 = '';

          newRow.delta = itemLivello3.firstTotal - itemLivello3.secondTotal;

          righeExcel.push(newRow);

        } */
      }

      totaleInterventoFirst += itemLivello1.secondTotal;

      totaleInterventoSecond += itemLivello1.firstTotal;

      const totaleRow = new ColonneRaffrontoExcel();

      totaleRow.importoQEPrecedent2 = itemLivello1.secondTotal;

      totaleRow.importoQEInEsame2 = itemLivello1.firstTotal;

      totaleRow.titolo = `Totale Sezione ${nomeSezioneLivello1}`;

      righeExcel.push(totaleRow);
    }

    const totaleRow = new ColonneRaffrontoExcel();

    totaleRow.importoQEPrecedent2 = totaleInterventoFirst;

    totaleRow.importoQEInEsame2 = totaleInterventoSecond;

    totaleRow.titolo = `Totale Intervento`;

    righeExcel.push(totaleRow);

    righeExcel.forEach((row: ColonneRaffrontoExcel) => {
      data.push(Object.values(row));
    });

    //Create a workbook with a worksheet

    let workbook = new Workbook();

    let worksheet = workbook.addWorksheet('Quadro di raffronto');

    worksheet.getColumn(2).width = 50;

    worksheet.getColumn(2).alignment = { wrapText: true };

    worksheet.getColumn(3).width = 16;

    worksheet.getColumn(3).alignment = { horizontal: 'right' };

    worksheet.getColumn(3).numFmt = this.patternFormattaNumeri;

    worksheet.getColumn(4).width = 16;

    worksheet.getColumn(4).alignment = { horizontal: 'right' };

    worksheet.getColumn(4).numFmt = this.patternFormattaNumeri;

    worksheet.getColumn(5).width = 16;

    worksheet.getColumn(5).alignment = { horizontal: 'right' };

    worksheet.getColumn(5).numFmt = this.patternFormattaNumeri;

    worksheet.getColumn(6).width = 16;

    worksheet.getColumn(6).alignment = { horizontal: 'right' };

    worksheet.getColumn(6).numFmt = this.patternFormattaNumeri;

    worksheet.getColumn(7).width = 16;

    worksheet.getColumn(7).alignment = { horizontal: 'right' };

    worksheet.getColumn(7).numFmt = this.patternFormattaNumeri;

    worksheet.mergeCells('A1', 'G4');

    let titleRow = worksheet.getCell('A1');

    titleRow.value = title;

    titleRow.font = { bold: true };

    titleRow.alignment = {
      vertical: 'middle',
      horizontal: 'center',
      wrapText: true,
    };

    let descrizioneRow = worksheet.addRow([]);

    worksheet.mergeCells(`A${descrizioneRow.number}:G${descrizioneRow.number}`);

    let descrizioneCell = worksheet.getCell(`A${descrizioneRow.number}`);

    descrizioneCell.value = cup.descrizione;

    descrizioneCell.alignment = {
      vertical: 'middle',
      horizontal: 'center',
      wrapText: true,
    };

    let descrizione2Row = worksheet.addRow([]);

    worksheet.mergeCells(
      `A${descrizione2Row.number}:G${descrizione2Row.number}`
    );

    let descrizione2Cell = worksheet.getCell(`A${descrizioneRow.number}`);

    descrizione2Cell.value = 'Quadro di raffronto';

    descrizione2Cell.alignment = {
      vertical: 'middle',
      horizontal: 'center',
      wrapText: true,
    };

    const intestazione = worksheet.addRow([]);

    worksheet.mergeCells(`C${intestazione.number}:D${intestazione.number}`);

    const intestazione1Cell = worksheet.getCell(`C${intestazione.number}`);

    intestazione1Cell.value = labelqeSecond;

    intestazione1Cell.font = { bold: true, size: 14 };

    intestazione1Cell.alignment = {
      vertical: 'middle',
      horizontal: 'center',
      wrapText: true,
    };

    worksheet.mergeCells(`E${intestazione.number}:F${intestazione.number}`);

    const intestazione2Cell = worksheet.getCell(`E${intestazione.number}`);

    intestazione2Cell.value = labelqeFirst;

    intestazione2Cell.font = { bold: true, size: 14 };

    intestazione2Cell.alignment = {
      vertical: 'middle',
      horizontal: 'center',
      wrapText: true,
    };

    const intestazione3Cell = worksheet.getCell(`G${intestazione.number}`);

    intestazione3Cell.value = '+/-';

    intestazione3Cell.font = { bold: true };

    intestazione3Cell.alignment = {
      vertical: 'middle',
      horizontal: 'center',
      wrapText: true,
    };

    // Adding Data with Conditional Formatting

    data.forEach((d) => {
      if (typeof d[0] === 'number' || typeof d[1] === 'number') {
        const totale = worksheet.addRow([]);

        worksheet.mergeCells(`A${totale.number}:C${totale.number}`);

        let titleTotal1 = worksheet.getCell(`A${totale.number}`);

        titleTotal1.value = `${d[2]}`;

        titleTotal1.font = { bold: true };

        titleTotal1.alignment = { horizontal: 'right' };

        titleTotal1.fill = {
          type: 'pattern',

          pattern: 'solid',

          fgColor: { argb: 'F6F6F6' },
        };

        let total1Row = worksheet.getCell(`D${totale.number}`);

        total1Row.value = d[0];

        total1Row.font = { bold: true };

        total1Row.alignment = { horizontal: 'right' };

        total1Row.fill = {
          type: 'pattern',

          pattern: 'solid',

          fgColor: { argb: 'F6F6F6' },
        };

        let titleTotal2 = worksheet.getCell(`E${totale.number}`);

        titleTotal2.value = `${d[2]}`;

        titleTotal2.font = { bold: true };

        titleTotal2.alignment = { horizontal: 'right' };

        titleTotal2.fill = {
          type: 'pattern',

          pattern: 'solid',

          fgColor: { argb: 'F6F6F6' },
        };

        let total2Row = worksheet.getCell(`F${totale.number}`);

        total2Row.value = d[1];

        total2Row.font = { bold: true };

        total2Row.alignment = { horizontal: 'right' };

        total2Row.fill = {
          type: 'pattern',

          pattern: 'solid',

          fgColor: { argb: 'F6F6F6' },
        };

        let totalDeltaRow = worksheet.getCell(`G${totale.number}`);

        totalDeltaRow.value = d[1] - d[0];

        totalDeltaRow.font = { bold: true };

        totalDeltaRow.alignment = { horizontal: 'right' };

        totalDeltaRow.fill = {
          type: 'pattern',

          pattern: 'solid',

          fgColor: { argb: 'F6F6F6' },
        };
      } else if (typeof d[0] === 'string' && d[0].split('.').length === 1) {
        // Sezione

        const sezione = worksheet.addRow([`SEZIONE ${d[0]}`]);

        // Allineamento

        sezione.getCell(1).alignment = {
          vertical: 'middle',
          horizontal: 'center',
        };

        sezione.getCell(1).font = { size: 14 };

        sezione.height = 30;

        worksheet.mergeCells(`A${sezione.number}:G${sezione.number}`);
      } else if (typeof d[0] === 'string' && d[0].split('.').length === 2) {
        console.log('HEADER: ', d);

        // Header

        const header = worksheet.addRow(d);

        header.getCell(1).font = { bold: true };

        header.getCell(2).font = { bold: true };

        header.getCell(4).font = { bold: true };

        header.getCell(5).font = { bold: true };

        header.getCell(6).font = { bold: true };

        header.getCell(7).font = { bold: true };
      } else if (typeof d[0] === 'string' && d[0].split('.').length > 2) {
        // Item

        console.log('ITEM: ', d);

        const item = worksheet.addRow(d);

        item.getCell(1).alignment = { horizontal: 'right' };

        item.getCell(2).alignment = { horizontal: 'right' };

        item.getCell(3).alignment = { horizontal: 'right' };

        item.getCell(4).alignment = { horizontal: 'right' };

        item.getCell(5).alignment = { horizontal: 'right' };

        item.getCell(6).alignment = { horizontal: 'right' };

        item.getCell(7).alignment = { horizontal: 'right' };
      }
    });

    //Generate & Save Excel File

    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });

      fs.saveAs(blob, title.substring(0, 100) + '.xlsx');
    });
  }

  public printRow(
    worksheet: Worksheet,
    i: number,
    qeTotaliEsecuzione: QEExcelTotaliEsecuzione
  ) {
    let TotaleImpegno: number = 0;
    let TotaleGiustificiativo: number = 0;
    let TotaleLiquidazioni: number = 0;
    let indeximpegni = i;
    let NumeroGiustificativi: number = 0;
    let NumeroLiquidazioni: number = 0;
    qeTotaliEsecuzione.impegni.forEach((impegno) => {
      let indexGiustificativo = indeximpegni - 1;
      let indexLiquidazione = indexGiustificativo;
      let numLiquidazioni = 0;
      TotaleImpegno = TotaleImpegno + impegno.importo;

      if (impegno.giustificativi) {
        this.printStringCell(worksheet,'', 'G' + indeximpegni  );
        this.printStringCell(worksheet,'', 'H' + indeximpegni  );
        if (impegno.giustificativi.length === 0) {
          this.printStringCell(worksheet,'', 'I' + indeximpegni  );
          this.printStringCell(worksheet,'', 'J' + indeximpegni  );
          NumeroLiquidazioni = NumeroLiquidazioni + 1;
        }
      } 

      impegno.giustificativi.forEach((giustificativo) => {
        NumeroGiustificativi = NumeroGiustificativi + 1;
        TotaleGiustificiativo = TotaleGiustificiativo + giustificativo.importo;
        indexGiustificativo = indexGiustificativo + 1;
        indexLiquidazione = indexGiustificativo - 1;
        this.printNumberCell(
          worksheet,
          giustificativo.importo ? giustificativo.importo : null,
          `H${indexGiustificativo}`
        );
        this.printStringCell(
          worksheet,
          giustificativo.atto,
          `G${indexGiustificativo}`
        );
        if(giustificativo.liquidazioni || giustificativo.liquidazioni.length == 0){
          this.printStringCell(worksheet,'', 'I' + indexGiustificativo  );
          this.printStringCell(worksheet,'', 'J' + indexGiustificativo  );
        }
  
        giustificativo.liquidazioni.forEach((liquidazione) => {
          NumeroLiquidazioni = NumeroLiquidazioni + 1;
          numLiquidazioni = numLiquidazioni + 1;
          TotaleLiquidazioni = TotaleLiquidazioni + liquidazione.importo;
          indexLiquidazione = indexLiquidazione + 1;
          this.printNumberCell(
            worksheet,
            liquidazione.importo ? liquidazione.importo : null,
            `J${indexLiquidazione}`
          );
          this.printStringCell(
            worksheet,
            liquidazione.atto,
            `I${indexLiquidazione}`
          );
        });
        if (indexLiquidazione < indexGiustificativo) {
          indexLiquidazione = indexGiustificativo;
        }
        worksheet.mergeCells(`H${indexGiustificativo}:H${indexLiquidazione}`);
        this.printNumberCell(worksheet, giustificativo.importo ? giustificativo.importo : null, `H${indexGiustificativo}`);
        worksheet.mergeCells(`G${indexGiustificativo}:G${indexLiquidazione}`);
        this.printStringCell(worksheet, giustificativo.atto, `G${indexGiustificativo}`);
        let numeroLiquidazioni = giustificativo.liquidazioni.length;
        if (numeroLiquidazioni > 1) {
          indexGiustificativo = indexGiustificativo + numeroLiquidazioni - 1;
        } else {
          indexGiustificativo = indexGiustificativo;
        }
      });
      if (indexGiustificativo < indeximpegni) {
        indexGiustificativo = indeximpegni;
        indexLiquidazione = indeximpegni;
      }
      worksheet.mergeCells(`F${indeximpegni}:F${indexLiquidazione}`);
      this.printNumberCell(worksheet, impegno.importo ? impegno.importo : null, `F${indeximpegni}`);
      worksheet.mergeCells(`E${indeximpegni}:E${indexLiquidazione}`);
      const cellE = this.printStringCell(worksheet, impegno.beneficiario, `E${indeximpegni}`);
      cellE.alignment = { horizontal: 'center', vertical: 'middle', wrapText: true };
      worksheet.mergeCells(`D${indeximpegni}:D${indexLiquidazione}`);
      this.printStringCell(worksheet, impegno.atto, `D${indeximpegni}`);
      const numeroGiustificativi = impegno.giustificativi.length;
      if (numeroGiustificativi > 1) {
        indeximpegni = indeximpegni + (indexLiquidazione -indeximpegni) + 1;
      } else {
        indeximpegni = indeximpegni + 1;
      }
    });

    // const impegno = qeTotaliEsecuzione.impegni[qeTotaliEsecuzione.impegni.length - 1];
    // if (impegno && impegno.giustificativi && (impegno.giustificativi.length === 1 || impegno.giustificativi.length === 0)) {
    //   indeximpegni = indeximpegni - 1;
    // } else {
    //   indeximpegni = indeximpegni - 2;
    // }
    indeximpegni = indeximpegni - 1;
    if (indeximpegni < i) {
      indeximpegni = i;
    }
    worksheet.mergeCells(`A${i}:A${indeximpegni}`);
    this.printStringCell(worksheet, `${qeTotaliEsecuzione.qeIndice}`, 'A' + i);

    this.printStringCell(
      worksheet,
      `${qeTotaliEsecuzione.title}.${qeTotaliEsecuzione.qeIndice}`,
      'A' + i
    );

    worksheet.mergeCells(`B${i}:B${indeximpegni}`);
    const cellB = this.printStringCell(worksheet, qeTotaliEsecuzione.descrizione, 'B' + i);
    cellB.alignment = { horizontal: 'center', vertical: 'middle', wrapText: true };
    worksheet.mergeCells(`C${i}:C${indeximpegni}`);
    this.printNumberCell(
      worksheet,
      qeTotaliEsecuzione.qeIndiceImporto,
      'C' + i
    );
    let indexTotali = indeximpegni + 1;
    if (qeTotaliEsecuzione.impegni && qeTotaliEsecuzione.impegni.length > 0) {
      var customCell = this.printStringCell(
        worksheet,
        `Totale`,
        'E' + indexTotali
      );
      customCell.font = { bold: true };
      customCell.fill = {
        type: 'pattern',
        bgColor: { argb: 'FFFFFF' },
        pattern: 'solid',
        fgColor: { argb: 'd3d3d3' },
      };
      customCell = this.printNumberCell(
        worksheet,
        TotaleImpegno,
        'F' + indexTotali
      );      
      customCell.font = { bold: true };
      customCell.fill = {
        type: 'pattern',
        bgColor: { argb: 'FFFFFF' },
        pattern: 'solid',
        fgColor: { argb: 'd3d3d3' },
      };
      customCell = this.printStringCell(
        worksheet,
        '',
        'G' + indexTotali
      );
      customCell.fill = {
        type: 'pattern',
        bgColor: { argb: 'FFFFFF' },
        pattern: 'solid',
        fgColor: { argb: 'd3d3d3' },
      };

      if (NumeroGiustificativi > 0) {
        customCell = this.printStringCell(worksheet, '', 'G' + indexTotali);
        customCell.font = { bold: true };
        customCell = this.printNumberCell(
          worksheet,
          TotaleGiustificiativo,
          'H' + indexTotali
        );
        customCell.fill = {
          type: 'pattern',
          bgColor: { argb: 'FFFFFF' },
          pattern: 'solid',
          fgColor: { argb: 'd3d3d3' },
        };
        customCell.font = { bold: true };
        customCell = this.printStringCell(
          worksheet,
          `Totale`,
          'E' + indexTotali
        );
        customCell.font = { bold: true };        
        customCell.fill = {
          type: 'pattern',
          bgColor: { argb: 'FFFFFF' },
          pattern: 'solid',
          fgColor: { argb: 'd3d3d3' },
        };
        if (NumeroLiquidazioni > 0) {
          if(TotaleLiquidazioni) {
            customCell = this.printStringCell(worksheet, '', 'I' + indexTotali);
            customCell.fill = {
              type: 'pattern',
              bgColor: { argb: 'FFFFFF' },
              pattern: 'solid',
              fgColor: { argb: 'd3d3d3' },
            };
            customCell.font = { bold: true };
            customCell = this.printNumberCell(
              worksheet,
              TotaleLiquidazioni,
              'J' + indexTotali
            );
            customCell.fill = {
              type: 'pattern',
              bgColor: { argb: 'FFFFFF' },
              pattern: 'solid',
              fgColor: { argb: 'd3d3d3' },
            };
            customCell.font = { bold: true };
          }
        }
      } else {
        customCell = this.printStringCell(worksheet, '', 'G' + indexTotali);
        customCell.fill = {
          type: 'pattern',
          bgColor: { argb: 'FFFFFF' },
          pattern: 'solid',
          fgColor: { argb: 'd3d3d3' },
        };
        customCell = this.printStringCell(worksheet, '', 'H' + indexTotali);
        customCell.fill = {
          type: 'pattern',
          bgColor: { argb: 'FFFFFF' },
          pattern: 'solid',
          fgColor: { argb: 'd3d3d3' },
        };
      }
    } else {
      customCell =  this.printStringCell(worksheet,'', 'E' + indexTotali  );
      customCell.fill = {
        type: 'pattern',
        bgColor: { argb: 'FFFFFF' },
        pattern: 'solid',
        fgColor: { argb: 'd3d3d3' },
      };
      customCell =    this.printStringCell(worksheet,'', 'F' + indexTotali  );
      customCell.fill = {
        type: 'pattern',
        bgColor: { argb: 'FFFFFF' },
        pattern: 'solid',
        fgColor: { argb: 'd3d3d3' },
      };
    }  

    return indeximpegni + 2;
  }

  public printStringCell(
    worksheet: Worksheet,
    value: string,
    coordinate: string
  ): Cell {
    let cell = worksheet.getCell(coordinate);
    cell.alignment = { horizontal: 'center', vertical: 'middle' };
    cell.value = value;
    cell.border = {
      top: { style: 'thin' },
      left: { style: 'thin' },
      bottom: { style: 'thin' },
      right: { style: 'thin' },
    };
    return cell;
  }

  public printNumberCell(
    worksheet: Worksheet,
    value: number,
    coordinate: string
  ): Cell {
    let cell = worksheet.getCell(coordinate);
    cell.alignment = { horizontal: 'right', vertical: 'middle', indent: 1 };
    cell.value = value;
    cell.numFmt = this.patternFormattaNumeri;
    cell.border = {
      top: { style: 'thin' },
      left: { style: 'thin' },
      bottom: { style: 'thin' },
      right: { style: 'thin' },
    };
    return cell;
  }

  public printBorderCell(cell: Cell) {
    cell.border = {
      top: { style: 'thin' },
      left: { style: 'thin' },
      bottom: { style: 'thin' },
      right: { style: 'thin' },
    };
  }

  public exportExcelConQuadroEconomicoDettaglio(dati: ExcelReportData) {
    const dtos: QEExcelTotaliEsecuzione[] = dati.qeTotaliEsecuzione;

    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet('Quadro Economico Dettaglio', { views:[
      {showGridLines:false} ] } );
    //Riga di partenza
    worksheet.mergeCells('A1', 'J1');
    var cellIntestazione = this.printStringCell(
      worksheet,
      'Report Dettaglio Spese Progetto',
      'A1'
    );
    cellIntestazione.font = { bold: true, size: 20 };
    worksheet.mergeCells('A2', 'J2');
    var today = new Date();
    var dd = today.getDate();
    var mm = today.getMonth() + 1;
    var yyyy = today.getFullYear();
    if (dd < 10) {
      dd = 0 + dd;
    }
    if (mm < 10) {
      mm = 0 + mm;
    }
    var formatToday = dd + '-' + mm + '-' + yyyy;
    cellIntestazione = this.printStringCell(
      worksheet,
      `${dati.title}`,
      'A2'
    );
    cellIntestazione.font  = { bold: true, size: 20 };
    cellIntestazione = this.printStringCell(
      worksheet,
      `Report generato il ${formatToday}`,
      'A3'
    );
    cellIntestazione.font = { bold: true, size: 11, italic: true };
     worksheet.mergeCells('A3', 'J3'); 
    let i = 5;
    worksheet.mergeCells('A' + i, 'C' + i);
    worksheet.getColumn('A').width = 15;
    worksheet.getColumn('B').width = 30;
    worksheet.getColumn('C').width = 15;
    worksheet.getColumn('D').width = 20;
    worksheet.getColumn('E').width = 20;
    worksheet.getColumn('F').width = 18;
    worksheet.getColumn('G').width = 18;
    worksheet.getColumn('H').width = 15;
    worksheet.getColumn('I').width = 18;
    worksheet.getColumn('J').width = 15;

    let QErow = worksheet.getCell('A' + i);
    QErow.alignment = { horizontal: 'center' };
    QErow.fill = {
      type: 'pattern',
      bgColor: { argb: 'FFFFFF' },
      pattern: 'solid',
      fgColor: { argb: '003153' },
    };
    QErow.font = { color: { argb: 'FFFFFF' } };

    QErow.value = 'QE';
    this.printBorderCell(QErow);
    worksheet.mergeCells('D' + i, 'F' + i);
    let impegniRow = worksheet.getCell('D' + i);

    impegniRow.fill = {
      type: 'pattern',
      bgColor: { argb: 'FFFFFF' },
      pattern: 'solid',
      fgColor: { argb: '003153' },
    };
    impegniRow.font = { color: { argb: 'FFFFFF' } };

    impegniRow.alignment = { horizontal: 'center' };
    impegniRow.value = 'Impegni';
    this.printBorderCell(impegniRow);
    worksheet.mergeCells('G' + i, 'H' + i);
    let giustificativiRow = worksheet.getCell('G' + i);
    giustificativiRow.alignment = { horizontal: 'center' };
    giustificativiRow.value = 'Giustificativi';
    giustificativiRow.fill = {
      type: 'pattern',
      bgColor: { argb: 'FFFFFF' },
      pattern: 'solid',
      fgColor: { argb: '003153' },
    };
    giustificativiRow.font = { color: { argb: 'FFFFFF' } };
    this.printBorderCell(giustificativiRow);

    worksheet.mergeCells('I' + i, 'J' + i);
    let liquidazioniRow = worksheet.getCell('I' + i);
    liquidazioniRow.alignment = { horizontal: 'center' };
    liquidazioniRow.value = 'Liquidazioni';
    liquidazioniRow.fill = {
      type: 'pattern',
      bgColor: { argb: 'FFFFFF' },
      pattern: 'solid',
      fgColor: { argb: '003153' },
    };
    liquidazioniRow.font = { color: { argb: 'FFFFFF' } };

    this.printBorderCell(liquidazioniRow);
    i = i + 1;
    let intRiga = worksheet.getCell('A' + i);
    intRiga.alignment = { horizontal: 'center' };
    this.printBorderCell(intRiga);
    intRiga.value = 'Cod. Riga';
    intRiga = worksheet.getCell('B' + i);
    intRiga.alignment = { horizontal: 'center' };
    this.printBorderCell(intRiga);
    intRiga.value = 'Descrizione';
    intRiga = worksheet.getCell('C' + i);
    intRiga.alignment = { horizontal: 'center' };
    this.printBorderCell(intRiga);
    intRiga.value = 'Valore';
    intRiga = worksheet.getCell('D' + i);
    intRiga.alignment = { horizontal: 'center' };
    this.printBorderCell(intRiga);
    intRiga.value = 'N. atto e data';
    intRiga = worksheet.getCell('E' + i);
    intRiga.alignment = { horizontal: 'center' };
    this.printBorderCell(intRiga);
    intRiga.value = 'Beneficiario';
    intRiga = worksheet.getCell('F' + i);
    intRiga.alignment = { horizontal: 'center' };
    this.printBorderCell(intRiga);
    intRiga.value = 'Valore';
    intRiga = worksheet.getCell('G' + i);
    intRiga.alignment = { horizontal: 'center' };
    this.printBorderCell(intRiga);
    intRiga.value = 'N. atto e data';
    intRiga = worksheet.getCell('H' + i);
    intRiga.alignment = { horizontal: 'center' };
    this.printBorderCell(intRiga);
    intRiga.value = 'Valore';
    intRiga = worksheet.getCell('I' + i);
    intRiga.alignment = { horizontal: 'center' };
    this.printBorderCell(intRiga);
    intRiga.value = 'N. atto e data';
    intRiga = worksheet.getCell('J' + i);
    this.printBorderCell(intRiga);
    intRiga.value = 'Valore';
    intRiga.alignment = { horizontal: 'center' };
    i = i +1;
    dtos.forEach((qeTotaliEsecuzione) => {
      if (qeTotaliEsecuzione.impegni && qeTotaliEsecuzione.impegni.length > 0) {
        i = this.printRow(worksheet, i, qeTotaliEsecuzione);
        i = i + 1;
      }
    });
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
      fs.saveAs(blob, 'QEEXCELDETTAGLIO' + '.xlsx');
    });
  }

  /**

    * Exportazione del Quadro economico in Excel

    */

  public exportExcelQadroEconomico(dati: ExcelReportData) {
    const title = dati.title;

    const cup: CUP = dati.cup;

    const qe: QE = dati.qe;

    let data: any[] = [];

    let numSection = qe.sections.length;

    let totaleProgetto = 0;

    let labelTotaleProgetto = 'Totale Progetto (';

    // Creazione del JSON per il quadro economico

    const righeExcel: ColonneQuadroEconomicoExcel[] = [];

    for (let section of qe.sections) {
      numSection--;

      const nomeSezione = section.title;
      const descrizioneSezione = !!section.description
        ? ` - ${section.description}`
        : '';
      const newRow = new ColonneQuadroEconomicoExcel();

      newRow.titolo = `${nomeSezione}${descrizioneSezione}`;
      newRow.descrizione = `${descrizioneSezione.replace(' - ', '')}`;
      labelTotaleProgetto += `${nomeSezione} ${numSection !== 0 ? '+' : ''}`;

      righeExcel.push(newRow);

      for (const item of section.items) {
        const newRow = new ColonneQuadroEconomicoExcel();

        newRow.titolo = `${nomeSezione}.${item.index}${
          item.subIndex ? '.' + item.subIndex : ''
        }`;

        newRow.descrizione = `${item.title}`;

        newRow.importo1 = '';

        newRow.importo2 = item.total;

        righeExcel.push(newRow);
      }

      const totaleRow = new ColonneQuadroEconomicoExcel();

      totaleRow.importo2 = section.total;

      totaleRow.titolo = `Totale Sezione ${nomeSezione}`;

      righeExcel.push(totaleRow);

      totaleProgetto += section.total;
    }

    const totaleRow = new ColonneQuadroEconomicoExcel();

    totaleRow.importo2 = totaleProgetto;

    totaleRow.titolo = `${labelTotaleProgetto})`;

    righeExcel.push(totaleRow);

    righeExcel.forEach((row: ColonneQuadroEconomicoExcel) => {
      data.push(Object.values(row));
    });

    //Create a workbook with a worksheet

    let workbook = new Workbook();

    let worksheet = workbook.addWorksheet('Quadro economico');

    // Allargo la colonna per la descrizione

    worksheet.getColumn(2).width = 50;

    worksheet.getColumn(2).alignment = { wrapText: true };

    worksheet.getColumn(3).width = 14;

    worksheet.getColumn(3).alignment = { horizontal: 'right' };

    worksheet.getColumn(3).numFmt = this.patternFormattaNumeri;

    worksheet.getColumn(4).width = 14;

    worksheet.getColumn(4).alignment = { horizontal: 'right' };

    worksheet.getColumn(4).numFmt = this.patternFormattaNumeri;

    //Add Row and formatting

    worksheet.mergeCells('A1', 'D4');

    let titleRow = worksheet.getCell('A1');

    titleRow.value = title;

    titleRow.font = { bold: true };

    titleRow.alignment = {
      vertical: 'middle',
      horizontal: 'center',
      wrapText: true,
    };

    let descrizioneRow = worksheet.addRow([]);

    worksheet.mergeCells(`A${descrizioneRow.number}:D${descrizioneRow.number}`);

    let descrizioneCell = worksheet.getCell(`A${descrizioneRow.number}`);

    descrizioneCell.value = cup.descrizione;

    descrizioneCell.alignment = {
      vertical: 'middle',
      horizontal: 'center',
      wrapText: true,
    };

    // creazione della descrizione

    const atto = (qe.detail && qe.detail.atto) || '';

    const numero = (qe.detail && qe.detail.numero) || 0;

    const dataQe = (qe.detail && qe.detail.data) || new Date();

    let descrizione = '';

    if (atto && numero) {
      descrizione = ` approvato con atto ${atto} numero ${numero} del ${this.datePipe.transform(
        dataQe,
        'dd MMM yyyy'
      )}`;
    }

    let descrizione2Row = worksheet.addRow([]);

    worksheet.mergeCells(
      `A${descrizione2Row.number}:D${descrizione2Row.number}`
    );

    let descrizione2Cell = worksheet.getCell(`A${descrizioneRow.number}`);

    descrizione2Cell.value = `Quadro economico ${descrizione}`;

    descrizione2Cell.alignment = {
      vertical: 'middle',
      horizontal: 'center',
      wrapText: true,
    };

    // Adding Data with Conditional Formatting

    data.forEach((d) => {
      if (typeof d[0] === 'number') {
        const totale = worksheet.addRow([]);

        worksheet.mergeCells(`A${totale.number}:C${totale.number}`);

        let titleTotalRow = worksheet.getCell(`A${totale.number}`);

        titleTotalRow.value = d[1];

        titleTotalRow.font = { bold: true };

        titleTotalRow.alignment = { horizontal: 'right' };

        titleTotalRow.fill = {
          type: 'pattern',

          pattern: 'solid',

          fgColor: { argb: 'F6F6F6' },
        };

        let totalRow = worksheet.getCell(`D${totale.number}`);

        totalRow.value = d[0];

        totalRow.font = { bold: true };

        totalRow.alignment = { horizontal: 'right' };

        totalRow.fill = {
          type: 'pattern',

          pattern: 'solid',

          fgColor: { argb: 'F6F6F6' },
        };
      } else if (typeof d[0] === 'string' && d[0].split('.').length === 1) {
        // Sezione

        const sezione = worksheet.addRow([`SEZIONE ${d[0]}`]);

        // Allineamento

        sezione.getCell(1).alignment = {
          vertical: 'middle',
          horizontal: 'center',
        };

        sezione.height = 30;

        worksheet.mergeCells(`A${sezione.number}:D${sezione.number}`);
      } else if (typeof d[0] === 'string' && d[0].split('.').length === 2) {
        // Header

        const header = worksheet.addRow(d);

        header.getCell(1).font = { bold: true };

        header.getCell(2).font = { bold: true };

        header.getCell(4).font = { bold: true };
      } else if (typeof d[0] === 'string' && d[0].split('.').length > 2) {
        // Item

        const item = worksheet.addRow(d);

        item.getCell(1).alignment = { horizontal: 'right' };

        item.getCell(2).alignment = { horizontal: 'right', wrapText: true };

        item.getCell(4).alignment = { horizontal: 'right' };
      }
    });

    //Generate & Save Excel File

    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });

      fs.saveAs(blob, title.substring(0, 100) + '.xlsx');
    });
  }

  public exportExcelConTotaliSezioni(dati: ExcelReportData) {
    const title = dati.title;

    const cup: CUP = dati.cup;

    const qe: QETotaliSezioniExcel = dati.qeConTotaliSezione;

    let data: any[] = [];

    let numSection = qe.sections.length;

    let totaleProgetto = 0;

    let labelTotaleProgetto = 'Totale Progetto (';

    // Creazione del JSON per il quadro economico

    const righeExcel: ColonneQuadroEconomicoConTotaliExcel[] = [];
    let totSommaImpegno = 0;
    let totSommaGiustificativo = 0;
    let totSommaLiquidazioni = 0;
    let totSommaMandato = 0;
    for (let section of qe.sections) {
      numSection--;

      const nomeSezione = section.title;

      const newRow = new ColonneQuadroEconomicoConTotaliExcel();

      newRow.titolo = `${nomeSezione}`;

      newRow.descrizione = ``;

      labelTotaleProgetto += `${nomeSezione} ${numSection !== 0 ? '+' : ''}`;

      righeExcel.push(newRow);

      let sommaImpegno = 0;
      let sommaGiustificativo = 0;
      let sommaLiquidazioni = 0;
      let sommaMandato = 0;

      for (const item of section.items) {
        const newRow = new ColonneQuadroEconomicoConTotaliExcel();

        newRow.titolo = `${nomeSezione}.${item.index}${
          item.subIndex ? '.' + item.subIndex : ''
        }`;

        newRow.descrizione = `${item.title}`;

        newRow.importo1 = '';

        newRow.importo2 = item.total;

        const totaleItem = qe.totali.find(
          (y) =>
            y.sectionIndex === item.qeSectionIndex &&
            y.index === item.index &&
            y.subIndex === item.subIndex
        );

        if (totaleItem) {
          if (totaleItem.subIndex === 0) {
            newRow.importoImpegno = totaleItem.impegni;
            sommaImpegno = sommaImpegno + totaleItem.impegni;
            newRow.importoGiustificativo = totaleItem.giustificativi;
            sommaGiustificativo += totaleItem.giustificativi;
            newRow.importoLiquidazione = totaleItem.liquidazioni;
            sommaLiquidazioni += totaleItem.liquidazioni;
            newRow.importoMandati = totaleItem.mandati;
            sommaMandato += totaleItem.mandati;
          } else {
            newRow.importoImpegno = '-';
            newRow.importoGiustificativo = '-';
            newRow.importoLiquidazione = '-';
            newRow.importoMandati = '-';
          }
        }

        righeExcel.push(newRow);
      }

      const totaleRow = new ColonneQuadroEconomicoConTotaliExcel();

      totaleRow.importo2 = section.total;
      totaleRow.titolo = `Totale Sezione ${nomeSezione}`;
      totaleRow.importoImpegno = sommaImpegno;
      totaleRow.importoGiustificativo = sommaGiustificativo;
      totaleRow.importoLiquidazione = sommaLiquidazioni;
      totaleRow.importoMandati = sommaMandato;

      totSommaImpegno += sommaImpegno;
      totSommaGiustificativo += sommaGiustificativo;
      totSommaLiquidazioni += sommaLiquidazioni;
      totSommaMandato += sommaMandato;

      righeExcel.push(totaleRow);

      totaleProgetto += section.total;
    }

    const totaleRow = new ColonneQuadroEconomicoConTotaliExcel();

    totaleRow.importo2 = totaleProgetto;

    totaleRow.titolo = `${labelTotaleProgetto})`;

    totaleRow.importoImpegno = totSommaImpegno; //section.total;
    totaleRow.importoGiustificativo = totSommaGiustificativo;
    totaleRow.importoLiquidazione = totSommaLiquidazioni;
    totaleRow.importoMandati = totSommaMandato;
    righeExcel.push(totaleRow);

    righeExcel.forEach((row: ColonneQuadroEconomicoConTotaliExcel) => {
      data.push(Object.values(row));
    });

    //Create a workbook with a worksheet

    let workbook = new Workbook();

    let worksheet = workbook.addWorksheet('Quadro economico');

    // Allargo la colonna per la descrizione

    worksheet.getColumn(2).width = 50;

    worksheet.getColumn(2).alignment = { wrapText: true };

    worksheet.getColumn(3).width = 14;

    worksheet.getColumn(3).alignment = { horizontal: 'right' };

    worksheet.getColumn(3).numFmt = this.patternFormattaNumeri;

    worksheet.getColumn(4).width = 14;

    worksheet.getColumn(4).alignment = { horizontal: 'right' };

    worksheet.getColumn(4).numFmt = this.patternFormattaNumeri;

    worksheet.getColumn(5).width = 14;

    worksheet.getColumn(5).alignment = { horizontal: 'right' };

    worksheet.getColumn(5).numFmt = this.patternFormattaNumeri;

    worksheet.getColumn(6).width = 14;

    worksheet.getColumn(6).alignment = { horizontal: 'right' };

    worksheet.getColumn(6).numFmt = this.patternFormattaNumeri;

    worksheet.getColumn(7).width = 14;

    worksheet.getColumn(7).alignment = { horizontal: 'right' };

    worksheet.getColumn(7).numFmt = this.patternFormattaNumeri;

    worksheet.getColumn(8).width = 14;

    worksheet.getColumn(8).alignment = { horizontal: 'right' };

    worksheet.getColumn(8).numFmt = this.patternFormattaNumeri;

    //Add Row and formatting

    worksheet.mergeCells('A1', 'H4');

    let titleRow = worksheet.getCell('A1');

    titleRow.value = title;

    titleRow.font = { bold: true };

    titleRow.alignment = {
      vertical: 'middle',
      horizontal: 'center',
      wrapText: true,
    };

    let descrizioneRow = worksheet.addRow([]);

    worksheet.mergeCells(`A${descrizioneRow.number}:H${descrizioneRow.number}`);

    let descrizioneCell = worksheet.getCell(`A${descrizioneRow.number}`);

    descrizioneCell.value = cup.descrizione;

    descrizioneCell.alignment = {
      vertical: 'middle',
      horizontal: 'center',
      wrapText: true,
    };

    // creazione della descrizione

    const atto = (qe.detail && qe.detail.atto) || '';

    const numero = (qe.detail && qe.detail.numero) || 0;

    const dataQe = (qe.detail && qe.detail.data) || new Date();

    let descrizione = '';

    if (atto && numero) {
      descrizione = ` approvato con atto ${atto} numero ${numero} del ${this.datePipe.transform(
        dataQe,
        'dd MMM yyyy'
      )}`;
    }

    let descrizione2Row = worksheet.addRow([]);

    worksheet.mergeCells(
      `A${descrizione2Row.number}:H${descrizione2Row.number}`
    );

    let descrizione2Cell = worksheet.getCell(`A${descrizioneRow.number}`);

    descrizione2Cell.value = `Quadro economico ${descrizione}`;

    descrizione2Cell.alignment = {
      vertical: 'middle',
      horizontal: 'center',
      wrapText: true,
    };

    let colonneTotaliSezione = false;

    // Adding Data with Conditional Formatting

    data.forEach((d) => {
      if (typeof d[0] === 'number') {
        const totale = worksheet.addRow([]);

        worksheet.mergeCells(`A${totale.number}:C${totale.number}`);

        let titleTotalRow = worksheet.getCell(`A${totale.number}`);

        titleTotalRow.value = d[1];

        titleTotalRow.font = { bold: true };

        titleTotalRow.alignment = { horizontal: 'right' };

        titleTotalRow.fill = {
          type: 'pattern',

          pattern: 'solid',

          fgColor: { argb: 'F6F6F6' },
        };

        this.setValueCell(worksheet, 'D', totale.number, d[0]);
        this.setValueCell(worksheet, 'E', totale.number, d[2]);
        this.setValueCell(worksheet, 'F', totale.number, d[3]);
        this.setValueCell(worksheet, 'G', totale.number, d[4]);
        this.setValueCell(worksheet, 'H', totale.number, d[5]);

        /*
        let totalRow = worksheet.getCell(`D${totale.number}`);

        totalRow.value = d[0];

        totalRow.font = { bold: true };

        totalRow.alignment = { horizontal: 'right' };

        totalRow.fill = {
          type: 'pattern',

          pattern: 'solid',

          fgColor: { argb: 'F6F6F6' },
        };
      */
      } else if (typeof d[0] === 'string' && d[0].split('.').length === 1) {
        // Sezione

        const sezione = worksheet.addRow([`SEZIONE ${d[0]}`]);

        // Allineamento

        sezione.getCell(1).alignment = {
          vertical: 'middle',
          horizontal: 'center',
        };

        sezione.height = 30;

        worksheet.mergeCells(`A${sezione.number}:D${sezione.number}`);

        if (!colonneTotaliSezione) {
          colonneTotaliSezione = true;

          let titleRowImpegni = worksheet.getCell(`E${sezione.number}`);

          titleRowImpegni.value = 'Impegni';

          titleRowImpegni.font = { bold: true };

          titleRowImpegni.alignment = {
            vertical: 'middle',
            horizontal: 'center',
            wrapText: true,
          };

          let titleRowGiustificativi = worksheet.getCell(`F${sezione.number}`);

          titleRowGiustificativi.value = 'Giustificativi';

          titleRowGiustificativi.font = { bold: true };

          titleRowGiustificativi.alignment = {
            vertical: 'middle',
            horizontal: 'center',
            wrapText: true,
          };

          let titleRowLiquidazioni = worksheet.getCell(`G${sezione.number}`);

          titleRowLiquidazioni.value = 'Liquidazioni';

          titleRowLiquidazioni.font = { bold: true };

          titleRowLiquidazioni.alignment = {
            vertical: 'middle',
            horizontal: 'center',
            wrapText: true,
          };

          let titleRowMandati = worksheet.getCell(`H${sezione.number}`);

          titleRowMandati.value = 'Mandati';

          titleRowMandati.font = { bold: true };

          titleRowMandati.alignment = {
            vertical: 'middle',
            horizontal: 'center',
            wrapText: true,
          };
        }
      } else if (typeof d[0] === 'string' && d[0].split('.').length === 2) {
        // Header

        const header = worksheet.addRow(d);

        header.getCell(1).font = { bold: true };

        header.getCell(2).font = { bold: true };

        header.getCell(4).font = { bold: true };
      } else if (typeof d[0] === 'string' && d[0].split('.').length > 2) {
        // Item

        const item = worksheet.addRow(d);

        item.getCell(1).alignment = { horizontal: 'right' };

        item.getCell(2).alignment = { horizontal: 'right', wrapText: true };

        item.getCell(4).alignment = { horizontal: 'right' };
      }
    });

    //Generate & Save Excel File

    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });

      fs.saveAs(blob, title.substring(0, 100) + '.xlsx');
    });
  }

  public exportExcelEsecuzione(dati: ExcelReportData) {
    const title = dati.title;

    const esecuzione: Fase1[] = dati.esecuzione;

    let data: any[] = [];

    const righeExcel = this.getJsonEsecuzione(esecuzione);

    console.log(righeExcel);

    righeExcel.forEach((row: ColonneEsecuzioneExcel) => {
      data.push(Object.values(row));
    });

    //Create a workbook with a worksheet

    let workbook = new Workbook();

    let worksheet = workbook.addWorksheet('Esecuzione');

    //Add Row and formatting

    worksheet.mergeCells('A1', 'C1');

    let fase1Titolo = worksheet.getCell('A1');

    fase1Titolo.value = 'Ordinativo di spesa';

    fase1Titolo.font = { bold: true };

    fase1Titolo.alignment = { vertical: 'middle', horizontal: 'center' };

    worksheet.mergeCells('D1', 'F1');

    let fase2Titolo = worksheet.getCell('D1');

    fase2Titolo.value = 'Giustificativi di Spesa';

    fase2Titolo.font = { bold: true };

    fase2Titolo.alignment = { vertical: 'middle', horizontal: 'center' };

    worksheet.mergeCells('G1', 'I1');

    let fase3Titolo = worksheet.getCell('G1');

    fase3Titolo.value = 'Liquidazione';

    fase3Titolo.font = { bold: true };

    fase3Titolo.alignment = { vertical: 'middle', horizontal: 'center' };

    worksheet.mergeCells('J1', 'L1');

    let fase4Titolo = worksheet.getCell('J1');

    fase4Titolo.value = 'Mandato';

    fase4Titolo.font = { bold: true };

    fase4Titolo.alignment = { vertical: 'middle', horizontal: 'center' };

    data.forEach((d) => {
      const row = worksheet.addRow(d);

      const numeroRiga = row.number;

      row.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' },
      };

      this.formatVaiCapo(worksheet, 1, `A${numeroRiga}`);
      this.formatVaiCapo(worksheet, 2, `B${numeroRiga}`);
      this.formatImporti(worksheet, 3, `C${numeroRiga}`);

      this.formatVaiCapo(worksheet, 4, `D${numeroRiga}`);
      this.formatVaiCapo(worksheet, 5, `E${numeroRiga}`);
      this.formatImporti(worksheet, 6, `F${numeroRiga}`);

      this.formatVaiCapo(worksheet, 7, `G${numeroRiga}`);
      this.formatVaiCapo(worksheet, 8, `H${numeroRiga}`);
      this.formatImporti(worksheet, 9, `I${numeroRiga}`);

      this.formatVaiCapo(worksheet, 10, `J${numeroRiga}`);
      this.formatVaiCapo(worksheet, 11, `K${numeroRiga}`);
      this.formatImporti(worksheet, 12, `L${numeroRiga}`);
    });

    //Generate & Save Excel File

    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });

      fs.saveAs(blob, title.substring(0, 100) + '.xlsx');
    });
  }

  /**

   * Exportazione del Quadro economico in Excel

   */

  public exportExcelEstesaEsecuzione(dati: ExcelReportData) {
    const title = dati.title;

    const esecuzione: Fase1[] = dati.esecuzione;

    let data: any[] = [];

    const righeExcel = this.getJsonEsecuzioneEstesa(esecuzione);

    righeExcel.forEach((row: ColonneEstesaEsecuzioneExcel) => {
      data.push(Object.values(row));
    });

    //Create a workbook with a worksheet

    let workbook = new Workbook();

    let worksheet = workbook.addWorksheet('Esecuzione Estesa');

    //Add Row and formatting

    worksheet.mergeCells('A1', 'F1');

    let fase1Titolo = worksheet.getCell('A1');

    fase1Titolo.value = 'Impiego';

    fase1Titolo.font = { bold: true };

    fase1Titolo.alignment = {
      vertical: 'middle',
      horizontal: 'center',
      wrapText: true,
    };

    worksheet.mergeCells('G1', 'O1');

    let fase2Titolo = worksheet.getCell('G1');

    fase2Titolo.value = 'Giustificativi di Spesa';

    fase2Titolo.font = { bold: true };

    fase2Titolo.alignment = {
      vertical: 'middle',
      horizontal: 'center',
      wrapText: true,
    };

    worksheet.mergeCells('P1', 'T1');

    let fase3Titolo = worksheet.getCell('P1');

    fase3Titolo.value = 'Liquidazione';

    fase3Titolo.font = { bold: true };

    fase3Titolo.alignment = {
      vertical: 'middle',
      horizontal: 'center',
      wrapText: true,
    };

    worksheet.mergeCells('U1', 'X1');

    let fase4Titolo = worksheet.getCell('U1');

    fase4Titolo.value = 'Mandato';

    fase4Titolo.font = { bold: true };

    fase4Titolo.alignment = {
      vertical: 'middle',
      horizontal: 'center',
      wrapText: true,
    };

    let quietanzaTitolo = worksheet.getCell('Y1');

    quietanzaTitolo.value = 'Quietanza';

    quietanzaTitolo.font = { bold: true };

    quietanzaTitolo.alignment = {
      vertical: 'middle',
      horizontal: 'center',
      wrapText: true,
    };

    const intestazioneTabella = worksheet.addRow([]);

    intestazioneTabella.font = { bold: true };

    intestazioneTabella.getCell(1).value = 'Tipo';

    intestazioneTabella.getCell(2).value = 'Data';

    intestazioneTabella.getCell(3).value = 'N.';

    intestazioneTabella.getCell(4).value = 'Oggetto';

    intestazioneTabella.getCell(5).value = 'Beneficiario';

    intestazioneTabella.getCell(6).value = 'Importo Totale';

    intestazioneTabella.getCell(7).value = 'Tipo';

    intestazioneTabella.getCell(8).value = 'Data';

    intestazioneTabella.getCell(9).value = 'N.';

    intestazioneTabella.getCell(10).value = 'Creditore';

    intestazioneTabella.getCell(11).value = 'Oggetto';

    intestazioneTabella.getCell(12).value = 'Importo Netto';

    intestazioneTabella.getCell(13).value = 'Cassa';

    intestazioneTabella.getCell(14).value = 'Iva';

    intestazioneTabella.getCell(15).value = 'Importo Totale';

    intestazioneTabella.getCell(16).value = 'Data';

    intestazioneTabella.getCell(17).value = 'N.';

    intestazioneTabella.getCell(18).value = 'Oggetto';

    intestazioneTabella.getCell(19).value = 'Beneficiario';

    intestazioneTabella.getCell(20).value = 'Importo Totale';

    intestazioneTabella.getCell(21).value = 'Data';

    intestazioneTabella.getCell(22).value = 'N.';

    intestazioneTabella.getCell(23).value = 'Importo';

    intestazioneTabella.getCell(24).value = 'Beneficiario';

    data.forEach((d) => {
      const row = worksheet.addRow(d);

      const numeroRiga = row.number;

      row.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' },
      };

      worksheet.getColumn(1).width = 12;

      worksheet.getColumn(2).width = 12;

      this.allineaAlCentro(worksheet, 3, `C${numeroRiga}`, 8);

      this.formatDescrizioniLunghe(worksheet, 4, `D${numeroRiga}`);

      this.formatDescrizioniLunghe(worksheet, 5, `E${numeroRiga}`, 30);

      this.formatImporti(worksheet, 6, `F${numeroRiga}`);

      worksheet.getColumn(7).width = 20;

      worksheet.getColumn(8).width = 12;

      this.allineaAlCentro(worksheet, 9, `I${numeroRiga}`, 8);

      this.formatDescrizioniLunghe(worksheet, 10, `J${numeroRiga}`, 30);

      this.formatDescrizioniLunghe(worksheet, 11, `K${numeroRiga}`);

      this.formatImporti(worksheet, 12, `L${numeroRiga}`);

      this.formatImporti(worksheet, 13, `M${numeroRiga}`);

      this.formatImporti(worksheet, 14, `N${numeroRiga}`);

      this.formatImporti(worksheet, 15, `O${numeroRiga}`);

      worksheet.getColumn(16).width = 12;

      this.allineaAlCentro(worksheet, 17, `Q${numeroRiga}`, 8);

      this.formatDescrizioniLunghe(worksheet, 18, `R${numeroRiga}`);

      worksheet.getColumn(19).width = 30;

      this.formatImporti(worksheet, 20, `T${numeroRiga}`);

      worksheet.getColumn(21).width = 12;

      this.allineaAlCentro(worksheet, 22, `V${numeroRiga}`, 8);

      worksheet.getColumn(23).width = 14;

      worksheet.getColumn(24).width = 30;
    });

    //Generate & Save Excel File

    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });

      fs.saveAs(blob, title.substring(0, 100) + '.xlsx');
    });
  }

  /**

   * Creazione del Json per Excel Esecuzione

   */

  private getJsonEsecuzioneEstesa(esecuzione: Fase1[]) {
    const righeExcel: ColonneEstesaEsecuzioneExcel[] = [];

    let newRow = new ColonneEstesaEsecuzioneExcel();

    for (let iF1 = 0; iF1 < esecuzione.length; iF1++) {
      const fase1 = esecuzione[iF1];

      newRow.tipoFase1 = fase1.tipo;

      newRow.dataFase1 =
        this.datePipe.transform(fase1.data, 'dd MMM yyyy') || '';

      newRow.numeroFase1 = fase1.numero;

      newRow.oggettoFase1 = fase1.descrizione;

      newRow.beneficiarioFase1 = fase1.beneficiario;

      newRow.importoTotaleFase1 =
        fase1.importoNetto + fase1.importoIVA + fase1.importoCassa;

      if (fase1.fasi2.length > 0) {
        for (let iF2 = 0; iF2 < fase1.fasi2.length; iF2++) {
          const fase2 = fase1.fasi2[iF2];

          newRow.tipoFase2 = fase2.tipo;

          newRow.dataFase2 =
            this.datePipe.transform(fase2.data, 'dd MMM yyyy') || '';

          newRow.numeroFase2 = fase2.numero;

          newRow.creditoreFase2 = fase1.beneficiario;

          newRow.oggettoFase2 = fase2.descrizione;

          newRow.importoNettoFase2 = fase2.importoNetto;

          newRow.cassaFase2 = fase2.cassa;

          newRow.ivaFase2 = fase2.iva;

          newRow.importoTotaleFase2 =
            fase2.importoNetto + fase2.cassa + fase2.iva;

          if (fase2.fasi3.length > 0) {
            for (let iF3 = 0; iF3 < fase2.fasi3.length; iF3++) {
              const fase3 = fase2.fasi3[iF3];

              newRow.dataFase3 =
                this.datePipe.transform(fase3.data, 'dd MMM yyyy') || '';

              newRow.numeroFase3 = fase3.numero;

              newRow.oggettoFase3 = '';

              newRow.beneficiarioFase3 = fase1.beneficiario;

              newRow.importoTotaleFase3 = fase3.importo;

              if (fase3.fasi4.length > 0) {
                for (let iF4 = 0; iF4 < fase3.fasi4.length; iF4++) {
                  const fase4 = fase3.fasi4[iF4];

                  newRow.dataFase4 =
                    this.datePipe.transform(fase4.data, 'dd MMM yyyy') || '';

                  newRow.numeroFase4 = fase4.numero;

                  newRow.beneficiarioFase4 = fase1.beneficiario;

                  newRow.importoFase4 = fase4.importo;

                  righeExcel.push(newRow);

                  newRow = new ColonneEstesaEsecuzioneExcel();
                }
              } else {
                righeExcel.push(newRow);

                newRow = new ColonneEstesaEsecuzioneExcel();
              }
            }
          } else {
            righeExcel.push(newRow);

            newRow = new ColonneEstesaEsecuzioneExcel();
          }
        }
      } else {
        righeExcel.push(newRow);

        newRow = new ColonneEstesaEsecuzioneExcel();
      }
    }

    return righeExcel;
  }

  /**

   * Creazione del Json per Excel Esecuzione

   */

  private getJsonEsecuzione(esecuzione: Fase1[]) {
    const righeExcel: ColonneEsecuzioneExcel[] = [];

    let newRow = new ColonneEsecuzioneExcel();

    for (let iF1 = 0; iF1 < esecuzione.length; iF1++) {
      const fase1 = esecuzione[iF1];

      console.log(fase1);

      newRow.descrizioneFase1 = `${fase1.tipo} \r\n n°${
        fase1.numero
      } del ${this.datePipe.transform(fase1.data, 'dd MMM yyyy')} \r\n ${
        fase1.beneficiario
      } \r\n ${fase1.descrizione || ''}`;

      newRow.nci1 = this.FormatCellaImporti(
        fase1.importoNetto,
        fase1.importoCassa,
        fase1.importoIVA
      );
      newRow.valoreFase1 =
        fase1.importoNetto + fase1.importoCassa + fase1.importoIVA;
      console.log(newRow);

      if (fase1.fasi2.length > 0) {
        for (let iF2 = 0; iF2 < fase1.fasi2.length; iF2++) {
          const fase2 = fase1.fasi2[iF2];

          newRow.descrizioneFase2 = `${fase2.tipo} \r\n n°${
            fase2.numero
          } del ${this.datePipe.transform(fase2.data, 'dd MMM yyyy')} \r\n ${
            fase1.beneficiario
          } \r\n ${fase2.descrizione || ''}`;

          newRow.nci2 = this.FormatCellaImporti(
            fase2.importoNetto,
            fase2.cassa,
            fase2.iva
          );
          newRow.valoreFase2 = fase2.importoNetto + fase2.cassa + fase2.iva;

          if (fase2.fasi3.length > 0) {
            for (let iF3 = 0; iF3 < fase2.fasi3.length; iF3++) {
              const fase3 = fase2.fasi3[iF3];

              newRow.descrizioneFase3 = `Liquidazione \r\n n°${
                fase3.numero
              } del ${this.datePipe.transform(fase3.data, 'dd MMM yyyy')}`;

              let NettoDiFase3Spalmato = 0;
              let IvaDiFase3Spalmato = 0;
              let CassaDiFase3Spalmato = 0;

              const totaleFase2 = fase2.importoNetto + fase2.iva + fase2.cassa;
              if (totaleFase2 > 0) {
                NettoDiFase3Spalmato = fase3.importoNetto;
                /* this.CalcolaImportoSpalmato(
                  fase3.importo,
                  totaleFase2,
                  fase2.importoNetto
                );*/
                IvaDiFase3Spalmato = fase3.iva ?? 0;
                /*  fase3.importo,
                  totaleFase2,
                  fase2.iva
                );*/
                CassaDiFase3Spalmato = fase3.cassa ?? 0;
                /*this.CalcolaImportoSpalmato(
                  fase3.importo,
                  totaleFase2,
                  fase2.cassa
                );*/
              }
              newRow.valoreFase3 = fase3.importo ?? 0;
              newRow.nci3 = this.FormatCellaImporti(
                NettoDiFase3Spalmato,
                CassaDiFase3Spalmato,
                IvaDiFase3Spalmato
              );

              if (fase3.fasi4.length > 0) {
                for (let iF4 = 0; iF4 < fase3.fasi4.length; iF4++) {
                  const fase4 = fase3.fasi4[iF4];

                  newRow.descrizioneFase4 = `Mandato \r\n n°${
                    fase4.numero
                  } del ${this.datePipe.transform(fase4.data, 'dd MMM yyyy')}`;

                  let NettoDiFase4Spalmato = 0;
                  let IvaDiFase4Spalmato = 0;
                  let CassaDiFase4Spalmato = 0;
                  if (totaleFase2 > 0) {
                    NettoDiFase4Spalmato = this.CalcolaImportoSpalmato(
                      fase4.importo,
                      totaleFase2,
                      fase2.importoNetto
                    );
                    IvaDiFase4Spalmato = this.CalcolaImportoSpalmato(
                      fase4.importo,
                      totaleFase2,
                      fase2.iva
                    );
                    CassaDiFase4Spalmato = this.CalcolaImportoSpalmato(
                      fase4.importo,
                      totaleFase2,
                      fase2.cassa
                    );
                  }
                  newRow.valoreFase4 = fase4.importo;
                  newRow.nci4 = this.FormatCellaImporti(
                    NettoDiFase4Spalmato,
                    CassaDiFase4Spalmato,
                    IvaDiFase4Spalmato
                  );
                  righeExcel.push(newRow);

                  newRow = new ColonneEsecuzioneExcel();
                }
              } else {
                righeExcel.push(newRow);

                newRow = new ColonneEsecuzioneExcel();
              }
            }
          } else {
            righeExcel.push(newRow);

            newRow = new ColonneEsecuzioneExcel();
          }
        }
      } else {
        righeExcel.push(newRow);

        newRow = new ColonneEsecuzioneExcel();
      }
    }

    return righeExcel;
  }

  private CalcolaImportoSpalmato(
    fase3Totale: number,
    fase2Totale: number,
    divisore: number
  ) {
    const t1 = (fase2Totale / divisore) * 100;
    return (fase3Totale / t1) * 100;
  }

  private FormatCellaImporti(
    importoNetto: number,
    importoCassa: number,
    importoIva: number
  ) {
    return ` Netto: ${this.decimalPipe.transform(
      importoNetto,
      '1.2-2'
    )} \r\n Iva: ${this.decimalPipe.transform(
      importoIva,
      '1.2-2'
    )} \r\n Cassa: ${this.decimalPipe.transform(importoCassa, '1.2-2')}`;
  }

  /**

   * Formatta importi per colonna

   * @param worksheet, foglio di lavoro

   * @param colonna, colonna da formattare

   */

  private formatImporti(
    worksheet: Worksheet,
    colonna: number,
    valoreCella: any
  ) {
    worksheet.getColumn(colonna).width = 14;

    worksheet.getColumn(colonna).numFmt = this.patternFormattaNumeri;

    worksheet.getCell(valoreCella).alignment = {
      horizontal: 'right',
      vertical: 'middle',
    };

    worksheet.getCell(valoreCella).border = {
      top: { style: 'thin' },
      left: { style: 'thin' },
      bottom: { style: 'thin' },
      right: { style: 'thin' },
    };
  }

  /**

   * Formattazione descrizione lunghe per colonna

   * @param worksheet, foglio di lavoro

   * @param colonna, colonna da formattare

   * @param width, larghezza della colonna, di default è 50

   */

  private formatDescrizioniLunghe(
    worksheet: Worksheet,
    colonna: number,
    valoreCella: any,
    width?: number
  ) {
    worksheet.getColumn(colonna).width = !width ? 50 : width;

    worksheet.getCell(valoreCella).alignment = {
      wrapText: true,
      vertical: 'middle',
    };

    worksheet.getCell(valoreCella).border = {
      top: { style: 'thin' },
      left: { style: 'thin' },
      bottom: { style: 'thin' },
      right: { style: 'thin' },
    };
  }

  /**

   * Formatta lle celle per andare a capo

   * Ricorda che per farlo funzionare, bisogna concatenare le stringhe con value += `${v} \r\n`;

   */

  private formatVaiCapo(
    worksheet: Worksheet,
    colonna: number,
    valoreCella: any
  ) {
    worksheet.getColumn(colonna).width = 50;

    worksheet.getCell(valoreCella).alignment = {
      wrapText: true,
      vertical: 'middle',
    };

    worksheet.getCell(valoreCella).border = {
      top: { style: 'thin' },
      left: { style: 'thin' },
      bottom: { style: 'thin' },
      right: { style: 'thin' },
    };
  }

  private allineaAlCentro(
    worksheet: Worksheet,
    colonna: number,
    valoreCella: any,
    width: number
  ) {
    worksheet.getColumn(colonna).width = width;

    worksheet.getCell(valoreCella).alignment = {
      horizontal: 'center',
      vertical: 'middle',
    };
  }

  private setValueCell(
    worksheet: Worksheet,
    colonna: string,
    cella: number,
    value: any
  ) {
    let totalRow = worksheet.getCell(`${colonna}${cella}`);

    totalRow.value = value;

    totalRow.font = { bold: true };

    totalRow.alignment = { horizontal: 'right' };

    totalRow.fill = {
      type: 'pattern',

      pattern: 'solid',

      fgColor: { argb: 'F6F6F6' },
    };
  }
}
