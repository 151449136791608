import { Message } from 'primeng/api';
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
  Pipe,
  PipeTransform,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { SpeseService } from 'src/app/service/spese.service';
import {
  ConfirmationService,
  MessageService,
  PrimeNGConfig,
} from 'primeng/api';
import {
  Fase1,
  Fase1Response,
  Fase2,
  Fase2Base,
  Fase2Response,
  Fase2Riferimenti,
  Fase3,
  Fase3Base,
  Fase3Response,
  Fase3Submit,
  Fase4,
  Fase4Base,
  Fase4Response,
  Fase5,
  Fase5Response,
  Fasi1Response,
} from 'src/app/model/fase1';
import { NgxSpinnerService } from 'ngx-spinner';
import { QE, QEItemBase } from 'src/app/model/qe';
import { QEService } from 'src/app/service/qe.service';
import { CUP } from 'src/app/model/cup';
import { EsportaExcelTipo } from 'src/app/service/export-excel.service';
import { SharedService } from 'src/app/service/shared.service';
import { Dizionario, ModelloDocumentaleCustom, Scenario, TipologiaDizionario, Fase } from 'src/app/model/scenari';
import { ScenarioService } from 'src/app/service/scenario.service';
import { Ruolo, TipoPermesso, Utente } from 'src/app/model/utente';
import { RuoloPipe } from 'src/app/pipe/ruolo-pipe';
import { DialogModel } from '../shared/rbk-modal/rbk-modal';
import { UploadFileComponent } from '../shared/upload-file/upload-file';

@Pipe({
  name: 'customCurrency'
})
export class CustomCurrencyPipe implements PipeTransform {
  transform(value: number, currency: string = 'EUR'): string {
    if (value === null || value === undefined) {
      return '';
    }
    const formattedNumber = new Intl.NumberFormat('it-IT', { style: 'decimal' }).format(value);
    const currencySymbol = new Intl.NumberFormat('it-IT', { style: 'currency', currency }).formatToParts(value)
      .find(part => part.type === 'currency')?.value;

    return `${currencySymbol} ${formattedNumber}`;
  }
}

export interface InfoSpesePerRiferimento {
  totale: number,
  spesePerImportonetto: Fase1[],
  spesePerIva: Fase1[],
  spesePerCassa: Fase1[]
}

@Component({
  selector: 'app-cup-esecuzione',
  templateUrl: './cup-esecuzione.component.html',
  styleUrls: ['./cup-esecuzione.component.scss'],
  providers: [ConfirmationService, MessageService],
})
export class CupEsecuzioneComponent {
  @Input() cup: CUP;
  @Input() qe: QE;
  @Input() isEsecuzioneEnable: boolean;
  @Input() idCup: number;
  /**
   * Permesso in base all'utente loggato
   */
  @Input() public permesso: TipoPermesso;
  /**
   * Utente loggato
   */
  @Input() public user: Utente;
  /**
   * La lista dei tipidizionario
   */
  @Input() public tipiDizionario: Dizionario[];

  @Output() sendRowImpegnate = new EventEmitter<QEItemBase[]>();

  @ViewChild(UploadFileComponent) private uploadFileComponent: UploadFileComponent;

  public beneficiarioString: string;

  public pIva_cFiscaleString: string;

  public dialogModel: DialogModel;
  
  visibleDialog: boolean = false;

  esportaExcelTipo = EsportaExcelTipo;

  datiModelloDocumentale: ModelloDocumentaleCustom;
  giustificativi: Fase2Base[];
  liquidazioni: Fase3Base[];
  mandati: Fase4Base[];
  visibleFormSpesa: boolean;
  visibleFormGiustificativo: boolean;
  visibleFormLiquidazione: boolean;
  visibleFormMandato: boolean;
  visibleFormQuietanza: boolean;
  visualizzaForm: boolean;
  stopFase5: boolean;

  // Id dell'elemento appena creato da inviare all'onchange per procedere con il salvataggio del documento se collegato
  entityIdAdd: number;

  hashMapRiferimentiQEInUso: {[compositeId: number]: InfoSpesePerRiferimento};
  spesa: Fase1;
  spese: Fase1[];
  quietanza: Fase5;
  fase2Riferimenti: Fase2Riferimenti;
  qeFlatItems: QEItemBase[];
  rowQEImpegnate: QEItemBase[] = [];
  filteredTab: Fase1[];
  selectedSpesa: Fase1;
  tipoSpeseForm: FormGroup;
  rif: Fase4;

  totaleFatture: number = 0;
  isId: boolean;
  id: number;
  spesa_id: number;
  isInEdit: boolean = false;
  visualizza: boolean;
  showMessages: boolean;
  selected_Riferimento: string;

  calcolaTotale: number;
  calcolaTotaleL: number;
  calcolaTotaleM: number;
  calcolaTotaleQ: number;

  public tipiSpeseFiltro: Dizionario[];
  public tipiSpeseFromDizionario: Dizionario[];
  public tipiGiustificativiFromDizionario: Dizionario[];
  public tipiAttiFromDizionario: Dizionario[];
  public TipoPermesso = TipoPermesso;
	public Ruolo = Ruolo;
	public RuoloPipe = new RuoloPipe();
  public tipoSpesa: string = 'Impegno/Liquidazione';

  beneficiari = [];
  Fase = Fase;

  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private speseServices: SpeseService,
    private qeService: QEService,
    private spinner: NgxSpinnerService,
    private primengConfig: PrimeNGConfig,
    private confirmationService: ConfirmationService,
    private sharedService: SharedService,
    private cdRef: ChangeDetectorRef,
    private scenarioServices: ScenarioService
  ) {  }

  ngAfterContentChecked(): void {
    this.cdRef.detectChanges();
  }

  async ngOnChanges(change: SimpleChanges) {
    const isEsecuzioneEnableChanges = change && change['isEsecuzioneEnable'];
    if (isEsecuzioneEnableChanges && isEsecuzioneEnableChanges.currentValue) {
      await this.getQeFlatItems();
    }
  }

  public async getQeFlatItems() {
    const response = await this.qeService.getFlatQEItems(this.cup?.id).toPromise();
    const flatItemCustom = {
      qeSectionIndex: 0,
      qeSectionQEMainId: 0,
      index: 0,
      subIndex: 0,
      title: 'Non dovuta',
      total: 0,
      compositeId: 0
    }
    if (response && response.success) {
      this.qeFlatItems = response.dtos || [];
      this.qeFlatItems.push(flatItemCustom);
    } else {
      console.log('500');
    }
  }

  async ngOnInit() {
    this.primengConfig.ripple = true;
    this.spese = [];
    this.qeFlatItems = [];
    this.isId = this.route.snapshot.paramMap.get('id') ? true : false;
    this.tipoSpeseForm = this.fb.group({ tipo: new FormControl(1) });
    if (this.isId) {
      const fasiCaricateConSuccesso = await this.onUpdateFasi();
      this.tipiSpeseFiltro = [new Dizionario(TipologiaDizionario.Spesa_Tipo)];
      this.tipiSpeseFromDizionario = this.tipiDizionario && this.tipiDizionario.filter(d => d.categoria === TipologiaDizionario.Spesa_Tipo) || [];
      this.tipiAttiFromDizionario = this.tipiDizionario && this.tipiDizionario.filter(d => d.categoria === TipologiaDizionario.Atto) || [];
      this.tipiGiustificativiFromDizionario = this.tipiDizionario && this.tipiDizionario.filter(d => d.categoria === TipologiaDizionario.Giustificativo_Tipo) || [];
      this.tipiSpeseFiltro = [...this.tipiSpeseFromDizionario];
      if (fasiCaricateConSuccesso) {
        this.openDialogNavigazioneDaDocumenti(this.filteredTab);
        await this.getQeFlatItems();
      }      
    } else {
      this.idCup = 0;
    }
  }

  /**
   * Carica documento da Cartella Monitorata
   */
  openDialogNavigazioneDaDocumenti(listaSpese: Fase1[]) {
    const dati = this.sharedService.getParametriNavigaDaDocumentiArchviazione();
    const idSpesa = dati?.idSottoSezione;
    const spesa = listaSpese?.find((spesa) => spesa.id === idSpesa);
    if (dati && dati?.sezione === 'Spesa') {
      switch (dati?.sottoSezione) {
        case 'Spesa':
          this.showDialogForm(false, true);
          break;
        case 'Giuistificativo':
          this.showDialogFatture(false, true, undefined, spesa, true);
          break;
        case 'Liquidazione':
          this.showDialogLiquidazioni(false, true, spesa, undefined, true);
          break;
        case 'Mandato':
          this.showDialogMandati(false, true, spesa, undefined, true);
          break;
        case 'Quitanza':
          this.showDialogQuietanze(false, spesa, undefined, true);
          break;
      }
    }
  }

  /**
   * Aggiunta di una nuova spesa, da unire con il salvataggio
   */
  async addSpesa(form: FormGroup) {
    this.spinner.show();
    let spesa = form.value;
    spesa.cupId = this.idCup;
    const resposne = await this.speseServices.submitFase1(spesa).toPromise();
    if (resposne && resposne.success) {
      this.entityIdAdd = resposne.dto.id;
      //this.popUpResponse(true, 'Spesa aggiunta con successo');
      this.sharedService.publishMessage('success', 'Successo', 'Spesa aggiunta con successo');
      await this.onUpdateFasi();
    } else {
      this.popUpResponse(false, 'Errore durante il salvataggio della spesa');
    }
    this.spinner.hide();
    this.visibleFormSpesa = false;
  }

  async addGiustificativo(form: FormGroup) {
    this.spinner.show();
    let giustificativo = form.value;
    const response = await this.speseServices.submitFase2(giustificativo).toPromise();
    if (response && response.success) {
      this.entityIdAdd = response.dto.id;
      let spesa = this.spese.find((x) => x.id === giustificativo.fase1Id);
      //this.popUpResponse(true, 'Giustificativo aggiunto con successo');
      this.sharedService.publishMessage('success', 'Successo', 'Giustificativo aggiunto con successo');
      if (!this.checkHasLiquidazioneFasi(spesa.tipo)) {
        await this.createCustomFase3(response.dto);
      } else {
        await this.onUpdateFasi();
      }
    } else {
      console.log('500');
    }
    this.visibleFormGiustificativo = false;
    this.spinner.hide();
  }

  async createCustomFase3(giustificativo: Fase2) {
    this.spinner.show();
    let fase3Submit: Fase3Submit = {
      id: 0,
      fase2Id: giustificativo.id,
      numero: giustificativo.numero,
      data: giustificativo.data,
      importo:
        giustificativo.importoNetto +
        giustificativo.iva +
        giustificativo?.cassa,
    };
    const response = await this.speseServices.submitFase3(fase3Submit).toPromise();
    if (response && response.success) {
      await this.onUpdateFasi();
    } else {
      console.log('500');
    }
  }

  async addLiquidazione(form: FormGroup) {
    this.spinner.show();
    let liquidazione = form.value;
    const response = await this.speseServices.submitFase3(liquidazione).toPromise();
    if (response && response.success) {
      this.entityIdAdd = response.dto?.id;
      //this.popUpResponse(true, 'Liquidazione aggiunta con successo');
      this.sharedService.publishMessage('success', 'Successo', 'Liquidazione aggiunta con successo');
      await this.onUpdateFasi();
    } else {
      this.popUpResponse(false, 'Errore durante il salvataggio della liquidazione');
      console.log('500');
    }
    this.visibleFormLiquidazione = false;
    this.spinner.hide();
  }

  public checkFase2(form: FormGroup) {
    let x: number = 0;
    while (x < this.spesa.fasi3.length) {
      if (form.value.fase2Id === this.spesa.fasi3[x].fase2Id) {
        return this.confirmCheckGiustificativiImpegnati(form);
      }
      x++;
    }
    return this.addLiquidazione(form);
  }

  confirmCheckGiustificativiImpegnati(form: FormGroup) {
    this.confirmationService.confirm({
      message: 'Questo giustificativo di spesa ha già una liquidazione associata. Procedere comunque?',
      header: 'Eliminazione',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Si, crea liquidazione',
      rejectLabel: 'No, torna indietro',
      acceptButtonStyleClass: 'p-button-danger p-button-text',
      rejectButtonStyleClass: 'p-button-text',
      accept: () => {
        this.addLiquidazione(form);
      },
    });
  }

  async addMandato(form: FormGroup) {
    this.spinner.show();
    if (!this.checkHasLiquidazioneFasi(this.spesa.tipo)) {
      const riferimentoFind = form.get('fase3Id').value;
      const fase2 = this.spesa.fasi2.find((fase2) => fase2.id === riferimentoFind);
      if (fase2) form.get('fase3Id').setValue(fase2.fasi3[0].id);
    }
    let mandato = form.value;
    const response = await this.speseServices.submitFase4(mandato).toPromise();
    if (response && response.success) {
      this.entityIdAdd = response.dto?.id;
      //this.popUpResponse(true, 'Mandato aggiunto con successo');
      this.sharedService.publishMessage('success', 'Successo', 'Mandato aggiunto con successo');
      await this.onUpdateFasi();
    } else {
      this.popUpResponse(false, 'Errore durante il salvataggio del mandato');
    }
    this.visibleFormMandato = false;
    this.spinner.hide();
  }

  checkFase3(form: FormGroup) {
    let mandato = form.value;
    let x: number = 0;
    while (x < this.spesa.fasi4.length) {
      if (mandato.fase3Id === this.spesa.fasi4[x].fase3Id) {
        return this.confirmCheckLiquidazioniImpegnate(form);
      }
      x++;
    }
    return this.addMandato(form);
  }

  confirmCheckLiquidazioniImpegnate(form) {
    this.confirmationService.confirm({
      message:
        this.checkHasLiquidazioneFasi(this.spesa.tipo)
          ? 'Questa liquidazione ha già un mandato associato. Procedere comunque?'
          : `Questa spesa di tipo ${this.spesa.tipo} ha già un mandato associato. Procedere comunque?`,
      header: 'Eliminazione',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Si, crea mandato',
      rejectLabel: 'No, torna indietro',
      acceptButtonStyleClass: 'p-button-danger p-button-text',
      rejectButtonStyleClass: 'p-button-text',
      accept: () => {
        this.addMandato(form);
      },
    });
  }

  async addQuietanza(form: FormGroup) {
    this.spinner.show();
    let quietanza = form.value;
    const response = await this.speseServices.submitFase5(quietanza).toPromise();
    if (response && response.success) {
      this.entityIdAdd = response.dtos?.id;
      //this.popUpResponse(true, 'Quietanza aggiunta con successo');
      this.sharedService.publishMessage('success', 'Successo', 'Quietanza aggiunta con successo');
      await this.onUpdateFasi();
    } else {
      this.popUpResponse(false, 'Errore durante il salvataggio della quietanza');
    }
    this.visibleFormQuietanza = false;
    this.spinner.hide();
  }
  
  calcolaTotaliFasiFiglie(fasePadre: any, form: FormGroup) {
    if (!fasePadre) return null;

    let impegnato = { importoNetto: 0, iva: 0, cassa: 0 };
    fasePadre.forEach((figlio:any) => {
      if (form.value.id !== figlio.id) {
        impegnato.importoNetto += figlio.importoNetto;
        impegnato.iva += figlio.iva;
        impegnato.cassa += figlio.cassa;
      }
    })
    return impegnato;
  }

  /**
   * Chiusura della modale per upload-file
   */
  public closeDialog(event?: any) {
    this.visibleFormSpesa = false;
    this.visibleFormGiustificativo = false;
    this.visibleFormLiquidazione = false;
    this.visibleFormMandato = false;
    this.visibleFormQuietanza = false;
  }

  onChangeItemCurrency(event: any) {
    let faseForm = event.formGroup; 
    let item = event.item;

    if (this.visibleFormSpesa) {
      this.checkImporto(event);
    };

    const checkDisponibile = (importoModificato: number, disponibile: number, fase: Fase) => {
      if (importoModificato > disponibile) {
        this.messageCheckImportiFasiFiglie(item, disponibile, fase);
      }
    };

    if (this.visibleFormGiustificativo) {
      const importoModificato = faseForm.get(item).value;
      const impegnato = this.calcolaTotaliFasiFiglie(this.spesa.fasi2, faseForm);

      const nettoDisponibile = this.spesa.importoNetto - impegnato.importoNetto;
      const ivaDisponibile = this.spesa.importoIVA - impegnato.iva;
      const cassaDisponibile = this.spesa.importoCassa - impegnato.cassa;

      if (item === 'importoNetto') {
        checkDisponibile(importoModificato, nettoDisponibile, Fase.Giustificativo);
      } else if (item === 'iva') {
        checkDisponibile(importoModificato, ivaDisponibile, Fase.Giustificativo);
      } else {
        checkDisponibile(importoModificato, cassaDisponibile, Fase.Giustificativo);
      }
    };

    if (this.visibleFormLiquidazione) {
      const importoModificato = faseForm.get(item).value;

      if (faseForm.value.fase2Id) {
        const giustificativoPadre = this.spesa.fasi2.find(x=>x.id === faseForm.value.fase2Id);
        const impegnato = this.calcolaTotaliFasiFiglie(giustificativoPadre?.fasi3, faseForm);

        const nettoDisponibile = giustificativoPadre.importoNetto - impegnato.importoNetto;
        const ivaDisponibile = giustificativoPadre.iva - impegnato.iva;
        const cassaDisponibile = giustificativoPadre.cassa - impegnato.cassa;

        if (item === 'importoNetto') {
          checkDisponibile(importoModificato, nettoDisponibile, Fase.Liquidazione);
        } else if (item === 'iva') {
          checkDisponibile(importoModificato, ivaDisponibile, Fase.Liquidazione);
        } else {
          checkDisponibile(importoModificato, cassaDisponibile, Fase.Liquidazione);
        }
      }
    };

    if (this.visibleFormMandato) {
      const importoModificato = faseForm.get(item).value;

      if (faseForm.value.fase3Id) {
        const hasLiquidazione = this.checkHasLiquidazioneFasi(this.spesa.tipo);

        const riferimentoPadre = hasLiquidazione
            ? this.spesa.fasi3.find(x => x.id === faseForm.value.fase3Id)
            : this.spesa.fasi2.find(x => x.id === faseForm.value.fase3Id);

        const mandatiFigli = hasLiquidazione
            ? this.spesa.fasi4.filter(x => x.fase3Id === riferimentoPadre.id)
            : (riferimentoPadre as Fase2).fasi3[0].fasi4;

        const importoTotalePadre = riferimentoPadre.importoNetto + riferimentoPadre.iva + riferimentoPadre.cassa;
        const impegnato = mandatiFigli.filter(x => x.id !== faseForm.value.id).reduce((acc, x) => acc + x.importo, 0);
        const disponibile = importoTotalePadre - impegnato;

        checkDisponibile(importoModificato, disponibile, Fase.Mandato);
      }
    };

    this.updateSum();
  }

  messageCheckImportiFasiFiglie(item: string, importo: number, fase: Fase) {
    const formattedImporto = new CustomCurrencyPipe().transform(importo);

    const messages = {
      importoNetto: {
          [Fase.Giustificativo]: `L'importo netto disponibile della spesa di riferimento è pari a ${formattedImporto}. 
            Inserire un importo minore o uguale.`,
          [Fase.Liquidazione]: `L'importo netto disponibile del giustificativo di riferimento è pari a ${formattedImporto}. 
            Inserire un importo minore o uguale.`
      },
      iva: {
          [Fase.Giustificativo]: `L'importo iva disponibile della spesa di riferimento è pari a ${formattedImporto}. 
            Inserire un importo minore o uguale.`,
          [Fase.Liquidazione]: `L'importo iva disponibile del giustificativo di riferimento è pari a ${formattedImporto}. 
            Inserire un importo minore o uguale.`
      },
      cassa: {
          [Fase.Giustificativo]: `L'importo cassa disponibile della spesa di riferimento è pari a ${formattedImporto}. 
            Inserire un importo minore o uguale.`,
          [Fase.Liquidazione]: `L'importo cassa disponibile del giustificativo di riferimento è pari a ${formattedImporto}. 
            Inserire un importo minore o uguale.`
      },
      default: {
          [Fase.Mandato]: `L'importo massimo disponibile è pari a ${formattedImporto}. Inserire un importo minore o uguale.`
      }
    };

    const messageError = messages[item]?.[fase] || messages.default[fase] || '';

    this.confirmationService.confirm({
      message: messageError,
      header: 'Controllo importi',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Chiudi',
      rejectVisible: false,
      acceptButtonStyleClass: 'p-button-text',
      accept: () => {
        this.datiModelloDocumentale.formGroup.get(item).setValue(0);
      }
    });
  }

  checkImporto(event: any) {
    let form = event.formGroup;
    let item = event.item;
    let codiceRiferimento: number;
    switch (item) {
      case 'importoNetto':
        codiceRiferimento = form.get('qeItemImportoNettoCompositeId').value;
        break;
      case 'importoIVA':
        codiceRiferimento = form.get('qeItemIVACompositeId').value;
        break;
      case 'importoCassa':
        codiceRiferimento = form.get('qeItemCassaCompositeId').value;
        break;
    }

    if (codiceRiferimento) {
      let flatItem = this.qeFlatItems.find(x => x.compositeId === codiceRiferimento);
      const residuo = this.calcolaResiduoDaQE(form, flatItem, item);
      const importo = form.get(item).value;
      if (importo > residuo) {
        this.messageCheckImporti(residuo, item);
      }
    }
  }

  messageCheckImporti(total: number, string: string) {
    const formattedImporto = new CustomCurrencyPipe().transform(total);
    this.confirmationService.confirm({
      message: `La spesa a cui fai riferimento ha un importo residuo pari a ${formattedImporto}. 
        <br /> Hai inserito un importo maggiore al totale della spesa di riferimento. Procedere lo stesso?`,
      header: 'Controllo importi',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Si',
      rejectLabel: 'No, torna indietro',
      acceptButtonStyleClass: 'p-button-danger p-button-text',
      rejectButtonStyleClass: 'p-button-text',
      accept: () => {},
      reject: () => {
        if (string === 'importoNetto') {
          this.datiModelloDocumentale.formGroup.get('importoNetto').setValue(0);
        }
        if (string === 'importoIVA') {
          this.datiModelloDocumentale.formGroup.get('importoIVA').setValue(0);
        }
        if (string === 'importoCassa') {
          this.datiModelloDocumentale.formGroup.get('importoCassa').setValue(0);
        }
      },
    });
  }

  /**
   * Mostra il residuo esatto dei campi degli importi nella form di spesa (Fase1)
   * Controllo se il riferimento selezionato e l'importo sono già collegati tra loro
   * e se lo stesso riferimento è stato selezionato per gli altri campi prima del salvataggio
   */
  private calcolaResiduoDaQE(form: FormGroup, flatItem: QEItemBase, item: string): number {
    const infoRiferimento = this.hashMapRiferimentiQEInUso[flatItem.compositeId];
    let residuo = flatItem.total - (infoRiferimento?.totale || 0);
    let spesa = this.spese.find(x => x.id === form.value.id); 

    // Se il riferiento è quello già collegato alla spesa
    if (spesa) {
      if (item === 'importoNetto' && infoRiferimento?.spesePerImportonetto.includes(spesa)) {
        residuo += spesa.importoNetto;
      } else if (item === 'importoIVA' && infoRiferimento?.spesePerIva.includes(spesa)) {
        residuo += spesa.importoIVA;
      } else if (item === 'importoCassa' && infoRiferimento?.spesePerCassa.includes(spesa)) {
        residuo += spesa.importoCassa;
      }
    }
    //Controllo se lo stesso riferimento non è stato selezionato negli altri campi prima del salvataggio
    const nettoRif = form.get('qeItemImportoNettoCompositeId').value;
    const ivaRif = form.get('qeItemIVACompositeId').value;
    const cassaRif = form.get('qeItemCassaCompositeId').value;
    const importoNetto = form.get('importoNetto').value;
    const importoIva = form.get('importoIVA').value;
    const importoCassa = form.get('importoCassa').value;

    if (item !== 'importoNetto' && flatItem.compositeId === nettoRif) {
      if (!infoRiferimento || !infoRiferimento.spesePerImportonetto.includes(spesa)) {
        residuo = residuo - importoNetto;
      } else if (infoRiferimento?.spesePerImportonetto.includes(spesa) && importoNetto !== spesa?.importoNetto) {
        residuo = residuo + spesa?.importoNetto - importoNetto;
      }
    }
    if (item !== 'importoIVA' && flatItem.compositeId === ivaRif) {
      if (!infoRiferimento || !infoRiferimento.spesePerIva.includes(spesa)) {
        residuo = residuo - importoIva;
      } else if (infoRiferimento?.spesePerIva.includes(spesa) && importoIva !== spesa?.importoIVA) {
        residuo = residuo + spesa?.importoIVA - importoIva;
      }
    }
    if (item !== 'importoCassa' && flatItem.compositeId === cassaRif) {
      if (!infoRiferimento || !infoRiferimento.spesePerCassa.includes(spesa)) {
        residuo = residuo - importoCassa;
      } else if (infoRiferimento?.spesePerCassa.includes(spesa) && importoCassa !== spesa?.importoCassa) {
        residuo = residuo + spesa?.importoCassa - importoCassa;
      }
    } 
    return residuo;
  }

  /**
   * Nasconde la fase di liquidazione a seconda della configurazione del tipo spesa
   */ 
  checkHasLiquidazioneFasi(tipoSpesa?: string): boolean {
    let hasLiquidazione: boolean = true;
    if (tipoSpesa) {
      const spesaDaDizionario = this.tipiSpeseFromDizionario.find(x=> x.descrizione === tipoSpesa);
      hasLiquidazione = spesaDaDizionario ? !spesaDaDizionario.hasFase3 : true;
    }
    return hasLiquidazione;
  }

  updateSum() {
    if (this.visibleFormSpesa) {
      const importoNetto = this.datiModelloDocumentale.formGroup.get('importoNetto').value;
      const importoIVA = this.datiModelloDocumentale.formGroup.get('importoIVA').value;
      const importoCassa = this.datiModelloDocumentale.formGroup.get('importoCassa').value;
      const sum = importoNetto + importoIVA + importoCassa;
      this.datiModelloDocumentale.formGroup.get('importoTotale').setValue(sum);
    }

    if (this.visibleFormGiustificativo || this.visibleFormLiquidazione) {
      const importoNetto = this.datiModelloDocumentale.formGroup.get('importoNetto').value;
      const importoIVA = this.datiModelloDocumentale.formGroup.get('iva').value;
      const importoCassa = this.datiModelloDocumentale.formGroup.get('cassa').value;
      const sum = importoNetto + importoIVA + importoCassa;
      this.datiModelloDocumentale.formGroup.get('totale').setValue(sum);
    }
  }

  onChangeItemDropdown(event: any) {
    if (this.visibleFormSpesa) {
      if (event.item === 'qeItemImportoNettoCompositeId') {
        this.onChangeImportoNetto(event.formGroup, 'importoNetto');
      }
      if (event.item === 'qeItemIVACompositeId') {
        this.onChangeIva(event.formGroup, 'importoIVA');
      }
      if (event.item === 'qeItemCassaCompositeId') {
        this.onChangeCassa(event.formGroup, 'importoCassa');
      }
    }
    //!!! SETTA GLI IMPORTI
    if (this.visibleFormLiquidazione && event.item === 'fase2Id') {
      let selectedRiferimento = this.giustificativi.find(rif => rif.id === event.formGroup.value.fase2Id);
      const liquidazioneId = event.formGroup.value.id;
      const mandatiAssociati = this.spesa.fasi4.filter(x=> x.fase3Id === liquidazioneId);
      let totaleMandatiAssociati = mandatiAssociati && mandatiAssociati.length ? mandatiAssociati.reduce((acc, x) => acc + x.importo, 0) : 0;
      
      const giustificativoDaRiferimentoSelezionato: Fase2 = this.spesa?.fasi2?.find(x=> x.id === event.formGroup.value.fase2Id);
      const originalLiquidazione: Fase3 = this.spesa?.fasi3?.find(x=> x.id === liquidazioneId);

      let isGiàAssociato: boolean = false;
      let restoredLiquidazione: Fase3;

      if (liquidazioneId) {
        //controllo che il giustificativo di riferimento non sia già il giustificativo a cui è associata la liquidazione
        //se è già associatosuggerisco gli importi pre modifica con restoredLiquidazione
        isGiàAssociato = giustificativoDaRiferimentoSelezionato.fasi3.map(x=>x.id).includes(liquidazioneId);
        restoredLiquidazione = giustificativoDaRiferimentoSelezionato.fasi3.find(x=>x.id === liquidazioneId);
      }
      if (isGiàAssociato) {
        event.formGroup.get('importoNetto').setValue(restoredLiquidazione.importoNetto);
        event.formGroup.get('iva').setValue(restoredLiquidazione.iva);
        event.formGroup.get('cassa').setValue(restoredLiquidazione.cassa);
      } else {
        const disponibile = this.calcolaTotaliFasiFiglie(giustificativoDaRiferimentoSelezionato?.fasi3, event.formGroup);

        if (totaleMandatiAssociati > disponibile.importoNetto + disponibile.iva + disponibile.cassa) {
          const liquidazione = this.spesa.fasi3.find(x=>x.id === liquidazioneId)
          selectedRiferimento = this.giustificativi.find(rif => rif.id === liquidazione.fase2Id);
          this.confirmationService.confirm({
            message: `Il totale dei mandati associati a questa liquidazione superano il totale della liquidazione stessa. </ br>
              Modificare prima i mandati per poter cambiare riferimento.`,
            header: 'Controllo importi',
            icon: 'pi pi-exclamation-triangle',
            acceptLabel: 'Chiudi',
            rejectVisible: false,
            acceptButtonStyleClass: 'p-button-text',
            accept: () => {}
          });
          selectedRiferimento = this.giustificativi.find(rif => rif.id === originalLiquidazione.fase2Id);
          event.formGroup.get('fase2Id').setValue(selectedRiferimento.id);
        } else {
          event.formGroup.get('importoNetto').setValue(selectedRiferimento.importoNetto - disponibile.importoNetto);
          event.formGroup.get('iva').setValue(selectedRiferimento.iva - disponibile.iva);
          event.formGroup.get('cassa').setValue(selectedRiferimento.cassa - disponibile.cassa);
        }
      }
      if (event.formGroup.value.iva === 0) {
        event.formGroup.get('iva').setValue(null);
        this.datiModelloDocumentale.DatiForm.find(x=>x.key === 'iva').readonly = true;
      }
      if (event.formGroup.value.cassa === 0) {
        event.formGroup.get('cassa').setValue(null);
        this.datiModelloDocumentale.DatiForm.find(x=>x.key === 'cassa').readonly = true;
      }

      this.fase2Riferimenti = new Fase2Riferimenti();
      this.fase2Riferimenti.riferimentoLiquidazione = selectedRiferimento.text;
    }

    if (this.visibleFormMandato && event.item === 'fase3Id') {
      this.onChangeRiferimentoLiquidazione(event.formGroup);
    }
    if (this.visibleFormQuietanza && event.item === 'fase4Id') {
      this.onChangeRiferimentoMandato(event.formGroup);
    }
  }

  public onChangeRiferimentoMandato(form: FormGroup) {
    const riferimentoFind = this.mandati.find(rif => rif.id === form.value.fase4Id);
    if (riferimentoFind) {
      this.fase2Riferimenti = new Fase2Riferimenti();
      this.fase2Riferimenti.riferimentoLiquidazione = riferimentoFind.text;
      const fase4 = this.spesa.fasi4.find(
        (fase4) => fase4.id === riferimentoFind.id
      );
      if (fase4) {
        const fase3 = this.spesa.fasi3.find(
          (fase3) => fase3.id === fase4.fase3Id
        );
        if (fase3) {
          const fase2 = this.spesa.fasi2.find(
            (fase2) => fase2.id === fase3.fase2Id
          );
          if (fase2) {
            this.fase2Riferimenti.beneficiario = fase2.beneficiario;
            this.fase2Riferimenti.pIvaCodiceFiscale = fase2.pIvaCodiceFiscale;
          }
        }
      }
    }
    this.datiModelloDocumentale.formGroup = form;
  }

  public onChangeRiferimentoLiquidazione(form: FormGroup) {
    const selectedRiferimento = this.liquidazioni.find(rif => rif.id === form.value.fase3Id);
    if (selectedRiferimento) {
      this.fase2Riferimenti = new Fase2Riferimenti();
      const descrizione = form.get('descrizione').value;
      if (descrizione && descrizione !== '') {
        this.fase2Riferimenti.riferimentoLiquidazione =
          descrizione + ' - ' + selectedRiferimento.text;
      } else {
        this.fase2Riferimenti.riferimentoLiquidazione = selectedRiferimento.text;
      }

      if (!this.checkHasLiquidazioneFasi(this.spesa.tipo)) {
        const fase2 = this.spesa.fasi2.find((fase2) => fase2.id === selectedRiferimento.id);
        if (fase2) {
          this.fase2Riferimenti.beneficiario = fase2.beneficiario;
          this.fase2Riferimenti.pIvaCodiceFiscale = fase2.pIvaCodiceFiscale;
        }
      } else {
        const fase3 = this.spesa.fasi3.find(fase3 => fase3.id === selectedRiferimento.id);
        if (fase3) {
          const fase2 = this.spesa.fasi2.find(fase2 => fase2.id === fase3.fase2Id);
          if (fase2) {
            this.fase2Riferimenti.beneficiario = fase2.beneficiario;
            this.fase2Riferimenti.pIvaCodiceFiscale = fase2.pIvaCodiceFiscale;
          }
        }
      }
      // Quando cambio riferimento faccio il controllo sull'importo
      const eventMandato = {formGroup: form, item: 'importo'};
      this.onChangeItemCurrency(eventMandato);
      this.datiModelloDocumentale.formGroup = form;
    }
  }

  onChangeItemTextarea(event: any) {
    if (this.visibleFormMandato) {
      if (event.item === 'descrizione') {
        this.onChangeDescrizione(event.formGroup);
      }
    }
  }

  public onChangeDescrizione(form: FormGroup) {
    if (form.value.descrizione !== '') {
      if (this.fase2Riferimenti && this.fase2Riferimenti.riferimentoLiquidazione) {
        this.fase2Riferimenti.riferimentoLiquidazione = form.value.descrizione + ' - ' + this.fase2Riferimenti.riferimentoLiquidazione;
      }
      this.datiModelloDocumentale.formGroup = form;
    }
  }

  onChangeCassa(form: FormGroup, itemImporto: string) {
    const items = this.qeFlatItems.filter(x=>x.compositeId === form.value.qeItemCassaCompositeId);
    if (items == null || items == undefined) return;

    const flatItem: QEItemBase = items[0];
    if (flatItem.compositeId !== 0) {
      const residuo = this.calcolaResiduoDaQE(form, flatItem, itemImporto);
      const importoCassa = form.get('importoCassa').value;
    
      if (importoCassa > residuo) {
        this.messageCheckImporti(residuo, 'importoCassa');
      }
      this.datiModelloDocumentale.DatiForm.find(x=> x.key === 'importoCassa').readonly = false;
      form.get('qeItemCassaIndex').setValue(flatItem.index);
      form.get('qeItemCassaSubIndex').setValue(flatItem.subIndex);
      form.get('qeItemCassaSectionIndex').setValue(flatItem.qeSectionIndex);
      form.get('qeItemCassaSectionQEMainId').setValue(flatItem.qeSectionQEMainId);
      form.get('qeItemCassaCompositeId').setValue(flatItem.compositeId);
      
    } else {
      this.datiModelloDocumentale.DatiForm.find(x=> x.key === 'importoCassa').readonly = true;
      form.get('importoCassa').setValue(0);

      form.get('qeItemCassaIndex').setValue(null);
      form.get('qeItemCassaSubIndex').setValue(null);
      form.get('qeItemCassaSectionIndex').setValue(null);
      form.get('qeItemCassaSectionQEMainId').setValue(null);
    }

    this.datiModelloDocumentale.formGroup = form;
  }

  onChangeIva(form: FormGroup, itemImporto: string) {
    const items = this.qeFlatItems.filter(x=> x.compositeId === form.value.qeItemIVACompositeId);
    if (items == null || items == undefined) return;

    const flatItem: QEItemBase = items[0];
    if (flatItem.compositeId !== 0) {
      const residuo = this.calcolaResiduoDaQE(form, flatItem, itemImporto);
      const importoIVA = form.get('importoIVA').value;
      
      if (importoIVA > residuo) {
        this.messageCheckImporti(residuo, 'importoIVA');
      }
      this.datiModelloDocumentale.DatiForm.find(x=> x.key === 'importoIVA').readonly = false;
      form.get('qeItemIVAIndex').setValue(flatItem.index);
      form.get('qeItemIVASubIndex').setValue(flatItem.subIndex);
      form.get('qeItemIVASectionIndex').setValue(flatItem.qeSectionIndex);
      form.get('qeItemIVASectionQEMainId').setValue(flatItem.qeSectionQEMainId);
      form.get('qeItemIVACompositeId').setValue(flatItem.compositeId);

    } else {
      this.datiModelloDocumentale.DatiForm.find(x=> x.key === 'importoIVA').readonly = true;
      form.get('importoIVA').setValue(0);

      form.get('qeItemIVAIndex').setValue(null);
      form.get('qeItemIVASubIndex').setValue(null);
      form.get('qeItemIVASectionIndex').setValue(null);
      form.get('qeItemIVASectionQEMainId').setValue(null);
    }

    this.datiModelloDocumentale.formGroup = form;
  }

  /***
   * !!! Alessia TODO: da capire cosa fa! 
   */
  onChangeImportoNetto(form: FormGroup, itemImporto: string) {
    const items = this.qeFlatItems.filter(x=> x.compositeId === form.value.qeItemImportoNettoCompositeId);
    if (items == null || items == undefined) return;

    const flatItem: QEItemBase = items[0];
    const residuo = this.calcolaResiduoDaQE(form, flatItem, itemImporto);
    const importoNetto = form.get('importoNetto').value;

    if (importoNetto > residuo) {
      this.messageCheckImporti(residuo, 'importoNetto');
    }
    form.get('qeItemImportoNettoIndex').setValue(flatItem.index);
    form.get('qeItemImportoNettoSubIndex').setValue(flatItem.subIndex);
    form.get('qeItemImportoNettoSectionIndex').setValue(flatItem.qeSectionIndex);
    form.get('qeItemImportoNettoSectionQEMainId').setValue(flatItem.qeSectionQEMainId);
    form.get('qeItemImportoNettoCompositeId').setValue(flatItem.compositeId);

    this.datiModelloDocumentale.formGroup = form;
  }

  async showDialogForm(isInEdit: boolean, visualizza: boolean, spesa?: Fase1) {
    this.spinner.show();
    this.datiModelloDocumentale = this.scenarioServices.getModelloDocumentaleCustom(Scenario.Spesa);
    
    const formGroup = this.scenarioServices.getFormGroup(Scenario.Spesa);
    this.datiModelloDocumentale.isFrom = Scenario.Spesa;
    this.datiModelloDocumentale.cup = this.cup;
    this.datiModelloDocumentale.idCup = this.cup?.id;
    this.datiModelloDocumentale.isEdit = isInEdit;

    if (isInEdit) {
      this.datiModelloDocumentale.entityID = spesa.id;
      formGroup.setValue({
        cig: spesa.cig || null,
        id: spesa.id,
        tipo: spesa.tipo,
        atto: spesa.atto,
        numero: spesa.numero,
        beneficiario: spesa.beneficiario,
        data: new Date(spesa.data),
        descrizione: spesa.descrizione,
        pIvaCodiceFiscale: spesa.pIvaCodiceFiscale,
        importoNetto: spesa.importoNetto,
        importoIVA: spesa.importoIVA,
        importoCassa: spesa.importoCassa,
        qeItemImportoNettoIndex: spesa.qeItemImportoNetto?.index ?? null,
        qeItemImportoNettoCompositeId:
          spesa.qeItemImportoNetto?.compositeId ?? null,
        qeItemImportoNettoSubIndex: spesa.qeItemImportoNetto?.subIndex ?? null,
        qeItemImportoNettoSectionIndex:
          spesa.qeItemImportoNetto?.qeSectionIndex ?? null,
        qeItemImportoNettoSectionQEMainId:
          spesa.qeItemImportoNetto?.qeSectionQEMainId ?? null,
        qeItemIVACompositeId: spesa.qeItemIVA?.compositeId ?? null,
        qeItemIVAIndex: spesa.qeItemIVA?.index ?? null,
        qeItemIVASubIndex: spesa.qeItemIVA?.subIndex ?? null,
        qeItemIVASectionIndex: spesa.qeItemIVA?.qeSectionIndex ?? null,
        qeItemIVASectionQEMainId: spesa.qeItemIVA?.qeSectionQEMainId ?? null,
        qeItemCassaIndex: spesa.qeItemCassa?.index ?? null,
        qeItemCassaCompositeId: spesa.qeItemCassa?.compositeId ?? null,
        qeItemCassaSubIndex: spesa.qeItemCassa?.subIndex ?? null,
        qeItemCassaSectionIndex: spesa.qeItemCassa?.qeSectionIndex ?? null,
        qeItemCassaSectionQEMainId:
          spesa.qeItemCassa?.qeSectionQEMainId ?? null,
        cupId: this.idCup,
        importoTotale:
          spesa.importoNetto + spesa.importoIVA + spesa.importoCassa,
      });
      formGroup.disable();

      if (!formGroup.value.qeItemIVACompositeId) {
        formGroup.get('qeItemIVACompositeId').setValue(0);
        this.datiModelloDocumentale.DatiForm.find(x=>x.key === 'importoIVA').readonly = true;
      }
      if (!formGroup.value.qeItemCassaCompositeId) {
        formGroup.get('qeItemCassaCompositeId').setValue(0);
        this.datiModelloDocumentale.DatiForm.find(x=>x.key === 'importoCassa').readonly = true;
      }
      if (spesa.fasi2?.length) {
        this.datiModelloDocumentale.DatiForm.find(x=>x.key === 'tipo').readonly = true;
      } 
      this.spesa = spesa;
    }

    const qeItemImportoNettoCompositeId = this.datiModelloDocumentale.DatiForm.find(x => x.key === 'qeItemImportoNettoCompositeId');
    const qeItemIVACompositeId = this.datiModelloDocumentale.DatiForm.find(x => x.key === 'qeItemIVACompositeId');
    const qeItemCassaCompositeId = this.datiModelloDocumentale.DatiForm.find(x => x.key === 'qeItemCassaCompositeId');
    if(this.qeFlatItems?.length === 0){
      await this.getQeFlatItems();
    } 
    qeItemImportoNettoCompositeId.lista = this.qeFlatItems.filter(x=> x.compositeId !== 0);
    qeItemIVACompositeId.lista = this.qeFlatItems;
    qeItemCassaCompositeId.lista = this.qeFlatItems;

    this.datiModelloDocumentale.DatiForm.find(x => x.key === 'tipo').lista = isInEdit 
      ? this.tipiSpeseFromDizionario.filter(x => x.attivo || x.descrizione === spesa.tipo) 
      : this.tipiSpeseFromDizionario.filter(x => x.attivo);
    this.datiModelloDocumentale.DatiForm.find(x => x.key === 'atto').lista = isInEdit
      ? this.tipiAttiFromDizionario.filter(x => x.attivo || x.descrizione === spesa.atto) 
      : this.tipiAttiFromDizionario.filter(x => x.attivo);
    this.datiModelloDocumentale.formGroup = formGroup;
    this.datiModelloDocumentale.entityTipo = this.scenarioServices.getTipologia(
      'Ordinativo di spesa',
      formGroup
    );
    this.spinner.hide();
    this.visualizza = visualizza;
    this.visibleFormSpesa = true;
  }

  showDialogFatture(
    isInEdit: boolean,
    visualizza: boolean,
    giustificativo: Fase2,
    spesa?: Fase1,
    isFromCartellaMonitorata = false
  ) {
    this.spinner.show();
    this.datiModelloDocumentale =
      this.scenarioServices.getModelloDocumentaleCustom(Scenario.Giustificativo);
    this.datiModelloDocumentale.isEdit = isInEdit;
    this.datiModelloDocumentale.cup = this.cup;
    this.datiModelloDocumentale.idCup = this.cup?.id;
    this.spesa = spesa;

    if (!isFromCartellaMonitorata) {
      this.sharedService.setAnteprimaDocumentoCartellaMonitorata(
        false,
        undefined
      );
    }

    const formGroup = this.scenarioServices.getFormGroup(Scenario.Giustificativo);

    if (isInEdit) {
      this.datiModelloDocumentale.entityID = giustificativo.id;
      formGroup.setValue({
        id: giustificativo.id,
        fase1Id: giustificativo.fase1Id,
        tipo: giustificativo.tipo,
        numero: giustificativo.numero,
        data: new Date(giustificativo.data),
        importoNetto: giustificativo.importoNetto,
        iva: giustificativo.iva,
        cassa: giustificativo.cassa,
        descrizione: giustificativo.descrizione || null,
        beneficiario: giustificativo.beneficiario || null,
        pIvaCodiceFiscale: giustificativo.pIvaCodiceFiscale || null,
        totale:
          giustificativo.importoNetto +
          giustificativo.iva +
          giustificativo.cassa,
      });

      if (spesa && spesa.pIvaCodiceFiscale === '11111111111') {
        this.datiModelloDocumentale.DatiForm.find(x=>x.key === 'beneficiario').readonly = false;
        this.datiModelloDocumentale.DatiForm.find(x=>x.key === 'pIvaCodiceFiscale').readonly = false;
      }
      formGroup.disable();
    } else {
      formGroup.get('id').setValue(0);
      formGroup.get('fase1Id').setValue(spesa.id);

      if (spesa && spesa.pIvaCodiceFiscale === '11111111111') {
        this.datiModelloDocumentale.DatiForm.find(x=>x.key === 'beneficiario').readonly = false;
        this.datiModelloDocumentale.DatiForm.find(x=>x.key === 'pIvaCodiceFiscale').readonly = false;
      } else {
        formGroup.get('beneficiario').setValue(spesa.beneficiario);
        formGroup.get('pIvaCodiceFiscale').setValue(spesa.pIvaCodiceFiscale);

        this.datiModelloDocumentale.DatiForm.find(x=>x.key === 'beneficiario').readonly = true;
        this.datiModelloDocumentale.DatiForm.find(x=>x.key === 'pIvaCodiceFiscale').readonly = true;
      }
    }

    this.datiModelloDocumentale.formGroup = formGroup;
    this.datiModelloDocumentale.DatiForm.find(x=>x.key === 'tipo').lista = isInEdit
      ? this.tipiGiustificativiFromDizionario.filter(x => x.attivo || x.descrizione === giustificativo.tipo)
      : this.tipiGiustificativiFromDizionario.filter(x => x.attivo);
    this.datiModelloDocumentale.entityTipo = this.scenarioServices.getTipologia(
      'Giustificativo di spesa',
      formGroup
    );
    this.spinner.hide();
    this.visualizza = visualizza;
    this.visibleFormGiustificativo = true;
  }

  async showDialogLiquidazioni(
    isInEdit: boolean,
    visualizza: boolean,
    spesa: Fase1,
    liquidazione?: Fase3,
    isFromCartellaMonitorata = false
  ) {
    this.spinner.show();
    this.datiModelloDocumentale = this.scenarioServices.getModelloDocumentaleCustom(Scenario.Liquidazione);
    this.datiModelloDocumentale.isEdit = isInEdit;
    this.datiModelloDocumentale.cup = this.cup;
    this.datiModelloDocumentale.idCup = this.cup?.id;

    if (!isFromCartellaMonitorata) {
      this.sharedService.setAnteprimaDocumentoCartellaMonitorata(false, undefined);
    }

    this.spesa = spesa;
    this.beneficiarioString = spesa.beneficiario;
    this.pIva_cFiscaleString = spesa.pIvaCodiceFiscale;

    const formGroup = this.scenarioServices.getFormGroup(Scenario.Liquidazione);
    const response = await this.speseServices.rifFase2(spesa.id).toPromise();
    if (response && response.success) this.giustificativi = response.dtos;

    if (isInEdit) {
      this.datiModelloDocumentale.entityID = liquidazione.id;
      formGroup.setValue({
        id: liquidazione.id,
        fase2Id: liquidazione.fase2Id,
        numero: liquidazione.numero,
        data: new Date(liquidazione.data),
        importoNetto: liquidazione.importoNetto,
        iva: liquidazione.iva,
        cassa: liquidazione.cassa,
        totale:
          liquidazione.importoNetto + liquidazione.iva + liquidazione.cassa,
      });
      formGroup.disable();
    } else {
      formGroup.get('id').setValue(0);
    }

    this.visualizza = visualizza;
    this.visualizzaForm = this.giustificativi.length > 0;

    //Elimino i giustificativi completamente liquidati dalla scelta perchè non selezionabili, 
    //mantengo il gijustificativo completamente liquidato solo se sono in edit ed è quello a cui la liquidazione fa riferimento
    const idGiustificativiToCheck = this.giustificativi.map(x=>x.id);
    this.spesa.fasi2.forEach(ord=> {
      if (idGiustificativiToCheck.includes(ord.id)) {
        const disponibile = this.calcolaTotaliFasiFiglie(ord.fasi3, formGroup);
        if (ord.importoNetto <= disponibile.importoNetto && ord.iva <= disponibile.iva && ord.cassa <= disponibile.cassa) {
          if (isInEdit) {
            this.giustificativi = this.giustificativi.filter(x=> x.id !== ord.id || x.id == liquidazione.fase2Id);
          } else {
            this.giustificativi = this.giustificativi.filter(x=> x.id !== ord.id);
          }
        }
      }
    })
    this.datiModelloDocumentale.DatiForm.find(x=>x.key === 'fase2Id').lista = this.giustificativi;
    this.datiModelloDocumentale.formGroup = formGroup;
    this.datiModelloDocumentale.entityTipo = this.scenarioServices.getTipologia('Liquidazione', formGroup);
    this.spinner.hide();
    const message = !this.visualizzaForm ? this.scenarioServices.getMessage('Liquidazione') : '';
    if (message) {
      this.popUpResponse(false, message);
    } else {
      this.visibleFormLiquidazione = true;
    }
  }

  async showDialogMandati(isInEdit: boolean, visualizza: boolean, spesa: Fase1, mandato?: Fase4, isFromCartellaMonitorata = false) {
    this.spinner.show();
    this.datiModelloDocumentale = this.scenarioServices.getModelloDocumentaleCustom(Scenario.Mandato);
    this.datiModelloDocumentale.isEdit = isInEdit;
    this.spesa = spesa;

    if (!isFromCartellaMonitorata) {
      this.sharedService.setAnteprimaDocumentoCartellaMonitorata(
        false,
        undefined
      );
    }

    this.beneficiarioString = spesa.beneficiario;
    this.pIva_cFiscaleString = spesa.pIvaCodiceFiscale;
    let fase3Id = mandato ? mandato.fase3Id : null;
    const formGroup = this.scenarioServices.getFormGroup(Scenario.Mandato);
    if (!this.checkHasLiquidazioneFasi(this.spesa.tipo)) {
      const response = await this.speseServices.rifFase2(spesa.id).toPromise();
      if (response && response.success) {
        this.liquidazioni = response.dtos;
      }
    } else {
      const response = await this.speseServices.rifFase3(spesa.id).toPromise();
      if (response && response.success) {
        this.liquidazioni = response.dtos;
      }
    }

    this.visualizzaForm = this.liquidazioni?.length > 0;
    if (isInEdit) {
      if (this.liquidazioni && this.liquidazioni.length > 0 && !this.checkHasLiquidazioneFasi(this.spesa.tipo)) {
        const fase3 = this.spesa.fasi3.find(fase3 => fase3.id === mandato.fase3Id);
        if (fase3) {
          const fase2 = this.spesa.fasi2.find(fase2 => fase2.id === fase3.fase2Id);
          if (fase2) {
            fase3Id = fase2.id;
          }
        } 
      }
      this.datiModelloDocumentale.entityID = mandato.id;
      formGroup.setValue({
        id: mandato.id,
        fase3Id: mandato.fase3Id,
        numero: mandato.numero,
        data: new Date(mandato.data),
        importo: mandato.importo,
        descrizione: mandato.descrizione,
      });
      formGroup.disable();
    } else {
      formGroup.get('id').setValue(0);
    }

    let idLiquidazioniNelMandato = this.liquidazioni.map(x=>x.id);
    if (!this.checkHasLiquidazioneFasi(this.spesa.tipo)) {
      //padre fase 2
      this.spesa.fasi2.forEach(giust => {
        if (idLiquidazioniNelMandato.includes(giust.id)) {
          //per ogni giustificativo prendo la liquidazione fantasma e calcolo il totale dei mandati associati
          const totaleMandati = giust.fasi3[0].fasi4.reduce((acc, x) => acc + x.importo, 0);
          const disponibile = giust.importoNetto + giust.iva + giust.cassa - totaleMandati;
          if (disponibile <= 0 && giust.fasi3[0].id !== formGroup.value.fase3Id) {
            this.liquidazioni = this.liquidazioni.filter(x=> x.id !== giust.id);
          }
        }
      })
    } else {
      //padre fase 3
      this.spesa.fasi3.forEach(liq => {
        if (idLiquidazioniNelMandato.includes(liq.id)) {
          //per ogni liquidazione calcolo il totale dei mandati associati
          const totaleMandati = liq.fasi4.reduce((acc, x) => acc + x.importo, 0);
          const disponibile = liq.importoNetto + liq.iva + liq.cassa - totaleMandati;
          if (disponibile <= 0 && liq.id !== formGroup.value.fase3Id) {
            this.liquidazioni = this.liquidazioni.filter(x=> x.id !== liq.id);
          }
        }
      })
    }
    if (isInEdit) {
      formGroup.get('fase3Id').setValue(mandato.fase3Id === fase3Id ? mandato.fase3Id : fase3Id);
    }
    this.datiModelloDocumentale.DatiForm.find(x=>x.key === 'fase3Id').lista = this.liquidazioni;
    this.datiModelloDocumentale.cup = this.cup;
    this.datiModelloDocumentale.idCup = this.cup?.id;
    this.datiModelloDocumentale.formGroup = formGroup;
    this.datiModelloDocumentale.entityTipo = this.scenarioServices.getTipologia('Mandato',formGroup);
    this.spinner.hide();
    this.visualizza = visualizza;
    const message = !this.visualizzaForm ? this.scenarioServices.getMessage('Mandato') : '';
    if (message) {
      this.popUpResponse(false, message);
    } else {
      this.visibleFormMandato = true;
    }
  }

  async showDialogQuietanze(
    isInEdit: boolean,
    spesa: Fase1,
    quietanza?: Fase5,
    isFromCartellaMonitorata = false
  ) {
    this.spinner.show();
    this.datiModelloDocumentale = this.scenarioServices.getModelloDocumentaleCustom(Scenario.Quietanza);
    this.datiModelloDocumentale.isEdit = isInEdit;
    this.spesa = spesa;
    this.quietanza = quietanza;

    if (!isFromCartellaMonitorata) {
      this.sharedService.setAnteprimaDocumentoCartellaMonitorata(
        false,
        undefined
      );
    }
    this.beneficiarioString = spesa.beneficiario;
    this.pIva_cFiscaleString = spesa.pIvaCodiceFiscale;

    const formGroup = this.scenarioServices.getFormGroup(Scenario.Quietanza);
    const response = await this.speseServices.rifFase4(spesa.id).toPromise();
    if (response && response.success) {
      this.mandati = response.dtos;
    } else {
      console.log('500', response);
    }

    this.visualizzaForm = true;
    if (!isInEdit) {
      if (this.mandati.length > 0 && this.mandati.length === spesa.fasi5.length) {
        this.visualizzaForm = false;
        this.stopFase5 = true;
      } else {
        this.visualizzaForm = this.mandati.length !== 0;
      }
    }

    this.spesa.fasi4.forEach(m => {
      if (!m.fase5) {
      } else if (m.fase5 && m.fase5.id !== quietanza?.id) {
        this.mandati = this.mandati.filter(x => x.id !== m.id);
      }
    }) 

    if (isInEdit) {
      this.datiModelloDocumentale.entityID = quietanza.id;
      formGroup.setValue({
        id: quietanza.id,
        fase4Id: quietanza.fase4Id,
        attachment: quietanza.attachmentUrl,
      });
      formGroup.disable();
    } else {
      formGroup.get('id').setValue(0);
    }

    this.datiModelloDocumentale.entityTipo = this.scenarioServices.getTipologia(
      'Quietanza',
      formGroup
    );
    this.spinner.hide();
    this.datiModelloDocumentale.cup = this.cup;
    this.datiModelloDocumentale.idCup = this.cup?.id;
    this.datiModelloDocumentale.formGroup = formGroup;
    this.datiModelloDocumentale.DatiForm.find(x=>x.key === 'fase4Id').lista = this.mandati || [];
    const message = !this.visualizzaForm ? this.scenarioServices.getMessage('Quietanza', this.stopFase5) : '';
    if (message) {
      this.popUpResponse(false, message);
    } else {
      this.visibleFormQuietanza = true;
    }
  }

  async checkBeforeSave(form: FormGroup, tipoFase: Fase) {
    let message = '';
    console.log(this.spesa, form);
    switch (tipoFase) {
      case Fase.Spesa:
        if (this.spesa.fasi2?.length) {
          message = `Questa spesa ha dei giustificativi associati. 
            I dati modificati potrebbero essere non più coerenti con le fasi successive. Procedere con il salvataggio?`;
        }            
        break;
      case Fase.Giustificativo: {
          const giustificativo = this.spesa.fasi2.find(x => x.id === form.value.id);
          if (giustificativo.fasi3.length) {
            message = `Questo giustificativo ha delle liquidazioni associate. 
              I dati modificati potrebbero essere non più coerenti con le fasi successive. Procedere con il salvataggio?`;
          }
        } 
        break;
      case Fase.Liquidazione:
        const fase4 = this.spesa.fasi4.find(x=> x.id === form.value.fase3Id);
        if (fase4) {
          message = `Questa liquidazione ha dei mandati associati. 
            I dati modificati potrebbero essere non più coerenti con le fasi successive. Procedere con il salvataggio?`;
        }
        break;
      case Fase.Mandato:
        const fase5 = this.spesa.fasi5.find(x=> x.id === form.value.fase4Id);
        if (fase5) {
          message = `Questo mandato ha già una quietanza associata. Procedere con il salvataggio?`;
        }
        break;
      default:
        break;
    }
    return message ? await this.confermaSalvataggio(message) : this.proseguiSalvataggio();
  }

  proseguiSalvataggio() {
    if (this.uploadFileComponent) {
      this.uploadFileComponent.saveAfterCheck();
    }
  }

  confermaSalvataggio(message: string): Promise<boolean> {
    return new Promise((resolve) => {
      this.confirmationService.confirm({
        message: message,
        header: 'Warning',
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: 'Si',
        rejectLabel: 'No, torna indietro',
        acceptButtonStyleClass: 'p-button-danger p-button-text',
        rejectButtonStyleClass: 'p-button-text',
        accept: () => {
          this.proseguiSalvataggio();
          resolve(true)},
        reject: () => resolve(false),
      });
    });
  }

  async saveFase(form: FormGroup, tipoFase: Fase) {
    this.spinner.show();
    if (tipoFase === Fase.Mandato && !this.checkHasLiquidazioneFasi(this.spesa.tipo)) {
      const riferimentoFind = form.get('fase3Id').value;
      const fase2 = this.spesa.fasi2.find((fase2) => fase2.id === riferimentoFind);
      if (fase2) form.get('fase3Id').setValue(fase2.fasi3[0].id);
    }
    const fase = form.value;

    const saveMethods = {
      [Fase.Spesa]: () => this.speseServices.updateFase1(fase, fase.id),
      [Fase.Giustificativo]: () => this.speseServices.updateFase2(fase, fase.id),
      [Fase.Liquidazione]: () => this.speseServices.updateFase3(fase, fase.id),
      [Fase.Mandato]: () => this.speseServices.updateFase4(fase, fase.id),
      [Fase.Quietanza]: () => this.speseServices.updateFase5(fase, fase.id),
    };
    const messageSuccess = {
      [Fase.Spesa]: 'Spesa modificata con successo',
      [Fase.Giustificativo]: 'Giustificativo modificato con successo',
      [Fase.Liquidazione]: 'Liquidazione modificata con successo',
      [Fase.Mandato]: 'Mandato modificato con successo',
      [Fase.Quietanza]: 'Quietanza modificata con successo',
    };
    const messageError = {
      [Fase.Spesa]: 'Errore durante la modifica della spesa',
      [Fase.Giustificativo]: 'Errore durante la modifica del giustificativo',
      [Fase.Liquidazione]: 'Errore durante la modifica della liquidazione',
      [Fase.Mandato]: 'Errore durante la modifica del mandato',
      [Fase.Quietanza]: 'Errore durante la modifica della quietanza',
    };

    const response = await saveMethods[tipoFase]().toPromise();
    if (response && response.success) {
      //this.popUpResponse(true, messageSuccess[tipoFase]);
      this.sharedService.publishMessage('success', 'Successo', messageSuccess[tipoFase]);
      await this.onUpdateFasi();
      if (tipoFase === Fase.Quietanza) {
        this.entityIdAdd = response.dtos?.id;
      }
    } else {
      this.popUpResponse(false, messageError[tipoFase]);
    }
    this.spinner.hide();
    this.closeDialog();
  }

  public eliminaFase(id: number, fase: Fase) {
    const messages = {
      [Fase.Spesa]: 'Sei sicuro di voler eliminare questa spesa?',
      [Fase.Giustificativo]: 'Sei sicuro di voler eliminare questo giustificativo di spesa?',
      [Fase.Liquidazione]: 'Sei sicuro di voler eliminare questa liquidazione?',
      [Fase.Mandato]: 'Sei sicuro di voler eliminare questo mandato?',
      [Fase.Quietanza]: 'Sei sicuro di voler eliminare questa quietanza?',
    };

    const detail = {
      [Fase.Spesa]: 'Spesa eliminata con successo',
      [Fase.Giustificativo]: 'Giustificativo eliminato con successo',
      [Fase.Liquidazione]: 'Liquidazione eliminata con successo',
      [Fase.Mandato]: 'Mandato eliminato con successo',
      [Fase.Quietanza]: 'Quietanza eliminata con successo'
    };

    const deleteMethods = {
      [Fase.Spesa]: () => this.speseServices.deleteFase1(id),
      [Fase.Giustificativo]: () => this.speseServices.deleteFase2(id),
      [Fase.Liquidazione]: () => this.speseServices.deleteFase3(id),
      [Fase.Mandato]: () => this.speseServices.deleteFase4(id),
      [Fase.Quietanza]: () => this.speseServices.deleteFase5(id),
    };

    this.confirmationService.confirm({
      message: messages[fase],
      header: 'Eliminazione',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Si, elimina',
      rejectLabel: 'No, torna indietro',
      acceptButtonStyleClass: 'p-button-danger p-button-text',
      rejectButtonStyleClass: 'p-button-text',
      accept: () => {
        deleteMethods[fase]().subscribe({
          next: async (resp: any) => {
            if (resp) {
              //this.popUpResponse(true, detail[fase]);
              this.sharedService.publishMessage('success', 'Successo', detail[fase]);
              await this.onUpdateFasi();
            }
          },
        });
      },
      reject: () => { },
    });
  }

  public popUpResponse(success: boolean, message: string) {
    this.dialogModel = {
      title: success ? "Successo" : "Attenzione",
      message: message,
      btnConferma: "Chiudi",
      hasBtnAnnulla: false
    };
    this.visibleDialog = true;
  }

  /* STYLE GRIGLIA */
  public mandatoToString(spesa, id: number) {
    this.rif = spesa.fasi4.filter((x) => x.id === id);
    return `Atto ${this.rif[0].numero}`;
  }

  public getQuietanze(spesa: Fase1, mandato: Fase4){
    return spesa.fasi5.filter(quietanze => quietanze.fase4Id === mandato.id);;
  }

  public resizeTextarea() {
    const textarea = document.getElementById('myTextarea');
    textarea.style.height = 'auto';
    textarea.style.height = `${textarea.scrollHeight}px`;
  }

  /* FILTRI TAB ESECUZIONE */
  filter(tipo: string) {
    if (!tipo) {
      this.filteredTab = this.spese;
    } else {
      this.filteredTab = this.spese.filter((item) => item.tipo == tipo);
    }
  }

  filterRiferimento(riferimento: string) {
    if (riferimento) {
      let itemRif = this.qeFlatItems.find((x) => x.title === riferimento);
      this.filteredTab = this.spese.filter(
        (x) =>
          x.qeItemImportoNetto?.title === itemRif.item ||
          x.qeItemIVA?.title === itemRif.item ||
          x.qeItemCassa?.title === itemRif.item
      );
    } else {
      this.filteredTab = this.spese;
    }
  }

  filterBeneficiario(beneficiario: any) {
    if (beneficiario) {
      this.filteredTab = this.spese.filter(
        (x) => x.beneficiario === beneficiario.nome
      );
    } else {
      this.filteredTab = this.spese;
    }
  }

  hashMapRowQE(): { [compositeId: number]: InfoSpesePerRiferimento } {
    const hashmap: { [compositeId: number]: InfoSpesePerRiferimento } = {};
    this.spese.forEach(spesa => {
      const importoNettoId = spesa.qeItemImportoNetto.compositeId;
      if (importoNettoId) {
        if (!hashmap[importoNettoId]) {
          hashmap[importoNettoId] = {
            totale: 0,
            spesePerImportonetto: [],
            spesePerIva: [],
            spesePerCassa: []
          };
        }
        hashmap[importoNettoId].totale += spesa.importoNetto;
        hashmap[importoNettoId].spesePerImportonetto.push(spesa);
      }
      const ivaId = spesa.qeItemIVA?.compositeId;
      if (ivaId) {
        if (!hashmap[ivaId]) {
          hashmap[ivaId] = {
            totale: 0,
            spesePerImportonetto: [],
            spesePerIva: [],
            spesePerCassa: []
          };
        }
        hashmap[ivaId].totale += spesa.importoIVA;
        hashmap[ivaId].spesePerIva.push(spesa);
      }
      const cassaId = spesa.qeItemCassa?.compositeId;
      if (cassaId) {
        if (!hashmap[cassaId]) {
          hashmap[cassaId] = {
            totale: 0,
            spesePerImportonetto: [],
            spesePerIva: [],
            spesePerCassa: []
          };
        }
        hashmap[cassaId].totale += spesa.importoCassa;
        hashmap[cassaId].spesePerCassa.push(spesa);
      }
    });
    return hashmap;
  }
  
  /**
   * Al completamento di ogni modifica all'interni dell'esecuzione, 
   * qst metodo assicura l'aggiornamento della sezione
   */
  async onUpdateFasi(addSpinner: boolean = false) {
    let retVal = false;
    delete this.beneficiari;
    const idCup = this.cup?.id || this.idCup;
    this.sharedService.deleteRowsImpegnate();
    const response = await this.speseServices.getAllFasiByCupId(idCup).toPromise();
    if (response?.success) {
      this.spese = response.dtos;
      this.calcolaRowImpegnate();
      this.entityIdAdd = 0;
      delete this.hashMapRiferimentiQEInUso;
      this.hashMapRiferimentiQEInUso = this.hashMapRowQE();
      retVal = true;
      this.beneficiari = this.getBeneficiariFiltro(this.spese);      
      this.spese.forEach(fase1 => {
        fase1.fasi3 = fase1.fasi2?.flatMap(fase2 => fase2.fasi3 || []);
        fase1.fasi4 = fase1.fasi3.flatMap(fase3 => fase3.fasi4 || []);
        fase1.fasi5 = fase1.fasi4.flatMap(fase4 => fase4.fase5 || []);
      });
      this.filteredTab = this.spese;
    } else {
      this.scenarioServices.showErrorMessage('Errore recupero Spese');
    }
    if (addSpinner) {
      this.spinner.hide();
    }
    return retVal;
  }

  /**
   * Individua le righe non eliminabili del QE
   */
  protected calcolaRowImpegnate() {
    var rowQEImpegnate = [];
    for (let spesa of this.spese) {
      if (!rowQEImpegnate.includes(JSON.stringify(spesa.qeItemImportoNetto))) {
        rowQEImpegnate.push(JSON.stringify(spesa.qeItemImportoNetto));
      }
      if (!rowQEImpegnate.includes(JSON.stringify(spesa.qeItemIVA))) {
        rowQEImpegnate.push(JSON.stringify(spesa.qeItemIVA));
      }
      if (
        spesa.qeItemCassa != null &&
        !rowQEImpegnate.includes(JSON.stringify(spesa.qeItemCassa))
      ) {
        rowQEImpegnate.push(JSON.stringify(spesa.qeItemCassa));
      }
    }
    this.rowQEImpegnate = [];
    for (let elem of rowQEImpegnate) {
      this.rowQEImpegnate.push(JSON.parse(elem));
    }
    this.sendRowImpegnate.emit(this.rowQEImpegnate);
  }

  private getBeneficiariFiltro(spese: Fase1[]) {
    return spese.map((spesa:Fase1) => {
      return { nome: spesa.beneficiario };
    });
  }

}
