<app-nav-menu></app-nav-menu>

<main class="custom-container">
  <router-outlet></router-outlet>
</main>


<ngx-spinner style="z-index: 99999999;" bdcolor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="ball-clip-rotate"
  [fullScreen]="true"></ngx-spinner>
<div id="anchor" style="z-index: 499999;"></div>
<p-confirmDialog [breakpoints]="{'960px': '85vw', '700px': '95vw'}" [style]="{width: '60vw'}" [baseZIndex]="10000">
</p-confirmDialog>
<p-toast></p-toast>
