import { SuccessAuthenticationResult } from './../service/authorization-service/authorize.service';
import { CUP } from './cup';

export class QEResponse {
  success: boolean;
  dto: QE;
  message?: string;
}
export class QE {
  cupId: number;
  detail?: QEDetail;
  id: number;
  publishedAt?: Date;
  qeMainId: number;
  sections: QESection[];
  version: number;

  constructor(
    cupId: number,
    sections: QESection[],
    id: number,
    version: number,
    qeMainId: number,
    publishedAt?: Date,
    detail?: QEDetail
  ) {
    this.cupId = cupId;
    this.sections = sections;
    this.id = id;
    this.version = version;
    this.publishedAt = publishedAt;
    this.detail = detail || null;
    this.qeMainId = qeMainId || 0;
  }
}

export interface QEModelResponse {
  success: boolean;
  dto: QEModel;
}

export interface QEModel {
  nome: string;
  draft: QE;
}

export interface ModelResponse {
  success: boolean;
  dtos: Model[];
}
export interface Model {
  nome: string;
  id: number;
}

export class QEHub {
  current: QE;
  draft: QE;
  histories: QEMainBase[];
}

export interface QEMainBase {
  id: number;
  version: number;
  publishedAt?: Date;
  detail?: QEDetail;
  cupId: number;
  qEMainId: number;
}

export interface QEDetail {
  idCup: number;
  id: number;
  tipo: string;
  atto: string;
  numero: string;
  data: Date;
  descrizione: string;
  allegato?: boolean;
  qeMainId?: number;
}

export interface QESection {
  qeMainId: number;
  title: string;
  description: string;
  index: number;
  total: number;
  items: QEItem[];
}
/*
Secion -> 0 - A
0 1 0 -> A.1 Header
0 1 1 -> A.1.1
0 1 2 -> A.1.2 

*/

export interface QEItem {
  qeSectionIndex: number;
  qeSectionQEMainId: number;
  index: number;
  subIndex: number;
  title: string;
  total: number;

  importDisabled?: boolean;
  deletable?: boolean;
  //flag?: boolean | false;
}
export class QECompareResponse {
  success: boolean;
  dto: QECompare;
}

export class QECompare {
  items: QEItemCompare[];
  firstQE: QE;
  secondQE: QE;
}

export interface QEItemCompare {
  firstIndex: number;
  firstSubIndex: number;
  secondIndex: number;
  secondSubIndex: number;
  firstSectionIndex: number;
  secondSectionIndex: number;
  secondSectionQEMainId: number;
  firstSectionQEMainId: number;
  firstSectionTitle: string;
  secondSectionTitle: string;
  firstSectionDescription: string;
  secondSectionDescription: string;
  firstTotal: number;
  secondTotal: number;
  items: QEItemCompare[];
}

export class Alarm {
  id: number;
  data: Date;
  descrizione: string;
  alertAt: number[];
  emails: string[];
  cupId: number;
  //qEHEaderId: number;
  //qEItemId: number;

  constructor(
    id?: number,
    data?: Date,
    descrizione?: string,
    alertAt?: number[],
    emails?: string[],
    cupId?: number
  ) {
    this.id = id || 0;
    this.data = data;
    this.descrizione = descrizione || '';
    this.alertAt = alertAt || [];
    this.emails = emails || [];
    this.cupId = cupId || 0;

    /* this.qEHEaderId = qEHEaderId || 0;
    this.qEItemId = qEItemId || 0; */
  }
}

export class Annotations {
  descrizione: string;
  data: Date;
  nota: string;
  attachmentUrl: string;
  cup: CUP;
  cupId: number;

  constructor(
    descrizione?: string,
    data?: Date,
    nota?: string,
    attachmentUrl?: string,
    cup?: CUP,
    cupId?: number
  ) {
    this.descrizione = descrizione || '';
    this.data = data;
    this.nota = nota || '';
    this.attachmentUrl = attachmentUrl || '';
    this.cup = cup;
    this.cupId = cupId || 0;
  }
}

export interface QEItemBaseResponse {
  success: boolean;
  dtos: QEItemBase[];
}

export interface QEItemBase {
  qeSectionIndex: number;
  qeSectionQEMainId: number;
  index: number;
  subIndex: number;
  title: string;
  total: number;
  compositeId: number;
  
  item?: string;
  parent?: string;
  section?: string;
  //flag?: boolean;
}

export class SectionTotali {
  sectionIndex: number;
  index: number;
  subIndex: number;
  impegni: number;
  giustificativi: number;
  liquidazioni: number;
  mandati: number;
}
export interface QETotaliSezioniExcelResponse {
  success: boolean;
  dto: QETotaliSezioniExcel;
}

export interface QETotaliSezioniExcel extends QE {
  totali: SectionTotali[];
}

export interface CupTotaliResponse {
  success: boolean;
  dtos: CupTotali[];
}

/**
 * Totali degli importi nel cup
 */
export interface CupTotali {
  totaleQE: number;
  cupId: number;
  codice: string;
  titolo: string;
  stato: string;
  descrizione: string;
  importo: number;
  impegni: number;
  giustificativi: number;
  liquidazioni: number;
  mandati: number;
}



export interface QEExcelTotaliEsecuzioneResponse {
  success: boolean;
  dtos: QEExcelTotaliEsecuzione[];
}

export interface QEExcelTotaliEsecuzione {
  qeIndice: number;
  title: string;
  qeIndiceImporto: number;
  descrizione:string;
  impegni: QEExcelTotaliEsecuzioneImpegno[]; 
  qeSubIndex: number;
  qeSectionIndex: number;
}

export interface QEExcelTotaliEsecuzioneDettaglio {
  numero: number;
  atto: string;
  importo: number;
}

export interface QEExcelTotaliMandatoDettaglio extends QEExcelTotaliEsecuzioneDettaglio{
  descrizione: string;
  isQuietanza: boolean;
}


export interface QEExcelTotaliEsecuzioneImpegno extends QEExcelTotaliEsecuzioneDettaglio
{
  beneficiario: string;
  giustificativi:  QEExcelTotaliGiustificativoDettaglio[];
}

export interface QEExcelTotaliGiustificativoDettaglio extends QEExcelTotaliEsecuzioneDettaglio {
  liquidazioni: QEExcelTotaliEsecuzioneDettaglio[];
}

