import { Pipe, PipeTransform } from '@angular/core';
import {
  ColOrdinamento,
  NomeTab,
  TipoChiave,
  TipoEntita,
} from '../model/documento';
import { Scenario } from '../model/scenari';

@Pipe({ name: 'TipoEntitaPipe' })
export class TipoEntitaPipe implements PipeTransform {
  transform(value: number): string {
    if (value === 18) {
      return 'Quadro Economico';
    } else {
      return TipoEntita[value];
    }
  }
}

@Pipe({ name: 'EntitaPipe' })
export class EntitaPipe implements PipeTransform {
  transform(value: number): TipoEntita {
    let result: TipoEntita;
    if (value) {
      result = value;
    }
    return result;
  }
}

@Pipe({ name: 'tipoChiavePipe' })
export class TipoChiavePipe implements PipeTransform {
  transform(value: TipoChiave): string {
    return TipoChiave[value];
  }
}

@Pipe({ name: 'tipoCampoPipe' })
export class TipoCampoPipe implements PipeTransform {
  transform(value: TipoChiave): string {
    let tipo: string;
    switch (TipoChiave[value]) {
      case 'Testo':
        tipo = 'text';
        break;
      case 'Data':
        tipo = 'date';
        break;
      case 'DataOra':
        tipo = 'date';
        break;
      case 'Numero':
        tipo = 'number';
        break;
      case 'Valuta':
        tipo = 'currency';
        break;
      case 'Lista':
        tipo = 'lista';
        break;
    }
    return tipo;
  }
}

@Pipe({ name: 'nomeModelloDocumentalePipe' })
export class NomeModelloDocumentalePipe implements PipeTransform {
  transform(value: Scenario): string {
    let result = '';
    switch (value) {
      case Scenario.Cup:
        result = 'CUP';
        break;
      case Scenario.QuadroEconomico:
        result = 'Quadro Economico';
        break;
      case Scenario.Spesa:
        result = 'Ordinativo di spesa';
        break;
      case Scenario.Giustificativo:
        result = 'Giustificativo di spesa';
        break;
      case Scenario.Liquidazione:
        result = 'Liquidazione';
        break;
      case Scenario.Mandato:
        result = 'Mandato';
        break;
      case Scenario.Quietanza:
        result = 'Quietanza';
        break;
      case Scenario.Incasso:
        result = 'Incassato';
        break;
      case Scenario.Fonte:
        result = 'FonteFinanziamento';
        break;
      case Scenario.Annotazione:
        result = 'Annotazione';
        break;
    }
    return result;
  }
}

@Pipe({ name: 'colonnaOrdinamentoPipe' })
export class ColonnaOrdinamentoPipe implements PipeTransform {
  transform(value: string): ColOrdinamento {
    let result: ColOrdinamento;
    switch (value) {
      case 'key1_Value':
        result = ColOrdinamento.Key1;
        break;
      case 'key2_Value':
        result = ColOrdinamento.Key2;
        break;
      case 'key3_Value':
        result = ColOrdinamento.Key3;
        break;
      case 'key4_Value':
        result = ColOrdinamento.Key4;
        break;
      case 'key5_Value':
        result = ColOrdinamento.Key5;
        break;
      case 'key6_Value':
        result = ColOrdinamento.Key6;
        break;
      case 'key7_Value':
        result = ColOrdinamento.Key7;
        break;
      case 'key8_Value':
        result = ColOrdinamento.Key8;
        break;
      case 'key9_Value':
        result = ColOrdinamento.Key9;
        break;
      case 'key10_Value':
        result = ColOrdinamento.Key10;
        break;
      case 'nomeModello':
        result = ColOrdinamento.Modello;
        break;
      case 'nomeFile':
        result = ColOrdinamento.NomeFile;
        break;
      case 'protocollo':
        result = ColOrdinamento.Progressivo;
        break;
      // case "nomeModello":
      //   result = ColOrdinamento.DataInserimento;
      //   break;
      // case "nomeModello":
      //   result = ColOrdinamento.Sezione;
      //   break;
      // case "nomeModello":
      //   result = ColOrdinamento.DataDocumento;
      //   break;
    }
    return result;
  }
}

@Pipe({ name: 'nomeTabPipe' })
export class NomeTabPipe implements PipeTransform {
  transform(value: NomeTab): string {
    let resultValue: string;
    switch (value) {
      case NomeTab.Documenti:
        resultValue = 'Esecuzione';
        break;
      case NomeTab.Fonte:
        resultValue = 'Fonte';
        break;
      case NomeTab.Progetto:
        resultValue = 'Progetto';
        break;
      case NomeTab.Gara:
        resultValue = 'Gara';
        break;
      default:
        break;
    }
    return resultValue;
  }
}
