<div class="d-flex flex-row">
  <div class="flex-1 m-2">

    <div class="d-flex flex-row">
      <div class="d-flex">
        <form [formGroup]="tipoSpeseForm">
          <p-dropdown optionLabel="descrizione" [options]="tipiSpeseFiltro" optionValue="descrizione"
            formControlName="tipo" (onChange)="filter($event.value)" placeholder="Tipo" [showClear]="true">
          </p-dropdown>
        </form>

        <p-dropdown placeholder="Riferimento" [(ngModel)]="selected_Riferimento" [options]="qeFlatItems"
          optionValue="title" optionLabel="title" [filter]="true" filterBy="text" [showClear]="true"
          (onChange)="filterRiferimento($event.value)" id="riferimentoDropdown">
          <ng-template pTemplate="selectedItem">
            <div class="flex align-items-center gap-2" *ngIf="selected_Riferimento">
              <div>{{ (selected_Riferimento.length>10)? (selected_Riferimento | slice:0:10)+'...':(selected_Riferimento)
                }}</div>
            </div>
          </ng-template>
          <ng-template let-rif pTemplate="item">
            <div class="flex align-items-center gap-2">
              <div>{{ rif.title }}</div>
            </div>
          </ng-template>
        </p-dropdown>

        <p-dropdown [options]="beneficiari" [options]="beneficiari" optionLabel="nome" [filter]="true" filterBy="nome"
          [showClear]="true" placeholder="Beneficiario" (onChange)="filterBeneficiario($event.value)"
          id="riferimentoDropBeneficiari">
        </p-dropdown>
      </div>

      <div class="d-flex my-2">
        <rbk-export-excel *ngIf="spese?.length > 0" [esecuzione]="spese" [cup]="cup"
          [tipoEsportazione]="esportaExcelTipo.Esecuzione"></rbk-export-excel>
        <rbk-export-excel *ngIf="spese?.length > 0" [esecuzione]="spese" [cup]="cup"
          [tipoEsportazione]="esportaExcelTipo.EsecuzioneEstesa"></rbk-export-excel>
        <rbk-export-excel *ngIf="spese?.length > 0" [esecuzione]="spese" [cup]="cup" [qe]="qe"
          [tipoEsportazione]="esportaExcelTipo.QuadroEconomicoConTotaliSezioni"></rbk-export-excel>
        <rbk-export-excel *ngIf="spese?.length > 0" [cup]="cup" [qe]="qe"
          [tipoEsportazione]="esportaExcelTipo.QuadroEconomicoDettaglio"></rbk-export-excel>
        <button *ngIf="!permesso || permesso === TipoPermesso.ReadWrite || permesso === TipoPermesso.ReadWriteDelete || user.roles === RuoloPipe.transform(Ruolo.SuperAdministrator)" 
          pButton label="SPESA" icon="pi pi-plus" (click)="showDialogForm(false, true)"></button>
      </div>
    </div>

    <div *ngIf="!filteredTab" class="text-center">
      <p-progressSpinner></p-progressSpinner>
      <div> Caricamento Spese in corso </div>
    </div>

    <p-card *ngIf="filteredTab">

      <p-table [value]="filteredTab" selectionMode="single" dataKey="id" [paginator]="true" [rows]="5"
        [showCurrentPageReport]="true" currentPageReportTemplate="Da {first} a {last} di {totalRecords} elementi"
        [rowsPerPageOptions]="[5, 10, 25, 50]" [(selection)]="selectedSpesa">

        <ng-template pTemplate="header">
          <tr>
            <th></th>
            <th>Tipo</th>
            <th>Descrizione</th>
            <th>CIG</th>
            <th>Importo</th>
            <th></th>
          </tr>
        </ng-template>

        <ng-template pTemplate="body" let-expanded="expanded" let-spesa>
          <tr [pSelectableRow]="spesa" [ngClass]="expanded ? 'expandedRow' : ''">
            <td>
              <button type="button" pButton pRipple [pRowToggler]="spesa"
                class="p-button-text p-button-rounded p-button-plain"
                [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"></button>
            </td>
            <td>{{ spesa.tipo }}</td>
            <td>{{ spesa.descrizione }} <br /> <span style="font-weight: 500;">{{spesa.beneficiario}}</span> </td>
            <td> <span style="font-weight: 500;">{{ spesa?.cig }}</span></td>
            <td>{{ spesa.importoNetto | currency:
              'EUR':'symbol':undefined:'it-IT'}}</td> <!--  + spesa.importoIVA + spesa?.importoCassa  -->
            <td>
              <div style="display: flex;">
                <p-button type="button" icon="pi pi-eye" styleClass="p-button-text"
                  (click)="showDialogForm(true, false, spesa)"></p-button>
                <p-button *ngIf="!permesso || permesso === TipoPermesso.ReadWrite || permesso === TipoPermesso.ReadWriteDelete || user.roles === RuoloPipe.transform(Ruolo.SuperAdministrator)"
                  type="button" icon="pi pi-pencil" styleClass="p-button-text" (click)="showDialogForm(true, true, spesa)"></p-button>
                <p-button *ngIf="spesa.fasi2.length < 1 && (!permesso || permesso === TipoPermesso.ReadWriteDelete || user.roles === RuoloPipe.transform(Ruolo.SuperAdministrator))" 
                  type="button" icon="pi pi-trash" styleClass="p-button-text p-button-danger" (click)="eliminaFase(spesa.id, Fase.Spesa)"></p-button>
              </div>
            </td>
          </tr>
        </ng-template>

        <ng-template pTemplate="rowexpansion" let-spesa>

          <tr>
            <td colspan="6">
              <div class="p-3">
                <div class="row">
                  <div class="col-3">
                    Giustificativi di spesa
                    <span *ngIf="!permesso || permesso === TipoPermesso.ReadWrite || permesso === TipoPermesso.ReadWriteDelete || user.roles === RuoloPipe.transform(Ruolo.SuperAdministrator)"
                      class="pi pi-plus detail" (click)="showDialogFatture(false, true, undefined, spesa)"></span>
                  </div>
                  <div *ngIf="checkHasLiquidazioneFasi(spesa.tipo)" class="col-3"> Liquidazioni
                    <span *ngIf="!permesso || permesso === TipoPermesso.ReadWrite || permesso === TipoPermesso.ReadWriteDelete || user.roles === RuoloPipe.transform(Ruolo.SuperAdministrator)"
                      class="pi pi-plus detail" (click)="showDialogLiquidazioni(false, true, spesa)"></span>
                  </div>
                  <div class="col-3">Mandati
                    <span *ngIf="!permesso || permesso === TipoPermesso.ReadWrite || permesso === TipoPermesso.ReadWriteDelete || user.roles === RuoloPipe.transform(Ruolo.SuperAdministrator)"
                      class="pi pi-plus detail" (click)="showDialogMandati(false, true, spesa)"></span>
                  </div>
                  <div class="col-3"> Quietanze
                    <span *ngIf="!permesso || permesso === TipoPermesso.ReadWrite || permesso === TipoPermesso.ReadWriteDelete || user.roles === RuoloPipe.transform(Ruolo.SuperAdministrator)"
                      class="pi pi-plus detail" (click)="showDialogQuietanze(false, spesa)"></span>
                  </div>
                </div>

                <div class="fase-fratello">
                  <div *ngFor="let fattura of spesa?.fasi2">
                    <div class="fase-figlio" style="margin: 10px 0; border: 1px solid;">
                      <div class="fattura col-3">
                        <div class="d-flex justify-content-between">
                          <div class="fase-width">
                            <strong>{{fattura?.tipo}}:</strong> 
                            {{fattura?.numero}} del {{fattura.data | date:'dd/MM/yyyy'}} 
                            <br />
                            <strong>{{fattura?.importoNetto | currency: 'EUR':'symbol':undefined:'it-IT'}}</strong>
                          </div>
                          <div>
                            <p-button *ngIf="(!permesso || permesso === TipoPermesso.ReadWrite || permesso === TipoPermesso.ReadWriteDelete || user.roles === RuoloPipe.transform(Ruolo.SuperAdministrator))" 
                              type="button" icon="pi pi-pencil" styleClass="p-button-text" (click)="showDialogFatture(true, true, fattura, spesa)"></p-button>
                            <p-button *ngIf="!(!permesso || permesso === TipoPermesso.ReadWrite || permesso === TipoPermesso.ReadWriteDelete || user.roles === RuoloPipe.transform(Ruolo.SuperAdministrator))" type="button" icon="pi pi-eye"
                              styleClass="p-button-text" (click)="showDialogFatture(true, false, fattura)"></p-button>
                            <p-button *ngIf="((!checkHasLiquidazioneFasi(spesa.tipo) && fattura?.fasi3 && fattura?.fasi3[0].fasi4?.length === 0) || (checkHasLiquidazioneFasi(spesa.tipo) && fattura?.fasi3.length < 1))
                              && (!permesso || permesso === TipoPermesso.ReadWriteDelete || user.roles === RuoloPipe.transform(Ruolo.SuperAdministrator))"
                              type="button" icon="pi pi-trash" styleClass="p-button-text p-button-danger" (click)="eliminaFase(fattura?.id, Fase.Giustificativo)"></p-button>
                          </div>
                        </div>
                      </div>

                      <div class="fase-fratello col-9">
                        <div *ngFor="let liquidazione of fattura?.fasi3">
                          <div class="fase-figlio">

                            <div *ngIf="checkHasLiquidazioneFasi(spesa.tipo)" class="liquidazione col-4">
                              <!--liquidazione-->
                              <div class="d-flex justify-content-between">
                                <div class="fase-width">
                                  Det numero {{liquidazione?.numero}} del {{liquidazione?.data | date:'dd/MM/yyyy'}}
                                  <br />
                                  <strong>{{liquidazione?.importoNetto | currency: 'EUR':'symbol':undefined:'it-IT'}}</strong>
                                </div>
                                <div>
                                  <p-button *ngIf="(!permesso || permesso === TipoPermesso.ReadWrite || permesso === TipoPermesso.ReadWriteDelete || user.roles === RuoloPipe.transform(Ruolo.SuperAdministrator))" 
                                    type="button" icon="pi pi-pencil" styleClass="p-button-text" (click)="showDialogLiquidazioni(true, true, spesa, liquidazione)"></p-button>
                                  <p-button *ngIf="liquidazione?.fasi4.length < 1 && (!permesso || permesso === TipoPermesso.ReadWriteDelete || user.roles === RuoloPipe.transform(Ruolo.SuperAdministrator))" 
                                    type="button" icon="pi pi-trash" styleClass="p-button-text p-button-danger" (click)="eliminaFase(liquidazione?.id, Fase.Liquidazione)"></p-button>
                                  <p-button *ngIf="!(!permesso || permesso === TipoPermesso.ReadWrite || permesso === TipoPermesso.ReadWriteDelete || user.roles === RuoloPipe.transform(Ruolo.SuperAdministrator))" type="button" icon="pi pi-eye"
                                    styleClass="p-button-text" (click)="showDialogLiquidazioni(true, false, spesa, liquidazione)"></p-button>
                                </div>
                              </div>
                            </div>

                            <div class="fase-fratello col-8">
                              <div *ngFor="let mandato of liquidazione?.fasi4">
                                <div class="fase-figlio">

                                  <div class="mandato col-6">
                                    <div class="d-flex justify-content-between">
                                      <div class="fase-width">
                                        Numero {{mandato?.numero}} del {{mandato?.data | date:'dd/MM/yyyy'}} <br />
                                        <strong>{{mandato?.importo | currency:
                                          'EUR':'symbol':undefined:'it-IT'}}</strong>
                                      </div>
                                      <div>
                                        <p-button *ngIf="(!permesso || permesso === TipoPermesso.ReadWrite || permesso === TipoPermesso.ReadWriteDelete || user.roles === RuoloPipe.transform(Ruolo.SuperAdministrator))" 
                                          type="button" icon="pi pi-pencil" styleClass="p-button-text" (click)="showDialogMandati(true, true, spesa, mandato)"></p-button>
                                        <p-button *ngIf="!mandato?.fase5 && (!permesso || permesso === TipoPermesso.ReadWriteDelete || user.roles === RuoloPipe.transform(Ruolo.SuperAdministrator))" 
                                          type="button" icon="pi pi-trash" styleClass="p-button-text p-button-danger" (click)="eliminaFase(mandato?.id, Fase.Mandato)"></p-button>
                                        <p-button *ngIf="!(!permesso || permesso === TipoPermesso.ReadWrite || permesso === TipoPermesso.ReadWriteDelete || user.roles === RuoloPipe.transform(Ruolo.SuperAdministrator))" type="button" icon="pi pi-eye"
                                          styleClass="p-button-text" (click)="showDialogMandati(true, false, spesa, mandato)"></p-button>
                                      </div>
                                    </div>
                                  </div>

                                  <div class="fase-fratello col-6">
                                    <div *ngFor="let quietanza of getQuietanze(spesa, mandato)">

                                      <div class="quietanza col-12">
                                        <div class="d-flex justify-content-between">
                                          <div class="fase-width">
                                            Quietanza presente
                                          </div>
                                          <div>
                                            <p-button *ngIf="!permesso || permesso === TipoPermesso.ReadWrite || permesso === TipoPermesso.ReadWriteDelete || user.roles === RuoloPipe.transform(Ruolo.SuperAdministrator)"
                                              type="button" icon="pi pi-pencil" styleClass="p-button-text" (click)="showDialogQuietanze(true, spesa, quietanza)"></p-button>
                                            <p-button *ngIf="!permesso || permesso === TipoPermesso.ReadWriteDelete || user.roles === RuoloPipe.transform(Ruolo.SuperAdministrator)"
                                              type="button" icon="pi pi-trash" styleClass="p-button-text p-button-danger" (click)="eliminaFase(quietanza?.id, Fase.Quietanza)"></p-button>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </p-card>

  </div>
</div>

<p-dialog *ngIf="visibleFormSpesa" header="Spesa" [(visible)]="visibleFormSpesa" class="p-dialog-full" [modal]="true"
  [draggable]="false" [resizable]="true" [maximizable]="true" (onHide)="closeDialog($event)">
  <upload-file *ngIf="visibleFormSpesa && datiModelloDocumentale?.formGroup" [entityIdAdd]="entityIdAdd" [cup]="cup"
    nomeModello="Ordinativo di spesa" [datiModelloDocumentale]="datiModelloDocumentale" [visualizza]="visualizza"
    (close)="closeDialog($event)" (add)="addSpesa($event)" (checkBeforeSave)="checkBeforeSave($event, Fase.Spesa)" 
    (save)="saveFase($event, Fase.Spesa)" (eventCurrency)="onChangeItemCurrency($event)" 
    (eventDropdown)="onChangeItemDropdown($event)"> 
  </upload-file>
</p-dialog>

<p-dialog *ngIf="visibleFormGiustificativo" header="Giustificativo di spesa" [(visible)]="visibleFormGiustificativo"
  [resizable]="true" class="p-dialog-full" [modal]="true" [draggable]="false" [maximizable]="true" (onHide)="closeDialog($event)">
  <upload-file *ngIf="visibleFormGiustificativo && datiModelloDocumentale?.formGroup" [entityIdAdd]="entityIdAdd" [cup]="cup"
    nomeModello="Giustificativo di spesa" [datiModelloDocumentale]="datiModelloDocumentale" [visualizza]="visualizza"
    (add)="addGiustificativo($event)" (save)="saveFase($event, Fase.Giustificativo)" (eventCurrency)="onChangeItemCurrency($event)"
    (close)="closeDialog($event)" (checkBeforeSave)="checkBeforeSave($event, Fase.Giustificativo)">
  </upload-file>
</p-dialog>

<p-dialog *ngIf="visibleFormLiquidazione" header="Liquidazione" [(visible)]="visibleFormLiquidazione" [resizable]="true"
  class="p-dialog-full" [modal]="true" [draggable]="false" [maximizable]="true" (onHide)="closeDialog($event)">
  <upload-file *ngIf="visibleFormLiquidazione && datiModelloDocumentale?.formGroup" [entityIdAdd]="entityIdAdd"
    nomeModello="Liquidazione" [datiModelloDocumentale]="datiModelloDocumentale" [riferimentoFase]="fase2Riferimenti?.riferimentoLiquidazione"
    [beneficiarioString]="beneficiarioString" [pIva_cFiscaleString]="pIva_cFiscaleString" [cup]="cup" [visualizza]="visualizza" 
    (close)="closeDialog($event)" (save)="saveFase($event, Fase.Liquidazione)" (add)="checkFase2($event)" 
    (eventDropdown)="onChangeItemDropdown($event)" (eventCurrency)="onChangeItemCurrency($event)" (checkBeforeSave)="checkBeforeSave($event, Fase.Liquidazione)">
  </upload-file>
</p-dialog>

<p-dialog *ngIf="visibleFormMandato" header="Mandato" [(visible)]="visibleFormMandato" class="p-dialog-full"
  [modal]="true" [draggable]="false" [resizable]="true" [maximizable]="true" (onHide)="closeDialog($event)">
  <upload-file [entityIdAdd]="entityIdAdd" *ngIf="visibleFormMandato && datiModelloDocumentale?.formGroup"
    nomeModello="Mandato" [datiModelloDocumentale]="datiModelloDocumentale" [entityID]="datiModelloDocumentale.entityID"
    [beneficiarioString]="beneficiarioString" [pIva_cFiscaleString]="pIva_cFiscaleString" [visualizza]="visualizza"
    [riferimentoFase]="fase2Riferimenti?.riferimentoLiquidazione" [cup]="cup"
    (add)="checkFase3($event)" (close)="closeDialog($event)"  (eventCurrency)="onChangeItemCurrency($event)"
    (eventDropdown)="onChangeItemDropdown($event)" (eventTextarea)="onChangeItemTextarea($event)" (save)="saveFase($event, Fase.Mandato)">
  </upload-file>
</p-dialog>

<p-dialog *ngIf="visibleFormQuietanza" header="Quietanza" [(visible)]="visibleFormQuietanza" class="p-dialog-full"
  [modal]="true" [draggable]="false" [resizable]="true" [maximizable]="true" (onHide)="closeDialog($event)">
  <upload-file [entityIdAdd]="entityIdAdd" *ngIf="visibleFormQuietanza && datiModelloDocumentale?.formGroup"
    nomeModello="Quietanza" [datiModelloDocumentale]="datiModelloDocumentale" [entityTipo]="9" [cup]="cup"
    [beneficiarioString]="beneficiarioString" [pIva_cFiscaleString]="pIva_cFiscaleString" 
    [riferimentoFase]="fase2Riferimenti?.riferimentoLiquidazione" [stopFase5]="stopFase5" (close)="closeDialog($event)"
    (eventDropdown)="onChangeItemDropdown($event)" (save)="saveFase($event, Fase.Quietanza)" (add)="addQuietanza($event)">
  </upload-file>
</p-dialog>

<rbk-modal *ngIf="visibleDialog" [dialogModel]="dialogModel" (onConfirm)="visibleDialog = false"></rbk-modal>

<p-confirmDialog [style]="{width: '50vw'}" [baseZIndex]="10000"></p-confirmDialog>
<p-toast></p-toast>