import { Component, DoCheck, OnInit } from '@angular/core';
import { UserClientService } from '../service/user-client.service';
import { Ruolo, Utente } from '../model/utente';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { EnteDto } from '../model/ente';
import { RuoloPipe } from '../pipe/ruolo-pipe';

@Component({
  selector: 'app-nav-menu',
  templateUrl: './nav-menu.component.html',
  styleUrls: ['./nav-menu.component.scss']
})

export class NavMenuComponent implements DoCheck {
  utente?: Utente
  isExpanded = false;
  infoUtente: string;
  isUserAdmin: boolean = false;
  isUserSuperAdmin: boolean = false;
  ente: EnteDto;
  visible: boolean = false;
  nomeEnte: string = '';
  public RuoloPipe = new RuoloPipe();
  public enteName: string = '';

  constructor(
    private userClientService: UserClientService,
    private spinner: NgxSpinnerService,
    private router: Router,
    private route: ActivatedRoute) {
  }

  ngDoCheck(): void {
    this.utente = this.userClientService.getUser();
    const infoUtente = localStorage.getItem('infoUtente');
    const infoRuolo = localStorage.getItem('infoRuolo');
    const titoloCup = localStorage.getItem('titoloCup');
    if(infoUtente && infoUtente !== 'undefined'){
      this.infoUtente = infoUtente;
    } else {
      if(this.utente && this.utente.clientId) {
        this.infoUtente = this.utente?.clientId;
      } else {
        this.infoUtente = '';
      }
    }
    if(titoloCup && infoUtente) {
      this.infoUtente += ' - ' + titoloCup;
      this.enteName = infoUtente;
    }

    if (infoRuolo) {
      switch (infoRuolo) {
        case "SuperAdministrator":
          this.isUserSuperAdmin = true;
          this.isUserAdmin = false;
          break;
        case "administrator":
          this.isUserAdmin = true;
          this.isUserSuperAdmin = false;
          break;
        case "user":
          this.isUserAdmin = false;
          this.isUserSuperAdmin = false;
          break;
        default:
          break;
      }
    }
    
  }

  async showDialogEnte() {
    if (this.infoUtente) {
      this.spinner.show();
      const utenteName = this.enteName ? this.enteName : this.infoUtente;
      const ente = await this.userClientService.getClientById(utenteName).toPromise();
      if(ente) {
        this.nomeEnte = `Ente: ${this.infoUtente}`;
        this.ente = ente;
        this.visible = true;
      }
      this.spinner.hide();
    }
  }

  onClose(bool) {
    this.visible = false;
  }

  collapse() {
    this.isExpanded = false;
  }

  toggle() {
    this.isExpanded = !this.isExpanded;
  }

  logout() {
    this.userClientService.logoutAsync(this.utente?.userName).subscribe({
      next: (resp: boolean) => {
        if (resp) {
          console.log(resp);
        } else {
          console.log(resp);
        }
      },
      error: (err: any) => {
        console.log(err);
      }
    })
    localStorage.removeItem('infoUtente');
    localStorage.removeItem('infoRuolo');
    this.router.navigate(["/login"]);
  }

  lavoraDocumentiSospesi() {
    this.router.navigate(["/DocumentiSospesi"]);
  }

}

