<p-tabView class="smallMenu" (onChange)="handleChangeFonti($event)">

  <!--Fonti Attuali-->
  <p-tabPanel header="Fonti Attuali">
    <div class="flex-1" style="margin:0">

      <div class="header">
        <div class="title-card">Fonti di finanziamento</div>
        <button *ngIf="!permesso || permesso === TipoPermesso.ReadWrite || permesso === TipoPermesso.ReadWriteDelete || user.roles === RuoloPipe.transform(Ruolo.SuperAdministrator)" 
          pButton label="FONTE" icon="pi pi-plus" style="height: 2.25em;" (click)="showDialogForm(false)"></button>
      </div>

      <div *ngIf="!filteredTab" class="text-center">
        <p-progressSpinner></p-progressSpinner>
        <div> Caricamento Fonti in Corso </div>
      </div>
      <p-card *ngIf="filteredTab">
        <p-table [value]="filteredTab" selectionMode="single" [(selection)]="fonte" dataKey="codice" [paginator]="true"
          [rows]="5" [showCurrentPageReport]="true"
          currentPageReportTemplate="Da {first} a {last} di {totalRecords} Fonti"
          [rowsPerPageOptions]="[5, 10, 25, 50]">

          <ng-template pTemplate="header">
            <tr>
              <th style="width: 10%;">Ente</th>
              <th style="width: 10%;">Atto</th>
              <th style="width: 5%;">Numero</th>
              <th style="width: 10%;">Data</th>
              <th style="width: 25%;">Descrizione</th>
              <th style="width: 10%;">Importo</th>
              <th style="width: 10%;">Incasso</th>
              <th style="width: 20%;"></th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-fonte>
            <tr [pSelectableRow]="fonte">
              <td>{{ fonte?.ente}}</td>
              <td>{{ fonte?.atto}}</td>
              <td>{{ fonte?.numero}}</td>
              <td>{{ fonte?.del | date:"dd/MM/yy"}}</td>
              <td>{{ fonte?.descrizione }}</td>
              <td>{{ fonte?.importoFinanziamento | currency: 'EUR':'symbol':undefined:'it-IT'}}</td>
              <td>{{ fonte?.sommaIncassato | currency: 'EUR':'symbol':undefined:'it-IT'}}
              </td>
              <td>
                <div style="display: flex;">
                  <div style="width: 35%;">
                    <p-button *ngIf="!permesso || permesso === TipoPermesso.ReadWrite || permesso === TipoPermesso.ReadWriteDelete || user.roles === RuoloPipe.transform(Ruolo.SuperAdministrator)"
                      type="button" label="REGISTRA INCASSO" styleClass="p-button-text" (click)="showDialogIncassi(fonte, undefined)"></p-button>
                  </div>
                  <div style="width: 35%;">
                    <p-button *ngIf="!permesso || permesso === TipoPermesso.ReadWrite || permesso === TipoPermesso.ReadWriteDelete || user.roles === RuoloPipe.transform(Ruolo.SuperAdministrator)"
                      type="button" label="SOSTITUISCI" styleClass="p-button-text" (click)="sostituisciFonte(fonte)"></p-button>
                  </div>
                  <div style="width: 15%;">
                    <p-button type="button" icon="pi pi-pencil" styleClass="p-button-text" (click)="showDialogForm(false, fonte)"></p-button>
                  </div>
                  <div style="width: 15%;" *ngIf="fonte.sommaIncassato === 0">
                    <p-button *ngIf="!permesso || permesso === TipoPermesso.ReadWriteDelete || user.roles === RuoloPipe.transform(Ruolo.SuperAdministrator)" 
                      type="button" icon="pi pi-trash" styleClass="p-button-text p-button-danger" (click)="eliminaFonteMessaggioConferma(fonte.id)"></p-button>
                  </div>
                </div>
              </td>
            </tr>
          </ng-template>
          <ng-template pTemplate="footer">
            <tr>
              <td colspan="5" class="text-right">Totale</td>
              <td colspan="4">{{totale | currency: 'EUR':'symbol':undefined:'it-IT'}}</td>
            </tr>
          </ng-template>
        </p-table>
      </p-card>

    </div>

  </p-tabPanel>

  <!--Incassi-->
  <p-tabPanel header="Incassi">
    <div class="d-flex flex-row">

      <div class="flex-1" style="margin:0">

        <div class="header">
          <div class="title-card">Incassi</div>
        </div>

        <p-toast></p-toast>
        <div *ngIf="!filteredTabIncassi" class="text-center">
          <p-progressSpinner></p-progressSpinner>
          <div> Caricamento Incassi in Corso </div>
        </div>

        <p-card *ngIf="filteredTabIncassi">

          <p-table [value]="filteredTabIncassi" selectionMode="single" [(selection)]="incasso" dataKey="codice"
            [paginator]="true" [rows]="5" [showCurrentPageReport]="true"
            currentPageReportTemplate="Da {first} a {last} di {totalRecords} Incassi"
            [rowsPerPageOptions]="[5, 10, 25, 50]">

            <ng-template pTemplate="header">
              <tr>
                <th>Atto</th>
                <th>Numero</th>
                <th>Data</th>
                <th>Descrizione</th>
                <th>Importo</th>
                <!-- <th>Allegato</th> -->
                <th style="width: 10%;"></th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-incasso>
              <tr>
                <td>{{ incasso.atto }}</td>
                <td>{{ incasso.numero }}</td>
                <td>{{ incasso.del | date:"dd/MM/yy"}}</td>
                <td>{{ incasso.descrizione }}</td>
                <td>{{ incasso.importoIncassato | currency: 'EUR':'symbol':undefined:'it-IT'}}</td>
                <!-- <td>{{ incasso.allegato }}</td> -->
                <td>
                  <div style="display: flex;">
                    <p-button *ngIf="!permesso || permesso === TipoPermesso.ReadWrite || permesso === TipoPermesso.ReadWriteDelete || user.roles === RuoloPipe.transform(Ruolo.SuperAdministrator)"
                      type="button" icon="pi pi-pencil" styleClass="p-button-text" (click)="showDialogIncassi(undefined, incasso)"></p-button>
                    <p-button *ngIf="!permesso || permesso === TipoPermesso.ReadWriteDelete || user.roles === RuoloPipe.transform(Ruolo.SuperAdministrator)"
                      type="button" icon="pi pi-trash" styleClass="p-button-text p-button-danger" (click)="eliminaIncasso(incasso?.id)"></p-button>
                  </div>
                </td>
              </tr>
            </ng-template>
            <ng-template pTemplate="footer">
              <tr>
                <td colspan="4" class="text-right">Totale</td>
                <td colspan="4">{{totaleIncassi | currency: 'EUR':'symbol':undefined:'it-IT'}}</td>
              </tr>
            </ng-template>
          </p-table>
        </p-card>

      </div>
    </div>
  </p-tabPanel>

  <!--Storico Fonti-->
  <p-tabPanel header="Storico Fonti">
    <div class="flex-1" style="margin:0">
      <div class="header">
        <div class="title-card">Storico Fonti</div>
      </div>
      <div *ngIf="!filteredTabStorico" class="text-center">
        <p-progressSpinner></p-progressSpinner>
        <div> Caricamento Fonti Storiche in Corso </div>
      </div>
      <p-card *ngIf="filteredTabStorico">
        <p-table [value]="filteredTabStorico" selectionMode="single" [(selection)]="fonte" dataKey="codice"
          [paginator]="true" [rows]="5" [showCurrentPageReport]="true"
          currentPageReportTemplate="Da {first} a {last} di {totalRecords} Fonti"
          [rowsPerPageOptions]="[5, 10, 25, 50]">

          <ng-template pTemplate="header">
            <tr>
              <th style="width: 10%;">Ente</th>
              <th style="width: 10%;">Atto</th>
              <th style="width: 5%;">Numero</th>
              <th style="width: 10%;">Data</th>
              <th style="width: 25%;">Descrizione</th>
              <th style="width: 10%;">Importo</th>
              <th style="width: 20%;"></th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-fonte>
            <tr [pSelectableRow]="fonte">
              <td>{{ fonte?.ente}}</td>
              <td>{{ fonte.atto}}</td>
              <td>{{ fonte.numero}}</td>
              <td>{{ fonte.del | date:"dd/MM/yy"}}</td>
              <td>{{ fonte.descrizione }}</td>
              <td>{{ fonte.importoFinanziamento | currency: 'EUR':'symbol':undefined:'it-IT'}}</td>
              <td>
                <div style="display: flex;">
                  <div>
                    <p-button type="button" icon="pi pi-eye" styleClass="p-button-text"
                      (click)="showDialogForm(false, fonte, true)"></p-button>
                  </div>
                  <div>
                    <p-button *ngIf="!permesso || permesso === TipoPermesso.ReadWriteDelete || user.roles === RuoloPipe.transform(Ruolo.SuperAdministrator)"
                      type="button" icon="pi pi-trash" styleClass="p-button-text p-button-danger" (click)="confirmStorico(fonte.id)"></p-button>
                  </div>
                </div>
              </td>
            </tr>
          </ng-template>
        </p-table>
      </p-card>

    </div>
  </p-tabPanel>

  <!--Documento fonti-->
  <p-tabPanel header="Documenti Fonti">
    <rbk-documenti [isId]="isId" [cupId]="cupId" [nomeTab]="NomeTab.Fonte" [isRefreshTab]="isRefreshTabDocumenti" [cup]="cup"
    [user]="user" [permesso]="permesso" (onRefreshTabChange)="onRefreshTab($event)"></rbk-documenti>
  </p-tabPanel>
</p-tabView>

<!--Dialog Fonte di finanziamento-->
<p-dialog *ngIf="visible && datiModelloDocumentale?.formGroup" [header]="header" [(visible)]="visible"
  class="p-dialog-full" [modal]="true" [draggable]="false" [maximizable]="true">

  <upload-file *ngIf="visible && datiModelloDocumentale?.formGroup" [entityIdAdd]="entityIdAdd" [sostituisciFonti]="storicizza"
    nomeModello="FonteFinanziamento" [datiModelloDocumentale]="datiModelloDocumentale" [visualizza]="!isFromStorico" [cup]="cup"
    [user]="user" [permesso]="permesso"
    (add)="addFonte($event)" (save)="saveFonte($event)" (close)="closeDialog($event)">
  </upload-file>

</p-dialog>

<!--Dialog Incassi-->
<p-dialog *ngIf="openIncassi && datiModelloDocumentale?.formGroup" header="Incasso" [(visible)]="openIncassi"
  class="p-dialog-full" [modal]="true" [draggable]="false" [maximizable]="true">
  <upload-file *ngIf="openIncassi && datiModelloDocumentale?.formGroup" [entityIdAdd]="entityIdAdd" [cup]="cup"
    nomeModello="Incassato" [datiModelloDocumentale]="datiModelloDocumentale" [user]="user" [permesso]="permesso"
    (add)="addIncasso($event)" (save)="saveIncasso($event)" (close)="closeDialog($event)">
  </upload-file>

</p-dialog>

<p-confirmDialog [style]="{width: '50vw'}" [baseZIndex]="10000"></p-confirmDialog>
<p-toast></p-toast>