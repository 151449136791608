<div *ngIf="!updateModello" class="row">
  <h1>
    <div class="title-card pull-left">Modelli Documentali</div>
  </h1>

  <div class="d-flex flex-row justify-content-between">
    <div >
      <p-dropdown [options]="sezioni" [(ngModel)]="sezioneSelezionata" optionLabel="nome" [showClear]="true"
        placeholder="Seleziona un' area" (onChange)="filterBrowse()"></p-dropdown>
      <p-dropdown class="p-3" [options]="modelliSelect" [(ngModel)]="modelloSelected" optionLabel="descrizione" [showClear]="true" 
        placeholder="Seleziona modello" (onChange)="filterBrowse()"></p-dropdown>
    </div>
    <div class="d-flex justify-content-end mb-2">
      <div>
        <p-button label="Modello Documentale" icon="pi pi-plus" (click)="valorizzaModelloDocumentale()"></p-button>
      </div>
    </div>
  </div>

  <!-- Spiner caricamento Lista documenti/preview documenti-->
  <div *ngIf="caricamentoGrid" class="text-center">
    <p-progressSpinner></p-progressSpinner>
    <div> Caricamento in corso </div>
  </div>

  <p-card *ngIf="!caricamentoGrid" class="grid-documenti">
    <p-table [value]="modelliDocumentale" [paginator]="true" [rows]="10" [showCurrentPageReport]="true"
      currentPageReportTemplate="Da {first} a {last} di {totalRecords} modelli documentali"
      [rowsPerPageOptions]="[5, 10, 25, 50]" [(selection)]="modelloDocumentaleSelezionato" dataKey="id"
      [resizableColumns]="true" >
      <ng-template pTemplate="header" let-columns>
        <tr class="ellipsis">
          <th pResizableColumn title="Nome Modello">Nome Modello</th>
          <th pResizableColumn title="Chiave 1">Chiave 1</th>
          <th pResizableColumn title="Chiave 2">Chiave 2</th>
          <th pResizableColumn title="Chiave 3">Chiave 3</th>
          <th pResizableColumn title="Chiave 4">Chiave 4</th>
          <th pResizableColumn title="Chiave 5">Chiave 5</th>
          <th pResizableColumn title="Chiave 6">Chiave 6</th>
          <th pResizableColumn title="Chiave 7">Chiave 7</th>
          <th pResizableColumn title="Chiave 8">Chiave 8</th>
          <th pResizableColumn title="Chiave 9">Chiave 9</th>
          <th pResizableColumn title="Chiave 10">Chiave 10</th>
          <th></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-modello let-columns="columns">
        <tr class="ellipsis">
          <td>{{modello.nome}}</td>
          <td title="{{modello.keysModelloDocumentale.key2_Tipo | tipoChiavePipe}}">
            <span [style.color]="!modello.keysModelloDocumentale.key2_Attivo ? '#dee2e6' : null">
              {{ modello.keysModelloDocumentale.key2_Etichetta }}
            </span>
          </td>
          <td title="{{modello.keysModelloDocumentale.key1_Tipo | tipoChiavePipe}}">
            <span [style.color]="!modello.keysModelloDocumentale.key1_Attivo ? '#dee2e6' : null">
              {{ modello.keysModelloDocumentale.key1_Etichetta }}
            </span>
          </td>
          <td title="{{modello.keysModelloDocumentale.key3_Tipo | tipoChiavePipe}}">
            <span [style.color]="!modello.keysModelloDocumentale.key3_Attivo ? '#dee2e6' : null">
              {{ modello.keysModelloDocumentale.key3_Etichetta }}
            </span>
          </td>
          <td title="{{modello.keysModelloDocumentale.key4_Tipo | tipoChiavePipe}}">
            <span [style.color]="!modello.keysModelloDocumentale.key4_Attivo ? '#dee2e6' : null">
              {{ modello.keysModelloDocumentale.key4_Etichetta }}
            </span>
          </td>
          <td title="{{modello.keysModelloDocumentale.key5_Tipo | tipoChiavePipe}}">
            <span [style.color]="!modello.keysModelloDocumentale.key5_Attivo ? '#dee2e6' : null">
              {{ modello.keysModelloDocumentale.key5_Etichetta }}
            </span>
          </td>
          <td title="{{modello.keysModelloDocumentale.key6_Tipo | tipoChiavePipe}}">
            <span [style.color]="!modello.keysModelloDocumentale.key6_Attivo ? '#dee2e6' : null">
              {{ modello.keysModelloDocumentale.key6_Etichetta }}
            </span>
          </td>
          <td title="{{modello.keysModelloDocumentale.key7_Tipo | tipoChiavePipe}}">
            <span [style.color]="!modello.keysModelloDocumentale.key7_Attivo ? '#dee2e6' : null">
              {{ modello.keysModelloDocumentale.key7_Etichetta }}
            </span>
          </td>
          <td title="{{modello.keysModelloDocumentale.key8_Tipo | tipoChiavePipe}}">
            <span [style.color]="!modello.keysModelloDocumentale.key8_Attivo ? '#dee2e6' : null">
              {{ modello.keysModelloDocumentale.key8_Etichetta }}
            </span>
          </td>
          <td title="{{modello.keysModelloDocumentale.key9_Tipo | tipoChiavePipe}}">
            <span [style.color]="!modello.keysModelloDocumentale.key9_Attivo ? '#dee2e6' : null">
              {{ modello.keysModelloDocumentale.key9_Etichetta }}
            </span>
          </td>
          <td title="{{modello.keysModelloDocumentale.key10_Tipo | tipoChiavePipe}}">
            <span [style.color]="!modello.keysModelloDocumentale.key10_Attivo ? '#dee2e6' : null">
              {{ modello.keysModelloDocumentale.key10_Etichetta }}
            </span>
          </td>
          <td class="not-ellipsis">
            <p-button *ngIf="!modello.systemModello" type="button" icon="pi pi-pencil" styleClass="p-button-text" title="Visualizza il documento"
              (click)="valorizzaModelloDocumentale(modello)"></p-button>
            <p-button *ngIf="!modello.systemModello" icon="pi pi-trash" type="button" styleClass="p-button-text p-button-danger" title="Cancella"
              (click)="deleteModelloDocumentale(modello.id)"></p-button>
            <p-button *ngIf="modello.systemModello" icon="pi pi-eye" type="button" styleClass="p-button-text" title="Visualizza"
              (click)="valorizzaModelloDocumentale(modello)"></p-button>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </p-card>
</div>

<p-card *ngIf="updateModello" header="Modello Documentale">
  <div class="campi-modello-documentale">

    <form *ngIf="formGroup" [formGroup]="formGroup">

      <div class="d-flex flex-column">

        <div class="d-flex flex-row  mb-1">

          <div class="flex flex-column mr-2 ml-0_5 padding-drop">
            <div>Nome Modello</div>
            <input type="text" formControlName="nome" class="form-control" [ngClass]="{ 'is-invalid': formGroup.get('nome').errors?.required}" />
              <div *ngIf="formGroup.get('nome').errors" class="invalid-feedback">
                <div *ngIf="formGroup.get('nome').errors?.required">Inserisci nome modello</div>
              </div>
          </div>

          <div class="flex flex-column mr-2">
            <div>Sezione</div>
            <p-multiSelect [options]="sezioni" formControlName="modelliSezione" optionLabel="nome"
              [showClear]="true" placeholder="Seleziona una sezione" [ngClass]="{ 'invalid': formGroup.get('modelliSezione').errors?.required}"></p-multiSelect>
              <div *ngIf="formGroup.get('modelliSezione').errors" class="invalid-feedback">
                <div *ngIf="formGroup.get('modelliSezione').errors?.required">Seleziona una o più sezioni</div>
              </div>
          </div>

          <div class="flex flex-column">
            <div>Attivo</div>
            <p-inputSwitch formControlName="attivo" styleClass="mt-1" (onChange)="changeActiveModello(formGroup.get('attivo').value)"></p-inputSwitch>
          </div>


        </div>

        <div class="d-flex justify-content-between align-items-center form-group mb-1">
          <div class="form-group mr-1">
            <label for="attivo" class="col-form-label">#</label>
          </div>
          <div class="form-group col-sm-3">
            <label for="attivo" class="col-form-label"><strong>Etichetta</strong></label>
          </div>
          <div class="form-group col-sm-2">
            <label for="descriione" class="col-form-label"><strong>Tipo</strong></label>
          </div>
          <div class="form-group col-sm-4 p-fluid">
            <label class="col-form-label"><strong>Valori Personalizzati</strong></label>
          </div>
          <div class="form-group col-sm-1">
            <label class="col-form-label"><strong>Attivo</strong></label>
          </div>
          <div class="form-group col-sm-1">
            <label class="col-form-label"><strong>Obbligatorio</strong></label>
          </div>
        </div>

        <div class="d-flex justify-content-between align-items-center form-group mb-1">
          <div class="form-group indice-width mr-1">
            1.
          </div>
          <div class="form-group col-sm-3">
            <input type="text" formControlName="key2_Etichetta" class="form-control" />
            <div *ngIf="formGroup.get('key2_Etichetta').errors" class="invalid-feedback">
              <div *ngIf="formGroup.get('key2_Etichetta').errors.required">Etichetta Obbligatoria</div>
            </div>
          </div>
          <div class="form-group col-sm-2 p-fluid">
            <p-dropdown [options]="tipoChiavi" optionLabel="label" optionValue="value" formControlName="key2_Tipo"
               [disabled]="true"></p-dropdown>
          </div>
          <div class="form-group col-sm-4 p-fluid">
            <p-chips formControlName="key2_CustomValues" [disabled]="formGroup.get('key2_Tipo').value !== TipoChiave.Lista">
            </p-chips>
          </div>
          <div class="form-group col-sm-1">
            <p-inputSwitch formControlName="key2_Attivo" styleClass="mt-1"></p-inputSwitch>
          </div>
          <div class="form-group col-sm-1">
            <p-inputSwitch formControlName="key2_Obbligatoria" styleClass="mt-1"></p-inputSwitch>
          </div>
        </div>

        <div class="d-flex justify-content-between align-items-center form-group mb-1">
          <div class="form-group indice-width mr-1">
            2.
          </div>
          <div class="form-group col-sm-3">
            <input type="text" formControlName="key1_Etichetta" class="form-control" (change)="onChangeKey(formGroup.get('key1_Etichetta').value, null, 1)" />
          </div>
          <div class="form-group col-sm-2 p-fluid">
            <p-dropdown id="key1_Tipo" [options]="tipoChiavi" optionLabel="label" optionValue="value" formControlName="key1_Tipo"
               appendTo="body" [ngClass]="{ 'ng-dirty ng-invalid': formGroup.get('key1_Tipo').errors?.required}"
               (onChange)="onChangeKey(formGroup.get('key1_Etichetta').value, formGroup.get('key1_Tipo').value, 1)"></p-dropdown>
          </div>
          <div class="form-group col-sm-4 p-fluid">
            <p-chips formControlName="key1_CustomValues" [disabled]="formGroup.get('key1_Tipo').value !== TipoChiave.Lista">
            </p-chips>
          </div>
          <div class="form-group col-sm-1">
            <p-inputSwitch formControlName="key1_Attivo" ></p-inputSwitch>
          </div>
          <div class="form-group col-sm-1">
            <p-inputSwitch formControlName="key1_Obbligatoria" ></p-inputSwitch>
          </div>
        </div>

        <div class="d-flex justify-content-between align-items-center form-group mb-1">
          <div class="form-group indice-width mr-1">
            3.
          </div>
          <div class="form-group col-sm-3">
            <input type="text" formControlName="key3_Etichetta" class="form-control" (change)="onChangeKey(formGroup.get('key3_Etichetta').value, null, 3)" />
          </div>
          <div class="form-group col-sm-2 p-fluid">
            <p-dropdown id="key3_Tipo" [options]="tipoChiavi" optionLabel="label" optionValue="value" formControlName="key3_Tipo"
               appendTo="body" [ngClass]="{ 'ng-dirty ng-invalid': formGroup.get('key3_Tipo').errors?.required}"
               (onChange)="onChangeKey(formGroup.get('key3_Etichetta').value, formGroup.get('key3_Tipo').value, 3)"></p-dropdown>
          </div>
          <div class="form-group col-sm-4 p-fluid">
            <p-chips formControlName="key3_CustomValues" [disabled]="formGroup.get('key3_Tipo').value !== TipoChiave.Lista">
            </p-chips>
          </div>
          <div class="form-group col-sm-1">
            <p-inputSwitch formControlName="key3_Attivo" ></p-inputSwitch>
          </div>
          <div class="form-group col-sm-1">
            <p-inputSwitch formControlName="key3_Obbligatoria" ></p-inputSwitch>
          </div>
        </div>

        <div class="d-flex justify-content-between align-items-center form-group mb-1">
          <div class="form-group indice-width mr-1">
            4.
          </div>
          <div class="form-group col-sm-3">
            <input type="text" formControlName="key4_Etichetta" class="form-control" (change)="onChangeKey(formGroup.get('key4_Etichetta').value, null, 4)" />
          </div>
          <div class="form-group col-sm-2 p-fluid">
            <p-dropdown id="key4_Tipo" [options]="tipoChiavi" optionLabel="label" optionValue="value" formControlName="key4_Tipo"
               appendTo="body" [ngClass]="{ 'ng-dirty ng-invalid': formGroup.get('key4_Tipo').errors?.required}"
               (onChange)="onChangeKey(formGroup.get('key4_Etichetta').value, formGroup.get('key4_Tipo').value, 4)"></p-dropdown>
          </div>
          <div class="form-group col-sm-4 p-fluid">
            <p-chips formControlName="key4_CustomValues" [disabled]="formGroup.get('key4_Tipo').value !== TipoChiave.Lista">
            </p-chips>
          </div>
          <div class="form-group col-sm-1">
            <p-inputSwitch formControlName="key4_Attivo" ></p-inputSwitch>
          </div>
          <div class="form-group col-sm-1">
            <p-inputSwitch formControlName="key4_Obbligatoria" ></p-inputSwitch>
          </div>
        </div>

        <div class="d-flex justify-content-between align-items-center form-group mb-1">
          <div class="form-group indice-width mr-1">
            5.
          </div>
          <div class="form-group col-sm-3">
            <input type="text" formControlName="key5_Etichetta" class="form-control" (change)="onChangeKey(formGroup.get('key5_Etichetta').value, null, 5)" />
          </div>
          <div class="form-group col-sm-2 p-fluid">
            <p-dropdown id="key5_Tipo" [options]="tipoChiavi" optionLabel="label" optionValue="value" formControlName="key5_Tipo"
               appendTo="body" [ngClass]="{ 'ng-dirty ng-invalid': formGroup.get('key5_Tipo').errors?.required}"
               (onChange)="onChangeKey(formGroup.get('key5_Etichetta').value, formGroup.get('key5_Tipo').value, 5)"></p-dropdown>
          </div>
          <div class="form-group col-sm-4 p-fluid">
            <p-chips formControlName="key5_CustomValues" [disabled]="formGroup.get('key5_Tipo').value !== TipoChiave.Lista">
            </p-chips>
          </div>
          <div class="form-group col-sm-1">
            <p-inputSwitch formControlName="key5_Attivo" ></p-inputSwitch>
          </div>
          <div class="form-group col-sm-1">
            <p-inputSwitch formControlName="key5_Obbligatoria" ></p-inputSwitch>
          </div>
        </div>

        <div class="d-flex justify-content-between align-items-center form-group mb-1">
          <div class="form-group indice-width mr-1">
            6.
          </div>
          <div class="form-group col-sm-3">
            <input type="text" formControlName="key6_Etichetta" class="form-control" (change)="onChangeKey(formGroup.get('key6_Etichetta').value, null, 6)" />
          </div>
          <div class="form-group col-sm-2 p-fluid">
            <p-dropdown id="key6_Tipo" [options]="tipoChiavi" optionLabel="label" optionValue="value" formControlName="key6_Tipo"
               appendTo="body" [ngClass]="{ 'ng-dirty ng-invalid': formGroup.get('key6_Tipo').errors?.required}"
               (onChange)="onChangeKey(formGroup.get('key6_Etichetta').value, formGroup.get('key6_Tipo').value, 6)"></p-dropdown>
          </div>
          <div class="form-group col-sm-4 p-fluid">
            <p-chips formControlName="key6_CustomValues" [disabled]="formGroup.get('key6_Tipo').value !== TipoChiave.Lista">
            </p-chips>
          </div>
          <div class="form-group col-sm-1">
            <p-inputSwitch formControlName="key6_Attivo" ></p-inputSwitch>
          </div>
          <div class="form-group col-sm-1">
            <p-inputSwitch formControlName="key6_Obbligatoria" ></p-inputSwitch>
          </div>
        </div>

        <div class="d-flex justify-content-between align-items-center form-group mb-1">
          <div class="form-group indice-width mr-1">
            7.
          </div>
          <div class="form-group col-sm-3">
            <input type="text" formControlName="key7_Etichetta" class="form-control" (change)="onChangeKey(formGroup.get('key7_Etichetta').value, null, 7)" />
          </div>
          <div class="form-group col-sm-2 p-fluid">
            <p-dropdown id="key7_Tipo" [options]="tipoChiavi" optionLabel="label" optionValue="value" formControlName="key7_Tipo"
               appendTo="body" [ngClass]="{ 'ng-dirty ng-invalid': formGroup.get('key7_Tipo').errors?.required}"
               (onChange)="onChangeKey(formGroup.get('key7_Etichetta').value, formGroup.get('key7_Tipo').value, 7)"></p-dropdown>
          </div>
          <div class="form-group col-sm-4 p-fluid">
            <p-chips formControlName="key7_CustomValues" [disabled]="formGroup.get('key7_Tipo').value !== TipoChiave.Lista">
            </p-chips>
          </div>
          <div class="form-group col-sm-1">
            <p-inputSwitch formControlName="key7_Attivo" ></p-inputSwitch>
          </div>
          <div class="form-group col-sm-1">
            <p-inputSwitch formControlName="key7_Obbligatoria" ></p-inputSwitch>
          </div>
        </div>

        <div class="d-flex justify-content-between align-items-center form-group mb-1">
          <div class="form-group indice-width mr-1">
            8.
          </div>
          <div class="form-group col-sm-3">
            <input type="text" formControlName="key8_Etichetta" class="form-control" (change)="onChangeKey(formGroup.get('key8_Etichetta').value, null, 8)" />
          </div>
          <div class="form-group col-sm-2 p-fluid">
            <p-dropdown id="key8_Tipo" [options]="tipoChiavi" optionLabel="label" optionValue="value" formControlName="key8_Tipo"
               appendTo="body" [ngClass]="{ 'ng-dirty ng-invalid': formGroup.get('key8_Tipo').errors?.required}"
               (onChange)="onChangeKey(formGroup.get('key8_Etichetta').value, formGroup.get('key8_Tipo').value, 8)"></p-dropdown>
          </div>
          <div class="form-group col-sm-4 p-fluid">
            <p-chips formControlName="key8_CustomValues" [disabled]="formGroup.get('key8_Tipo').value !== TipoChiave.Lista">
            </p-chips>
          </div>
          <div class="form-group col-sm-1">
            <p-inputSwitch formControlName="key8_Attivo" ></p-inputSwitch>
          </div>
          <div class="form-group col-sm-1">
            <p-inputSwitch formControlName="key8_Obbligatoria"></p-inputSwitch>
          </div>
        </div>

        <div class="d-flex justify-content-between align-items-center form-group mb-1">
          <div class="form-group indice-width mr-1">
            9.
          </div>
          <div class="form-group col-sm-3">
            <input type="text" formControlName="key9_Etichetta" class="form-control" (change)="onChangeKey(formGroup.get('key9_Etichetta').value, null, 9)" />
          </div>
          <div class="form-group col-sm-2 p-fluid">
            <p-dropdown id="key9_Tipo" [options]="tipoChiavi" optionLabel="label" optionValue="value" formControlName="key9_Tipo"
               appendTo="body" [ngClass]="{ 'ng-dirty ng-invalid': formGroup.get('key9_Tipo').errors?.required}"
               (onChange)="onChangeKey(formGroup.get('key9_Etichetta').value, formGroup.get('key9_Tipo').value, 9)"></p-dropdown>
          </div>
          <div class="form-group col-sm-4 p-fluid">
            <p-chips formControlName="key9_CustomValues" [disabled]="formGroup.get('key9_Tipo').value !== TipoChiave.Lista">
            </p-chips>
          </div>
          <div class="form-group col-sm-1">
            <p-inputSwitch formControlName="key9_Attivo" ></p-inputSwitch>
          </div>
          <div class="form-group col-sm-1">
            <p-inputSwitch formControlName="key9_Obbligatoria" ></p-inputSwitch>
          </div>
        </div>

        <div class="d-flex justify-content-between align-items-center form-group mb-1">
          <div class="form-group indice-width mr-1">
            10.
          </div>
          <div class="form-group col-sm-3">
            <input type="text" formControlName="key10_Etichetta" class="form-control" (change)="onChangeKey(formGroup.get('key10_Etichetta').value, null, 10)" />
          </div>
          <div class="form-group col-sm-2 p-fluid">
            <p-dropdown id="key10_Tipo" [options]="tipoChiavi" optionLabel="label" optionValue="value" formControlName="key10_Tipo"
               appendTo="body" [ngClass]="{ 'ng-dirty ng-invalid': formGroup.get('key10_Tipo').errors?.required}"
               (onChange)="onChangeKey(formGroup.get('key10_Etichetta').value, formGroup.get('key10_Tipo').value, 10)"></p-dropdown>
          </div>
          <div class="form-group col-sm-4 p-fluid">
            <p-chips formControlName="key10_CustomValues" [disabled]="formGroup.get('key10_Tipo').value !== TipoChiave.Lista">
            </p-chips>
          </div>
          <div class="form-group col-sm-1">
            <p-inputSwitch formControlName="key10_Attivo" ></p-inputSwitch>
          </div>
          <div class="form-group col-sm-1">
            <p-inputSwitch formControlName="key10_Obbligatoria" ></p-inputSwitch>
          </div>
        </div>
      </div>
    </form>
  </div>
  <ng-template pTemplate="footer">
    <div class="d-flex justify-content-end">
      <button pButton label="ANNULLA" class="p-button-secondary mr-1" (click)="closeModelloDocumentale()"></button>
      <p-button *ngIf="(!formGroup?.enabled || isModelloActiveChange) && !formGroup.get('systemModello').value" icon="pi pi-check" (click)="modificaModelloDocumentale()" label="Modifica"></p-button>
      <button pButton *ngIf="formGroup?.enabled && !formGroup.get('systemModello').value && !isModelloActiveChange" icon="pi pi-check" (click)="salvaModelloDocumentale()" label="Salva"
       [disabled]="formGroup.invalid"></button>
    </div>
  </ng-template>
</p-card>

<p-toast></p-toast>

<rbk-modal *ngIf="visibleDialog" [dialogModel]="dialogModel" (onConfirm)="onConfirmPopup()" (onCancel)="onClosePopup()"></rbk-modal>