<p-tabView [activeIndex]="activeIndex" styleClass="" class="smallMenu" (onChange)="handleChange($event)">
  <!--Bozza Quadro economico-->
  <p-tabPanel header="QE Bozza" [disabled]="permesso === TipoPermesso.None">
    <rbk-qe *ngIf="isDraft && cup" [cup]="cup" [qe]="qeBozza" [isDraft]="true" (publishBozza)="aftersaveBozza($event)"
      [idLastStorico]="idLast" (publishQE)="aftersaveBozza($event)" [user]="user" [permesso]="permesso"  [tipiDizionario]="tipiDizionario"></rbk-qe>
  </p-tabPanel>
  <!--Attuale Quadro economico-->
  <p-tabPanel header="QE Attuale" [disabled]="permesso === TipoPermesso.None">
    <p *ngIf="!qe">Non sono presenti Quadri Economici</p>
    <rbk-qe *ngIf="isAttuale && cup" [cup]="cup" [qe]="qe" [isAttuale]="true" [idLastStorico]="idLast" 
      [user]="user" [permesso]="permesso" [tipiDizionario]="tipiDizionario"></rbk-qe>
  </p-tabPanel>
  <!--Storico Quadro economico-->
  <p-tabPanel header="Storico QE" [disabled]="permesso === TipoPermesso.None">
    <p *ngIf="!qeStorico">Non sono presenti Quadri Economici da confrontare</p>
    <!--QE Storico-->
    <div *ngIf="qeStorico && cup">
      <p-table [value]="qeStorico" selectionMode="single" [(selection)]="qeStoricoEdit" dataKey="codice"
        [(selection)]="qeStoricoEdit" dataKey="data">
        <ng-template pTemplate="header">
          <tr>
            <th>data</th>
            <th>atto</th>
            <th>numero</th>
            <!-- <th>importo</th> -->
            <th></th>
            <th>RAFFRONTO</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-qe>
          <tr [pSelectableRow]="qe">
            <td *ngIf="qe?.publishedAt">{{ qe?.detail?.data | date:"dd/MM/yy"}} </td>
            <td *ngIf="!qe?.publishedAt">BOZZA</td>

            <td *ngIf="qe?.publishedAt">{{ qe?.detail.atto }}</td>
            <td *ngIf="!qe?.publishedAt"></td>

            <td *ngIf="qe?.publishedAt">{{ qe?.detail.numero }}</td>
            <td *ngIf="!qe?.publishedAt"></td>

            <!-- <td>{{( qe.sections[0]?.total + qe.sections[1]?.total + qe.sections[2]?.total ) | currency:
              'EUR':'symbol':undefined:'it-IT' }}
            </td> -->
            <td>
              <p-button type="button" icon="pi pi-search" styleClass="p-button-text"
                (click)="showDialogQE(qe)"></p-button>
                <p-button *ngIf="!permesso || permesso === TipoPermesso.ReadWrite || permesso === TipoPermesso.ReadWriteDelete || user.roles === RuoloPipe.transform(Ruolo.SuperAdministrator)" type="button" icon="pi pi-pencil"
                (click)="editCurretQE(qe)" styleClass="p-button-text"></p-button>
            </td>
            <td>
              <p-button *ngIf="qe?.id !== idFirst" type="button" icon="pi pi-search" styleClass="p-button-text"
                (click)="showDialogRaffronto(qe)"></p-button>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </p-tabPanel>
</p-tabView>


<p-dialog #dialog *ngIf="qe_edit && qeStoricoEdit" header="QE" [(visible)]="qe_edit" [modal]="true" [draggable]="false"
  (visibleChange)="closeDialog()" [maximizable]="true">
  <rbk-qe *ngIf="isStorico && cup" [cup]="cup" [qe]="qeStoricoEdit" [isStorico]="true" [tipiDizionario]="tipiDizionario"
    [idLastStorico]="idLast" (updateQe)="afterUpdateQE($event)" (onCloseDialog)="closeDialog()" [user]="user" [permesso]="permesso"></rbk-qe>
</p-dialog>

<p-dialog *ngIf="qe_modal && qeStoricoEdit" header="QE" [(visible)]="qe_modal" [modal]="true" [draggable]="false"
  [closable]="true" (visibleChange)="closeDialog()" [maximizable]="true">
  <rbk-qe *ngIf="isStorico && cup" [cup]="cup" [qe]="qeStoricoEdit" [isStorico]="true" [isAnteprima]="true" [tipiDizionario]="tipiDizionario"
    [idLastStorico]="idLast" (updateQe)="afterUpdateQE($event)" (onCloseDialog)="closeDialog()" [user]="user" [permesso]="permesso"></rbk-qe>
</p-dialog>

<p-dialog *ngIf="raffronto_modal && qeStoricoEdit" header="Raffronto" [(visible)]="raffronto_modal" [maximizable]="true"
  [modal]="true" [draggable]="false">
  <app-raffronto [qe]="qeStoricoEdit" [cup]="cup"></app-raffronto>
</p-dialog>
