import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { QE, QEMainBase } from "src/app/model/qe";
import { Campo, ModelloDocumentaleCustom, Scenario } from "src/app/model/scenari";
import { ScenarioService } from "src/app/service/scenario.service";
import * as _ from 'lodash';
import { SharedService } from "src/app/service/shared.service";
import { CUP } from "src/app/model/cup";
import { Documento, DocumentoComplete, DocumentoKeyValue, DocumentoKeyValueUpdate, DocumentoSearch, ModelloDocumentale, SezioniDocument, TipoEntita } from "src/app/model/documento";
import { DocumentiService } from "src/app/service/documenti.service";
import { MimeTypePipe } from "src/app/pipe/format-size-pipe";
import { DocumentiDaArchiviareBrowse, DraftSearch } from "../../documenti-sospesi/documenti-sospesi";
import { NgxSpinnerService } from "ngx-spinner";
import { TipoChiavePipe, TipoEntitaPipe } from "src/app/pipe/tipo-entita-pipe";
import { ConfirmEventType, ConfirmationService, MessageService } from "primeng/api";
import { FileUpload } from "primeng/fileupload";
import { SafeResourceUrl } from "@angular/platform-browser";
import { PdfViewerComponent } from "ng2-pdf-viewer";
import { KeyTypePipe } from "src/app/pipe/key-type-pipe";
import { DialogModel } from "../rbk-modal/rbk-modal";
import { PermessiArea, Ruolo, TipoPermesso, Utente } from "src/app/model/utente";
import { UserClientService } from "src/app/service/user-client.service";
import { RuoloPipe } from "src/app/pipe/ruolo-pipe";

@Component({
  selector: 'upload-file',
  templateUrl: './upload-file.html',
  styleUrls: ['./upload-file.scss']
})
export class UploadFileComponent implements OnInit, OnChanges, OnDestroy {
	CALENDER_CONFIG_IT = {
		firstDayOfWeek: 0,
		dayNames: ["Domenica", "Lunedì", "Martedì", "Mercoled'", "Giovedì", "Venerdì", "Sabato"],
		dayNamesShort: ["Dom", "Lun", "Mar", "Mer", "Gio", "Ven", "Sab"],
		dayNamesMin: ["Do","Lu","Ma","Me","Gi","Ve","Sa"],
		monthNames: [ "Gennaio","Febbraio","Marzo","Aprile","Maggio","Giugno","Luglio","Agosto","Settembre","Ottobre","Novembre","Dicembre" ],
		monthNamesShort: [ "Gen", "Feb", "Mar", "Apr", "Mag", "Giu","Lug", "Ago", "Set", "Ott", "Nov", "Dic" ],
		today: 'Oggi',
		clear: 'Clear',
		dateFormat: 'dd/mm/yy',
		weekHeader: 'Wk'
	};

	/**
	 * Tutte le info per caricare i dati della form
	 * !!! Alessia ToDo Cancella tutto quello che non serve
	 */
	@Input() public datiModelloDocumentale: ModelloDocumentaleCustom;

  @Input() public formGroup: FormGroup<any>;

  /**
   * Se true abilita il salvataggio del file
   */
  @Input() public isEnabledLoadFile: boolean;

  @Input() public cup: CUP;

  @Input() public buttonDocumenti: boolean = false;

  @Input() public isInEdit: boolean;

  @Input() public abilitaPubblica: boolean;

  @Input() public editCurrent: boolean;

  @Input() public nomeModello: string;

  @Input() public header: string;

  @Input() public beneficiarioString: string = '';

  @Input() public pIva_cFiscaleString: string = '';

  @Input() public visualizza: boolean = true;

  @Input() public stopFase5: boolean = false;

  @Input() public buttonAnnulla: boolean = true;

  @Input() public buttonCancella: boolean = false;

  @Input() public showSecondButton: boolean = true;

  @Input() public entityID: number;

  @Input() public entityTipo: any;

  @Input() public isQeAttuale: boolean = false;
  @Input() public isQeBozza: boolean = false;

  @Input() public qe: QE;
  /**
   * Indica se lo storico è in edit
   */
  @Input() public isEditQeStorico: boolean;
  /**
   * La lista dei qe storici
   */
  @Input() public qeStorico: QEMainBase[];
  /**
   * Id dell'elemento appena aggiunto in cui bisogna salvare il documento se viene agganciato
   */
  @Input() public entityIdAdd: number;
  /**
   * Totale del quadro economico
   */
  @Input() public totaleQE: number;
  /**
   * Se l'utente è abilitato o meno a vedere dei campi
   */
  @Input() public isUtenteAbilitato: boolean;
  /**
   * True se è un modello documentale di sistema
   */
  @Input() public hasSystemModello: boolean;
  /**
   * Se l'utente è abilitato o meno a vedere dei campi
   */
  @Input() public nomeModelliDocumenti: string[];
  /**
   * La descrizione che riferisce alla fase precedente
   */
  @Input() public riferimentoFase: string = '';
  /**
   * Identificativo della sezione
   */
  @Input() public idSezione?: number;
  /**
   * Indica che sono nello scenario cup
   */
  @Input() public isScenarioCup: boolean = false;
  /**
   * Indica che sto aggiungendo un doc da cartella monitorata
   */
  @Input() public isScenarioCartellaMonitorata: boolean = false;
  /**
   * Se true indica che so sostituendo una fonte
   */
  @Input() public sostituisciFonti: boolean = false;
  /**
   * Inizializza il cup
   */
  @Input() public isCupInitialized: boolean = false;
  /**
   * Utente loggato
   */
  @Input() public user: Utente;
  /**
   * Permessi per tutti gli scenari
   */
  @Input() public permesso: TipoPermesso;
  /**
   * Se true indica che è un'anteprima 
   */
  @Input() public isAnteprima: boolean = false;
  /**
   * Ripristina il documento se cancellato nei documenti da lavorare
   */
  @Input() public isRipristinaDocumento: boolean = false;

  @Output() add = new EventEmitter<FormGroup<any>>();

  @Output() save = new EventEmitter<FormGroup<any>>();

  @Output() checkBeforeSave = new EventEmitter<FormGroup<any>>();

  @Output() close = new EventEmitter<boolean>();

  @Output() bloccaSaveBozza = new EventEmitter<boolean>();

  @Output() eventTextarea = new EventEmitter<{ formGroup: FormGroup<any>, item: string }>();

  @Output() eventCurrency = new EventEmitter<{ formGroup: FormGroup<any>, item: string }>();

  @Output() eventDropdown = new EventEmitter<{ formGroup: FormGroup<any>, item: string }>();

  @Output() eventRadio = new EventEmitter<{ formGroup: FormGroup<any>, item: string }>();

  @Output() filterAutoComplite = new EventEmitter<{ formGroup: FormGroup<any>, item: string }>();

  @Output() delete = new EventEmitter<{ formGroup: FormGroup<any> }>();

  @Output() onClickBtn = new EventEmitter<{ formGroup: FormGroup<any>, item: string }>();
  /**
   * Evento che resetta il cupInitialized per refreshare il cup
   */
  @Output() public onRefreshCup = new EventEmitter<boolean>();
  @Output() public onRipristinaDocumento = new EventEmitter<void>();

	/**
	 * Indica il titolo della modale in base
	 * a se è un add/visualizzazione
	 */
	public headerDialog: string = '';
  	/**
	 * Url del documento
	 */
	public urlSafe: SafeResourceUrl;
	/**
	 * Lista dei file associati allo scenario di riferimento
	 */
	public listFiles: Documento[] = [];

	/**
	 * Documento selezionato
	 */
	selectedDocumento: DocumentiDaArchiviareBrowse;

  public message: string;

  public selected: any;

  public label: string;

  public listaFileTemporanea: File[] = [];

	public pdfSrc;

	public nomeFileAnteprimaDocumento: string;

	public isPdf: boolean;

	public indexRinominafile: number = -1;

	public indexRinominafileAdd: number = -1;

	public changedName: string = '';

	public filePreviewSelected: File;

	public filePreviewCartellaMonitorata: File;

	public caricamentoFileInCorso: boolean = false;

  	public isDraft: boolean;

  	public boolDisabled: boolean = true;
	/**
     * Se ppreme btn per caricare ile da cartella monitorata
	 */
	public showDocumentCM: boolean = false;
	/**
	 * Flag, includi senza Cup
	 */
   public includiSenzaCup: boolean;
	/**
     * Lista dei documenti recuperati da lavorare
     */
    public documentBrowse: DocumentiDaArchiviareBrowse[] = [];
	/**
	 * Paginazione Griglia Documenti da Cartella Monitorata
	 */
	public first = 0;
  	public rows = 10;
	/**
	 * Tutti i documenti dalla cartella monitorata
	 */
    public allListaDocumenti: DocumentiDaArchiviareBrowse[] = [];
	/**
	 * Spinner per il caricamento dei documenti da cartella monitarata
	 */
	public caricamentoGrid: boolean = false;
	/**
	 * Documento che arriva dalla selezione
	 */
	public documentoView: DocumentoComplete;
  /**
	 * La pipe per il tipo di entità
	 */
	public tipoEntitaPipe = new TipoEntitaPipe();
	/**
	 * La pipe per il tipo di valore di ritorno
	 */
	public tipoKeyValuePipe = new KeyTypePipe();
	/**
	 * La pipe per il tipo di chiave
	 */
	public tipoKeyPipe = new TipoChiavePipe();
	/**
	 * Documento a cui cambiare il nome file
	 */
	public documentoChangeNomeFile: Documento;
	/**
	 * !!!! Alessia da capire cosa è e cosa fa
	 */
	public visible: boolean = false;
	/**
		 * Url del documento
		 */
	public urlBlob: string;
  	/**
	 * Indica il messaggio da visualizzare nel dialog
	 */
	public messageDialog: string =
    'Per caricare il documento è necessario salvare i dati inseriti. Procedo?';
	/**
	 * Se true apre il popup per cancellare un file
	 */
	public isShowDialogDeleteDoc: boolean = false;
  	/**
	 * ******************************************************************************************************************************
	 * Gestione Anteprima
	 * ******************************************************************************************************************************
	 */
    @ViewChild(PdfViewerComponent) private pdfComponent: PdfViewerComponent;
    renderText = true;
    originalSize = false;
    fitToPage = false;
    showAll = true;
    autoresize = false;
    showBorders = true;
    renderTextModes = [0, 1, 2];
    renderTextMode = 1;
    rotation = 0;
    zoom = 1;
    zoomScale = 'page-width';
    zoomScales = ['page-width', 'page-fit', 'page-height'];
    pdfQuery = '';
    totalPages: number;
    page = 1;
	/**
	 * ******************************************************************************************************************************
	 * Fine Gestione Anteprima
	 * ******************************************************************************************************************************
	 */

	/**
    * Sezioni Modello Documentale
    */
	public sezioni: SezioniDocument[];
	/**
    * L'd della sezione a cui associare il documento
    */
	public sezioneId: number;
	public dialogModel: DialogModel;
    public visibleDialog: boolean = false;
	public TipoPermesso = TipoPermesso;
	public Ruolo = Ruolo;
	public RuoloPipe = new RuoloPipe();

	/**
		 * Pipe per conventire l'estensione del file con il tipo del file
		 */
	private _mimeTypePipe = new MimeTypePipe();

	constructor(
			private confirmationService: ConfirmationService,
			private scenarioServices: ScenarioService,
			private _sharedService: SharedService,
			private _documentiService: DocumentiService,
			private spinner: NgxSpinnerService,
			private messageService: MessageService,
			private userClientService: UserClientService
	) { }

	ngOnDestroy (): void
	{
		delete this.visible;
		this.pdfSrc = undefined;
		this.isPdf = false;
		this.urlSafe = undefined;
		this.listaFileTemporanea = [];
		if (!this.isScenarioCartellaMonitorata) {
			this._sharedService.deleteParametriNavigaDaDocumentiArchviazione();
			this._sharedService.setAnteprimaDocumentoCartellaMonitorata(false, undefined);
		}
	}

  	async ngOnChanges ( changes: SimpleChanges ): Promise<void> {
		const entityIdAddChange = changes['entityIdAdd'];
		if(entityIdAddChange && !entityIdAddChange.firstChange && entityIdAddChange.currentValue &&
			entityIdAddChange.currentValue !== entityIdAddChange.previousValue){
				this.entityID = this.entityIdAdd;
				this.datiModelloDocumentale.entityID = this.entityIdAdd;
				await this.salvataggioDocumenti();
		}

		const isCupInitializedChange = changes['isCupInitialized'];
		if(isCupInitializedChange && isCupInitializedChange.currentValue){
			await this.ngOnInit();
			this.onRefreshCup.emit(false);
		}
	}

  	async ngOnInit(): Promise<void> {
		this.caricamentoFileInCorso = this.datiModelloDocumentale.isEdit;
    	const isAnteprimaDocumento = this._sharedService.isAnteprimaDocumentoCartellaMonitorata();
		const idDocumentoCartellaMonitorata = this._sharedService.getDocumentoIdCartellaMonitorata();
		this.sezioni = await this._sharedService.getSezioniModelloDocumentale();

		if (idDocumentoCartellaMonitorata && isAnteprimaDocumento && !this.isDraft) {
			// Se provengo da cartella monitorata, mostro l'anteprima del documento selezionato
			this.showDialog(false);
			await this.loadFileFromServer(idDocumentoCartellaMonitorata);
			this.onSelectFile(this.filePreviewCartellaMonitorata);
			this.label = this.nomeModello === 'Quadro Economico' ? 'PUBBLICA' : 'AGGIUNGI';
			if (this.qe && !this.qe.publishedAt) {
				this.isDraft = false;
			}
		} else {
			this.message = this.scenarioServices.getMessage(this.nomeModello, this.stopFase5);
			this.label = this.nomeModello === 'Quadro Economico' ? 'PUBBLICA' : 'AGGIUNGI';
			this.isDraft = true;
			if (this.qe && !this.qe.publishedAt) {
				this.isDraft = false;
			}
			await this.getListNomeFile();
		}
		console.log(this.nomeModello, this.permesso);
  }

  public focusImporto(event){
	event.srcElement.select();
  }

  	/**
	 * Apre la modale per caricare i documenti
	 */
	public showDialog(isAddFile: boolean) {
		this.visible = true;
		this.headerDialog = isAddFile ? 'Carica File' : 'Lista File';
		this.pdfSrc = undefined;
		this.isPdf = false;
		this.urlSafe = undefined;
	}

  	/**
	 * prendiInCaricoDocumento
	 */
	public async prendiInCaricoDocumento(documento){
		this.showDocumentCM = false;
		this.caricamentoFileInCorso = true;
		this._sharedService.setAnteprimaDocumentoCartellaMonitorata(true, documento.id);
		this.showDialog(false);
		await this.loadFileFromServer(documento.id);
		const d = new Documento();
		d.id = documento.id;
		d.entityID = this.datiModelloDocumentale?.entityID;
		d.cupid = this.cup?.id;
		d.entityTipo

		this.listFiles.push()
		this.onSelectFile(this.filePreviewCartellaMonitorata);
	}

	public pageChange(event){
		// this.first = event.first;
		this.rows = event.rows;
		this.showDocumentiCM();
	}

  	/**
     * Filtra la griglia in base ai modelli selezionati
     * @param selectedModelli  modelli selezionati nel filtro
     */
	public async showDocumentiCM() {
		this.caricamentoGrid = true;
		this.documentBrowse = [];
		const filtri: DraftSearch = new DraftSearch();
		filtri.codiceCup = !this.includiSenzaCup && this.cup && this.cup?.codice || '';
		filtri.includiSenzaCup = !!this.includiSenzaCup;
		filtri.pageIndex = this.first;
		filtri.pageSize = this.rows;
		const response = await this._documentiService.getSearchDraftDocument(filtri).toPromise();
		if(response && response.success){
			this.documentBrowse = response.dtos;
		}
		this.showDocumentCM = true;
		this.caricamentoGrid = false;
	  }

  /**
   * Recupera la lista di tutti i file
   */
	public async getListNomeFile() {
		this.listFiles = [];
		if(this.datiModelloDocumentale.isEdit ||
			(this.datiModelloDocumentale.isFrom === Scenario.QuadroEconomico && (this.isQeAttuale || this.isEditQeStorico))){
			let filterDocumentiCup = await this.filtriDocumenti();
			if(filterDocumentiCup){
				const resultFilteredBrowse = await this._documentiService.filterDocumento(filterDocumentiCup, this.sezioni).toPromise();
				console.log("Documenti caricati", resultFilteredBrowse);
				if (resultFilteredBrowse && resultFilteredBrowse.length > 0) {
					if(this.datiModelloDocumentale?.documentoID) {
						this.listFiles = resultFilteredBrowse.filter(x => x.id === this.datiModelloDocumentale?.documentoID);
						await this.loadFileFromServer(this.datiModelloDocumentale?.documentoID);
					} else if (this.datiModelloDocumentale?.entityID) {
						this.listFiles = resultFilteredBrowse.filter(x => x.entityID === this.datiModelloDocumentale?.entityID);
					}

					// Sto sostituendo la fonte e pulisco il file in modo tale da sostituire il file
					if(this.sostituisciFonti) {
						this.listFiles = [];
						this.sostituisciFonti = false;
					}

					// Mostro l'anteprima del primo documento caricato, o dell'unico se nella lista è presente un solo documento
					if(this.listFiles?.length >= 1){
						await this.loadFileFromServer(this.listFiles[0].id);
					} else {
						this.caricamentoFileInCorso = false;
					}
				} else {
					this.caricamentoFileInCorso = false;
				}
			} else {
				this.caricamentoFileInCorso = false;
			}
		}
	}

  	async filtriDocumenti(){
		let filterDocumentiCup;
		if(this.datiModelloDocumentale?.cup?.id || this.datiModelloDocumentale?.idCup){
			const modello = await this.getModello();
			filterDocumentiCup = new DocumentoSearch();
			filterDocumentiCup.cupId = this.datiModelloDocumentale?.cup?.id || this.datiModelloDocumentale?.idCup;
			filterDocumentiCup.entityId = this.datiModelloDocumentale?.entityID;
			filterDocumentiCup.entityTipo = this.datiModelloDocumentale?.entityTipo;
			filterDocumentiCup.modelloIDs = !!modello ? [modello?.id]: [];

			if (this.datiModelloDocumentale?.entityTipo === TipoEntita.FonteFinanziamento
				|| this.datiModelloDocumentale?.entityTipo === TipoEntita.Incassato
				|| this.datiModelloDocumentale?.isFrom === Scenario.Fonte
				|| this.datiModelloDocumentale?.isFrom === Scenario.Incasso) {

				filterDocumentiCup.sezione = this.sezioni[1];
			} else {
				filterDocumentiCup.sezione = this.sezioni[0];
			}
		}
		return filterDocumentiCup;
	}

  	/**
	 * Preview dei documenti recuperati dal server tramite id
	 * @param documentoId
	 */
	public async loadFileFromServer(documentoId: number) {
		this.caricamentoFileInCorso = true;
		delete this.documentoView;
		const isAnteprimaDocumento = this._sharedService.isAnteprimaDocumentoCartellaMonitorata();
		const response = isAnteprimaDocumento ?
			await this._documentiService.getDocumentoArchiviato(documentoId).toPromise() :
			await this._documentiService.getDocumento(documentoId).toPromise();
	    if(response && response.success){
			this.documentoView = response.dto;
		} else {
			this.dialogModel = {
				title: "Attenzione",
				message: "Documento non trovato",
				btnConferma: "Ok",
				hasBtnAnnulla: false
			};
			this.visibleDialog = true;
			this.caricamentoFileInCorso = false;
		}
		if(this.sostituisciFonti) {
			delete this.documentoView;
		}

		if (this.documentoView) {
			const re = /(?:\.([^.]+))?$/;
			const extension = re.exec(this.documentoView.nomeFile)[1];
			const mediaType = this._mimeTypePipe.transform(extension);
			if (this.documentoView.blob) {
				const data = 'data:' + mediaType + ';base64,' + this.documentoView.blob;
				this.pdfSrc = data;
				this.nomeFileAnteprimaDocumento = this.documentoView.nomeFile;
				if(isAnteprimaDocumento){
					this.filePreviewCartellaMonitorata = new File([this.documentoView.blob], this.documentoView.nomeFile, { type: 'application/pdf' });
				} else {
					this.filePreviewSelected = new File([this.documentoView.blob], this.documentoView.nomeFile, { type: 'application/pdf' });
				}
				this.isPdf = mediaType === 'application/pdf';
			}
		}
		this.caricamentoFileInCorso = false;
	}

  	/**
	 * Recupero il modello di riferimento
	 * Questo await dovrebbe essere di poco tempo, il risultato viene settato in _sharedService
	 * e non recuperato se già valorizzato
	 */
	protected async getModello() {
		if (!this.nomeModello && this.datiModelloDocumentale?.isFrom  === Scenario.Documento) {
			this.nomeModello = this.datiModelloDocumentale?.formGroup.value.nomeModello;
		}
		let modelloDocumentale: ModelloDocumentale;
		let modelliDocumentali: ModelloDocumentale[] = [];
		let sezione = this.sezioni.find(s => s.id === this.idSezione);
		let sezioneNome = this.sezioni[0].nome;
		this.sezioneId = this.sezioni[0].id;
		if (sezione && sezione.nome) {
			sezioneNome = sezione.nome;
		} else if (this.datiModelloDocumentale?.isFrom === Scenario.Fonte || this.datiModelloDocumentale?.isFrom === Scenario.Incasso){
			sezioneNome = this.sezioni[1].nome;
			this.sezioneId = this.sezioni[1].id;
		} else if (this.datiModelloDocumentale?.isFrom === Scenario.Annotazione) {
			sezioneNome = '*';
		}
		modelliDocumentali = await this._documentiService.getModelliDocumentaliSezione(sezioneNome, '*').toPromise();
		if (modelliDocumentali && modelliDocumentali?.length > 0){
			modelloDocumentale = modelliDocumentali.find((modello) => modello.nome === this.nomeModello);
		}
		return modelloDocumentale;
	}

	async isPublishing() {
		if(!this.isDraft){
			this.isDraft = true;
			this.datiModelloDocumentale.isEdit = true;
			this.datiModelloDocumentale?.formGroup.enable();
		} else if(this.isDraft){
			this.entityID = this.qe.qeMainId;
			this.datiModelloDocumentale.entityID = this.entityID;
			await this.salvataggioDocumenti();
			this.add.emit(this.datiModelloDocumentale?.formGroup);
		} else {
			this.add.emit(this.datiModelloDocumentale?.formGroup);
		}
	}

	modifica() {
		this.datiModelloDocumentale?.formGroup.enable();
		if (this.datiModelloDocumentale.isFrom === Scenario.Documento) {
			this.isEnabledLoadFile = true;
			this.buttonDocumenti = true;
		}
		this.listaFileTemporanea = [];
	}

	async onAdd() {
		this.add.emit(this.datiModelloDocumentale?.formGroup);
	}

	async onSave() {
		if ((this.datiModelloDocumentale.isFrom === Scenario.Spesa) || (this.datiModelloDocumentale.isFrom === Scenario.Giustificativo) 
			|| (this.datiModelloDocumentale.isFrom === Scenario.Liquidazione) || (this.datiModelloDocumentale.isFrom === Scenario.Mandato)) {
			this.checkBeforeSave.emit(this.datiModelloDocumentale.formGroup);
		} else {
			if (this.datiModelloDocumentale.isFrom !== Scenario.Documento) {
				this.save.emit(this.datiModelloDocumentale.formGroup);
			}
			if (!!this.datiModelloDocumentale.idCup || !!this.datiModelloDocumentale.entityID || this.datiModelloDocumentale.isFrom === Scenario.Documento) {
				await this.salvataggioDocumenti();
			}
		}
	}

	async saveAfterCheck() {
		this.save.emit(this.datiModelloDocumentale.formGroup);
		if (!!this.datiModelloDocumentale.idCup || !!this.datiModelloDocumentale.entityID || this.datiModelloDocumentale.isFrom === Scenario.Documento) {
			await this.salvataggioDocumenti();
		}
	}

	async salvataggioDocumenti(){
		// Salvataggio dei documenti
		const isAnteprimaDocumento = this._sharedService.isAnteprimaDocumentoCartellaMonitorata();
		const documentoId = this._sharedService.getDocumentoIdCartellaMonitorata();
		if(documentoId && isAnteprimaDocumento && this.filePreviewCartellaMonitorata){
			await this.onAddDocument(this.filePreviewCartellaMonitorata, documentoId);
			this.onClose(true);
		}	 else {
		if(this.datiModelloDocumentale.entityID || (!this.datiModelloDocumentale.entityID && this.datiModelloDocumentale.isFrom === Scenario.Documento)) {
			// Se sono in modifica dei dati aggiorno le chiavi del/i documento/i associato/i a quel scenario
			if(this.listaFileTemporanea && this.listFiles && this.listaFileTemporanea.length === 0 && this.listFiles.length === 0){
				this.onClose(true);
			}else {
				if (this.listaFileTemporanea && this.listaFileTemporanea.length > 0) {
					for (let file of this.listaFileTemporanea) {
						await this.onAddDocument(file);
					}
				this.onClose(true);
				}
				if (this.listFiles && this.listFiles.length > 0) {
					for (let documento of this.listFiles) {
						await this.onUpdateDocumento(documento);
					}
				this.onClose(true);
				}
			}
		} else {
			// Sono in aggiunta de dati
			if (this.listaFileTemporanea && this.listaFileTemporanea.length > 0) {
				for (let file of this.listaFileTemporanea) {
					await this.onAddDocument(file);
				}
				this.onClose(true);
			} else {
			this.onClose(true);
			}
		}
		}
	}

	onClose(event: any) {
		this.listaFileTemporanea = [];
		this.close.emit(event);
		this._sharedService.deleteParametriNavigaDaDocumentiArchviazione();
		this._sharedService.setAnteprimaDocumentoCartellaMonitorata(false, undefined);
	}

  onDelete() {
    this.delete.emit({ formGroup: this.datiModelloDocumentale?.formGroup });
  }

  onRipristina() {
    this.onRipristinaDocumento.emit();
  }

  onChangeItem(item) {
    //this.change.emit({ formGroup: this.formGroup, functionName: item.onChangeNameFunction });
  }

  onFilterAutoComplite(event, item: Campo) {
    const lista = item.listaAutoComplete;
    const query = event.query;
    item.lista = query ? lista.filter(x =>
      x.descrizione.toLowerCase().indexOf(event.query.toLowerCase()) > 0) :
      lista;
  }

  currencyChange(item: Campo) {
    if (item.tipo === 'currency' && !item.readonly) {
      this.eventCurrency.emit({ formGroup: this.datiModelloDocumentale?.formGroup, item: item.key });
    }
  }

  dropdownChange(item: Campo) {
    if (item.tipo === 'lista') {
      this.eventDropdown.emit({ formGroup: this.datiModelloDocumentale?.formGroup, item: item.key });
    }
  }

  radioChange(item: Campo){
      this.eventRadio.emit({ formGroup: this.datiModelloDocumentale?.formGroup, item: item.key });
  }

  textareaChange(item: Campo) {
    if (item.hasAction) {
      this.eventTextarea.emit({ formGroup: this.datiModelloDocumentale?.formGroup, item: item.key });
    }
  }

  btnClick(item: Campo){
    this.onClickBtn.emit({ formGroup: this.datiModelloDocumentale?.formGroup, item: item.key });
  }

  checkIsTouched(key: string) {
    let retVal = false;
    if (key && this.datiModelloDocumentale?.formGroup && this.datiModelloDocumentale?.formGroup.controls[key]) {
      retVal = this.datiModelloDocumentale?.formGroup.controls[key].touched && !this.datiModelloDocumentale?.formGroup.controls[key].valid;
    }
    return retVal;
  }

  /**
   * Controllo se il file è selezionato
   * @param fileSelected il file selezionato
   */
  public onSelectFile(fileSelected: File) {
	this.isEnabledLoadFile = !!fileSelected;
	this.bloccaSaveBozza.emit(this.isEnabledLoadFile);
  }

  	/**
   	 * Preview del file selezionato
     */
	public async previewFile(file: File, fileUploadedEvent?: any) {
		this.spinner.show();
		this.filePreviewSelected = file;
		// Alla selezione, carico l'intera lista di file
		if (fileUploadedEvent) {
			this.listaFileTemporanea = fileUploadedEvent.currentFiles;
		}

		// Anteprima del file selezionato
		if (file) {
			// Verifica se il file è un PDF
			this.isPdf = file.type === 'application/pdf';
			// Leggi il file e genera un'anteprima
			await this.readAsync(file);
			this.onSelectFile(file);
		}
		this.spinner.hide();
	}

	/**
	 * Recupera estenzione dal filename
	 */
	public getExtensioneFile(filename: string) {
		const re = /(?:\.([^.]+))?$/;
		return (re.exec(filename) && re.exec(filename)[1]) || 'pdf';
	}

	private async readAsync(file: File) {
		this.filePreviewSelected = file;
		return new Promise<void>((resolve) => {
			const reader = new FileReader();
			reader.onload = (e: any) => {
				// Ottieni l'URL dell'anteprima
				this.pdfSrc = e.target.result;
				this.nomeFileAnteprimaDocumento = file.name;
				this.onSelectFile(file);
				resolve();
			};
			reader.readAsDataURL(file);
		});
	}

  /**
	 * Carica il documento e lo associa allo scenario in cui si trova
	 * @param event file da caricare
	 */
	public async onAddDocument(originaFile: File, documentoIdCartellaMonitorata?: number) {
		this.spinner.show();
		 if (originaFile) {
			let tipologia;
			if (!this.nomeModello && (this.datiModelloDocumentale?.isFrom === Scenario.Documento || this.datiModelloDocumentale?.isFrom === Scenario.DocumentiDaArchiviareBrowse)) {
				this.nomeModello = this.datiModelloDocumentale?.formGroup.value.nomeModello;
				this.datiModelloDocumentale.entityTipo = this.scenarioServices.getTipologia(this.nomeModello, this.datiModelloDocumentale?.formGroup);
			}
			if (this.nomeModello !== 'Quadro Economico') {
				this.datiModelloDocumentale.entityTipo = this.scenarioServices.getTipologia(this.nomeModello, this.datiModelloDocumentale?.formGroup);
			} else {
				this.datiModelloDocumentale.entityTipo = this.isDraft ?
					TipoEntita.QuadroEconomicoBozza :
					TipoEntita.QuadroEconomicoStorico;
			}
			if (this.datiModelloDocumentale?.entityTipo) {
				tipologia = this.tipoEntitaPipe.transform(this.datiModelloDocumentale?.entityTipo);
			}

			const modello = await this.getModello();
			const docKeyValue = this.scenarioServices.
				getDocKeyValueByModello(this.datiModelloDocumentale?.isFrom, this.datiModelloDocumentale?.formGroup, tipologia, this.nomeModello, this.beneficiarioString, this.pIva_cFiscaleString, this.riferimentoFase);
				let newDoc: Documento = {
					id: 0,
					file: originaFile,
					keys: docKeyValue,
					nomeFile: originaFile.name,
					entityID: this.datiModelloDocumentale?.entityID ?? 0,
					entityTipo: tipologia ?? 1,
					cupid: this.datiModelloDocumentale?.idCup ?? this.datiModelloDocumentale?.cup?.id,
					modelloID: modello.id,
					idDocumentoBozza: documentoIdCartellaMonitorata,
					modello: modello,
					idSezione: this.idSezione ? this.idSezione : this.sezioneId
				};
				const result = await this._documentiService.createDocumento(newDoc).toPromise();
				if (result && result.success) {
					this.messageService.add({severity: 'success', summary: 'Successo', detail: 'Caricamento file riuscito'});
					this.getListNomeFile();
				} else {
					this.messageService.add({
						severity: 'error',
						summary: 'Attenzione',
						detail: 'Caricamento file non riuscito',
					});
				}
				this.spinner.hide();
				this._sharedService.deleteParametriNavigaDaDocumentiArchviazione();
    			this._sharedService.setAnteprimaDocumentoCartellaMonitorata(false, undefined);
		 }
	}

	/**
	 * Update delle chiavi del documento
	 */
	public async onUpdateDocumento(documento: Documento) {
		this.spinner.show();
		if(documento) {
			if (!this.nomeModello && this.datiModelloDocumentale?.isFrom === Scenario.Documento) {
				this.nomeModello = this.datiModelloDocumentale?.formGroup.value.nomeModello;
				this.entityTipo = this.scenarioServices.getTipologia(this.nomeModello, this.datiModelloDocumentale?.formGroup);
			}
			if (this.nomeModello !== 'Quadro Economico') {
				this.entityTipo = this.scenarioServices.getTipologia(this.nomeModello, this.datiModelloDocumentale?.formGroup);
			}
			if (this.entityTipo) {
				var tipologia = this.tipoEntitaPipe.transform(this.entityTipo);
			}

			let docKeyValue: DocumentoKeyValue;
			if(this.datiModelloDocumentale?.isFrom === Scenario.Documento) {
				const modello = await this.getModello();
				const keysModello = modello.keysModelloDocumentale;
				const formGroup = this.datiModelloDocumentale.formGroup;
				docKeyValue = {
					id: 0,
					key1_Value: keysModello.key1_Attivo ? this.tipoKeyValuePipe.transform(this.tipoKeyPipe.transform(keysModello.key1_Tipo), formGroup.controls['key1_Value'].value) : '',
					key2_Value: new Date(formGroup.controls['key2_Value'].value),
					key3_Value: keysModello.key3_Attivo ? this.tipoKeyValuePipe.transform(this.tipoKeyPipe.transform(keysModello.key3_Tipo), formGroup.controls['key3_Value'].value) : '',
					key4_Value: keysModello.key4_Attivo ? this.tipoKeyValuePipe.transform(this.tipoKeyPipe.transform(keysModello.key4_Tipo), formGroup.controls['key4_Value'].value) : '',
					key5_Value: keysModello.key5_Attivo ? this.tipoKeyValuePipe.transform(this.tipoKeyPipe.transform(keysModello.key5_Tipo), formGroup.controls['key5_Value'].value) : '',
					key6_Value: keysModello.key6_Attivo ? this.tipoKeyValuePipe.transform(this.tipoKeyPipe.transform(keysModello.key6_Tipo), formGroup.controls['key6_Value'].value) : '',
					key7_Value: keysModello.key7_Attivo ? this.tipoKeyValuePipe.transform(this.tipoKeyPipe.transform(keysModello.key7_Tipo), formGroup.controls['key7_Value'].value) : '',
					key8_Value: keysModello.key8_Attivo ? this.tipoKeyValuePipe.transform(this.tipoKeyPipe.transform(keysModello.key8_Tipo), formGroup.controls['key8_Value'].value) : '',
					key9_Value: keysModello.key9_Attivo ? this.tipoKeyValuePipe.transform(this.tipoKeyPipe.transform(keysModello.key9_Tipo), formGroup.controls['key9_Value'].value) : '',
					key10_Value: keysModello.key10_Attivo ? this.tipoKeyValuePipe.transform(this.tipoKeyPipe.transform(keysModello.key10_Tipo), formGroup.controls['key10_Value'].value) : '',
				}
			} else {
				docKeyValue = this.scenarioServices.getDocKeyValueByModello(
					this.datiModelloDocumentale?.isFrom, 
					this.datiModelloDocumentale?.formGroup, 
					tipologia, 
					this.nomeModello, 
					this.beneficiarioString, 
					this.pIva_cFiscaleString
				);
			}
			if(documento.id > 0 && documento.keys.id > 0) {
				docKeyValue.id = documento.keys.id;
				docKeyValue.idDocumento = documento.id;
			}

			const documentoKeyValueUpdate: DocumentoKeyValueUpdate = {
				entita: this.datiModelloDocumentale?.entityTipo || TipoEntita.Nessuna,
  				entitaID: this.datiModelloDocumentale.entityID,
  				chiaviModello: docKeyValue,
				documentoID: documento.id
			};

			const result = await this._documentiService.updateKeyDocumento(documentoKeyValueUpdate).toPromise();
			if(result){
				this.scenarioServices.showSuccessMessage('Caricamento file riuscito')
				await this.getListNomeFile();
			} else {
				this.scenarioServices.showErrorMessage('Caricamento File non riuscito');
			}
			this.spinner.hide();
		}
	}

	/**
	 * Rimuove il file selezionato
	 * @param file
	 */
	public removeFile(file: File, uploader: FileUpload) {
		const index = uploader.files.indexOf(file);
		uploader.remove(null, index);
		this.listaFileTemporanea.slice(index, 1);
		this.cleanAfterDelete();
	}

	/**
	 * Dopo a cancellazione, pulizia di tutti i riferimenti al documento caricato
	 */
	cleanAfterDelete(){
		delete this.pdfSrc;
		delete this.urlSafe;
		this.isPdf = false;
		if (this.listaFileTemporanea.length === 0) {
			this.onSelectFile(undefined);
		}
	}


	/**
	 * Scarica il file selezionato
	 * @param documentoId id del documento
	 * @param nomeFile nome del documento
	 */
	async downloadFile(documentoId: number, nomeFile: string) {
		this.spinner.show();
		let documento: DocumentoComplete;
		const response = await this._documentiService.getDocumento(documentoId).toPromise();
		if(response && response.success){
			documento = response.dto;
		} else {
			console.log('500');
		}


		let mediaType = '';
		if (documento.mediaType === 'application/pkcs7-mime') {
			mediaType = 'application/pdf';
		} else {
			mediaType = documento.mediaType;
		}
		if (documento) {
			var data = 'data:' + mediaType + ';base64,' + documento.blob;
			const a = document.createElement('a');
			document.body.appendChild(a);
			a.href = data;
			a.download = nomeFile ? nomeFile : '';
			a.click();
			window.URL.revokeObjectURL(data);
			document.body.removeChild(a);
		}

		this.spinner.hide();
	}


	/**
	 * Metodo chiamato al change del nome file
	 * @param documento documento in cui cambiare il nomefile
	 */
	onChangeNomeFile(documento: Documento, nomeFile: string) {
		this.documentoChangeNomeFile = documento;
	}

	/**
	 * Rinomina del Nome File dall'upload
	 */
	renameFile(index: number) {
		const fileDaSostituire = this.listaFileTemporanea[index];
		const fileNameDaSostituire = this.changedName !== '' ? this.changedName : fileDaSostituire.name;

		const re = /(?:\.([^.]+))?$/;
		const extension = re.exec(fileDaSostituire.name)[1];
		const mediaType = this._mimeTypePipe.transform(extension);

		// Rinomina del nome file
		var blob = fileDaSostituire.slice(0, fileDaSostituire.size, mediaType);
		const fileName = `${fileNameDaSostituire}.${extension}`;
		const file = new File([blob], fileName, { type: mediaType });

		this.listaFileTemporanea.splice(index, 1, file);

		this.indexRinominafileAdd = -1;
		this.changedName = '';
	}

	/**
	 * Rinomina del Nome File dalla lista dei caricati
	 */
	async renameFileLista(index: number) {
		const fileDaSostituire = this.listFiles[index];
		const re = /(?:\.([^.]+))?$/;
		const extension = re.exec(fileDaSostituire.nomeFile)[1];

		if (this.changedName !== '') {
			fileDaSostituire.nomeFile = this.changedName;
			const nomeFileChanged = this.changedName.concat('.' + extension).replace(/\.\./g, '.');
			this._documentiService.updateNomeFile(fileDaSostituire.id, nomeFileChanged).toPromise();
		}

		this.listFiles.splice(index, 1, fileDaSostituire);
		this.indexRinominafile = -1;
		this.changedName = '';
		this.getListNomeFile();
	}

	annullaRenameLista (index: number) {
		const fileDaSostituire = this.listFiles[index];
		this.listFiles.splice(index, 1, fileDaSostituire);
		this.indexRinominafile = -1;
		this.indexRinominafileAdd = -1;
	}

	annullaRename (index: number) {
		this.indexRinominafileAdd = -1;
	}

	/**
	 * Cancella il file
	 * @param idFile id del file da cancellare
	 */
	public async deleteFile(idFile: number) {
		this.spinner.show();
		const resultDelete = await this._documentiService.deleteDocumento(idFile).toPromise();
		if (resultDelete) {
			this.cleanAfterDelete();
			this.scenarioServices.showSuccessMessage('Il file è stato cancellato con successo!')
			await this.getListNomeFile();
		} else {
			this.scenarioServices.showErrorMessage('Non è stato possibile cancellare il file');
		}
		this.visible = false;
		this.spinner.hide();
	}

	public showDialogDeleteFile(idFile: number) {
		this.isShowDialogDeleteDoc = true;
		this.confirmationService.confirm({
			message: 'Sei sicuro di voler cancellare questo file?',
			header: 'Cancella File',
			icon: 'pi pi-exclamation-triangle',
			rejectLabel: 'No',
			acceptLabel: 'Si',
			dismissableMask: false,
			accept: async () => {
				this.isShowDialogDeleteDoc = false;
				await this.deleteFile(idFile);
			},
			reject: (type: ConfirmEventType) => {
				if (type === ConfirmEventType.REJECT) {
					this.isShowDialogDeleteDoc = false;
				}
			},
		});
	}

	/**
	 * ******************************************************************************************************************************
	 * Gestione Anteprima
	 * ******************************************************************************************************************************
	 */

	rotateDoc() {
		if (this.rotation === 0) {
			this.rotation = 90;
		} else if (this.rotation === 90) {
			this.rotation = 180;
		} else if (this.rotation === 180) {
			this.rotation = 0;
		}
	}

	// Event for search operation
	searchQueryChanged(event) {
		const newQuery = event.target.value;
		// newQuery: string //
		if (newQuery !== this.pdfQuery) {
			this.pdfQuery = newQuery;
			this.pdfComponent.pdfFindController.executeCommand('find', {
				query: this.pdfQuery,
				highlightAll: true
			});
		} else {
			this.pdfComponent.pdfFindController.executeCommand('findagain', {
				query: this.pdfQuery,
				highlightAll: true
			});
		}
	}

	// Event handler when new PDF file is selected

	callBackFn(event) {
		// console.log('callBackFn', event);
		// Setting total number of pages
		this.totalPages = event._pdfInfo.numPages
	}
	pageRendered(event) {
		// console.log('pageRendered', event);
	}
	textLayerRendered(event) {
		// console.log('textLayerRendered', event);
	}
	onError(event) {
		console.error('onError', event);
	}
	onProgress(event) {
		// console.log('onProgress', event);
	}

	plusZoom() {
		this.zoom++;
	}

	minusZoom() {
		if (this.zoom > 1) {
			this.zoom--;
		}
	}

	print() {
		var data = 'data:' + this.documentoView.mediaType + ';base64,' + this.documentoView.blob;
		const newTab = window.open();
		if (newTab) {
			newTab.document.write(`
				<html>
				<body style="margin:0;">
					<embed src="${data}" type="${this.documentoView.mediaType}" width="100%" height="100%" />
					<script>
					// Esegue la stampa del documento dopo che la nuova scheda è stata caricata
					window.onload = function() {
						window.print();
					};
					</script>
				</body>
				</html>
			`);
			newTab.document.close(); // Necessario per completare il caricamento del documento
		}
	}

	async previewFilePage() {
		if (typeof FileReader !== 'undefined') {
			const reader = new FileReader();
			reader.onload = async (e: any) => {
				let byte = e.target.result;
				const blob = new Blob([byte], { type: 'application/pdf' });
				const fileUrl = URL.createObjectURL(blob); // Set source.
				// Apri una nuova scheda con l'URL del blob
				const newWindow = window.open();
				if (newWindow) {
					newWindow.document.write(`
						<html>
							<body style="margin:0;">
								<iframe src="${fileUrl}" type="${blob.type}" width="100%" height="100%" style="border:none;"></iframe>
								<script>
									document.getElementById("title").innerHTML = "${fileUrl}";
								</script>
							</body>
						</html>
					`);
					newWindow.document.close();
				}
			};
			const isAnteprimaDocumento = this._sharedService.isAnteprimaDocumentoCartellaMonitorata();
			let file = isAnteprimaDocumento ? this.filePreviewCartellaMonitorata : this.filePreviewSelected;
			if(this.documentoView) {
				var data = 'data:' + this.documentoView.mediaType + ';base64,' + this.documentoView.blob;
				let newTab = window.open('about:blank', this.documentoView.nomeFile);
				if (newTab) {
					newTab.document.write(`
					<html>
						<head>
							<title>${this.documentoView.nomeFile}</title>
						</head>
						<body style="margin:0;">
							<iframe src="${data}" width="100%" height="100%" style="border:none;"></iframe>	
							<script type="text/javascript">
								window.addEventListener('load', function() {
									$('#viewer').$['toolbar'].$['title'].textContent = "${this.documentoView.nomeFile}";
									$('#viewer').$['toolbar'].$['title'].innerText = "${this.documentoView.nomeFile}";
									$('#viewer').$['toolbar'].$['title'].innerHTML = "${this.documentoView.nomeFile}";
								});
							</script>
						</body>
					</html>
					`);
					newTab.document.close();
				}
			} else {
				reader.readAsArrayBuffer(file);
			}
		}
	}

	async onConfirmPopup() {
		this.visibleDialog = false;
		// switch(this.process) {
		//   case 'Salva':
		// 	this.updateModello = false;
		// 	await this.filterBrowse();
		// 	break;
		//   case 'Cancella':
		// 	await this.filterBrowse();
		// 	break;
		//   case 'ChangeActiveModello':
		// 	this.visibleDialog = false;
		// 	await this.salvaModelloDocumentale();
		// 	this.isModelloActiveChange = false;
		// 	this.updateModello = false;
		// 	await this.filterBrowse();
		// 	break;
		//   default:
		// 	break;
		// }
		// this.process = '';
	}

	onClosePopup() {
		this.visibleDialog = false;
		// this.isModelloActiveChange = false;
		// if (this.process === 'ChangeActiveModello') {
		//   this.formGroup.get('attivo').setValue(!this.formGroup.get('attivo').value);
		// }
		// this.process = '';
	}
}
