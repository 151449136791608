import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Campo, ModelloDocumentaleCustom, Scenario, TipologiaDizionario, Dizionario } from '../model/scenari';
import { FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { tipiAtti, tipiSpese } from '../model/spesa';
import { DocumentoKeyControl, ModelloDocumentaleKeys, SezioniDocument, TipoChiave, TipoEntita } from '../model/documento';
import { TipoCampoPipe } from '../pipe/tipo-entita-pipe';
import { MessageService } from 'primeng/api';


export enum IndiceTab {
  CUP, 
  QE, 
  Esecuzione, 
  Documenti, 
  Fonte, 
  Promemoria, 
  Annotazioni
}

export interface responseDictionary{
  success: boolean;
  dtos: Dizionario[];
}

@Injectable({
  providedIn: 'root'
})
export class ScenarioService {
  BASE_URL = ''
  TIPOLOGICA_URL = 'api/Tipologica'

  datiModelloDocumentale: ModelloDocumentaleCustom;

  tipiAtti: tipiAtti[] = [
    { atto: 'Determina' },
    { atto: 'Delibera di G.C.' }
  ];

  sezioniSpesa = [
    { descrizione: 'Spesa'},
    { descrizione: 'Giuistificativo'},
    { descrizione: 'Liquidazione'},
    { descrizione: 'Mandato'},
    { descrizione: 'Quitanza'}
  ];

  sezioniFonte = [
    { descrizione: 'Fonte di finaziamento'},
    { descrizione: 'Registra incasso'},
    { descrizione: 'Sostituisci fonte'},
    { descrizione: 'Documento Fonti'}
  ];


  constructor(
    @Inject('BASE_URL') baseUrl: string,
    private http: HttpClient,
    private messageService: MessageService
  ) {
    this.BASE_URL = baseUrl
    this.TIPOLOGICA_URL = baseUrl + this.TIPOLOGICA_URL
  }

  /**
   * Mostra la modale per i messaggi di errore
   */
 public showErrorMessage(message: string){
    this.messageService.add({
      severity: 'error',
      summary: 'Attenzione',
      detail: message
    });
  }

  /**
   * Mostra la modale per i messaggi di success
   */
  public showSuccessMessage(message: string){
    this.messageService.add({
      severity: 'success',
      summary: 'Successo',
      detail: message
    });
  }

  /**
   * API a cui passo il campo nello scenario e mi restituisce il dizionario
   */
  getAllDescrizioniDizionario(campo?: TipologiaDizionario): Observable<responseDictionary> {
    /* campo può essere uguale a zero */
    return campo !== undefined ? 
     this.http.get<responseDictionary>(`${this.TIPOLOGICA_URL}/GetAll?Tipo=${campo}`) : 
      this.http.get<responseDictionary>(`${this.TIPOLOGICA_URL}/GetAll`);
  }
  /**
   * API 
   */
  addOrUpdateDescrizioniDizionario(obj: any): Observable<any> {
    return this.http.post<any>(`${this.TIPOLOGICA_URL}/AddorUpdate`, obj);
  }
  /**
   * API ELIMINAZIONE
   */
  deleteDescrizioniDizionario(id: number){
    return this.http.delete(`${this.TIPOLOGICA_URL}/Delete/${id}`);
  }
  /**
   * API ELIMINAZIONE
   */
  changeNumeroFasiOrdinativoInDizionario(obj: any) {
    return this.http.post(`${this.TIPOLOGICA_URL}/ChangeNumeroFasi`, obj);
  }

  getTipologia(nomeModello, formGroup): TipoEntita {
    switch (nomeModello){
      case 'CUP':{
        return TipoEntita.SchedaCup;
      }
      case 'Ordinativo di spesa': {
        if(formGroup.value.atto === 'Determina'){
          return TipoEntita.Determina;
        }else{
          return TipoEntita.Delibera;
        }
      }
      case 'Giustificativo di spesa': {
        if (formGroup.value.tipo === 'Fattura') {
          return TipoEntita.Fattura;
        } else if (formGroup.value.tipo === 'Ricevuta') {
          return TipoEntita.Ricevuta;
        } else {
          return TipoEntita.CedolinoPaga;
        }
      }
      case 'Liquidazione': {
        return null;
      }
      case 'Mandato': {
        return TipoEntita.Mandato;
      }
      case 'Quietanza': {
        return TipoEntita.Quietanza;
      }
      case 'FonteFinanziamento': {
        return TipoEntita.FonteFinanziamento
      }
      case 'Incassato': {
        return TipoEntita.Incassato;
      }
      default: return null;
      
      // case 'Ordine di servizio': {
      //   return TipoEntita.OrdineDiServizio;
      // }
      // case 'SAL': {
      //   return TipoEntita.SAL;
      // }
      // case 'Corrispondenza': {
      //   if(formGroup.value.tipologia === 'Entrata'){
      //     return TipoEntita.Entrata;
      //   }else{
      //     return TipoEntita.Uscita;
      //   }
      // }
      // case 'Delibera di Giunta': {
      //   return TipoEntita.DeliberaDiGiunta;
      // }
      // case 'Delibera di Consiglio': {
      //   return TipoEntita.DeliberaDiConsiglio;
      // }
      // case 'Determina': {
      //   return TipoEntita.Determina;
      // }
      // case 'Contratto': {
      //   return TipoEntita.Contratto;
      // }
      // case 'Altro': {
      //   return TipoEntita.Altro;
      // }
      // case 'Annotazione': {
      //   return TipoEntita.Annotazione;
      // }
    } 
  }

  getScenarioDaDocumenti(nomeModello: string, entityId?: number): Scenario{
    switch(nomeModello){
      case 'CUP': {
        return Scenario.Cup;
      }
      case 'Quadro Economico': {
        return Scenario.QuadroEconomico;
      }
      case 'Ordinativo di spesa': {
        return Scenario.Spesa;
      }
      case 'Giustificativo di spesa': {
        return Scenario.Giustificativo;
      }
      case 'Liquidazione': {
        return Scenario.Liquidazione;
      }
      case 'Liquidazione': {
        return Scenario.Liquidazione;
      }
      case 'Mandato': {
        return Scenario.Mandato;
      }
      case 'Quietanza': {
        return Scenario.Quietanza;
      }
      case 'Ordine di servizio':
      case 'SAL':  
      case 'Progetto': 
      case 'Corrispondenza': 
      case 'Delibera di Giunta': 
      case 'Delibera di Consiglio': 
      case 'Determina': 
      case 'Contratto': 
      case 'Altro': {
        return Scenario.Documento
      }
      case 'FonteFinanziamento': {
        if(entityId === 0) {
          return Scenario.Documento
        } else {
          return Scenario.Fonte;
        }
      }
      case 'Incassato': {
        if(entityId === 0) {
          return Scenario.Documento
        } else {
          return Scenario.Incasso;
        }
      }
      case 'Annotazione': {
        return Scenario.Annotazione;
      }
      default:
         return Scenario.Documento;      
    }
  }

  getMessage(nomeModello: string, condition?: boolean): string {
    switch (nomeModello) {
      case 'Liquidazione': {
        return 'Non ci sono fatture da liquidare';
      };
      case 'Mandato': {
        return 'Non ci sono liquidazioni a cui far riferimento';
      }
      case 'Quietanza': {
        if (condition) {
          return 'Non ci sono mandati a cui associare una nuova quietanza';
        } else {
          return 'Non ci sono madati a cui far riferimento';
        }
      }
      default: return null;
    }
  }


  getModelloDocumentaleCustom(scenario: Scenario, modelli?: any): any {
    switch (scenario) {
      case Scenario.ModelloDocumentaleStart: {
        return this.createModelloDocumentaleCustomStart(modelli);
      }
      case Scenario.ModelloDocumentale: {
        return this.createModelloDocumentaleCustom(modelli);
      }
      case Scenario.Cup: {
        return this.createModelloDocumentaleCustomCUP();
      }
      case Scenario.Spesa: {
        return this.createModelloDocumentaleCustomSpesa();
      }
      case Scenario.Giustificativo: {
        return this.createModelloDocumentaleCustomGiustificativo();
      }
      case Scenario.Liquidazione: {
        return this.createModelloDocumentaleCustomLiquidazione();
      }
      case Scenario.Mandato: {
        return this.createModelloDocumentaleCustomMandato();
      }
      case Scenario.Quietanza: {
        return this.createModelloDocumentaleCustomQuietanza();
      }
      case Scenario.Annotazione: {
        return this.createModelloDocumentaleCustomAnnotazione();
      }
      case Scenario.Documento: {
        return this.createModelloDocumentaleCustom(modelli);
      }
      case Scenario.Fonte: {
        return this.createModelloDocumentaleCustomFonte();
      }
      case Scenario.Incasso: {
        return this.createModelloDocumentaleCustomIncasso();
      }
      case Scenario.QuadroEconomico: {
        return this.createModelloDocumentaleCustomQuadroEconomico();
      }
      case Scenario.DocumentiDaArchiviareBrowse: {
        return  this.createDocumentiDaArchiviare();
      }
      case Scenario.Anteprima: {
        return this.creaAnteprima();
      }
      default: {
        break;
      }
    }
  }


  private createCampo(key: string, label: string, tipo: any, lista: any[], optionLabel: string, optionValue: string, dimensioneBasic: number,
    hasAction?: boolean, readonly?: boolean, errorMessage?: string, functionName?: string, placeholder?: string): Campo {
    const campo = new Campo();
    campo.key = key;
    campo.label = label;
    campo.tipo = tipo;
    campo.lista = lista || [];
    campo.optionLabel = optionLabel || '';
    campo.optionValue = optionValue || '';
    campo.dimensioneBasic = dimensioneBasic;
    campo.hasAction = !!hasAction;
    campo.readonly = !!readonly;
    campo.errorMessage = errorMessage || '';
    campo.placeholder = placeholder || '';
    
    campo.functionName = functionName || '';
    return campo;
}

private createModelloDocumentaleCustomStart(sezioni: SezioniDocument[]){
  const datiModelloDocumentale = new ModelloDocumentaleCustom();
  datiModelloDocumentale.isFrom = Scenario.Documento;
  datiModelloDocumentale.DatiForm = [];
  
  datiModelloDocumentale.DatiForm.push(
    // this.createCampo('sezione', 'Sezione', 'lista', sezioni, 'nome', 'id', 12, true, false, '', '', 'Seleziona sezione'),
    this.createCampo('nomeModelloDocumentale', 'Modello Documentale', 'lista', [], 'nome', 'nome', 12, false, false, '', '', 'Seleziona modello'),
  );
  return datiModelloDocumentale;
}

private createModelloDocumentaleCustom(keysModelloDocumentale: ModelloDocumentaleKeys) {
  const datiModelloDocumentale = new ModelloDocumentaleCustom();
  datiModelloDocumentale.isFrom = Scenario.Documento;
  datiModelloDocumentale.DatiForm = [];
  datiModelloDocumentale.DatiForm = this.creazioneModelloDocumentaleCustom(keysModelloDocumentale);
  return datiModelloDocumentale;
}

creazioneModelloDocumentaleCustom(keysModelloDocumentale: ModelloDocumentaleKeys){
  const campi: Campo[] = [];
  let campo1 = undefined;
  for(let i = 1; i <= 10; i++){
    const key_Value = `key${i}_Value`;
    const keyEtichetta = `key${i}_Etichetta`;
    const keyTipo = `key${i}_Tipo`;
    const keyCustomValues = `key${i}_CustomValues`;
    const keyObbligatoria = `key${i}_Obbligatoria`;
    const keyActive = `key${i}_Attivo`;

    if(keysModelloDocumentale[keyActive]) {
      const campo = this.createCampo(
        key_Value,
        keysModelloDocumentale[keyEtichetta], 
        new TipoCampoPipe().transform(keysModelloDocumentale[keyTipo]),
        this.createObjDropdownModelliDocumentali(keysModelloDocumentale[keyCustomValues]),
        '',
        '',
        12, 
        false,
        false, 
        keysModelloDocumentale[keyObbligatoria] ? 'Campo Obbligatorio' : '', 
        '', 
        keysModelloDocumentale[keyTipo] === TipoChiave.Lista ? 'Seleziona dalla lista' : '');
        if(key_Value !== 'key1_Value') {
          campi.push(campo);
          if(campo1) {
            campi.push(campo1);
            campo1 = undefined;
          }
        } else {
          campo1 = campo;
        }
    }
  }
  return campi;
}

private createObjDropdownModelliDocumentali(valori: string){
  let array = [];
  let arrayObj = [];
  if(valori) {
    array = JSON.parse(valori);
  } 
  for(let a of array){
    arrayObj.push({label:a, value:a});
  }
  return arrayObj;
}


private createModelloDocumentaleCustomCUP(): ModelloDocumentaleCustom {
    const datiModelloDocumentale = new ModelloDocumentaleCustom();
    datiModelloDocumentale.isFrom = Scenario.Cup;
    datiModelloDocumentale.DatiForm = [];

    datiModelloDocumentale.DatiForm.push(
        this.createCampo('codice', 'CUP', 'text', [], '', '', 12, true, false, 'Inserisci un codice valido'),
        this.createCampo('titolo', 'Titolo', 'text', [], '', '', 12, false, false, 'Inserisci un titolo'),
        this.createCampo('dataCup', 'Del', 'date',[], '', '', 12, false, false, 'Inserisci una data'),
        this.createCampo('indirizzo', 'Indirizzo', 'text', [], '', '', 12, false,  false, 'Inserisci un indirizzo'),
        this.createCampo('importo', 'Importo €', 'currency', [], '', '', 12, false, false, 'Inserisci un importo'),
        this.createCampo('descrizione', 'Descrizione', 'text',[], '', '',  12, false,  false, 'Inserisci una descrizione'),
        this.createCampo('stato', 'Stato', 'lista', [], '', '', 12, false, false, 'Seleziona uno stato','','Seleziona uno stato')
    );

    return datiModelloDocumentale;
}



private createModelloDocumentaleCustomSpesa(): ModelloDocumentaleCustom {
    const datiModelloDocumentale = new ModelloDocumentaleCustom();
    datiModelloDocumentale.isFrom = Scenario.Spesa;
    datiModelloDocumentale.DatiForm = [];

    datiModelloDocumentale.DatiForm.push(
        this.createCampo('cig', 'CIG', 'text',[], '', '', 12, true,  false, 'Inserisci un codice valido'),
        this.createCampo('tipo', 'Tipo', 'lista',[], 'descrizione', 'descrizione', 12, false, false, 'Seleziona un tipo', '', 'Seleziona un tipo'),
        this.createCampo('atto', 'Atto', 'lista',[], 'descrizione', 'descrizione', 12, false, false, 'Seleziona un atto', '', 'Seleziona un atto'),
        this.createCampo('numero', 'Numero', 'number',[], '', '', 12, false, false, "Inserisci il numero dell'atto"),
        this.createCampo('data', 'Del', 'date',[], '', '', 12, false, false, 'Inserisci una data'),
        this.createCampo('descrizione', 'Descrizione', 'textarea',[], '', '', 12, false, false, 'Inserisci una descrizione'),
        this.createCampo('beneficiario', 'Beneficiario', 'text',[], '', '', 6, false, false, 'Inserisci un beneficiario'),
        this.createCampo('pIvaCodiceFiscale', 'P.I./C.F.', 'text',[], '', '', 6, true, false, 'Inserisci una P.Iva o C.Fiscale'),
        this.createCampo('importoNetto', 'Importo Netto €', 'currency',[], '', '', 6, false, false, 'Inserisci un importo'),
        this.createCampo('qeItemImportoNettoCompositeId', 'Riferimento Importo Netto', 'lista',[], 'title', 'compositeId', 6, false, false, 'Seleziona un riferimento', '', 'Seleziona riferimento importo'),
        this.createCampo('importoCassa', 'Cassa €', 'currency',[], '', '', 6, false, false),
        this.createCampo('qeItemCassaCompositeId', 'Riferimento Cassa', 'lista',[], 'title', 'compositeId', 6, false, false, 'Seleziona un riferimento', '', 'Seleziona riferimento Cassa'),
        this.createCampo('importoIVA', 'IVA €', 'currency',[], '', '', 6, false, false, 'Inserisci un importo'),
        this.createCampo('qeItemIVACompositeId', 'Riferimento IVA', 'lista',[], 'title', 'compositeId', 6, false, false, 'Seleziona un riferimento', '', 'Seleziona riferimento IVA'),
        this.createCampo('importoTotale', 'Totale €', 'currency',[], '', '', 12, false, true)
    );

    return datiModelloDocumentale;
}

  /**
   * Inizializza le associazioni Key/Value in base al modello specifico
   */
  getDocKeyValueByModello(scenario: Scenario, formGroup: FormGroup, tipologia: string, nomeModello: string, beneficiario?: string, pIva_cFiscaleString?: string, riferimentoFase?: string) {
    if(scenario === Scenario.Documento) {
      if(formGroup.controls['key1_Value']) {
        return {
          id: 0,
          key1_Value: formGroup.controls['key1_Value'].value,
          key2_Value: formGroup.controls['key2_Value'].value,
          key3_Value: formGroup.controls['key3_Value'].value,
          key4_Value: formGroup.controls['key4_Value'].value,
          key5_Value: formGroup.controls['key5_Value'].value,
          key6_Value: formGroup.controls['key6_Value'].value,
          key7_Value: formGroup.controls['key7_Value'].value,
          key8_Value: formGroup.controls['key8_Value'].value,
          key9_Value: formGroup.controls['key9_Value'].value,
          key10_Value: formGroup.controls['key10_Value'].value,
        }
      }
    }
    const isNomeModelloCaricaDaBrowse = 
      nomeModello === 'Ordine di servizio' ||
      nomeModello === 'SAL' ||
      nomeModello === 'Progetto' ||
      nomeModello === 'Corrispondenza' ||
      nomeModello === 'Delibera di Giunta' ||
      nomeModello === 'Delibera di Consiglio' ||
      nomeModello === 'Determina' ||
      nomeModello === 'Contratto' ||
      nomeModello === 'Altro' ||
      nomeModello === 'FonteFinanziamento' ||
      nomeModello === 'Incassato';
      const keyControls = this.getKeyValueForDoc(scenario, formGroup);
    return {
      id: 0,
      key1_Value: scenario === Scenario.Cup ? (formGroup.controls['titolo']?.value ? formGroup.controls['titolo']?.value : '') : (formGroup.controls['atto']?.value ?  formGroup.controls['atto']?.value : ''),
      key2_Value: formGroup.controls['data'] ? formGroup.controls['data'].value : formGroup.controls['del'] ? formGroup.controls['del'].value : formGroup.controls['dataCup'] ? formGroup.controls['dataCup'].value : new Date(),
      key3_Value: (scenario === Scenario.Cup ? formGroup.controls['codice']?.value.toString() : ((formGroup.controls['numero'] && formGroup.controls['numero']?.value.toString()) ? formGroup.controls['numero']?.value.toString() : null) ),
      key4_Value: ((scenario === Scenario.Fonte || scenario === Scenario.Incasso) ? (formGroup.controls['atto']?.value ? formGroup.controls['atto'].value : '') : (formGroup.controls['descrizione']?.value ? formGroup.controls['descrizione'].value : '')),
      key5_Value: ((scenario === Scenario.Fonte || scenario === Scenario.Incasso) ? (formGroup.controls['descrizione']?.value ? formGroup.controls['descrizione'].value : '') : (tipologia ? tipologia : '')),
      key6_Value: (scenario === Scenario.Fonte) ? formGroup.controls['importoFinanziamento'].value.toString() : (scenario === Scenario.Incasso) ? formGroup.controls['importoIncassato'].value.toString() : beneficiario ? beneficiario : formGroup.controls['beneficiario']?.value ? formGroup.controls['beneficiario'].value : null,
      key7_Value: ((scenario === Scenario.Fonte) || (scenario === Scenario.Incasso)) ? (formGroup.controls['ente']?.value ? formGroup.controls['ente']?.value : '') : pIva_cFiscaleString ? pIva_cFiscaleString : formGroup.controls['pIvaCodiceFiscale']?.value ? formGroup.controls['pIvaCodiceFiscale'].value : null,
      key8_Value: formGroup.controls['nota'] ? formGroup.controls['nota'].value : (formGroup.controls['note'] ? formGroup.controls['note'].value : null),
      key9_Value: null,
      key10_Value: null,
    };
  }

  getKeyValueForDoc(scenario: Scenario, formGroup: FormGroup) {
    let keyControlValue: DocumentoKeyControl;
    switch (scenario) {
      case Scenario.Cup:
        keyControlValue = {
          key1_Control: 'codice',
          key2_Control: 'data',
          key3_Control: 'titolo',
          key4_Control: 'descrizione',
        }
        break;
      case Scenario.QuadroEconomico:
        keyControlValue = {
          key1_Control: 'tipo',
          key2_Control: 'data',
          key3_Control: 'atto',
          key4_Control: 'numero',
          key5_Control: 'descrizione',
        }
        break;
      case Scenario.Spesa:
        keyControlValue = {
          key1_Control: 'tipo',
          key2_Control: 'data',
          key3_Control: 'cig',
          key4_Control: 'atto',
          key5_Control: 'numero',
          key6_Control: 'descrizione',
          key7_Control: 'beneficiario',
          key8_Control: 'pIvaCodiceFiscale',
        }
        break;
      case Scenario.Giustificativo:
        keyControlValue = {
          key1_Control: 'tipo',
          key2_Control: 'data',
          key3_Control: 'numero',
          key4_Control: 'descrizione',
          key5_Control: 'beneficiario',
          key6_Control: 'pIvaCodiceFiscale',
        }
        break;
      case Scenario.Liquidazione:
        keyControlValue = {
          key1_Control: 'descrizione',
          key2_Control: 'data',
          key3_Control: 'numero',
          key4_Control: 'beneficiario',
          key5_Control: 'pIvaCodiceFiscale',
        }
        break;
      case Scenario.Mandato:
        keyControlValue = {
          key1_Control: 'numero',
          key2_Control: 'data',
          key3_Control: 'descrizione',
          key4_Control: 'beneficiario',
          key5_Control: 'pIvaCodiceFiscale',
        }
        break;
      case Scenario.Quietanza:
        keyControlValue = {
          key1_Control: 'riferimento',
          key2_Control: 'data',
        }
        break;
      case Scenario.Fonte:
        keyControlValue = {
          key1_Control: 'ente',
          key2_Control: 'data',
          key3_Control: 'atto',
          key4_Control: 'numero',
          key5_Control: 'descrizione',
        }
        break;
      case Scenario.Incasso:
        keyControlValue = {
          key1_Control: 'ente',
          key2_Control: 'data',
          key3_Control: 'atto',
          key4_Control: 'numero',
          key5_Control: 'descrizione',
        }
        break;
      case Scenario.Annotazione:
        keyControlValue = {
          key1_Control: 'descrizione',
          key2_Control: 'data',
          key3_Control: 'atto',
          key4_Control: 'nota',
        }
        break;
    }
    return keyControlValue;
  }


  getFormGroup(scenario: Scenario, key?: any, keysValue?: ModelloDocumentaleKeys) {
    let formGroup: FormGroup;
    switch (scenario) {
      case Scenario.ModelloDocumentaleStart: 
      formGroup =  new FormGroup(
        {
          nomeModelloDocumentale: new FormControl('')
        });
      break;
      case Scenario.ModelloDocumentale: 
      formGroup =  new FormGroup(
        {
          id: new FormControl(''),
          nomeModello: new FormControl(''),
          key2_Value: new FormControl('', keysValue?.key2_Obbligatoria ? Validators.required : null),
          key1_Value: new FormControl('', keysValue?.key1_Obbligatoria ? Validators.required : null),
          key3_Value: new FormControl('', keysValue?.key3_Obbligatoria ? Validators.required : null),
          key4_Value: new FormControl('', keysValue?.key4_Obbligatoria ? Validators.required : null),
          key5_Value: new FormControl('', keysValue?.key5_Obbligatoria ? Validators.required : null),
          key6_Value: new FormControl('', keysValue?.key6_Obbligatoria ? Validators.required : null),
          key7_Value: new FormControl('', keysValue?.key7_Obbligatoria ? Validators.required : null),
          key8_Value: new FormControl('', keysValue?.key8_Obbligatoria ? Validators.required : null),
          key9_Value: new FormControl('', keysValue?.key9_Obbligatoria ? Validators.required : null),
          key10_Value: new FormControl('', keysValue?.key10_Obbligatoria ? Validators.required : null),
        });
      break;
      case Scenario.DocumentiDaArchiviareBrowse: 
      formGroup =  new FormGroup(
        {
          sezione: new FormControl(''),
          sottoSezioneQE: new FormControl(''),
          sottoSezioneSpesa: new FormControl(''), 
          sottoSezioneFonte: new FormControl(''), 
          cup: new FormControl(''), 
          spese: new FormControl(''),
          gistificativo: new FormControl(''), 
          liquidazione: new FormControl(''), 
          mandato: new FormControl(''), 
          quitanza: new FormControl(''), 
          fonti: new FormControl('')
      });
      break;
      case Scenario.Cup: 
        formGroup = new FormGroup({
          id: new FormControl(null),
          codice: new FormControl('', [
            Validators.required,
            Validators.maxLength(15),
            Validators.minLength(15),
            Validators.pattern('[A-Z][0-9]{2,2}[A-Z][0-9]*'),
          ]),
          titolo: new FormControl('', Validators.required),
          dataCup: new FormControl('', Validators.required),
          descrizione: new FormControl('', Validators.required),
          importo: new FormControl(null, Validators.required),
          indirizzo: new FormControl('', Validators.required),
          stato: new FormControl('', Validators.required),
          allegato: new FormControl(null)
        });
      break;
      case Scenario.Spesa: 
        formGroup = new FormGroup({
          cig: new FormControl('', [Validators.minLength(10), Validators.maxLength(10),]),
          cupId: new FormControl(),
          id: new FormControl(0),
          tipo: new FormControl('', Validators.required),
          atto: new FormControl('', Validators.required),
          numero: new FormControl('', Validators.required),
          beneficiario: new FormControl('', Validators.required),
          data: new FormControl('', Validators.required),
          descrizione: new FormControl('', Validators.required),
          importoNetto: new FormControl(0, Validators.required),
          importoIVA: new FormControl(0, Validators.required),
          importoCassa: new FormControl(0),
          pIvaCodiceFiscale: new FormControl(null, [this.cf_piPatternValidator(/^([0-9]){11}$/, /^[A-Z]{6}[0-9]{2}[A-Z][0-9]{2}[A-Z][0-9]{3}[A-Z]$/),]),
          qeItemImportoNettoCompositeId: new FormControl(null, Validators.required),
          qeItemImportoNettoIndex: new FormControl(),
          qeItemImportoNettoSubIndex: new FormControl(),
          qeItemImportoNettoSectionIndex: new FormControl(),
          qeItemImportoNettoSectionQEMainId: new FormControl(),
          qeItemIVACompositeId: new FormControl(null, Validators.required),
          qeItemIVAIndex: new FormControl(),
          qeItemIVASubIndex: new FormControl(),
          qeItemIVASectionIndex: new FormControl(),
          qeItemIVASectionQEMainId: new FormControl(),
          qeItemCassaCompositeId: new FormControl(),
          qeItemCassaIndex: new FormControl(),
          qeItemCassaSubIndex: new FormControl(),
          qeItemCassaSectionIndex: new FormControl(),
          qeItemCassaSectionQEMainId: new FormControl(),
          importoTotale: new FormControl(0),
        });
      break;
      case Scenario.Giustificativo: 
        formGroup = new FormGroup({
          id: new FormControl(0),
          fase1Id: new FormControl(),
          tipo: new FormControl<tipiSpese | null>(null, Validators.required),
          numero: new FormControl(null, Validators.required),
          data: new FormControl<Date | null>(null, Validators.required),
          descrizione: new FormControl(null),
          importoNetto: new FormControl(0, Validators.required),
          iva: new FormControl(0, Validators.required),
          cassa: new FormControl(0),
          beneficiario: new FormControl(null, Validators.required),
          pIvaCodiceFiscale: new FormControl(null, [
            this.cf_piPatternValidator(
              /^([0-9]){11}$/,
              /^[A-Z]{6}[0-9]{2}[A-Z][0-9]{2}[A-Z][0-9]{3}[A-Z]$/
            ),
          ]),
          totale: new FormControl(0),
        });
        break;
        case Scenario.Liquidazione: 
        formGroup = new FormGroup({
          id: new FormControl(),
          fase2Id: new FormControl(Validators.required),
          numero: new FormControl(null, Validators.required),
          data: new FormControl<Date | null>(null, Validators.required),
          importoNetto: new FormControl(0, Validators.required),
          iva: new FormControl(0, Validators.required),
          cassa: new FormControl(0),
          totale: new FormControl(0),
        });
        break;
        case Scenario.Mandato: 
        formGroup = new FormGroup({
          id: new FormControl(),
          fase3Id: new FormControl(null, Validators.required),
          descrizione: new FormControl(null, Validators.required),
          numero: new FormControl(null, Validators.required),
          data: new FormControl<Date | null>(null, Validators.required),
          importo: new FormControl(null, Validators.required),
        });
      break;
      case Scenario.Quietanza:
            formGroup = new FormGroup({
              id: new FormControl(),
              fase4Id: new FormControl(Validators.required),
              attachment: new FormControl(null),
            });
      break;
      case Scenario.Annotazione:
            formGroup = new FormGroup({
              id: new FormControl(),
              cupId: new FormControl(),
              descrizione: new FormControl('', Validators.required),
              data: new FormControl<Date>(null, Validators.required),
              nota: new FormControl('', Validators.required),
              allegato: new FormControl(null),
            });
      break;
      case Scenario.Documento:
        formGroup = new FormGroup({
          key2_Value: new FormControl(new Date(key?.key2_Value) || '', keysValue.key2_Obbligatoria ? Validators.required : null),
          key1_Value: new FormControl(keysValue.key1_Tipo === TipoChiave.Data  ? new Date(key?.key1_Value) || '' : key?.key1_Value || '', keysValue.key1_Obbligatoria ? Validators.required : null),
          key3_Value: new FormControl(keysValue.key3_Tipo === TipoChiave.Data  ? new Date(key?.key3_Value) || '' : key?.key3_Value || '', keysValue.key3_Obbligatoria ? Validators.required : null),
          key4_Value: new FormControl(keysValue.key4_Tipo === TipoChiave.Data  ? new Date(key?.key4_Value) || '' : key?.key4_Value || '', keysValue.key4_Obbligatoria ? Validators.required : null),
          key5_Value: new FormControl(keysValue.key5_Tipo === TipoChiave.Data  ? new Date(key?.key5_Value) || '' : key?.key5_Value || '', keysValue.key5_Obbligatoria ? Validators.required : null),
          key6_Value: new FormControl(keysValue.key6_Tipo === TipoChiave.Data  ? new Date(key?.key6_Value) || '' : key?.key6_Value || '', keysValue.key6_Obbligatoria ? Validators.required : null),
          key7_Value: new FormControl(keysValue.key7_Tipo === TipoChiave.Data  ? new Date(key?.key7_Value) || '' : key?.key7_Value || '', keysValue.key7_Obbligatoria ? Validators.required : null),
          key8_Value: new FormControl(keysValue.key8_Tipo === TipoChiave.Data  ? new Date(key?.key8_Value) || '' : key?.key8_Value || '', keysValue.key8_Obbligatoria ? Validators.required : null),
          key9_Value: new FormControl(keysValue.key9_Tipo === TipoChiave.Data ? new Date(key?.key9_Value) || '' : key?.key9_Value || '', keysValue.key9_Obbligatoria ? Validators.required : null),
          key10_Value: new FormControl(keysValue.key10_Tipo === TipoChiave.Data  ? new Date(key?.key10_Value) || '' : key?.key10_Value || '', keysValue.key10_Obbligatoria ? Validators.required : null),
        });
       break;
       case Scenario.Fonte:
        formGroup = new FormGroup({
          ente: new FormControl("", Validators.required),
          atto: new FormControl("", Validators.required),
          numero: new FormControl("", Validators.required),
          del: new FormControl<Date | null>(null, Validators.required),
          descrizione: new FormControl("", Validators.required),
          importoFinanziamento: new FormControl(null, Validators.required),
        });
        break;
        case Scenario.Incasso:
          formGroup = new FormGroup({
          ente: new FormControl(''),
          atto: new FormControl('', Validators.required),
          numero: new FormControl('', Validators.required),
          del: new FormControl<Date | null>(null, Validators.required),
          descrizione: new FormControl('', Validators.required),
          importoIncassato: new FormControl(null, Validators.required),
          fonteFinanziamentoID: new FormControl(),
        });
       break;
       case Scenario.QuadroEconomico:
        formGroup = new FormGroup({
          cupId: new FormControl(0),
          id: new FormControl(0),
          qeMainId: new FormControl(0),
          tipo: new FormControl('', Validators.required),
          atto: new FormControl('', Validators.required),
          numero: new FormControl(null, Validators.required),
          data: new FormControl(undefined, Validators.required),
          descrizione: new FormControl('', Validators.required),
          allegato: new FormControl(false),
        });
        break;
      case Scenario.Anteprima:
        formGroup = new FormGroup({});
        break; 
      default: 
        break;
    }
    return formGroup;
  }

  createDocumentiDaArchiviare(): any {
    const modelloDocumentale = new ModelloDocumentaleCustom();
    modelloDocumentale.isFrom = Scenario.DocumentiDaArchiviareBrowse;
    modelloDocumentale.DatiForm = [];
    modelloDocumentale.DatiFormDocumento = [];

    const cup = new Campo();
    cup.key = 'cup';
    cup.label = 'Cup';
    // cup.tipo = 'autocomplete';
    cup.tipo = 'lista';
    cup.lista = [];
    cup.listaAutoComplete = [];
    cup.optionLabel = 'descrizione';
    cup.optionValue = 'idCup';
    cup.errorMessage = 'Seleziona un Cup';
    cup.placeholder = 'Seleziona un Cup';
    cup.visible = false;
    modelloDocumentale.DatiForm.push(cup);

    const sezione = new Campo();
    sezione.key = 'sezione';
    sezione.label = 'Sezione';
    sezione.tipo = 'lista';
    sezione.lista = [];
    sezione.optionLabel = 'descrizione';
    sezione.optionValue = 'descrizione';
    sezione.errorMessage = 'Seleziona sezione';
    sezione.placeholder = 'Seleziona sezione';
    sezione.visible = true;
    modelloDocumentale.DatiForm.push(sezione);

    const sottoSezioneSpesa = new Campo();
    sottoSezioneSpesa.key = 'sottoSezioneSpesa';
    sottoSezioneSpesa.tipo = 'lista';
    sottoSezioneSpesa.label = 'Tipo Spesa da aggiungere';
    sottoSezioneSpesa.lista = this.sezioniSpesa;
    sottoSezioneSpesa.optionLabel = 'descrizione';
    sottoSezioneSpesa.optionValue = 'descrizione';
    sottoSezioneSpesa.errorMessage = 'Seleziona qualcosa';
    sottoSezioneSpesa.placeholder = 'Seleziona qualcosa';
    sottoSezioneSpesa.visible = false;
    modelloDocumentale.DatiForm.push(sottoSezioneSpesa);

    const sottoSezioneFonte = new Campo();
    sottoSezioneFonte.key = 'sottoSezioneFonte';
    sottoSezioneFonte.label = 'Tipo Fonte da aggiungere';
    sottoSezioneFonte.tipo = 'lista';
    sottoSezioneFonte.lista = this.sezioniFonte;
    sottoSezioneFonte.optionLabel = 'descrizione';
    sottoSezioneFonte.optionValue = 'descrizione';
    sottoSezioneFonte.errorMessage = 'Seleziona qualcosa';
    sottoSezioneFonte.placeholder = 'Seleziona qualcosa';
    sottoSezioneFonte.visible = false;
    modelloDocumentale.DatiForm.push(sottoSezioneFonte);

    const spese = new Campo();
    spese.key = 'spese';
    spese.label = 'Spese';
    spese.tipo = 'lista';
    spese.lista = [];
    spese.listaAutoComplete = [];
    spese.optionLabel = 'descrizione';
    spese.optionValue = 'idFase1';
    spese.errorMessage = 'Seleziona una Spesa';
    spese.placeholder = 'Seleziona una Spesa';
    spese.visible = false;
    modelloDocumentale.DatiForm.push(spese);

   const fonti = new Campo();
   fonti.key = 'fonti';
   fonti.label = 'Fonti di Finanziamento';
   fonti.tipo = 'lista';
   fonti.lista = [];
   fonti.listaAutoComplete = [];
   fonti.optionLabel = 'descrizione';
   fonti.optionValue = 'idFonte';
   fonti.errorMessage = 'Seleziona una Fonte di Finanziamento';
   fonti.placeholder = 'Seleziona una Fonte di Finanziamento';
   fonti.visible = false;
   modelloDocumentale.DatiForm.push(fonti);

   const btnAggiungi = new Campo();
   btnAggiungi.key = 'btnAggiungi';
   btnAggiungi.tipo = 'button';
   btnAggiungi.visible = false;
   modelloDocumentale.DatiForm.push(btnAggiungi);

   const errore = new Campo();
   errore.key = 'errore';
   errore.visible = false;
   modelloDocumentale.DatiForm.push(errore);
    
    return modelloDocumentale;
  }

  private creaAnteprima() {
    const modelloDocumentale = new ModelloDocumentaleCustom();
    modelloDocumentale.isFrom = Scenario.Anteprima;
    modelloDocumentale.DatiForm = [];
    modelloDocumentale.DatiFormDocumento = [];
    return modelloDocumentale;
  }

  private createModelloDocumentaleCustomGiustificativo(): ModelloDocumentaleCustom {
    this.datiModelloDocumentale = new ModelloDocumentaleCustom();
    this.datiModelloDocumentale.isFrom = Scenario.Giustificativo;
    this.datiModelloDocumentale.DatiForm = [];

    /*  TIPO */
    this.datiModelloDocumentale.DatiForm[0] = new Campo();
    this.datiModelloDocumentale.DatiForm[0].key = 'tipo';
    this.datiModelloDocumentale.DatiForm[0].label = 'Tipo';
    this.datiModelloDocumentale.DatiForm[0].tipo = 'lista';
    this.datiModelloDocumentale.DatiForm[0].optionLabel = 'descrizione';
    this.datiModelloDocumentale.DatiForm[0].optionValue = 'descrizione';
    this.datiModelloDocumentale.DatiForm[0].dimensioneBasic = 12;
    this.datiModelloDocumentale.DatiForm[0].lista = [];
    this.datiModelloDocumentale.DatiForm[0].errorMessage = 'Seleziona un tipo';
    this.datiModelloDocumentale.DatiForm[0].placeholder = 'Seleziona un tipo';

    /* NUMERO */
    this.datiModelloDocumentale.DatiForm[1] = new Campo();
    this.datiModelloDocumentale.DatiForm[1].key = 'numero';
    this.datiModelloDocumentale.DatiForm[1].label = 'Num Atto';
    this.datiModelloDocumentale.DatiForm[1].tipo = 'number';
    this.datiModelloDocumentale.DatiForm[1].dimensioneBasic = 12;
    this.datiModelloDocumentale.DatiForm[1].errorMessage = "Inserisci il numero dell'atto";

    /* DATA */ //dateformat
    this.datiModelloDocumentale.DatiForm[2] = new Campo();
    this.datiModelloDocumentale.DatiForm[2].key = 'data';
    this.datiModelloDocumentale.DatiForm[2].label = 'Del';
    this.datiModelloDocumentale.DatiForm[2].tipo = 'date';
    this.datiModelloDocumentale.DatiForm[2].dimensioneBasic = 12;
    this.datiModelloDocumentale.DatiForm[2].errorMessage = 'Inserisci una data';

    /* ALLEGATO */
    this.datiModelloDocumentale.DatiForm[3] = new Campo();
    this.datiModelloDocumentale.DatiForm[3].tipo = 'allegato';
    this.datiModelloDocumentale.DatiForm[3].dimensioneBasic = 12;

    /* DESCRIZIONE */
    this.datiModelloDocumentale.DatiForm[4] = new Campo();
    this.datiModelloDocumentale.DatiForm[4].key = 'descrizione';
    this.datiModelloDocumentale.DatiForm[4].label = 'Descrizione';
    this.datiModelloDocumentale.DatiForm[4].tipo = 'textarea';
    this.datiModelloDocumentale.DatiForm[4].dimensioneBasic = 12;
    //datiModelloDocumentale.DatiForm[4].errorMessage = 'Inserisci una descrizione';

    /* BENEFICIARIO */
    this.datiModelloDocumentale.DatiForm[5] = new Campo();
    this.datiModelloDocumentale.DatiForm[5].key = 'beneficiario';
    this.datiModelloDocumentale.DatiForm[5].label = 'Beneficiario';
    this.datiModelloDocumentale.DatiForm[5].tipo = 'text';
    this.datiModelloDocumentale.DatiForm[5].dimensioneBasic = 6;
    this.datiModelloDocumentale.DatiForm[5].readonly = true;
    this.datiModelloDocumentale.DatiForm[5].errorMessage = 'Inserisci un beneficiario';
    this.datiModelloDocumentale.DatiForm[5].classeCss = 'disabled';

    /* PARTITA IVA / CODICE FISCALE */
    this.datiModelloDocumentale.DatiForm[6] = new Campo();
    this.datiModelloDocumentale.DatiForm[6].key = 'pIvaCodiceFiscale';
    this.datiModelloDocumentale.DatiForm[6].label = 'P.I./C.F.';
    this.datiModelloDocumentale.DatiForm[6].tipo = 'text';
    this.datiModelloDocumentale.DatiForm[6].dimensioneBasic = 6;
    this.datiModelloDocumentale.DatiForm[6].readonly = true;
    this.datiModelloDocumentale.DatiForm[6].hasAction = true;
    this.datiModelloDocumentale.DatiForm[6].errorMessage = 'Inserisci una P.Iva o C.Fiscale';
    this.datiModelloDocumentale.DatiForm[6].classeCss = 'disabled';

    //this.datiModelloDocumentale.DatiForm[6].onChange() = 'oninput="this.value = this.value.toUpperCase()"';

    /* IMPORTO NETTO */
    this.datiModelloDocumentale.DatiForm[7] = new Campo();
    this.datiModelloDocumentale.DatiForm[7].key = 'importoNetto';
    this.datiModelloDocumentale.DatiForm[7].label = 'Importo Netto €';
    this.datiModelloDocumentale.DatiForm[7].tipo = 'currency';
    this.datiModelloDocumentale.DatiForm[7].dimensioneBasic = 3;
    this.datiModelloDocumentale.DatiForm[7].errorMessage = 'Inserisci un importo';

    /* CASSA */
    this.datiModelloDocumentale.DatiForm[8] = new Campo();
    this.datiModelloDocumentale.DatiForm[8].key = 'cassa';
    this.datiModelloDocumentale.DatiForm[8].label = 'Cassa €';
    this.datiModelloDocumentale.DatiForm[8].tipo = 'currency';
    this.datiModelloDocumentale.DatiForm[8].dimensioneBasic = 3;

    /* IMPORTO IVA */
    this.datiModelloDocumentale.DatiForm[9] = new Campo();
    this.datiModelloDocumentale.DatiForm[9].key = 'iva';
    this.datiModelloDocumentale.DatiForm[9].label = 'IVA €';
    this.datiModelloDocumentale.DatiForm[9].tipo = 'currency';
    this.datiModelloDocumentale.DatiForm[9].dimensioneBasic = 3;
    this.datiModelloDocumentale.DatiForm[9].errorMessage = 'Inserisci un importo';

    /* TOTALE */
    this.datiModelloDocumentale.DatiForm[10] = new Campo();
    this.datiModelloDocumentale.DatiForm[10].key = 'totale';
    this.datiModelloDocumentale.DatiForm[10].label = 'Totale €';
    this.datiModelloDocumentale.DatiForm[10].tipo = 'currency';
    this.datiModelloDocumentale.DatiForm[10].dimensioneBasic = 3;
    this.datiModelloDocumentale.DatiForm[10].readonly = true;

    return this.datiModelloDocumentale;
  }

  private createModelloDocumentaleCustomLiquidazione(isEditable: boolean = true): ModelloDocumentaleCustom {
    this.datiModelloDocumentale = new ModelloDocumentaleCustom();
    this.datiModelloDocumentale.isFrom = Scenario.Liquidazione;

    this.datiModelloDocumentale.DatiForm = [];

    /* NUMERO */
    this.datiModelloDocumentale.DatiForm[0] = new Campo();
    this.datiModelloDocumentale.DatiForm[0].key = 'numero';
    this.datiModelloDocumentale.DatiForm[0].label = 'Determina Num';
    this.datiModelloDocumentale.DatiForm[0].tipo = 'number';
    this.datiModelloDocumentale.DatiForm[0].dimensioneBasic = 12;
    this.datiModelloDocumentale.DatiForm[0].errorMessage = "Inserisci il numero dell'atto";

    /* DATA */ //dateformat
    this.datiModelloDocumentale.DatiForm[1] = new Campo();
    this.datiModelloDocumentale.DatiForm[1].key = 'data';
    this.datiModelloDocumentale.DatiForm[1].label = 'Del';
    this.datiModelloDocumentale.DatiForm[1].tipo = 'date';
    this.datiModelloDocumentale.DatiForm[1].dimensioneBasic = 12;
    this.datiModelloDocumentale.DatiForm[1].errorMessage = 'Inserisci la data';
    
    /* RIFERIMENTO IMPORTO NETTO*/
    this.datiModelloDocumentale.DatiForm[2] = new Campo();
    this.datiModelloDocumentale.DatiForm[2].key = 'fase2Id';
    this.datiModelloDocumentale.DatiForm[2].label = 'Riferimento Giustificativo';
    this.datiModelloDocumentale.DatiForm[2].tipo = 'lista';
    this.datiModelloDocumentale.DatiForm[2].optionLabel = 'text';
    this.datiModelloDocumentale.DatiForm[2].optionValue = 'id';
    this.datiModelloDocumentale.DatiForm[2].lista = [];
    this.datiModelloDocumentale.DatiForm[2].dimensioneBasic = 12;
    this.datiModelloDocumentale.DatiForm[2].errorMessage = 'Seleziona un riferimento';
    this.datiModelloDocumentale.DatiForm[2].placeholder = 'Seleziona un riferimento';

    /* IMPORTO NETTO */
    this.datiModelloDocumentale.DatiForm[3] = new Campo();
    this.datiModelloDocumentale.DatiForm[3].key = 'importoNetto';
    this.datiModelloDocumentale.DatiForm[3].label = 'Importo Netto €';
    this.datiModelloDocumentale.DatiForm[3].tipo = 'currency';
    this.datiModelloDocumentale.DatiForm[3].dimensioneBasic = 12;
    this.datiModelloDocumentale.DatiForm[3].errorMessage = 'Inserisci un importo';
    
    /* CASSA */
    this.datiModelloDocumentale.DatiForm[4] = new Campo();
    this.datiModelloDocumentale.DatiForm[4].key = 'cassa';
    this.datiModelloDocumentale.DatiForm[4].label = 'Importo Cassa €';
    this.datiModelloDocumentale.DatiForm[4].tipo = 'currency';
    this.datiModelloDocumentale.DatiForm[4].dimensioneBasic = 12;
    this.datiModelloDocumentale.DatiForm[4].readonly = false;

    /* IVA */
    this.datiModelloDocumentale.DatiForm[5] = new Campo();
    this.datiModelloDocumentale.DatiForm[5].key = 'iva';
    this.datiModelloDocumentale.DatiForm[5].label = 'Importo Iva €';
    this.datiModelloDocumentale.DatiForm[5].tipo = 'currency';
    this.datiModelloDocumentale.DatiForm[5].dimensioneBasic = 12;
    this.datiModelloDocumentale.DatiForm[5].errorMessage = 'Inserisci un importo';
    
    /* IMPORTO TOTALE */
    this.datiModelloDocumentale.DatiForm[6] = new Campo();
    this.datiModelloDocumentale.DatiForm[6].key = 'totale';
    this.datiModelloDocumentale.DatiForm[6].label = 'Importo Totale €';
    this.datiModelloDocumentale.DatiForm[6].tipo = 'currency';
    this.datiModelloDocumentale.DatiForm[6].dimensioneBasic = 12;
    this.datiModelloDocumentale.DatiForm[6].readonly = true;
    
    return this.datiModelloDocumentale;
  }

  private createModelloDocumentaleCustomMandato(isEditable: boolean = true): ModelloDocumentaleCustom {
    this.datiModelloDocumentale = new ModelloDocumentaleCustom();
    this.datiModelloDocumentale.isFrom = Scenario.Mandato;

    this.datiModelloDocumentale.DatiForm = [];

    /* NUMERO */
    this.datiModelloDocumentale.DatiForm[0] = new Campo();
    this.datiModelloDocumentale.DatiForm[0].key = 'numero';
    this.datiModelloDocumentale.DatiForm[0].label = 'Numero';
    this.datiModelloDocumentale.DatiForm[0].tipo = 'number';
    this.datiModelloDocumentale.DatiForm[0].dimensioneBasic = 12;
    this.datiModelloDocumentale.DatiForm[0].errorMessage = 'Inserisci il numero del mandato';

    /* DATA */ //dateformat
    this.datiModelloDocumentale.DatiForm[1] = new Campo();
    this.datiModelloDocumentale.DatiForm[1].key = 'data';
    this.datiModelloDocumentale.DatiForm[1].label = 'Del';
    this.datiModelloDocumentale.DatiForm[1].tipo = 'date';
    this.datiModelloDocumentale.DatiForm[1].dimensioneBasic = 12;
    this.datiModelloDocumentale.DatiForm[1].errorMessage = 'Inserisci una data';

    /* IMPORTO NETTO */
    this.datiModelloDocumentale.DatiForm[2] = new Campo();
    this.datiModelloDocumentale.DatiForm[2].key = 'importo';
    this.datiModelloDocumentale.DatiForm[2].label = 'Importo Totale €';
    this.datiModelloDocumentale.DatiForm[2].tipo = 'currency';
    this.datiModelloDocumentale.DatiForm[2].dimensioneBasic = 12;
    this.datiModelloDocumentale.DatiForm[2].errorMessage = 'Inserisci un importo';

    /* ALLEGATO */
    this.datiModelloDocumentale.DatiForm[3] = new Campo();
    this.datiModelloDocumentale.DatiForm[3].tipo = 'allegato';
    this.datiModelloDocumentale.DatiForm[3].dimensioneBasic = 12;

    /* RIFERIMENTO IMPORTO NETTO*/
    this.datiModelloDocumentale.DatiForm[4] = new Campo();
    this.datiModelloDocumentale.DatiForm[4].key = 'fase3Id';
    this.datiModelloDocumentale.DatiForm[4].label = 'Riferimento Liquidazione';
    this.datiModelloDocumentale.DatiForm[4].tipo = 'lista';
    this.datiModelloDocumentale.DatiForm[4].optionLabel = 'text';
    this.datiModelloDocumentale.DatiForm[4].optionValue = 'id';
    this.datiModelloDocumentale.DatiForm[4].lista = [];
    this.datiModelloDocumentale.DatiForm[4].dimensioneBasic = 12;
    this.datiModelloDocumentale.DatiForm[4].errorMessage = 'Seleziona un riferimento';
    this.datiModelloDocumentale.DatiForm[4].placeholder = 'Seleziona un riferimento';

    /* DESCRIZIONE */
    this.datiModelloDocumentale.DatiForm[5] = new Campo();
    this.datiModelloDocumentale.DatiForm[5].key = 'descrizione';
    this.datiModelloDocumentale.DatiForm[5].label = 'Descrizione';
    this.datiModelloDocumentale.DatiForm[5].tipo = 'textarea';
    this.datiModelloDocumentale.DatiForm[5].dimensioneBasic = 12;
    this.datiModelloDocumentale.DatiForm[5].hasAction = true;
    this.datiModelloDocumentale.DatiForm[5].errorMessage = 'Inserisci una descrizione';

    return this.datiModelloDocumentale;

  }

  private createModelloDocumentaleCustomQuietanza(isEditable: boolean = true): ModelloDocumentaleCustom {
    this.datiModelloDocumentale = new ModelloDocumentaleCustom();
    this.datiModelloDocumentale.isFrom = Scenario.Quietanza;

    this.datiModelloDocumentale.DatiForm = [];

    /* RIFERIMENTO IMPORTO NETTO*/
    this.datiModelloDocumentale.DatiForm[0] = new Campo();
    this.datiModelloDocumentale.DatiForm[0].key = 'fase4Id';
    this.datiModelloDocumentale.DatiForm[0].label = 'Riferimento Mandato';
    this.datiModelloDocumentale.DatiForm[0].tipo = 'lista';
    this.datiModelloDocumentale.DatiForm[0].optionLabel = 'text';
    this.datiModelloDocumentale.DatiForm[0].optionValue = 'id';
    this.datiModelloDocumentale.DatiForm[0].lista = [];
    this.datiModelloDocumentale.DatiForm[0].dimensioneBasic = 12;
    this.datiModelloDocumentale.DatiForm[0].errorMessage = 'Seleziona un riferimento';
    this.datiModelloDocumentale.DatiForm[0].placeholder = 'Seleziona un riferimento';

    /* ALLEGATO */
    this.datiModelloDocumentale.DatiForm[1] = new Campo();
    this.datiModelloDocumentale.DatiForm[1].tipo = 'allegato';
    this.datiModelloDocumentale.DatiForm[1].dimensioneBasic = 3;

    return this.datiModelloDocumentale;
  }

  private createModelloDocumentaleCustomAnnotazione(isEditable: boolean = true): ModelloDocumentaleCustom {
    this.datiModelloDocumentale = new ModelloDocumentaleCustom();
    this.datiModelloDocumentale.isFrom = Scenario.Annotazione;

    this.datiModelloDocumentale.DatiForm = [];

    /* DATA */ //dateformat
    this.datiModelloDocumentale.DatiForm[0] = new Campo();
    this.datiModelloDocumentale.DatiForm[0].key = 'data';
    this.datiModelloDocumentale.DatiForm[0].label = 'Del';
    this.datiModelloDocumentale.DatiForm[0].tipo = 'date';
    this.datiModelloDocumentale.DatiForm[0].dimensioneBasic = 12;
    this.datiModelloDocumentale.DatiForm[0].errorMessage = 'Inserisci una data';

    /* DESCRIZIONE */
    this.datiModelloDocumentale.DatiForm[1] = new Campo();
    this.datiModelloDocumentale.DatiForm[1].key = 'descrizione';
    this.datiModelloDocumentale.DatiForm[1].label = 'Descrizione';
    this.datiModelloDocumentale.DatiForm[1].tipo = 'textarea';
    this.datiModelloDocumentale.DatiForm[1].dimensioneBasic = 12;
    this.datiModelloDocumentale.DatiForm[1].errorMessage = 'Inserisci una descrizione';

    /* NOTE */
    this.datiModelloDocumentale.DatiForm[2] = new Campo();
    this.datiModelloDocumentale.DatiForm[2].key = 'nota';
    this.datiModelloDocumentale.DatiForm[2].label = 'Note';
    this.datiModelloDocumentale.DatiForm[2].tipo = 'textarea';
    this.datiModelloDocumentale.DatiForm[2].dimensioneBasic = 12;

    /* ALLEGATO */
    this.datiModelloDocumentale.DatiForm[3] = new Campo();
    this.datiModelloDocumentale.DatiForm[3].tipo = 'allegato';
    this.datiModelloDocumentale.DatiForm[3].dimensioneBasic = 3;

    return this.datiModelloDocumentale;
  }

  private createModelloDocumentaleCustomFonte(isEditable: boolean = true): ModelloDocumentaleCustom {
    this.datiModelloDocumentale = new ModelloDocumentaleCustom();
    this.datiModelloDocumentale.isFrom = Scenario.Fonte;

    this.datiModelloDocumentale.DatiForm = [];

    /* ENTE */
    this.datiModelloDocumentale.DatiForm[0] = new Campo();
    this.datiModelloDocumentale.DatiForm[0].key = 'ente';
    this.datiModelloDocumentale.DatiForm[0].label = 'Ente';
    this.datiModelloDocumentale.DatiForm[0].tipo = 'text';
    this.datiModelloDocumentale.DatiForm[0].dimensioneBasic = 12;
    this.datiModelloDocumentale.DatiForm[0].errorMessage = 'Inserisci un ente';

    /* ATTO */
    this.datiModelloDocumentale.DatiForm[1] = new Campo();
    this.datiModelloDocumentale.DatiForm[1].key = 'atto';
    this.datiModelloDocumentale.DatiForm[1].label = 'Atto';
    this.datiModelloDocumentale.DatiForm[1].tipo = 'text';
    this.datiModelloDocumentale.DatiForm[1].dimensioneBasic = 12;
    //this.datiModelloDocumentale.DatiForm[2].errorMessage = 'Inserisci un atto';

    /* NUMERO */
    this.datiModelloDocumentale.DatiForm[2] = new Campo();
    this.datiModelloDocumentale.DatiForm[2].key = 'numero';
    this.datiModelloDocumentale.DatiForm[2].label = 'Numero';
    this.datiModelloDocumentale.DatiForm[2].tipo = 'text';
    this.datiModelloDocumentale.DatiForm[2].dimensioneBasic = 12;
    this.datiModelloDocumentale.DatiForm[2].errorMessage = "Inserisci il numero dell'atto";

    /* DATA */
    this.datiModelloDocumentale.DatiForm[3] = new Campo();
    this.datiModelloDocumentale.DatiForm[3].key = 'del';
    this.datiModelloDocumentale.DatiForm[3].label = 'Del';
    this.datiModelloDocumentale.DatiForm[3].tipo = 'date';
    this.datiModelloDocumentale.DatiForm[3].dimensioneBasic = 12;
    this.datiModelloDocumentale.DatiForm[3].errorMessage = 'Inserisci una data';

    /* DESCRIZIONE */
    this.datiModelloDocumentale.DatiForm[4] = new Campo();
    this.datiModelloDocumentale.DatiForm[4].key = 'descrizione';
    this.datiModelloDocumentale.DatiForm[4].label = 'Descrizione';
    this.datiModelloDocumentale.DatiForm[4].tipo = 'textarea';
    this.datiModelloDocumentale.DatiForm[4].dimensioneBasic = 12;
    this.datiModelloDocumentale.DatiForm[4].errorMessage = 'Inserisci una descrizione';

    /* IMPORTO */
    this.datiModelloDocumentale.DatiForm[5] = new Campo();
    this.datiModelloDocumentale.DatiForm[5].key = 'importoFinanziamento';
    this.datiModelloDocumentale.DatiForm[5].label = 'Importo €';
    this.datiModelloDocumentale.DatiForm[5].tipo = 'currency';
    this.datiModelloDocumentale.DatiForm[5].dimensioneBasic = 12;
    this.datiModelloDocumentale.DatiForm[5].errorMessage = 'Inserisci un importo';

    /* ALLEGATO */
    this.datiModelloDocumentale.DatiForm[6] = new Campo();
    this.datiModelloDocumentale.DatiForm[6].tipo = 'allegato';
    this.datiModelloDocumentale.DatiForm[6].dimensioneBasic = 12;

    return this.datiModelloDocumentale;
  }

  private createModelloDocumentaleCustomIncasso(isEditable: boolean = true): ModelloDocumentaleCustom {
    this.datiModelloDocumentale = new ModelloDocumentaleCustom();
    this.datiModelloDocumentale.isFrom = Scenario.Incasso;

    this.datiModelloDocumentale.DatiForm = [];

    /* ENTE */
    this.datiModelloDocumentale.DatiForm[0] = new Campo();
    this.datiModelloDocumentale.DatiForm[0].key = 'ente';
    this.datiModelloDocumentale.DatiForm[0].label = 'Ente';
    this.datiModelloDocumentale.DatiForm[0].tipo = 'text';
    this.datiModelloDocumentale.DatiForm[0].dimensioneBasic = 12;
    this.datiModelloDocumentale.DatiForm[0].readonly = true;

    /* ATTO */
    this.datiModelloDocumentale.DatiForm[1] = new Campo();
    this.datiModelloDocumentale.DatiForm[1].key = 'atto';
    this.datiModelloDocumentale.DatiForm[1].label = 'Atto';
    this.datiModelloDocumentale.DatiForm[1].tipo = 'text';
    this.datiModelloDocumentale.DatiForm[1].dimensioneBasic = 12;
    //this.datiModelloDocumentale.DatiForm[2].errorMessage = 'Inserisci un importo';

    /* NUMERO */
    this.datiModelloDocumentale.DatiForm[2] = new Campo();
    this.datiModelloDocumentale.DatiForm[2].key = 'numero';
    this.datiModelloDocumentale.DatiForm[2].label = 'Numero';
    this.datiModelloDocumentale.DatiForm[2].tipo = 'text';
    this.datiModelloDocumentale.DatiForm[2].dimensioneBasic = 12;
    this.datiModelloDocumentale.DatiForm[2].errorMessage = "Inserisci il numero dell'atto";

    /* DATA */
    this.datiModelloDocumentale.DatiForm[3] = new Campo();
    this.datiModelloDocumentale.DatiForm[3].key = 'del';
    this.datiModelloDocumentale.DatiForm[3].label = 'Del';
    this.datiModelloDocumentale.DatiForm[3].tipo = 'date';
    this.datiModelloDocumentale.DatiForm[3].dimensioneBasic = 12;
    this.datiModelloDocumentale.DatiForm[3].errorMessage = 'Inserisci una data';

    /* DESCRIZIONE */
    this.datiModelloDocumentale.DatiForm[4] = new Campo();
    this.datiModelloDocumentale.DatiForm[4].key = 'descrizione';
    this.datiModelloDocumentale.DatiForm[4].label = 'Descrizione';
    this.datiModelloDocumentale.DatiForm[4].tipo = 'textarea';
    this.datiModelloDocumentale.DatiForm[4].dimensioneBasic = 12;
    this.datiModelloDocumentale.DatiForm[4].errorMessage = 'Inserisci una descrizione';

    /* IMPORTO */
    this.datiModelloDocumentale.DatiForm[5] = new Campo();
    this.datiModelloDocumentale.DatiForm[5].key = 'importoIncassato';
    this.datiModelloDocumentale.DatiForm[5].label = 'Importo €';
    this.datiModelloDocumentale.DatiForm[5].tipo = 'currency';
    this.datiModelloDocumentale.DatiForm[5].dimensioneBasic = 12;
    this.datiModelloDocumentale.DatiForm[5].errorMessage = 'Inserisci un importo';

    return this.datiModelloDocumentale;
  }

  private createModelloDocumentaleCustomQuadroEconomico(isEditable: boolean = true): ModelloDocumentaleCustom {
    this.datiModelloDocumentale = new ModelloDocumentaleCustom();
    this.datiModelloDocumentale.isFrom = Scenario.QuadroEconomico;

    this.datiModelloDocumentale.DatiForm = [];

    /* TIPO */
    this.datiModelloDocumentale.DatiForm[0] = new Campo();
    this.datiModelloDocumentale.DatiForm[0].key = 'tipo';
    this.datiModelloDocumentale.DatiForm[0].label = 'Tipo';
    this.datiModelloDocumentale.DatiForm[0].tipo = 'lista';
    this.datiModelloDocumentale.DatiForm[0].optionLabel = 'descrizione';
    this.datiModelloDocumentale.DatiForm[0].optionValue = 'descrizione';
    this.datiModelloDocumentale.DatiForm[0].lista = [];
    this.datiModelloDocumentale.DatiForm[0].dimensioneBasic = 12;
    this.datiModelloDocumentale.DatiForm[0].errorMessage = 'Seleziona un tipo';
    this.datiModelloDocumentale.DatiForm[0].placeholder = 'Seleziona un tipo';

    /* ATTO */
    this.datiModelloDocumentale.DatiForm[1] = new Campo();
    this.datiModelloDocumentale.DatiForm[1].key = 'atto';
    this.datiModelloDocumentale.DatiForm[1].label = 'Atto';
    this.datiModelloDocumentale.DatiForm[1].tipo = 'lista';
    this.datiModelloDocumentale.DatiForm[1].optionLabel = 'descrizione';
    this.datiModelloDocumentale.DatiForm[1].optionValue = 'descrizione';
    this.datiModelloDocumentale.DatiForm[1].lista = [];
    this.datiModelloDocumentale.DatiForm[1].dimensioneBasic = 12;
    this.datiModelloDocumentale.DatiForm[1].errorMessage = 'Seleziona un atto';
    this.datiModelloDocumentale.DatiForm[1].placeholder = 'Seleziona un atto';

    /* NUMERO */
    this.datiModelloDocumentale.DatiForm[2] = new Campo();
    this.datiModelloDocumentale.DatiForm[2].key = 'numero';
    this.datiModelloDocumentale.DatiForm[2].label = 'Numero';
    this.datiModelloDocumentale.DatiForm[2].tipo = 'number';
    this.datiModelloDocumentale.DatiForm[2].dimensioneBasic = 12;
    this.datiModelloDocumentale.DatiForm[2].errorMessage = "Inserisci il numero dell'atto";

    /* DATA */
    this.datiModelloDocumentale.DatiForm[3] = new Campo();
    this.datiModelloDocumentale.DatiForm[3].key = 'data';
    this.datiModelloDocumentale.DatiForm[3].label = 'Del';
    this.datiModelloDocumentale.DatiForm[3].tipo = 'date';
    this.datiModelloDocumentale.DatiForm[3].dimensioneBasic = 12;
    this.datiModelloDocumentale.DatiForm[3].errorMessage = 'Inserisci una data';

    /* ALLEGATO */
    this.datiModelloDocumentale.DatiForm[4] = new Campo();
    this.datiModelloDocumentale.DatiForm[4].tipo = 'allegato';
    this.datiModelloDocumentale.DatiForm[4].dimensioneBasic = 12;

    /* DESCRIZIONE */
    this.datiModelloDocumentale.DatiForm[5] = new Campo();
    this.datiModelloDocumentale.DatiForm[5].key = 'descrizione';
    this.datiModelloDocumentale.DatiForm[5].label = 'Descrizione';
    this.datiModelloDocumentale.DatiForm[5].tipo = 'textarea';
    this.datiModelloDocumentale.DatiForm[5].dimensioneBasic = 12;
    this.datiModelloDocumentale.DatiForm[5].errorMessage = 'Inserisci una descrizione';

    /* ID */
    this.datiModelloDocumentale.DatiForm[6] = new Campo();
    this.datiModelloDocumentale.DatiForm[6].key = 'id';
    this.datiModelloDocumentale.DatiForm[6].tipo = 'hidden';
    /* CUPID */
    this.datiModelloDocumentale.DatiForm[7] = new Campo();
    this.datiModelloDocumentale.DatiForm[7].key = 'cupId';
    this.datiModelloDocumentale.DatiForm[7].tipo = 'hidden';
    /* QEMAINID */
    this.datiModelloDocumentale.DatiForm[8] = new Campo();
    this.datiModelloDocumentale.DatiForm[8].key = 'qeMainId';
    this.datiModelloDocumentale.DatiForm[8].tipo = 'hidden';

    return this.datiModelloDocumentale;
  }

  private createModelloDocumentaleCustomDocumento(): ModelloDocumentaleCustom {

    this.datiModelloDocumentale = new ModelloDocumentaleCustom();
    this.datiModelloDocumentale.isFrom = Scenario.Documento;

    this.datiModelloDocumentale.DatiForm = [];

    /* NOME MODELLO */
    this.datiModelloDocumentale.DatiForm[0] = new Campo();
    this.datiModelloDocumentale.DatiForm[0].key = 'nomeModello';
    this.datiModelloDocumentale.DatiForm[0].label = 'Nome Modello';
    this.datiModelloDocumentale.DatiForm[0].tipo = 'lista';
    this.datiModelloDocumentale.DatiForm[0].lista = [];
    this.datiModelloDocumentale.DatiForm[0].dimensioneBasic = 6;
    this.datiModelloDocumentale.DatiForm[0].optionLabel = 'nomeModello';
    this.datiModelloDocumentale.DatiForm[0].optionValue = 'nomeModello';
    this.datiModelloDocumentale.DatiForm[0].classeCss = 'p-dropdown-documenti';
    this.datiModelloDocumentale.DatiForm[0].errorMessage = 'Seleziona un modello';
    this.datiModelloDocumentale.DatiForm[0].placeholder = 'Seleziona un modello';

    /* TIPO ATTO */
    this.datiModelloDocumentale.DatiForm[1] = new Campo();
    this.datiModelloDocumentale.DatiForm[1].key = 'tipologia';
    this.datiModelloDocumentale.DatiForm[1].label = 'Tipo Atto';
    this.datiModelloDocumentale.DatiForm[1].tipo = 'lista';
    this.datiModelloDocumentale.DatiForm[1].lista = [];
    this.datiModelloDocumentale.DatiForm[1].dimensioneBasic = 6;
    this.datiModelloDocumentale.DatiForm[1].optionLabel = 'tipo';
    this.datiModelloDocumentale.DatiForm[1].optionValue = 'tipo';
    this.datiModelloDocumentale.DatiForm[1].classeCss = 'p-dropdown-documenti';
    this.datiModelloDocumentale.DatiForm[1].errorMessage = 'Seleziona un atto';
    this.datiModelloDocumentale.DatiForm[1].placeholder = 'Seleziona un atto';

    /* DEL */
    this.datiModelloDocumentale.DatiForm[2] = new Campo();
    this.datiModelloDocumentale.DatiForm[2].key = 'data';
    this.datiModelloDocumentale.DatiForm[2].label = 'Del';
    this.datiModelloDocumentale.DatiForm[2].tipo = 'date';
    this.datiModelloDocumentale.DatiForm[2].dimensioneBasic = 12;
    this.datiModelloDocumentale.DatiForm[2].classeCss = 'carica-file-p-calendar';
    this.datiModelloDocumentale.DatiForm[2].errorMessage = 'Inserisci una data';

    /* NUMERO */
    this.datiModelloDocumentale.DatiForm[3] = new Campo();
    this.datiModelloDocumentale.DatiForm[3].key = 'numero';
    this.datiModelloDocumentale.DatiForm[3].label = 'Numero';
    this.datiModelloDocumentale.DatiForm[3].tipo = 'text';
    this.datiModelloDocumentale.DatiForm[3].dimensioneBasic = 12;
    this.datiModelloDocumentale.DatiForm[3].errorMessage = "Inserisci il numero dell'atto";

    /* DESCRIZIONE */
    this.datiModelloDocumentale.DatiForm[4] = new Campo();
    this.datiModelloDocumentale.DatiForm[4].key = 'descrizione';
    this.datiModelloDocumentale.DatiForm[4].label = 'Descrizione';
    this.datiModelloDocumentale.DatiForm[4].tipo = 'textarea';
    this.datiModelloDocumentale.DatiForm[4].dimensioneBasic = 12;
    this.datiModelloDocumentale.DatiForm[4].errorMessage = 'Inserisci una descrizione';

    /* NOTE */
    this.datiModelloDocumentale.DatiForm[5] = new Campo();
    this.datiModelloDocumentale.DatiForm[5].key = 'note';
    this.datiModelloDocumentale.DatiForm[5].label = 'Note';
    this.datiModelloDocumentale.DatiForm[5].tipo = 'textarea';
    this.datiModelloDocumentale.DatiForm[5].dimensioneBasic = 12;

    return this.datiModelloDocumentale;
  }


  cf_piPatternValidator(pattern1: RegExp, pattern2: RegExp): ValidatorFn {
    return (control: FormControl) => {
      const value = control.value;
      const isValid = pattern1.test(value) || pattern2.test(value);
      return isValid ? null : { patternMismatch: true };
    };
  }

}
